import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import styled, {css} from "styled-components" // eslint-disable-line
import {motion, useViewportScroll, useTransform, AnimatePresence} from "framer-motion" // eslint-disable-line

import * as selectors from "../../data/selectors"

import CIcon from "../../view/components/CIcon"
import CLink from "../../view/components/CLink"

const DEBUG = false && __DEV__
import {debbify} from "../../data/selectors/helpers"
const debby = (...args) => debbify("CUmbrellaContactButton", ...args)

class CUmbrellaContactButton extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    label: PropTypes.string.isRequired,
    align: PropTypes.oneOf(["left", "center"]),
    color: PropTypes.string,
    colorCaret: PropTypes.string,
    linkTo: PropTypes.string,
    linkHash: PropTypes.string,
    variant: PropTypes.oneOf(["default", "umbrella-portfoliolink"]),
    mediaQueryClass: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
  }
  static defaultProps = {
    align: "center",
    color: "#1e1e1e",
    colorCaret: "white",
    linkTo: "/contact",
    linkHash: "#form",
    variant: "default",
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  render = () => {
    const {mediaQueryClass, align, label, color, colorCaret, linkTo, linkHash, variant} = this.props
    const d = {debug: DEBUG || this.props.debug}
    const isLg = selectors.getMediaQueryMatching(mediaQueryClass, "==", "lg")
    const isMd = selectors.getMediaQueryMatching(mediaQueryClass, "==", "md")
    const iconScale = isMd ? 0.6 : isLg ? 0.8 : 1
    debby("render()", {mediaQueryClass, label, isMd, iconScale})
    return (
      <Container {...d} align={align}>
        <CLink to={linkTo} hash={linkHash}>
          <motion.div
          // whileHover={{scale: !!linkTo ? 1.05 : 1}}
          //
          >
            <Button align={align} variant={variant}>
              <Label color={color} variant={variant}>
                {label}
              </Label>
              <Icon>
                <CIcon id={`RoundedCaretRight`} color={color} colorSecondary={colorCaret} scale={iconScale} />
              </Icon>
            </Button>
          </motion.div>
        </CLink>
      </Container>
    )
  }
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${props => props.align};
  border: ${props => (props.debug ? 1 : 0)}px solid red;
  ${props => selectors.getBackgroundColorByProps(props, "rgba(255,255,0,0.75)")}
`

const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: ${props => (props.debug ? 1 : 0)}px solid red;

  padding: 10px 20px;
  ${props =>
    props.align == "left" &&
    css`
      padding: 10px 20px 10px 0px;
    `}
  ${props =>
    props.variant == "umbrella-portfoliolink" &&
    css`
      padding: 5px 10px 0px 0px;
    `}
`

const Label = styled.div`
  line-height: 1.4em;
  color: ${props => props.color};
  letter-spacing: 0.1em;
  text-align: left;
  font-weight: ${props => props.theme.vars.fonts.weights.semibold};

  font-size: 20px;
  ${props =>
    props.variant == "umbrella-portfoliolink" &&
    css`
      ${props.theme.media.lg} {
        font-size: 16px;
      }
      ${props.theme.media.md} {
        font-size: 14px;
      }
    `}
`

const Icon = styled.div`
  align-self: stretch;
  display: flex;
  width: 26px;
  transform: translate3d(0px, 2px, 0px);
  justify-content: center; // vcenter
  background-color: ${props => (props.debug ? "yellow" : "none")};

  margin-left: 10px;
  ${props =>
    props.variant == "umbrella-portfoliolink" &&
    css`
      ${props.theme.media.md} {
        margin-left: 5px;
      }
    `}
`

export default CUmbrellaContactButton
