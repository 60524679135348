import {createGlobalStyle} from "styled-components"

// const colorTabsActive = "#108ee9" // default
const colorTabsActive = "black"
const colorTabsHover = "black"

const GlobalStyle = createGlobalStyle`

  .my-masonry-grid {
    display: flex;
    // margin-left: -30px;
    width: auto;
  }
  .my-masonry-grid_column {
    // padding-left: 30px; /* gutter size */
    background-clip: padding-box;
  }

  ///////////////////////////////////////////////////////////////////////////////
  //  rc-tabs

  .rc-tabs {
    box-sizing: border-box;
    position: relative;
    overflow: hidden;

    // custom
    font-family: "Raleway";
    // letter-spacing: 0.2em;
    // text-transform: uppercase;
  }
  .rc-tabs-bar,
  .rc-tabs-nav-container {
    font-size: 14px;
    line-height: 1.5;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    white-space: nowrap;
    outline: none;
    zoom: 1;
    transition: padding .45s;
  }
  .rc-tabs-ink-bar {
    z-index: 1;
    position: absolute;
    box-sizing: border-box;
    margin-top: -3px;
    background-color: ${colorTabsActive};
    transform-origin: 0 0;
    width: 0;
    height: 0;
  }
  .rc-tabs-ink-bar-animated {
    transition: transform 0.3s cubic-bezier(0.35, 0, 0.25, 1), left 0.3s cubic-bezier(0.35, 0, 0.25, 1), top 0.3s cubic-bezier(0.35, 0, 0.25, 1), height 0.3s cubic-bezier(0.35, 0, 0.25, 1), width 0.3s cubic-bezier(0.35, 0, 0.25, 1);
  }
  .rc-tabs-tab-prev,
  .rc-tabs-tab-next {
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    z-index: 1;
    line-height: 36px;
    cursor: pointer;
    border: none;
    background-color: transparent;
    position: absolute;
  }
  .rc-tabs-tab-prev-icon,
  .rc-tabs-tab-next-icon {
    position: relative;
    display: inline-block;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    line-height: inherit;
    vertical-align: baseline;
    text-align: center;
    text-transform: none;
    font-smoothing: antialiased;
    text-stroke-width: 0;
    font-family: sans-serif;
  }
  .rc-tabs-tab-prev-icon:before,
  .rc-tabs-tab-next-icon:before {
    display: block;
  }
  .rc-tabs-tab-btn-disabled {
    cursor: default;
    color: #ccc;
  }
  .rc-tabs-nav-wrap {
    overflow: hidden;
  }
  .rc-tabs-nav {
    box-sizing: border-box;
    padding-left: 0;
    position: relative;
    margin: 0;
    float: left;
    list-style: none;
    display: inline-block;
    transform-origin: 0 0;
  }
  .rc-tabs-nav-animated {
    transition: transform 0.5s cubic-bezier(0.35, 0, 0.25, 1);
  }
  .rc-tabs-nav:before,
  .rc-tabs-nav:after {
    display: table;
    content: " ";
  }
  .rc-tabs-nav:after {
    clear: both;
  }
  .rc-tabs-tab {
    box-sizing: border-box;
    position: relative;
    display: block;
    transition: color 0.3s cubic-bezier(0.35, 0, 0.25, 1);
    padding: 8px 20px;
    cursor: pointer;

    font-weight: 500;
    letter-spacing: 0.2em;
    text-transform: uppercase;
  }
  .rc-tabs-tab:hover {
    color: ${colorTabsHover};
  }
  .rc-tabs-tab-active,
  .rc-tabs-tab-active:hover {
    color: ${colorTabsActive};
    cursor: default;
    transform: translateZ(0);
  }
  .rc-tabs-tab-disabled {
    cursor: default;
    color: #ccc;
  }
  .rc-tabs-tab-disabled:hover {
    color: #ccc;
  }
  .rc-tabs-content {
    zoom: 1;
  }
  .rc-tabs-content .rc-tabs-tabpane {
    overflow: auto;
  }
  .rc-tabs-content-animated {
    transition: transform 0.3s cubic-bezier(0.35, 0, 0.25, 1), margin-left 0.3s cubic-bezier(0.35, 0, 0.25, 1), margin-top 0.3s cubic-bezier(0.35, 0, 0.25, 1);
    display: -ms-flexbox;
    display: flex;
    will-change: transform;
  }
  .rc-tabs-content-animated .rc-tabs-tabpane {
    -ms-flex-negative: 0;
        flex-shrink: 0;
  }
  .no-flexbox .rc-tabs-content {
    transform: none !important;
    overflow: auto;
  }
  .no-csstransitions .rc-tabs-tabpane-inactive,
  .no-flexbox .rc-tabs-tabpane-inactive,
  .rc-tabs-content-no-animated .rc-tabs-tabpane-inactive {
    display: none;
  }
  .rc-tabs-left {
    border-right: 2px solid #f3f3f3;
  }
  .rc-tabs-left .rc-tabs-bar {
    float: left;
    height: 100%;
    margin-right: 10px;
    border-right: 1px solid #f3f3f3;
  }
  .rc-tabs-left .rc-tabs-nav-container {
    height: 100%;
  }
  .rc-tabs-left .rc-tabs-nav-container-scrolling {
    padding-top: 32px;
    padding-bottom: 32px;
  }
  .rc-tabs-left .rc-tabs-nav-wrap {
    height: 100%;
  }
  .rc-tabs-left .rc-tabs-content-animated {
    -ms-flex-direction: column;
        flex-direction: column;
  }
  .rc-tabs-left .rc-tabs-content-animated .rc-tabs-tabpane {
    height: 100%;
  }
  .rc-tabs-left .rc-tabs-nav-scroll {
    height: 99999px;
  }
  .rc-tabs-left .rc-tabs-nav-swipe {
    position: relative;
    top: 0;
  }
  .rc-tabs-left .rc-tabs-nav-swipe .rc-tabs-nav {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1;
        flex: 1;
    -ms-flex-direction: column;
        flex-direction: column;
    height: 100%;
  }
  .rc-tabs-left .rc-tabs-nav-swipe .rc-tabs-nav .rc-tabs-tab {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    -ms-flex-pack: center;
        justify-content: center;
  }
  .rc-tabs-left .rc-tabs-tab-prev,
  .rc-tabs-left .rc-tabs-tab-next {
    margin-top: -2px;
    height: 0;
    line-height: 32px;
    width: 0;
    display: block;
    text-align: center;
    opacity: 0;
    transition: width .3s, height .3s, opacity .3s;
  }
  .rc-tabs-top .rc-tabs-tab-arrow-show,
  .rc-tabs-left .rc-tabs-tab-arrow-show,
  .rc-tabs-bottom .rc-tabs-tab-arrow-show,
  .rc-tabs-right .rc-tabs-tab-arrow-show {
    opacity: 1;
    width: 100%;
    height: 32px;
  }
  .rc-tabs-left .rc-tabs-tab-next {
    bottom: 0;
  }
  .rc-tabs-left .rc-tabs-tab-next-icon {
    transform: rotate(90deg);
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
  }
  .rc-tabs-left .rc-tabs-tab-next-icon:before {
    content: ">";
  }
  .rc-tabs-left .rc-tabs-tab-prev {
    top: 2px;
  }
  .rc-tabs-left .rc-tabs-tab-prev-icon {
    transform: rotate(270deg);
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  }
  .rc-tabs-left .rc-tabs-tab-prev-icon:before {
    content: ">";
  }
  .rc-tabs-left .rc-tabs-ink-bar {
    width: 2px;
    right: 0;
    top: 0;
  }
  .rc-tabs-left .rc-tabs-tab {
    padding: 16px 24px;
  }
  .rc-tabs-right {
    border-left: 2px solid #f3f3f3;
  }
  .rc-tabs-right .rc-tabs-bar {
    float: right;
    height: 100%;
    margin-left: 10px;
    border-left: 1px solid #f3f3f3;
  }
  .rc-tabs-right .rc-tabs-nav-container {
    height: 100%;
  }
  .rc-tabs-right .rc-tabs-nav-container-scrolling {
    padding-top: 32px;
    padding-bottom: 32px;
  }
  .rc-tabs-right .rc-tabs-nav-wrap {
    height: 100%;
  }
  .rc-tabs-right .rc-tabs-nav-scroll {
    height: 99999px;
  }
  .rc-tabs-right .rc-tabs-nav-swipe {
    position: relative;
  }
  .rc-tabs-right .rc-tabs-nav-swipe .rc-tabs-nav {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1;
        flex: 1;
    -ms-flex-direction: column;
        flex-direction: column;
    height: 100%;
  }
  .rc-tabs-right .rc-tabs-nav-swipe .rc-tabs-nav .rc-tabs-tab {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    -ms-flex-pack: center;
        justify-content: center;
  }
  .rc-tabs-right .rc-tabs-tab-prev,
  .rc-tabs-right .rc-tabs-tab-next {
    margin-top: -2px;
    height: 0;
    width: 0;
    display: block;
    text-align: center;
    line-height: 32px;
    opacity: 0;
    transition: width .3s, height .3s, opacity .3s;
  }
  .rc-tabs-top .rc-tabs-tab-arrow-show {
    opacity: 1;
    width: 100%;
    height: 32px;
  }
  .rc-tabs-right .rc-tabs-tab-next {
    bottom: 0;
  }
  .rc-tabs-right .rc-tabs-tab-next-icon {
    transform: rotate(90deg);
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
  }
  .rc-tabs-right .rc-tabs-tab-next-icon:before {
    content: ">";
  }
  .rc-tabs-right .rc-tabs-tab-prev {
    top: 2px;
  }
  .rc-tabs-right .rc-tabs-tab-prev-icon {
    transform: rotate(270deg);
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  }
  .rc-tabs-right .rc-tabs-tab-prev-icon:before {
    content: ">";
  }
  .rc-tabs-right .rc-tabs-content-animated {
    -ms-flex-direction: column;
        flex-direction: column;
  }
  .rc-tabs-right .rc-tabs-content-animated .rc-tabs-tabpane {
    height: 100%;
  }
  .rc-tabs-right .rc-tabs-ink-bar {
    width: 2px;
    left: 0;
    top: 0;
  }
  .rc-tabs-right .rc-tabs-tab {
    padding: 16px 24px;
  }
  .rc-tabs-bottom {
    border-top: 2px solid #f3f3f3;
  }
  .rc-tabs-bottom .rc-tabs-content {
    width: 100%;
  }
  .rc-tabs-bottom .rc-tabs-bar {
    border-top: 1px solid #f3f3f3;
  }
  .rc-tabs-bottom .rc-tabs-nav-container-scrolling {
    padding-left: 32px;
    padding-right: 32px;
  }
  .rc-tabs-bottom .rc-tabs-nav-scroll {
    width: 99999px;
  }
  .rc-tabs-bottom .rc-tabs-nav-swipe {
    position: relative;
    left: 0;
  }
  .rc-tabs-bottom .rc-tabs-nav-swipe .rc-tabs-nav {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1;
        flex: 1;
    width: 100%;
  }
  .rc-tabs-bottom .rc-tabs-nav-swipe .rc-tabs-nav .rc-tabs-tab {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    margin-right: 0;
    padding: 8px 0;
    -ms-flex-pack: center;
        justify-content: center;
  }
  .rc-tabs-bottom .rc-tabs-nav-wrap {
    width: 100%;
  }
  .rc-tabs-bottom .rc-tabs-content-animated {
    -ms-flex-direction: row;
        flex-direction: row;
  }
  .rc-tabs-bottom .rc-tabs-content-animated .rc-tabs-tabpane {
    width: 100%;
  }
  .rc-tabs-bottom .rc-tabs-tab-next {
    right: 2px;
  }
  .rc-tabs-bottom .rc-tabs-tab-next-icon:before {
    content: ">";
  }
  .rc-tabs-bottom .rc-tabs-tab-prev {
    left: 0;
  }
  .rc-tabs-bottom .rc-tabs-tab-prev-icon:before {
    content: "<";
  }
  .rc-tabs-bottom .rc-tabs-tab-prev,
  .rc-tabs-bottom .rc-tabs-tab-next {
    margin-right: -2px;
    width: 32px;
    height: 100%;
    top: 0;
    text-align: center;
  }
  .rc-tabs-bottom .rc-tabs-ink-bar {
    height: 2px;
    top: 3px;
    left: 0;
  }
  .rc-tabs-bottom .rc-tabs-tab {
    float: left;
    height: 100%;
    margin-right: 30px;
  }
  .rc-tabs-bottom .rc-tabs-tabpane-inactive {
    height: 0;
    overflow: visible;
  }
  .rc-tabs-top {
    border-bottom: 2px solid #f3f3f3;
  }
  .rc-tabs-top .rc-tabs-content {
    width: 100%;
  }
  .rc-tabs-top .rc-tabs-bar {
    border-bottom: 1px solid #f3f3f3;
  }
  .rc-tabs-top .rc-tabs-nav-container-scrolling {
    padding-left: 32px;
    padding-right: 32px;
  }
  .rc-tabs-top .rc-tabs-nav-scroll {
    width: 99999px;
  }
  .rc-tabs-top .rc-tabs-nav-swipe {
    position: relative;
    left: 0;
  }
  .rc-tabs-top .rc-tabs-nav-swipe .rc-tabs-nav {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1;
        flex: 1;
    width: 100%;
  }
  .rc-tabs-top .rc-tabs-nav-swipe .rc-tabs-nav .rc-tabs-tab {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    margin-right: 0;
    padding: 8px 0;
    -ms-flex-pack: center;
        justify-content: center;
  }
  .rc-tabs-top .rc-tabs-nav-wrap {
    width: 100%;
  }
  .rc-tabs-top .rc-tabs-content-animated {
    -ms-flex-direction: row;
        flex-direction: row;
  }
  .rc-tabs-top .rc-tabs-content-animated .rc-tabs-tabpane {
    width: 100%;
  }
  .rc-tabs-top .rc-tabs-tab-next {
    right: 2px;
  }
  .rc-tabs-top .rc-tabs-tab-next-icon:before {
    content: ">";
  }
  .rc-tabs-top .rc-tabs-tab-prev {
    left: 0;
  }
  .rc-tabs-top .rc-tabs-tab-prev-icon:before {
    content: "<";
  }
  .rc-tabs-top .rc-tabs-tab-prev,
  .rc-tabs-top .rc-tabs-tab-next {
    margin-right: -2px;
    width: 0;
    height: 0;
    top: 0;
    text-align: center;
    opacity: 0;
    transition: width .3s, height .3s, opacity .3s;
  }
  .rc-tabs-top .rc-tabs-tab-arrow-show {
    opacity: 1;
    width: 32px;
    height: 100%;
  }
  .rc-tabs-top .rc-tabs-ink-bar {
    height: 2px;
    bottom: 0;
    left: 0;
  }
  .rc-tabs-top .rc-tabs-tab {
    float: left;
    height: 100%;
    margin-right: 30px;
  }
  .rc-tabs-top .rc-tabs-tabpane-inactive {
    height: 0;
    overflow: visible;
  }


  ///////////////////////////////////////////////////////////////////////////////
  //  react-image-lightbox
  
  @keyframes closeWindow {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  .ril__outer {
    background-color: rgba(255, 255, 255, 0.85);
    outline: none;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 30000;
    width: 100%;
    height: 100%;
    -ms-content-zooming: none;
    -ms-user-select: none;
    -ms-touch-select: none;
    touch-action: none;
  }

  .ril__outerClosing {
    opacity: 0;
  }

  .ril__inner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .ril__image,
  .ril__imagePrev,
  .ril__imageNext {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    max-width: none;
    -ms-content-zooming: none;
    -ms-user-select: none;
    -ms-touch-select: none;
    touch-action: none;
  }

  .ril__imageDiscourager {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  .ril__navButtons {
    border: none;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 20px;
    height: 34px;
    padding: 40px 30px;
    margin: auto;
    cursor: pointer;
    opacity: 0.7;
  }
  .ril__navButtons:hover {
    opacity: 1;
  }
  .ril__navButtons:active {
    opacity: 0.7;
  }

  .ril__navButtonPrev {
    left: 0;
    background: rgba(0, 0, 0, 0.2)
      url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjM0Ij48cGF0aCBkPSJtIDE5LDMgLTIsLTIgLTE2LDE2IDE2LDE2IDEsLTEgLTE1LC0xNSAxNSwtMTUgeiIgZmlsbD0iI0ZGRiIvPjwvc3ZnPg==')
      no-repeat center;
  }

  .ril__navButtonNext {
    right: 0;
    background: rgba(0, 0, 0, 0.2)
      url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjM0Ij48cGF0aCBkPSJtIDEsMyAyLC0yIDE2LDE2IC0xNiwxNiAtMSwtMSAxNSwtMTUgLTE1LC0xNSB6IiBmaWxsPSIjRkZGIi8+PC9zdmc+')
      no-repeat center;
  }

  .ril__downloadBlocker {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7');
    background-size: cover;
  }

  .ril__caption,
  .ril__toolbar {
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
  }

  .ril__caption {
    bottom: 0;
    max-height: 150px;
    overflow: auto;
  }

  .ril__captionContent {
    padding: 10px 20px;
    color: #fff;
  }

  .ril__toolbar {
    top: 0;
    height: 50px;
  }

  .ril__toolbarSide {
    height: 50px;
    margin: 0;
  }

  .ril__toolbarLeftSide {
    padding-left: 20px;
    padding-right: 0;
    flex: 0 1 auto;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ril__toolbarRightSide {
    padding-left: 0;
    padding-right: 20px;
    flex: 0 0 auto;
  }

  .ril__toolbarItem {
    display: inline-block;
    line-height: 50px;
    padding: 0;
    color: #fff;
    font-size: 120%;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .ril__toolbarItemChild {
    vertical-align: middle;
  }

  .ril__builtinButton {
    width: 40px;
    height: 35px;
    cursor: pointer;
    border: none;
    opacity: 0.7;
  }
  .ril__builtinButton:hover {
    opacity: 1;
  }
  .ril__builtinButton:active {
    outline: none;
  }

  .ril__builtinButtonDisabled {
    cursor: default;
    opacity: 0.5;
  }
  .ril__builtinButtonDisabled:hover {
    opacity: 0.5;
  }

  .ril__closeButton {
    background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIj48cGF0aCBkPSJtIDEsMyAxLjI1LC0xLjI1IDcuNSw3LjUgNy41LC03LjUgMS4yNSwxLjI1IC03LjUsNy41IDcuNSw3LjUgLTEuMjUsMS4yNSAtNy41LC03LjUgLTcuNSw3LjUgLTEuMjUsLTEuMjUgNy41LC03LjUgLTcuNSwtNy41IHoiIGZpbGw9IiNGRkYiLz48L3N2Zz4=')
      no-repeat center;
  }

  .ril__zoomInButton {
    background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCI+PGcgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCI+PHBhdGggZD0iTTEgMTlsNi02Ii8+PHBhdGggZD0iTTkgOGg2Ii8+PHBhdGggZD0iTTEyIDV2NiIvPjwvZz48Y2lyY2xlIGN4PSIxMiIgY3k9IjgiIHI9IjciIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLXdpZHRoPSIyIi8+PC9zdmc+')
      no-repeat center;
  }

  .ril__zoomOutButton {
    background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCI+PGcgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCI+PHBhdGggZD0iTTEgMTlsNi02Ii8+PHBhdGggZD0iTTkgOGg2Ii8+PC9nPjxjaXJjbGUgY3g9IjEyIiBjeT0iOCIgcj0iNyIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiLz48L3N2Zz4=')
      no-repeat center;
  }

  .ril__outerAnimating {
    animation-name: closeWindow;
  }

  @keyframes pointFade {
    0%,
    19.999%,
    100% {
      opacity: 0;
    }
    20% {
      opacity: 1;
    }
  }

  .ril__loadingCircle {
    width: 60px;
    height: 60px;
    position: relative;
  }

  .ril__loadingCirclePoint {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
  .ril__loadingCirclePoint::before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 11%;
    height: 30%;
    background-color: #fff;
    border-radius: 30%;
    animation: pointFade 800ms infinite ease-in-out both;
  }
  .ril__loadingCirclePoint:nth-of-type(1) {
    transform: rotate(0deg);
  }
  .ril__loadingCirclePoint:nth-of-type(7) {
    transform: rotate(180deg);
  }
  .ril__loadingCirclePoint:nth-of-type(1)::before,
  .ril__loadingCirclePoint:nth-of-type(7)::before {
    animation-delay: -800ms;
  }
  .ril__loadingCirclePoint:nth-of-type(2) {
    transform: rotate(30deg);
  }
  .ril__loadingCirclePoint:nth-of-type(8) {
    transform: rotate(210deg);
  }
  .ril__loadingCirclePoint:nth-of-type(2)::before,
  .ril__loadingCirclePoint:nth-of-type(8)::before {
    animation-delay: -666ms;
  }
  .ril__loadingCirclePoint:nth-of-type(3) {
    transform: rotate(60deg);
  }
  .ril__loadingCirclePoint:nth-of-type(9) {
    transform: rotate(240deg);
  }
  .ril__loadingCirclePoint:nth-of-type(3)::before,
  .ril__loadingCirclePoint:nth-of-type(9)::before {
    animation-delay: -533ms;
  }
  .ril__loadingCirclePoint:nth-of-type(4) {
    transform: rotate(90deg);
  }
  .ril__loadingCirclePoint:nth-of-type(10) {
    transform: rotate(270deg);
  }
  .ril__loadingCirclePoint:nth-of-type(4)::before,
  .ril__loadingCirclePoint:nth-of-type(10)::before {
    animation-delay: -400ms;
  }
  .ril__loadingCirclePoint:nth-of-type(5) {
    transform: rotate(120deg);
  }
  .ril__loadingCirclePoint:nth-of-type(11) {
    transform: rotate(300deg);
  }
  .ril__loadingCirclePoint:nth-of-type(5)::before,
  .ril__loadingCirclePoint:nth-of-type(11)::before {
    animation-delay: -266ms;
  }
  .ril__loadingCirclePoint:nth-of-type(6) {
    transform: rotate(150deg);
  }
  .ril__loadingCirclePoint:nth-of-type(12) {
    transform: rotate(330deg);
  }
  .ril__loadingCirclePoint:nth-of-type(6)::before,
  .ril__loadingCirclePoint:nth-of-type(12)::before {
    animation-delay: -133ms;
  }
  .ril__loadingCirclePoint:nth-of-type(7) {
    transform: rotate(180deg);
  }
  .ril__loadingCirclePoint:nth-of-type(13) {
    transform: rotate(360deg);
  }
  .ril__loadingCirclePoint:nth-of-type(7)::before,
  .ril__loadingCirclePoint:nth-of-type(13)::before {
    animation-delay: 0ms;
  }

  .ril__loadingContainer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .ril__imagePrev .ril__loadingContainer,
  .ril__imageNext .ril__loadingContainer {
    display: none;
  }

  .ril__errorContainer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
  }
  .ril__imagePrev .ril__errorContainer,
  .ril__imageNext .ril__errorContainer {
    display: none;
  }

  .ril__loadingContainer__icon {
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }

`
export default GlobalStyle
