import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import styled, {css} from "styled-components"

import * as selectors from "../../../data/selectors"

import CIcon from "../../../view/components/CIcon"
import CSpacer from "../../../view/components/CSpacer"
import CImageFaded from "../../../view/components/CImageFaded"

import {basicTheme} from "../../../view/Theme"

import CH2 from "../../../view/components/text/CH2"

const DEBUG = false && __DEV__
import {debbify} from "../../../data/selectors/helpers"
const debby = (...args) => debbify("CPortfolioDetailsHeroOverlay", ...args)

class CPortfolioDetailsHeroOverlay extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    relative: PropTypes.bool,
    header: PropTypes.string.isRequired,
    subheader: PropTypes.string.isRequired,
    features: PropTypes.array,
    mediaQueryClass: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
    uncheckedFeaturedPostfix: PropTypes.string,
    sectionKey: PropTypes.oneOf(["art-galleries", "art-institutions", "artists"]),
    fadedImages: PropTypes.array,
  }
  static defaultProps = {
    debug: DEBUG,
    relative: true,
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  renderFeatureItem = (item, i) => {
    const {uncheckedFeaturedPostfix} = this.props
    const d = {debug: DEBUG || this.props.debug}
    const {checked, feature} = item
    const iconId = checked ? "Feature" : "FeatureUnchecked"
    const postfix = !checked && !!uncheckedFeaturedPostfix ? ` ${uncheckedFeaturedPostfix}` : ``
    debby("renderFeaturesUnderSubheader()", {checked, feature})
    return (
      <FeatureContainer {...d} key={`feature_${i}`} isChecked={checked}>
        <FeatureIconContainer {...d}>
          <CIcon id={iconId} color={basicTheme.vars.colors.ciCyan} />
        </FeatureIconContainer>
        <FeatureTextContainer {...d}>
          {feature}
          {postfix}
        </FeatureTextContainer>
      </FeatureContainer>
    )
  }

  render = () => {
    const {header, subheader, features, relative, mediaQueryClass, sectionKey, fadedImages} = this.props
    const d = {debug: DEBUG || this.props.debug}
    if (!features) {
      return <div />
    }
    let renderFeaturesUnderSubheader = true
    if (mediaQueryClass == "lg" || mediaQueryClass == "md") {
      renderFeaturesUnderSubheader = false
    }
    let extraTransforms = [`scale(1.1) translate(-3%,5%)`]
    if (mediaQueryClass == "lg") {
      extraTransforms = [`scale(1.05) translate(-1%,0%)`]
    } else if (mediaQueryClass == "md") {
      extraTransforms = [`scale(1.01)`]
    }
    debby("render()", {fadedImages})
    return (
      <PaddedContainer relative={relative} {...d} variant={"header"} {...d}>
        <CSpacer {...d} variant={"headermenu"} />
        <TwoPartContent {...d}>
          <ContentText {...d}>
            <ContentTextRespectingImage>
              <HeaderContainer {...d}>
                <CH2 text={header} />
              </HeaderContainer>
              <SubheaderContainer {...d}>{subheader}</SubheaderContainer>
              {renderFeaturesUnderSubheader && <FeaturesContainer {...d}>{features.map(this.renderFeatureItem)}</FeaturesContainer>}
            </ContentTextRespectingImage>
          </ContentText>

          <ContentImage {...d}>
            <ImageFadedResponsive>
              <CImageFaded
                //  params
                {...d}
                sectionKey={selectors.getCC(`PortfolioDetailsHeroOverlay-${sectionKey}`)} // e.g. "PortfolioDetailsHeroOverlayArtGalleries"
                sourcesWp={fadedImages}
                baseTransform={`translate(1%,-3%) scale(1.48)`} // so if fits its container
                extraTransforms={extraTransforms} // depending on layout
              />
            </ImageFadedResponsive>
          </ContentImage>
        </TwoPartContent>

        {!renderFeaturesUnderSubheader && <FeaturesContainer {...d}>{features.map(this.renderFeatureItem)}</FeaturesContainer>}
      </PaddedContainer>
    )
  }
}

//  prettier-ignore
const PaddedContainer = styled.div`
  ${props => !props.relative && css`
    position: absolute; left: 0; right: 0; top: 0; bottom: 0;
  `}

  ${props => props.relative && css`
    position: relative;
    min-height: 100vh;
  `}

  ${props => css`
    ${props.theme.media.lgdown} {
      min-height: inherit;
    }
  `}

  display: flex;
  flex-direction: column; // so it's vertical, for spacer
  ${props => css`
    ${props.theme.media.smdown} {
      display: block; // normal rows
    }
  `}

  ${props => selectors.getPaddingLeftRightByThemeAndVariant(props.theme, props.variant)}
  ${props => selectors.getBackgroundColorByProps(props, "rgba(0,0,255,0.25)")}
  background-color: ${props => props.theme.vars.colors.westar};
`

const TwoPartContent = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  ${props => selectors.getBackgroundColorByProps(props, "rgba(255,0,0,0.25)")}

  flex-direction: row; // left, right
  ${props => css`
    ${props.theme.media.smdown} {
      flex-direction: column; // top, bottom
    }
  `}
`

const ContentText = styled.div`
  min-width: 0;
  flex: 1;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  padding-top: 50px;
  padding-bottom: 50px;
  ${props => css`
    ${props.theme.media.lg} {
      padding-bottom: 0;
    }
    ${props.theme.media.md} {
      padding-bottom: 0;
    }
    ${props.theme.media.sm} {
      padding-bottom: 0;
    }
    ${props.theme.media.xs} {
      padding-bottom: 0;
    }
  `}

  ${props => selectors.getDebugOverlayCss(props, "ContentText", "rgba(0,255,255,0.35)")}
`

const ContentTextRespectingImage = styled.div`
  padding-right: 15%; // depending on CImageFaded transform props..
  ${props => css`
    ${props.theme.media.smdown} {
      padding-right: 0;
      max-width: 400px;
      align-self: center;
    }
  `}
`

const ContentImage = styled.div`
  min-width: 0;
  pointer-events: none;
  flex: 1;
  align-self: stretch;
  display: flex;
  align-items: flex-start; // vertical
  justify-content: center;
  padding-top: 50px;
  padding-bottom: 50px;
  ${props => selectors.getDebugOverlayCss(props, "ContentImage", "rgba(255,255,0,0.35)")}
  ${props => css`
    ${props.theme.media.smdown} {
      padding-top: 0;
      padding-bottom: 0;
    }
  `}
`

const ImageFadedResponsive = styled.div`
  width: 100%;
  margin-bottom: -80px;
  ${props => css`
    ${props.theme.media.sm} {
      width: 50%;
      margin-bottom: 0px;
    }
    ${props.theme.media.xs} {
      width: 70%;
      margin-bottom: 0px;
    }
  `}
`

const HeaderContainer = styled.div`
  padding-top: 15px;
  padding-bottom: 10px;
  ${props => selectors.getDebugOverlayCss(props, "HeaderContainer", "rgba(255,255,0,0.35)")}
`
const SubheaderContainer = styled.div`
  padding: 20px 0px;
  line-height: 1.7;
  letter-spacing: 0.1em;
  font-kerning: none;
  ${props => selectors.getDebugOverlayCss(props, "SubheaderContainer", "rgba(0,255,0,0.35)")}
  font-size: 18px;
  ${props => css`
    ${props.theme.media.md} {
      font-size: 16px;
    }
  `}
`

const FeaturesContainer = styled.div`
  ${props => selectors.getDebugOverlayCss(props, "FeaturesContainer", "rgba(255,0,0,0.35)")}

  padding: 20px 0px;
  ${props => css`
    ${props.theme.media.md} {
      padding: 70px 0px 20px 0px;
    }
    ${props.theme.media.lg} {
      padding: 70px 0px 20px 0px;
    }
  `}
`

const FeatureContainer = styled.div`
  display: flex;
  margin-bottom: 8px;
  opacity: ${props => (props.isChecked ? 1 : 0.5)};
`

const FeatureIconContainer = styled.div`
  width: 33px;
  align-self: stretch;
  padding-top: 2px;
  background-color: ${props => (props.debug ? "rgba(255,0,0,0.2)" : "none")};
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`

const FeatureTextContainer = styled.div`
  align-self: stretch;
  flex: 1;
  font-size: 16px;
  letter-spacing: 0.1em;
  line-height: 1.5;
`

export default CPortfolioDetailsHeroOverlay
