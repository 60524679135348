import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import {connect} from "react-redux"
import {createSelector} from "reselect"
import styled, {ThemeProvider} from "styled-components"
import {withController} from "react-scroll-parallax"

import withScreenProps from "../../../data/hocs/withScreenProps" // {postData, ..}
import * as selectors from "../../../data/selectors"
const god = selectors.getObjectDeep

import CFooter from "../../../view/components/CFooter"
import CImage from "../../../view/components/CImage"
import CGetInTouch from "../../../view/components/CGetInTouch"
import CEditInWordPress from "../../../view/components/CEditInWordPress"
import CSpacer from "../../../view/components/CSpacer"
import CButton from "../../../view/components/CButton" // eslint-disable-line
import CContent from "../../../view/components/CContent"

import CH2 from "../../../view/components/text/CH2"
import CH3 from "../../../view/components/text/CH3"
import CHomeSubheader from "../../../view/components/text/CHomeSubheader"

import {CPressAppearances, CPressKit, CLogoGuidelines} from "./components"

const debby = (funcName, data = null) => console.log("SPress." + funcName, data) // eslint-disable-line
const debbyWarn = (funcName, data = null) => console.warn("SPress." + funcName, data) // eslint-disable-line

class SPress extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    sitemapData: PropTypes.array,
    pressAppearances: PropTypes.array,

    parallaxController: PropTypes.object.isRequired,

    //  withScreenProps
    dispatch: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    intlLocale: PropTypes.string.isRequired,
    themeKey: PropTypes.string,
    postData: PropTypes.object,
    editInWordPressProps: PropTypes.object,
    pageTemplate: PropTypes.string,
  }
  static defaultProps = {
    debug: false && __DEV__,
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  render = () => {
    const {debug, intl, postData, editInWordPressProps, intlLocale, sitemapData, parallaxController, pageTemplate, pressAppearances} = this.props
    const d = {debug}
    const parallaxRatio = 0.1
    const heroImageSrcData = selectors.getSrcAndSrcSetByWordpressImage(god(postData, "featuredImage"), {useCropped: !!parallaxRatio})
    const privacySmallprintHtml = selectors.getPrivacySmallprintHtml(intl, intlLocale, sitemapData)
    const pressKitFiles = god(postData, "sectionPressKit.brandMaterials.files", [])
    const brandUsageRules = god(postData, "sectionLogoGuidelines.brandUsageRules", [])
    debby("render()", {props: this.props, brandUsageRules})
    return (
      <ThemeProvider theme={{}}>
        <Container {...d}>
          <CImage
            //
            src={heroImageSrcData}
            srcSet={heroImageSrcData.srcSet}
            overlayColor={`rgba(0,0,0,0.25)`}
            parallaxRatio={parallaxRatio}
            parallaxController={parallaxController}
            heightGrows>
            <PaddedContainer {...d} variant={`content`}>
              <HeaderContainer>
                <CH2 {...d} text={god(postData, "sectionTop.header", "")} />
                <CSpacer {...d} variant={`herosections`} />
                <CHomeSubheader {...d} text={god(postData, "sectionTop.subheader", "")} variant={`home-demo`} />
              </HeaderContainer>
            </PaddedContainer>
          </CImage>

          <PressAppearancesContainer {...d}>
            <CPressAppearances
              //
              items={pressAppearances}
              loadMoreItemsLabel={intl.formatMessage({id: `LoadMoreItems`})}
              showEditButton={!!editInWordPressProps.userId}
            />
          </PressAppearancesContainer>

          <PressKitContainer {...d}>
            <CPressKit
              //
              header={god(postData, "sectionPressKit.header")}
              subheader={god(postData, "sectionPressKit.subheader", "")}
              files={pressKitFiles}
              brandMaterialsHeader={god(postData, "sectionPressKit.brandMaterials.header")}
              brandMaterialsSubheader={god(postData, "sectionPressKit.brandMaterials.subheader")}
            />
          </PressKitContainer>

          <LogoGuidelinesContainer {...d}>
            <CLogoGuidelines
              //
              header={god(postData, "sectionLogoGuidelines.header")}
              subheader={god(postData, "sectionLogoGuidelines.subheader")}
              styleguideUrl={god(postData, "sectionLogoGuidelines.styleguideUrl")}
              rules={brandUsageRules}
            />
          </LogoGuidelinesContainer>

          <UsabilityTermsContainer {...d}>
            <CH3 text={god(postData, "sectionUsabilityTerms.header")} />
            <CSpacer {...d} variant={`herosections`} />
            <CContent html={god(postData, "sectionUsabilityTerms.content")} />
          </UsabilityTermsContainer>

          <GetInTouchContainer {...d}>
            <CGetInTouch
              //  labels
              {...d}
              pageTemplate={pageTemplate}
              header={god(postData, "sectionGetInTouch.labelHeader")}
              button={god(postData, "sectionGetInTouch.labelButton")}
              teamMember={god(postData, "sectionGetInTouch.teamMember")}
              smallprint={privacySmallprintHtml}
              onLinkPress={this.onLinkPress}
            />
          </GetInTouchContainer>

          <CFooter />

          <CEditInWordPress fixed {...editInWordPressProps} />
        </Container>
      </ThemeProvider>
    )
  }
}

const Container = styled.div`
  position: relative;
  overflow-x: hidden; // because of huge images
  background-color: ${props => props.theme.backgroundColor};
  transition: all ${props => props.theme.vars.transitions.themeTransitionDuration}s linear;
`

const PaddedContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  transition: all ${props => props.theme.vars.transitions.themeTransitionDuration}s linear;
  ${props => selectors.getPaddingLeftRightByThemeAndVariant(props.theme, props.variant)}
  ${props => false && props.debug && selectors.getDebugOverlayCss(props, `PaddedContainer`, `rgba(0,0,255,0.35)`)}
  color: white;
  text-align: ${props => (props.align == "center" ? "center" : "left")};
`

const HeaderContainer = styled.div`
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  ${props => props.debug && selectors.getDebugOverlayCss(props, `HeaderContainer`, `rgba(255,0,0,0.35)`)}
`

const PressAppearancesContainer = styled.div`
  padding-top: 20px;
  padding-bottom: 40px;
  ${props => props.debug && selectors.getDebugOverlayCss(props, `PressAppearancesContainer`, `rgba(255,0,0,0.35)`)}
  ${props => selectors.getPaddingLeftRightByThemeAndVariant(props.theme, "header")}
  background-color: ${props => props.theme.vars.colors.gallery};
`

const GetInTouchContainer = styled.div`
  width: 100%;
  padding: 90px 0px;
  ${props => props.debug && selectors.getDebugOverlayCss(props, `GetInTouchContainer`, `rgba(0,255,255,0.35)`)}
`

const PressKitContainer = styled.div`
  ${props => props.debug && selectors.getDebugOverlayCss(props, `PressKitContainer`, `rgba(255,0,255,0.35)`)}
`

const LogoGuidelinesContainer = styled.div`
  display: flex;
  ${props => selectors.getPaddingLeftRightByThemeAndVariant(props.theme, "content")}
  ${props => props.debug && selectors.getDebugOverlayCss(props, `LogoGuidelinesContainer`, `rgba(255,0,255,0.35)`)}
`

const UsabilityTermsContainer = styled.div`
  padding-top: 50px;
  padding-bottom: 50px;
  ${props => selectors.getPaddingLeftRightByThemeAndVariant(props.theme, "content")}
  ${props => props.debug && selectors.getDebugOverlayCss(props, `UsabilityTermsContainer`, `rgba(255,0,255,0.35)`)}
  background-color: ${props => props.theme.vars.colors.gallery};
`

////////////////////////////////////////////////////////////////////////

const getPressAppearances = createSelector([selectors.getPostData], postData => {
  const appearancesUnsorted = god(postData, "sectionPressAppearances.items", [])
  const appearances = appearancesUnsorted.sort((a, b) => b.dateTimestamp - a.dateTimestamp) // newest first
  return appearances
})

const mapStateToProps = (state, props) => ({
  sitemapData: selectors.getSitemapData(state),
  pressAppearances: getPressAppearances(state, props),
})
export default withController(withScreenProps(connect(mapStateToProps)(SPress)))
