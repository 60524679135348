import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import SmoothCollapse from "react-smooth-collapse"
import styled, {css} from "styled-components" // eslint-disable-line
import Measure from "react-measure"

const DEBUG = false && __DEV__
const debby = (funcName, data = null) => DEBUG && console.log("CCollapse." + funcName, data) // eslint-disable-line

class CCollapse extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    className: PropTypes.string,
    collapsed: PropTypes.bool,
    children: PropTypes.any.isRequired,
    fadeAlpha: PropTypes.bool,
    animateCollapse: PropTypes.bool,
    heightTransition: PropTypes.string,
    delayShow: PropTypes.number, // delayShow of the animation, in ms
    delayHide: PropTypes.number, // delayShow of the animation, in ms
    collapsedHeight: PropTypes.number,
    onContentHeightChanged: PropTypes.func,
  }
  static defaultProps = {
    debug: false && __DEV__,
    collapsed: false,
    fadeAlpha: true,
    heightTransition: "0.5s ease",
    delayShow: 20,
    delayHide: 20,
    animateCollapse: true,
    collapsedHeight: 0,
  }
  constructor(props) {
    super(props)
    this.state = {
      collapsed: props.collapsed,
      contentHeight: 0,
    }
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  UNSAFE_componentWillReceiveProps = nextProps => {
    if (nextProps.collapsed != this.state.collapsed) {
      this.timeoutIdSwitch = setTimeout(
        () => {
          this.setState({collapsed: nextProps.collapsed})
        },
        nextProps.collapsed ? nextProps.delayHide : nextProps.delayShow,
      )
    }
  }

  componentWillUnmount = () => {
    clearTimeout(this.timeoutIdSwitch)
  }

  onContentResize = contentRect => {
    const contentHeight = contentRect.bounds.height
    this.setState({contentHeight})
    !!this.props.onContentHeightChanged && this.props.onContentHeightChanged(contentHeight)
  }

  render = () => {
    const {contentHeight} = this.state
    const {debug, animateCollapse, heightTransition, className, collapsedHeight} = this.props
    const d = {debug: debug || CCollapse.defaultProps.debug}
    if (!animateCollapse) {
      if (collapsed) {
        return <div />
      }
      return (
        <Container {...d} collapsed={collapsed}>
          {this.props.children}
        </Container>
      )
    }
    let collapsedHeightUsed = collapsedHeight
    let collapsed = this.state.collapsed
    if (!!contentHeight && !!collapsedHeight) {
      if (contentHeight < collapsedHeight) {
        collapsedHeightUsed = 0
        collapsed = false
      }
    }
    if (!!collapsedHeightUsed) {
      return (
        <Container
          //
          {...d}
          collapsed={collapsed}
          collapsedHeight={collapsedHeightUsed}>
          <SmoothCollapse
            //
            expanded={!collapsed}
            heightTransition={heightTransition}
            collapsedHeight={collapsedHeightUsed + "px"}
            className={className}>
            <Measure
              //
              onResize={this.onContentResize}
              bounds>
              {({measureRef}) => <div ref={measureRef}>{this.props.children}</div>}
            </Measure>
          </SmoothCollapse>
        </Container>
      )
    }

    return (
      <Container
        //
        {...d}
        collapsed={collapsed}>
        <SmoothCollapse
          //
          expanded={!collapsed}
          heightTransition={heightTransition}
          className={className}>
          {this.props.children}
        </SmoothCollapse>
      </Container>
    )
  }
}

const Container = styled.div`
  transition: opacity 0.5s ease-in-out;
  transition-delay: 0.1s;
  opacity: ${props => (props.collapsed && !props.collapsedHeight ? 0 : 1)};
  position: relative;
`

export default CCollapse
