import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import {connect} from "react-redux"
import {withController} from "react-scroll-parallax"
import styled, {ThemeProvider, css} from "styled-components" // eslint-disable-line

import * as selectors from "../../../data/selectors"
import withScreenProps from "../../../data/hocs/withScreenProps" // {postData, pageTemplate, ..}

import CExhibitorsHeader from "../SExhibitors/CExhibitorsHeader"
import CUmbrellaCategories from "./CUmbrellaCategories"
import CExhibitorsAppDownload from "../SExhibitors/CExhibitorsAppDownload"

import CEditInWordPress from "../../../view/components/CEditInWordPress"
import CFooter from "../../../view/components/CFooter"
import CButton from "../../../view/components/CButton" // eslint-disable-line

const DEBUG = false && __DEV__
import {debbify} from "../../../data/selectors/helpers"
const debby = (...args) => debbify("SUmbrella", ...args)

const god = selectors.getObjectDeep

class SUmbrella extends Component {
  static propTypes = {
    parallaxController: PropTypes.object.isRequired,

    stageWidth: PropTypes.number,
    sitemapData: PropTypes.array,
    fixedSectionsItems: PropTypes.array,
    defaultThemeProps: PropTypes.object,

    //  withScreenProps
    dispatch: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    intlLocale: PropTypes.string.isRequired,
    themeKey: PropTypes.string,
    postData: PropTypes.object,
    wordPressUserId: PropTypes.number,
    editInWordPressProps: PropTypes.object,
    pageTemplate: PropTypes.string,
    mediaQueryClass: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
  }
  static defaultProps = {
    defaultThemeProps: {
      backgroundColor: "white",
      textColor: "black",
    },
  }

  constructor(props) {
    super(props)
    this.state = {
      availWidthFeatureMatrix: undefined,
    }
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  render = () => {
    const {defaultThemeProps, mediaQueryClass, intl, intlLocale, sitemapData, postData, fixedSectionsItems, stageWidth, themeKey, parallaxController, wordPressUserId, editInWordPressProps, pageTemplate} = this.props // eslint-disable-line
    const d = {debug: DEBUG}
    const themeOverrides = {}
    const featuredImage = god(postData, "featuredImage")
    debby("render()", {mediaQueryClass, wordPressUserId, featuredImage})
    return (
      <ThemeProvider theme={{...defaultThemeProps, ...themeOverrides}}>
        <Container {...d}>
          {/* Header */}
          {true && (
            <CExhibitorsHeader
              //
              {...d}
              intlLocale={intlLocale}
              stageWidth={stageWidth}
              parallaxController={parallaxController}
              featuredImage={featuredImage}
              {...god(postData, "sectionTop", {})} // headerText, handImage
              mediaQueryClass={mediaQueryClass}
            />
          )}

          {/* Categories - Exhibitors & Art Lovers */}
          {true && (
            <CategoriesContainer {...d}>
              <PaddedContainer {...d} variant={`header`}>
                <CUmbrellaCategories
                  //
                  {...d}
                  {...god(postData, "sectionCategories", {})}
                  mediaQueryClass={mediaQueryClass}
                />
              </PaddedContainer>
            </CategoriesContainer>
          )}

          {/* App Download - PaddedContainer is in Component itself */}
          {true && (
            <AppDownloadContainer {...d}>
              <CExhibitorsAppDownload
                //
                {...d}
                {...god(postData, "sectionAppDownload", {})}
                intlLocale={intlLocale}
                contactButtonLabel={god(postData, "sectionDownloadTop.contactLabel")}
                variant={"umbrella-main"}
              />
            </AppDownloadContainer>
          )}

          <CFooter variant={"umbrella"} />

          <CEditInWordPress fixed {...editInWordPressProps} />

          {d.debug && true && (
            <div style={{position: "fixed", pointerEvents: "none", fontSize: 9, left: 0, bottom: 0, zIndex: 20000, color: "blue", backgroundColor: "rgba(255,255,255,0.5)", padding: 10}}>
              <div>SUmbrella_web.js</div>
              <div>test: yo</div>
              <div>wordPressUserId: {wordPressUserId}</div>
              <div>
                editInWordPressProps: <pre>{JSON.stringify(editInWordPressProps, null, 2)}</pre>
              </div>
            </div>
          )}
        </Container>
      </ThemeProvider>
    )
  }
}

const Container = styled.div`
  position: relative;
  overflow-x: hidden; // because of huge images
  background-color: ${props => props.theme.backgroundColor};
  transition: all ${props => props.theme.vars.transitions.themeTransitionDuration}s linear;
`

const PaddedContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  transition: all ${props => props.theme.vars.transitions.themeTransitionDuration}s linear;
  ${props => selectors.getPaddingLeftRightByThemeAndVariant(props.theme, props.variant, "padding")}
  ${props => true && props.debug && selectors.getDebugOverlayCss(props, `PaddedContainer`, `rgba(0,0,255,0.15)`)}
  text-align: ${props => (props.align == "center" ? "center" : "left")};
`

const CategoriesContainer = styled.div`
  border: ${props => (props.debug ? 3 : 0)}px solid red;
  ${props => false && props.debug && selectors.getDebugOverlayCss(props, `CategoriesContainer`, `rgba(255,0,255,0.15)`)}
  padding-top: 40px;
  ${props => css`
    ${props.theme.media.smdown} {
      padding-bottom: 50px;
    }
  `}
`
const AppDownloadContainer = styled.div`
  border: ${props => (props.debug ? 3 : 0)}px solid blue;
`

const getIntl = (state, props) => props.intl // eslint-disable-line

const mapStateToProps = state => ({
  stageWidth: state.device.stageDimensions.width,
  sitemapData: selectors.getSitemapData(state),
  // fixedSectionsItems: getFixedSectionsItems(state, props),
})
export default withController(withScreenProps(connect(mapStateToProps)(SUmbrella)))
