import React, {Component} from "react"
import PropTypes from "prop-types"
import styled, {css, ThemeProvider} from "styled-components" // eslint-disable-line
import isEqual from "lodash/isEqual"
import {Row, Col} from "react-grid-system"

import CGalleryStatsMonth from "./CGalleryStatsMonth"

// import CLink from "../../../view/components/CLink"
// import CCollapse from "../../../view/components/CCollapse"
import * as selectors from "../../../data/selectors"

class CSectionGalleryStats extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    defaultThemeProps: PropTypes.object,
    gallerySlug: PropTypes.string.isRequired,
    thisMonth: PropTypes.object.isRequired,
    lastMonth: PropTypes.object.isRequired,
  }

  static defaultProps = {
    debug: false,
    defaultThemeProps: {
      textColor: "black",
    },
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  render = () => {
    const {gallerySlug, thisMonth, lastMonth, defaultThemeProps, debug} = this.props
    if (!thisMonth || !lastMonth) {
      return <div />
    }
    // const {artworksLiked, artistsLiked, firstScans, scans, triggeredNotificationsSent, wallsCreated} = data // eslint-disable-line
    const d = {debug: debug || CSectionGalleryStats.defaultProps.debug}
    return (
      <ThemeProvider theme={{...defaultThemeProps}}>
        <PaddedContainer {...d} variant={"header"}>
          <Header>Gallery Analytics</Header>
          <div>
            <Row {...rowProps}>
              <Col lg={6} md={6} sm={12} xs={12}>
                <CGalleryStatsMonth
                  //
                  {...d}
                  gallerySlug={gallerySlug}
                  header={"This Month"}
                  data={thisMonth}
                />
              </Col>
              <Col lg={6} md={6} sm={12} xs={12}>
                <CGalleryStatsMonth
                  //
                  {...d}
                  gallerySlug={gallerySlug}
                  header={"Last Month"}
                  data={lastMonth}
                />
              </Col>
            </Row>
          </div>
        </PaddedContainer>
      </ThemeProvider>
    )
  }
}

const Header = styled.div`
  margin-top: 40px;
  font-size: 25px;
  text-align: center;
  font-weight: ${props => props.theme.vars.fonts.weights.bold};
  text-transform: uppercase;
  letter-spacing: 0.15em;
  font-kerning: none;
  margin-bottom: 10px;
`

const rowProps = {
  type: "flex",
  align: "start",
  justify: "start",
  width: "100%",
  height: "100%",
  gutter: 0,
}

const PaddedContainer = styled.div`
  ${props => selectors.getPaddingLeftRightByThemeAndVariant(props.theme, props.variant)}
  background-color: white;
  padding-top: 20px;
  padding-bottom: 20px;
  transition: all ${props => props.theme.vars.transitions.themeTransitionDuration}s linear;
  ${props => props.debug && selectors.getDebugOverlayCss(props, "CSectionGalleryStats.PaddedContainer", "rgba(0,0,255,0.35)")}
`

export default CSectionGalleryStats
