import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import {connect} from "react-redux"
import {createSelector} from "reselect" // eslint-disable-line
import styled from "styled-components"
import {replace} from "connected-react-router"

import withScreenProps from "../../../data/hocs/withScreenProps"
import * as actions from "../../../data/actions"
import * as selectors from "../../../data/selectors"
const god = selectors.getObjectDeep

import CFooter from "../../../view/components/CFooter"
import CSpacer from "../../../view/components/CSpacer"
import CGetInTouch from "../../../view/components/CGetInTouch"
import CEditInWordPress from "../../../view/components/CEditInWordPress"

import {BranchManager} from "../../../data/utils"

import {CQRCodeContainer} from "../SDemo/components"

const DEBUG = true && __DEV__
import {debbify} from "../../../data/selectors/helpers"
const debby = (...args) => debbify("SLinking", ...args)

class SLinking extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    sitemapData: PropTypes.array,
    linkingKeyData: PropTypes.object,
    iosOrAndroid: PropTypes.bool,
    linkingKey: PropTypes.string,

    //  withScreenProps
    dispatch: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    intlLocale: PropTypes.string.isRequired,
    themeKey: PropTypes.string,
    postData: PropTypes.object,
    editInWordPressProps: PropTypes.object,
    pageTemplate: PropTypes.string,
  }
  static defaultProps = {
    debug: DEBUG,
  }

  componentDidMount = () => {
    const {linkingKey} = this.props
    debby("componentDidMount()", {linkingKey})
    if (!!linkingKey) {
      this.props.dispatch(actions.requestBranchLinkingKeyData(linkingKey))
    } else {
      this.props.dispatch(replace("/"))
    }
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  render = () => {
    const {intl, postData, editInWordPressProps, pageTemplate, intlLocale, sitemapData, linkingKeyData, iosOrAndroid, linkingKey} = this.props
    const d = {debug: DEBUG || this.props.debug}
    const privacySmallprintHtml = selectors.getPrivacySmallprintHtml(intl, intlLocale, sitemapData) // for CGetInTouch
    const copiedToClipboardLabel = intl.formatMessage({id: `CopiedToClipboardSuccess`})
    debby("render()", {linkingKey})
    return (
      <Container {...d}>
        <CSpacer {...d} variant={`headermenu`} />
        <CSpacer {...d} variant={`headermenu`} />

        {!!linkingKeyData && (
          <CQRCodeContainer
            //
            qrHeader={god(postData, `demoData.${intlLocale}.sectionQrCode.header`)}
            qrSteps={god(postData, `demoData.${intlLocale}.sectionQrCode.steps`)}
            qrCodeImageUrl={!!linkingKeyData ? linkingKeyData.qrCodeUrl : null}
            qrCodeBranchUrl={!!linkingKeyData ? linkingKeyData.branchUrl : null}
            iosOrAndroid={iosOrAndroid} // QR code is clickable and opens its link
            mobileHeader={god(postData, `demoData.${intlLocale}.sectionQrMobile.header`)}
            mobileOr={god(postData, `demoData.${intlLocale}.sectionQrMobile.or`)}
            mobileTodo={god(postData, `demoData.${intlLocale}.sectionQrMobile.todo`)}
            mobileButtonLabel={god(postData, `demoData.${intlLocale}.sectionQrMobile.buttonLabel`)}
            copiedToClipboardLabel={copiedToClipboardLabel}
          />
        )}

        {false && (
          <GetInTouchContainer {...d}>
            <CGetInTouch
              //  labels
              {...d}
              pageTemplate={pageTemplate}
              header={god(postData, "sectionGetInTouch.labelHeader")}
              button={god(postData, "sectionGetInTouch.labelButton")}
              teamMember={god(postData, "sectionGetInTouch.teamMember")}
              smallprint={privacySmallprintHtml}
              onLinkPress={this.onLinkPress}
            />
          </GetInTouchContainer>
        )}

        <CFooter />
        <CEditInWordPress fixed {...editInWordPressProps} />
      </Container>
    )
  }
}

const Container = styled.div`
  position: relative;
  background-color: ${props => props.theme.vars.colors.westar};
  transition: all ${props => props.theme.vars.transitions.themeTransitionDuration}s linear;
`

const GetInTouchContainer = styled.div`
  ${props => selectors.getDebugOverlayCss(props, "GetInTouchContainer", "rgba(255,0,0,0.15)")}
  background-color: white;
  padding: 90px 0px;
`

const getStateBranch = state => state.branch

const getLinkingKeyData = createSelector(
  //
  [getStateBranch, selectors.getLinkingKeyByMatch],
  (stateBranch, linkingKey) => {
    const linkingKeyDataRaw = god(stateBranch, `linkingKeyData.${linkingKey}.data`) // eslint-disable-line
    if (!!linkingKeyDataRaw) {
      const artworkSlug = god(linkingKeyDataRaw, "artworkSlug")
      const artistSlug = god(linkingKeyDataRaw, "artistSlug")
      const wallId = god(linkingKeyDataRaw, "wallId")
      const eventSlug = god(linkingKeyDataRaw, "eventSlug")
      if (!!wallId) {
        return {
          qrCodeUrl: `https://iazzu.com/api/qr/walls/${linkingKey}.png`,
          branchUrl: `https://iazzu.app.link/${linkingKey}`,
        }
      } else if (!!artworkSlug) {
        return {
          qrCodeUrl: `https://iazzu.com/api/qr/artworks/${linkingKey}.png`,
          branchUrl: `https://iazzu.app.link/${linkingKey}`,
        }
      } else if (!!artistSlug) {
        return {
          qrCodeUrl: `https://iazzu.com/api/qr/artists/${linkingKey}.png`,
          branchUrl: `https://iazzu.app.link/${linkingKey}`,
        }
      } else if (!!eventSlug) {
        return {
          qrCodeUrl: `https://iazzu.com/api/qr/events/${linkingKey}.png`,
          branchUrl: `https://iazzu.app.link/${linkingKey}`,
        }
      }
    }
    const galleryCode = god(stateBranch, `linkingKeyData.${linkingKey}.data.galleryCode`, god(stateBranch, "galleryCode"))
    if (!!galleryCode) {
      return {
        qrCodeUrl: BranchManager.getQrImageUrl(galleryCode),
        branchUrl: BranchManager.getBranchUrl(galleryCode),
      }
    }
    return null
  },
)

const mapStateToProps = (state, props) => ({
  linkingKeyData: getLinkingKeyData(state, props),
  sitemapData: selectors.getSitemapData(state),
  iosOrAndroid: selectors.getIsIosOrAndroid(),
  linkingKey: selectors.getLinkingKeyByMatch(null, props),
})
export default withScreenProps(connect(mapStateToProps)(SLinking))
