const getConsoleFunc = (warn = false) => (!warn ? console.log : console.warn)
export const debbify = (prefix, funcName, data = {}, isWarning = false) => (Object.keys(data).length ? getConsoleFunc(isWarning)(`${prefix}.` + funcName, data) : getConsoleFunc(isWarning)(`${prefix}.` + funcName))
const debby = (...args) => debbify("BranchManager", ...args) // eslint-disable-line

var BranchManager = {
  getQrImageUrl(galleryCode) {
    return !!galleryCode ? `https://iazzu.com/api/qr/galleries/${galleryCode}.png` : null
  },

  getBranchUrl(galleryCode) {
    return !!galleryCode ? `https://iazzu.app.link/${galleryCode}` : null
  },
}

export default BranchManager
