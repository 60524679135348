import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import styled, {css, ThemeProvider} from "styled-components" // eslint-disable-line

import * as selectors from "../../data/selectors"

import CButton from "../../view/components/CButton"
import CCollapse from "../../view/components/CCollapse"
import CImage from "../../view/components/CImage"

class CTestimonialButton extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    behaviour: PropTypes.string,
    label: PropTypes.string,
    gallery: PropTypes.object,
    iosOrAndroid: PropTypes.bool,
    qrCodeLabel: PropTypes.string,
  }
  static defaultProps = {
    debug: false && __DEV__,
  }

  constructor(props) {
    super(props)
    this.state = {
      galleryQrCodeShown: false,
    }
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  onButtonClick = () => {
    const {behaviour, gallery, iosOrAndroid} = this.props
    if (behaviour == "gallery") {
      if (iosOrAndroid) {
        window.open(gallery.branchUrl)
      } else {
        this.setState({galleryQrCodeShown: true})
      }
    }
  }

  render = () => {
    const {galleryQrCodeShown} = this.state
    const {debug, behaviour, label, gallery, qrCodeLabel} = this.props
    const d = {debug: debug || CTestimonialButton.defaultProps.debug}
    if (!behaviour || behaviour == "none") {
      return null
    }
    return (
      <TestimonialButtonContainer {...d}>
        <CCollapse collapsed={galleryQrCodeShown}>
          <CButton
            //
            label={label}
            variant={`testimonial`}
            onClick={this.onButtonClick}
            noHoverScale
          />
        </CCollapse>
        {behaviour == "gallery" && (
          <CCollapse collapsed={!galleryQrCodeShown}>
            <CollapsedContent>
              <CImage ratio={1} src={gallery.qrCodeUrl} />
            </CollapsedContent>
            <QrCodeInfo dangerouslySetInnerHTML={{__html: qrCodeLabel}} />
          </CCollapse>
        )}
      </TestimonialButtonContainer>
    )
  }
}

const TestimonialButtonContainer = styled.div`
  padding-top: 20px;
  ${props => selectors.getDebugOverlayCss(props, "TestimonialButtonContainer", "rgba(0,0,255,0.15)")}// padding-bottom: 20px;
`
const CollapsedContent = styled.div`
  // padding: 10px 0px;
  max-width: 200px;
  margin: 0 auto;
`

const QrCodeInfo = styled.div`
  padding: 20px 30px;
  text-align: center;
  font-size: 13px;
  line-height: 1.5;
  letter-spacing: 0.1em;
  color: ${props => props.theme.vars.colors.siccoro};
`

export default CTestimonialButton
