import {createStore} from "redux"
import {CONFIG} from "../../../config"

let reactotronRedux = null

var ReactotronManagerBase = {
  useReactotron() {
    return __DEV__ && CONFIG.redux.useReactotron
  },

  loadDynamically() {
    return new Promise(resolve => {
      if (!reactotronRedux) {
        import(/* webpackChunkName: "reactotron-redux" */ "reactotron-redux").then(inst => {
          console.warn(`Dynamic Component 'ReactotronManagerBase' initialized.`)
          reactotronRedux = inst.reactotronRedux
          resolve()
        })
      } else {
        resolve()
      }
    })
  },

  init(reactotronInstance) {
    return new Promise(resolve => {
      if (!ReactotronManagerBase.useReactotron()) {
        resolve()
        return
      }
      console.warn("Using Reactotron.")
      ReactotronManagerBase.loadDynamically().then(() => {
        const importantActions = [
          "persist/REHYDRATE",
          "persist/PERSIST",
          // actionsConsts.SET_THREE_INITIALIZED,
        ]

        reactotronInstance.configure({name: "iazzu v3"}).use(
          reactotronRedux({
            isActionImportant: action => importantActions.indexOf(action.type) !== -1,
          }),
        )
        resolve()
      })
    })
  },

  getCreateStoreFunction(reactotronInstance) {
    if (!ReactotronManagerBase.useReactotron()) {
      // console.warn("Not using Reactotron.")
      return createStore
    }
    return reactotronInstance.createStore
  },

  createEnhancer(reactotronInstance) {
    if (!ReactotronManagerBase.useReactotron()) {
      // console.warn("Not using Reactotron.")
      return null
    }
    return reactotronInstance.createEnhancer()
  },
}

export default ReactotronManagerBase
