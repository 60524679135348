import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import styled, {ThemeProvider, css} from "styled-components" // eslint-disable-line

import * as selectors from "../../../data/selectors"

import CText from "../../../view/components/CText"
import CIcon from "../../../view/components/CIcon"
import CLink from "../../../view/components/CLink"

import {debbify} from "../../../data/selectors/helpers"
const debby = (...args) => debbify("CAdvancedSupport", ...args)

class CAdvancedSupport extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    headerText: PropTypes.string,
    subheaderText: PropTypes.string,
    openTicketButtonLabel: PropTypes.string,
    openTicketEmail: PropTypes.string,
  }
  static defaultProps = {
    debug: false && __DEV__,
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  render = () => {
    const {debug, headerText, subheaderText, openTicketButtonLabel, openTicketEmail} = this.props
    const d = {debug: debug || CAdvancedSupport.defaultProps.debug}
    debby("CAdvancedSupport.render()", {headerText, subheaderText, openTicketButtonLabel, openTicketEmail})
    return (
      <Container {...d}>
        <HeaderText {...d}>{headerText}</HeaderText>
        <SubheaderText {...d}>
          <CText html={subheaderText} />
        </SubheaderText>
        <CLink to={`mailto:${openTicketEmail}`}>
          <OpenTicketContainer {...d}>
            <OpenTicketButtonLabel {...d}>{openTicketButtonLabel}</OpenTicketButtonLabel>
            <CIcon id={"OpenTicketIconCaretRight"} />
          </OpenTicketContainer>
        </CLink>
      </Container>
    )
  }
}

const Container = styled.div`
  background-color: #f7f7f7;
  margin-bottom: 60px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${props => true && props.debug && selectors.getDebugOverlayCss(props, `Container`, `rgba(255,0,255,0.15)`)}

  padding: 80px 30px;
  ${props => css`
    ${props.theme.media.mddown} {
      padding: 70px 20px;
    }
  `}
`

const HeaderText = styled.div`
  font-weight: ${props => props.theme.vars.fonts.weights.extrabold};
  color: #1e1e1e;
  letter-spacing: 3px;
  text-align: center;
  line-height: 1.3;
  ${props => true && props.debug && selectors.getDebugOverlayCss(props, `HeaderText`, `rgba(255,0,0,0.15)`)}

  font-size: 28px;
  ${props => css`
    ${props.theme.media.mddown} {
      font-size: 26px;
    }
    ${props.theme.media.xs} {
      font-size: 22px;
    }
  `}
`

const SubheaderText = styled.div`
  letter-spacing: 2.5px;
  text-align: center;
  line-height: 1.8;
  font-weight: ${props => props.theme.vars.fonts.weights.regular};
  ${props => true && props.debug && selectors.getDebugOverlayCss(props, `SubHeaderText`, `rgba(0,255,0,0.15)`)}

  color: #1e1e1e;
  a,
  * > a {
    color: #1e1e1e;
  }

  font-size: 26px;
  ${props => css`
    ${props.theme.media.mddown} {
      font-size: 24px;
    }
    ${props.theme.media.xs} {
      font-size: 22px;
    }
  `}
`

const OpenTicketContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 10px;
  margin-top: 30px;
  ${props => true && props.debug && selectors.getDebugOverlayCss(props, `OpenTicketContainer`, `rgba(0,0,255,0.15)`)}
`

const OpenTicketButtonLabel = styled.div`
  font-size: 20px;
  color: #1e1e1e;
  letter-spacing: 2.5px;
  text-align: right;
  line-height: 27px;
  font-weight: ${props => props.theme.vars.fonts.weights.bold};
  padding-right: 10px;

  ${props => css`
    ${props.theme.media.mddown} {
      font-weight: ${props => props.theme.vars.fonts.weights.medium};
      padding-bottom: 2px;
    }
  `}
`

export default CAdvancedSupport
