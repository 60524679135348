//  API
export const REQUEST_SEND_CONTACT_REQUEST = "REQUEST_SEND_CONTACT_REQUEST"
export const RECEIVE_SEND_CONTACT_REQUEST = "RECEIVE_SEND_CONTACT_REQUEST"
export const CLEAR_CONTACT_REQUESTS = "CLEAR_CONTACT_REQUESTS"
export const REQUEST_WHITEPAPER = "REQUEST_WHITEPAPER"
export const RECEIVE_WHITEPAPER = "RECEIVE_WHITEPAPER"
export const REQUEST_ADD_NEWSLETTER_SUBSCRIBER = "REQUEST_ADD_NEWSLETTER_SUBSCRIBER"
export const RECEIVE_ADD_NEWSLETTER_SUBSCRIBER = "RECEIVE_ADD_NEWSLETTER_SUBSCRIBER"
export const RECEIVE_SUGGESTED_CURRENCY = "RECEIVE_SUGGESTED_CURRENCY"
export const REQUEST_PUBLIC_WALLS_FOR_GALLERY = "REQUEST_PUBLIC_WALLS_FOR_GALLERY"
export const RECEIVE_PUBLIC_WALLS_FOR_GALLERY = "RECEIVE_PUBLIC_WALLS_FOR_GALLERY"
export const REQUEST_KNOWLEDGEBASE_ARTICLES = "REQUEST_KNOWLEDGEBASE_ARTICLES"
export const RECEIVE_KNOWLEDGEBASE_ARTICLES = "RECEIVE_KNOWLEDGEBASE_ARTICLES"

export const REQUEST_POST_DATA = "REQUEST_POST_DATA"
export const RECEIVE_POST_DATA = "RECEIVE_POST_DATA"
export const CLEAR_POST_DATA = "CLEAR_POST_DATA"

export const REQUEST_GEO_INFOS = "REQUEST_GEO_INFOS"
export const RECEIVE_GEO_INFOS = "RECEIVE_GEO_INFOS"

export const RECEIVE_GALLERY_STATS = "RECEIVE_GALLERY_STATS"

//  Branch
export const RECEIVE_BRANCH_LINKING_KEY_DATA = "RECEIVE_BRANCH_LINKING_KEY_DATA"
export const RECEIVE_BRANCH_SHARE_LINK_INFOS = "RECEIVE_BRANCH_SHARE_LINK_INFOS"

//  Device
export const SET_STAGE_DIMENSIONS = "SET_STAGE_DIMENSIONS"
export const SET_TAB_VISIBLE = "SET_TAB_VISIBLE"

//  Routes
export const REQUEST_SITEMAP = "REQUEST_SITEMAP"
export const RECEIVE_SITEMAP = "RECEIVE_SITEMAP"
export const REQUEST_GLOBALS = "REQUEST_GLOBALS"
export const RECEIVE_GLOBALS = "RECEIVE_GLOBALS"

//  Session
export const SET_WAYPOINT_THEME_KEY = "SET_WAYPOINT_THEME_KEY"
export const SHOW_ALERT = "SHOW_ALERT"
export const SHOW_VIDEO_OVERLAY = "SHOW_VIDEO_OVERLAY"
export const HIDE_VIDEO_OVERLAY = "HIDE_VIDEO_OVERLAY"
export const SHOW_LIGHTBOX = "SHOW_LIGHTBOX"
export const HIDE_LIGHTBOX = "HIDE_LIGHTBOX"
export const SET_REFERRER_CODE = "SET_REFERRER_CODE"
export const SHOW_REFERRER_CODE_POPUP = "SHOW_REFERRER_CODE_POPUP"
export const CLOSE_REFERRER_CODE_POPUP = "CLOSE_REFERRER_CODE_POPUP"
export const SET_EMBEDDED_MODE_ACTIVE = "SET_EMBEDDED_MODE_ACTIVE"
export const SHOW_IMAGE_OVERLAY = "SHOW_IMAGE_OVERLAY"
export const HIDE_IMAGE_OVERLAY = "HIDE_IMAGE_OVERLAY"

//  User
export const REQUEST_WORDPRESS_USER = "REQUEST_WORDPRESS_USER"
export const RECEIVE_WORDPRESS_USER = "RECEIVE_WORDPRESS_USER"
