import React, {Component} from "react"
import PropTypes from "prop-types"
import {connect} from "react-redux"
import {injectIntl} from "react-intl"
import isEqual from "lodash/isEqual"
import styled, {css} from "styled-components" // eslint-disable-line
import {motion, useViewportScroll, useTransform, AnimatePresence} from "framer-motion" // eslint-disable-line
import ReactPlayer from "react-player"

import * as selectors from "../../data/selectors"
import * as actions from "../../data/actions"
import CIcon from "../../view/components/CIcon"

const debby = (funcName, data = null) => console.log("SHome." + funcName, data) // eslint-disable-line
const god = selectors.getObjectDeep

class CVideoOverlay extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    shown: PropTypes.bool,
    header: PropTypes.string,
    youtubeUrl: PropTypes.string,
  }
  static defaultProps = {
    debug: false && __DEV__,
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  onCloseClick = () => this.props.dispatch(actions.closeVideoOverlay())

  onError = error => {
    debugger
    throw error
  }

  render = () => {
    const {debug, shown, header, youtubeUrl} = this.props
    const d = {debug: debug || CVideoOverlay.defaultProps.debug}
    return (
      <AnimatePresence>
        {shown && (
          <PresenceContainer initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}}>
            <HeaderContainer {...d}>
              <TitleContainer {...d}>{header}</TitleContainer>
              <CloseContainer {...d} onClick={this.onCloseClick}>
                <CIcon id={`MobileMenuClose`} color={"white"} />
              </CloseContainer>
            </HeaderContainer>
            {!!youtubeUrl && (
              <AspectContainer {...d} ratio={16 / 9}>
                <PlayerContent {...d}>
                  <ReactPlayer
                    //
                    playing
                    url={youtubeUrl}
                    width={"100%"}
                    height={"100%"}
                    onError={this.onError}
                    // config={{youtube: {playerVars: {showinfo: 0, modestbranding: 1}}}}
                  />
                </PlayerContent>
              </AspectContainer>
            )}
          </PresenceContainer>
        )}
      </AnimatePresence>
    )
  }
}

const PresenceContainer = styled(motion.div)`
  position: fixed;
  z-index: 20001; // in front of CHeaderMenu
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  ${props => selectors.getPaddingLeftRightByThemeAndVariant(props.theme, "header")}
  background-color: ${props => props.theme.vars.colors.codGray};
`

const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  ${props => false && props.debug && selectors.getDebugOverlayCss(props, `HeaderContainer`, `rgba(255,0,255,0.35)`)}
`

const TitleContainer = styled.div`
  flex: 1;
  align-self: stretch;
  padding-bottom: 40px;
  color: white;
  letter-spacing: 0.1em;
  font-size: 16px;
  ${props => props.debug && selectors.getDebugOverlayCss(props, `TitleContainer`, `rgba(255,0,255,0.35)`)}
`

const CloseContainer = styled.div`
  width: 50px;
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0.5;
  :hover {
    opacity: 1;
  }
  transition: opacity 0.2s ease-in-out;
  ${props => props.debug && selectors.getDebugOverlayCss(props, `CloseContainer`, `rgba(255,255,0,0.35)`)}
`

const AspectContainer = styled.div`
  position: relative;
  width: 100%;
  padding-top: ${props => (1 / props.ratio) * 100}%;
  ${props => props.debug && selectors.getDebugOverlayCss(props, `AspectContainer`, `rgba(255,0,255,0.35)`)}
`

const PlayerContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

// eslint-disable-next-line
const mapStateToProps = (state, props) => ({
  shown: god(state, "session.videoOverlay.shown", false),
  header: god(state, "session.videoOverlay.header", ""),
  youtubeUrl: god(state, "session.videoOverlay.youtubeUrl", ""),
})
export default injectIntl(connect(mapStateToProps)(CVideoOverlay))
