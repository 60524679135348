import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import styled, {css} from "styled-components" // eslint-disable-line
import * as selectors from "../../../data/selectors" // eslint-disable-line

class CH4 extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    text: PropTypes.string,
    variant: PropTypes.oneOf(["default", "home-hero", "home-demo", "porfolio-top"]),
  }
  static defaultProps = {
    debug: false && __DEV__,
    variant: "default",
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  render = () => {
    const {debug, text, variant} = this.props
    const d = {debug: debug || CH4.defaultProps.debug}
    return <H4 variant={variant} {...d} dangerouslySetInnerHTML={{__html: text}} />
  }
}

const fontSizeXl = 21
const lineHeightXl = 25
const scaleLg = 0.9
const scaleMd = 0.7

const H4 = styled.h4`
  letter-spacing: 0.15em;
  font-kerning: none;
  text-transform: uppercase;
  padding: 0;
  margin: 0;

  hyphens: auto;

  font-weight: ${props => props.theme.vars.fonts.weights.extrabold};

  font-size: ${fontSizeXl}px;
  line-height: ${lineHeightXl}px;
  ${props => css`
    ${props.theme.media.lg} {
      font-size: ${fontSizeXl * scaleLg}px;
      line-height: ${lineHeightXl * scaleLg}px;
    }
    ${props.theme.media.md} {
      font-size: ${fontSizeXl * scaleMd}px;
      line-height: ${lineHeightXl * scaleMd}px;
    }
  `}

  ${props => selectors.getDebugOverlayCss(props, "H4", "rgba(0,255,0,0.25)")}
`

export default CH4
