import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import {injectIntl} from "react-intl"
import {connect} from "react-redux"
// import {createSelector} from "reselect"
import styled, {css, ThemeProvider} from "styled-components" // eslint-disable-line

import * as selectors from "../../data/selectors"
import * as actions from "../../data/actions"

const DEBUG_DEFAULT = false && __DEV__ // because of injectIntl

import CFloatingInput from "../../view/components/CFloatingInput"
import CButton from "../../view/components/CButton"
import CCollapse from "../../view/components/CCollapse"
import CSmallprint from "../../view/components/text/CSmallprint"

const god = selectors.getObjectDeep

class CFooterNewsletter extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    globalsLocalized: PropTypes.object,
    sitemapData: PropTypes.array,
    intlLocale: PropTypes.string,
    subscribeRequestBusy: PropTypes.bool,
  }
  static defaultProps = {
    debug: DEBUG_DEFAULT,
  }

  constructor(props) {
    super(props)
    this.state = {
      email: "",
      // email: __DEV__ ? "skizzo@backlab.at" : "", // DEBUG
      formValid: false,
      formSubmittedOnce: false,
      formRequestedOnce: false,
      formRequestResult: null,
      requestPerformedInDryMode: undefined,
    }
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  validateInput = (value, name) => {
    switch (name) {
      case "email": {
        return !!value && selectors.getEmailIsValid(value)
      }
    }
    return true
  }

  getFormIsValid = () => {
    const formValid = this.validateInput(this.state.email, "email")
    this.setState({formValid})
    return formValid
  }

  onInputChange = email => this.setState({email})

  submitForm = event => {
    !!event && event.preventDefault()
    const {dispatch, intl} = this.props
    this.setState({formSubmittedOnce: true, formRequestedOnce: true}, () => {
      if (!this.getFormIsValid()) {
        return
      }
      const fields = {
        lang: intl.locale,
        email: this.state.email,
      }
      dispatch(actions.addNewsletterSubscriber(fields)).then(({success, error, errorIntlId, dryMode}) => {
        this.setState({formRequestResult: {success, error, errorIntlId}, requestPerformedInDryMode: dryMode})
      })
    })
  }

  onPrivacyPolicyLinkPress = hrefProps => window.open(hrefProps.href)

  render = () => {
    const {email} = this.state
    const {intl, debug, subscribeRequestBusy, globalsLocalized, sitemapData, intlLocale} = this.props

    const {formSubmittedOnce, requestPerformedInDryMode} = this.state // client-side validation
    const {formRequestedOnce, formRequestResult} = this.state // server result
    const d = {debug: debug || DEBUG_DEFAULT}

    const formRequestSuccess = god(formRequestResult, "success", false)
    const showResult = formSubmittedOnce && !subscribeRequestBusy && formRequestedOnce && !!formRequestResult
    const inputsDisabledAfterSubmit = showResult && formRequestSuccess
    const disabled = subscribeRequestBusy || inputsDisabledAfterSubmit

    const hrefProps = selectors.getHrefProps("/other/privacy-policy", {sitemapData, locale: intlLocale})
    // const text = intl.formatMessage({id: `ContactSmallprintPrivacyPolicy`})
    const text = god(globalsLocalized, "sectionNewsletter.privacyPolicy", "")
    const privacyPolicyHtml = text.split(`<link>`).join(`<a href='${hrefProps.linkToWithBasePath}'>`).split("</link>").join("</a>") // prettier-ignore
    return (
      <Container {...d}>
        <CCollapse collapsed={formRequestSuccess}>
          <Header dangerouslySetInnerHTML={{__html: god(globalsLocalized, "sectionNewsletter.header")}} />
          <FormContainer>
            <InputContainer {...d}>
              <CFloatingInput
                //
                {...d}
                themeKey={"dark"}
                size={"small"}
                placeholder={intl.formatMessage({id: `ContactKeyEmail`})}
                name={"email"}
                value={email}
                type={"email"}
                validateInput={this.validateInput}
                required
                onChange={this.onInputChange}
                errorText={intl.formatMessage({id: `FormErrorInvalidEmail`})}
                showError={formSubmittedOnce}
                onSubmit={this.submitForm}
                disabled={disabled}
              />
            </InputContainer>
            <Button {...d}>
              <CButton
                //
                label={god(globalsLocalized, "sectionNewsletter.button")}
                onClick={this.submitForm}
                canBeBusy
                disabled={disabled}
                busy={debug || subscribeRequestBusy}
                themeKey={"transparent-light"}
                size={"small"}
                noHoverScale // weirdly enough, there is some hiddden overflow somewhere
              />
            </Button>
          </FormContainer>
          <PrivacyPolicyContainer {...d}>
            <ThemeProvider theme={{textColor: "white"}}>
              <CSmallprint
                //
                text={privacyPolicyHtml}
                onLinkPress={this.onPrivacyPolicyLinkPress}
              />
            </ThemeProvider>
          </PrivacyPolicyContainer>
        </CCollapse>
        <CCollapse collapsed={!formRequestSuccess}>
          <Header dangerouslySetInnerHTML={{__html: god(globalsLocalized, "sectionNewsletter.thankYou", "") + (requestPerformedInDryMode ? " <br/>(DRY MODE)" : "")}} />
        </CCollapse>
      </Container>
    )
  }
}

const Container = styled.div`
  color: white;
  overflow: visible;
  ${props => props.debug && selectors.getDebugOverlayCss(props, `LogoContainer`, `rgba(255,0,255,0.35)`)}
`

const Header = styled.div`
  text-transform: uppercase;
  font-size: 13px;
  line-height: 1.5;
  letter-spacing: 0.1em;
  font-kerning: none;
`

const FormContainer = styled.div`
  display: flex;
  width: 100%;
  padding-top: 10px;
`

const PrivacyPolicyContainer = styled.div`
  padding-top: 5px;
`

const InputContainer = styled.div`
  flex: 1;
  padding-right: 10px;
  ${props => props.debug && selectors.getDebugOverlayCss(props, `InputContainer`, `rgba(255,0,255,0.35)`)}
`

const Button = styled.div`
  padding-top: 10px; // must match <CButton size="small"/>
  ${props => props.debug && selectors.getDebugOverlayCss(props, `Button`, `rgba(0,255,255,0.35)`)}
`

const mapStateToProps = state => ({
  intlLocale: selectors.getIntlLocale(state),
  sitemapData: selectors.getSitemapData(state),
  globalsLocalized: selectors.getGlobalsLocalized(state),
  subscribeRequestBusy: selectors.getSubscribeRequestBusy(state), // one of all in session
})
export default injectIntl(connect(mapStateToProps)(CFooterNewsletter))
