import React, {Component, Fragment} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import styled, {css} from "styled-components" // eslint-disable-line

import * as selectors from "../../../data/selectors"
// const god = selectors.getObjectDeep

import CUmbrellaContactButton from "../../../view/components/CUmbrellaContactButton"

const DEBUG = false && __DEV__
import {debbify} from "../../../data/selectors/helpers"
const debby = (...args) => debbify("CExhibitorsDownload", ...args)

class CExhibitorsDownload extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    infoParagraph: PropTypes.string,
    contactLabel: PropTypes.string,
  }
  static defaultProps = {
    debug: false,
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  render = () => {
    const {infoParagraph, contactLabel} = this.props
    const d = {debug: DEBUG || this.props.debug} // eslint-disable-line
    if (!infoParagraph) {
      return <div />
    }
    debby("render()")
    return (
      <Fragment>
        <DownloadInfoParagraph {...d} dangerouslySetInnerHTML={{__html: selectors.getHtmlSafe(infoParagraph)}} />
        {!!contactLabel && <CUmbrellaContactButton debug={d.debug} align={"center"} label={contactLabel} />}
      </Fragment>
    )
  }
}

const DownloadInfoParagraph = styled.div`
  color: #000000;
  letter-spacing: 2.5px;
  text-align: center;
  border: ${props => (props.debug ? 1 : 0)}px solid blue;

  font-size: 26px;
  line-height: 36px;
  ${props => css`
    ${props.theme.media.md} {
      font-size: 24px;
      line-height: 39px;
    }
    ${props.theme.media.smdown} {
      font-size: 22px;
      line-height: 30.6px;
      padding-top: 85px;
      padding-bottom: 35px;
    }
  `}
`

export default CExhibitorsDownload
