import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import styled, {css} from "styled-components" // eslint-disable-line

import * as selectors from "../../../data/selectors"

import CCollapse from "../../../view/components/CCollapse" // eslint-disable-line
import CIcon from "../../../view/components/CIcon"

import CH4 from "../../../view/components/text/CH4"

const DEBUG = false && __DEV__
import {debbify} from "../../../data/selectors/helpers"
const debby = (...args) => debbify("CExhibitorsAdditionalServices", ...args)

class CExhibitorsAdditionalServices extends Component {
  static propTypes = {
    mediaQueryClass: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]).isRequired,
    header: PropTypes.string,
    services: PropTypes.array,
  }

  constructor(props) {
    super(props)
    this.state = {
      servicesCollapsed: !DEBUG,
    }
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  toggleServicesCollapsed = () => this.setState({servicesCollapsed: !this.state.servicesCollapsed})

  renderServiceItem = (item, index) => {
    const d = {debug: DEBUG}
    return (
      <ServiceItem {...d} key={`item_${index}`}>
        <ItemIcon {...d}>
          <CIcon id={"FeatureChartAvailable"} />
        </ItemIcon>
        <ItemText {...d}>{item}</ItemText>
      </ServiceItem>
    )
  }

  render = () => {
    const {servicesCollapsed} = this.state
    const {header, services} = this.props
    const d = {debug: DEBUG}
    if (!header || !services || !services.length) {
      return <div />
    }
    debby("render()")
    return (
      <AllContainer {...d} variant={`header`}>
        <PaddedContainer {...d}>
          <HeaderContainer {...d} onClick={this.toggleServicesCollapsed}>
            <Header {...d}>
              <CH4 text={header} />
            </Header>
            <CaretIcon {...d}>
              <CIcon id={`HeaderMenuCaretDown`} rotationDegrees={servicesCollapsed ? 0 : 180} />
            </CaretIcon>
          </HeaderContainer>
          <CCollapse collapsed={servicesCollapsed}>
            <ContentContainer {...d}>{services.map(this.renderServiceItem)}</ContentContainer>
          </CCollapse>
        </PaddedContainer>
      </AllContainer>
    )
  }
}

const AllContainer = styled.div`
  ${props => true && selectors.getDebugOverlayCss(props, "AllContainer", "rgba(255,255,0,0.25)")}
  ${props => selectors.getPaddingLeftRightByThemeAndVariant(props.theme, props.variant, "padding")} // background-color: red;
`

const PaddedContainer = styled.div`
  ${props => true && selectors.getDebugOverlayCss(props, "PaddedContainer", "rgba(255,0,0,0.25)")}
  background-color: white;

  padding: 20px 40px;
  ${props => css`
    ${props.theme.media.smdown} {
      padding: 20px 0px;
    }
  `}
`

const HeaderContainer = styled.div`
  ${props => true && selectors.getDebugOverlayCss(props, "CExhibitorsAdditionalServices", "rgba(255,0,0,0.25)")}
  display: flex;
  padding-bottom: 20px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
`

const Header = styled.div`
  ${props => true && selectors.getDebugOverlayCss(props, "CExhibitorsAdditionalServices", "rgba(0,0,255,0.25)")}
  flex: 1;
  padding-right: 20px;
`

const CaretIcon = styled.div`
  ${props => true && selectors.getDebugOverlayCss(props, "Caret", "rgba(255,0,0,0.25)")}
  width: 25px;
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
`

const ContentContainer = styled.div`
  // background-color: red;
`

const ServiceItem = styled.div`
  border: ${props => (props.debug ? 1 : 0)}px solid blue;
  margin: 30px 0px;
  display: flex;
`

const ItemIcon = styled.div`
  width: 40px;
  transform: translate3d(0px, 4px, 0px);
`
const ItemText = styled.div`
  flex: 1;
  line-height: 1.4em;
  letter-spacing: 0.1em;
  color: #1e1e1e;

  font-size: 21px;
  ${props => css`
    ${props.theme.media.sm} {
      font-size: 17px;
    }
    ${props.theme.media.xs} {
      font-size: 16px;
    }
  `}
`

export default CExhibitorsAdditionalServices
