import React, {Component} from "react"
import PropTypes from "prop-types" // eslint-disable-line
import isEqual from "lodash/isEqual"
import {injectIntl} from "react-intl"
import {connect} from "react-redux"
import styled, {css} from "styled-components" // eslint-disable-line

import * as selectors from "../../../data/selectors"
import * as actions from "../../../data/actions"

import CImage from "../../../view/components/CImage"
import CButton from "../../../view/components/CButton"
import CEditInWordPress from "../../../view/components/CEditInWordPress"

const DEBUG_DEFAULT = false && __DEV__ // because of injectIntl

class CPressAppearance extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    dateTimestamp: PropTypes.number.isRequired,
    featuredImage: PropTypes.object.isRequired,
    logoBackgroundColor: PropTypes.string,
    link: PropTypes.string,
    externalLinks: PropTypes.array,
    additionalImages: PropTypes.array,
    hideTitle: PropTypes.bool,
    showEditButton: PropTypes.bool,
  }
  static defaultProps = {
    debug: DEBUG_DEFAULT,
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  showGallery = () => {
    const {dispatch, additionalImages} = this.props
    console.log(additionalImages)
    dispatch(actions.showLightbox(additionalImages))
  }

  render = () => {
    const {debug, intl, id, title, featuredImage, logoBackgroundColor, dateTimestamp, additionalImages, link, hideTitle, showEditButton} = this.props
    const d = {debug: debug || DEBUG_DEFAULT}
    const imageData = selectors.getSrcAndSrcSetByWordpressImage(featuredImage)
    const dateReadable = selectors.getTimestampFormattedString({intl, timestamp: dateTimestamp * 1000, formatIntlId: `DateTimeReadableDate`})
    const hasImages = !!additionalImages && !!additionalImages.length
    const hasLink = !hasImages && !!link
    return (
      <Container {...d}>
        <LogoContainer {...d} backgroundColor={logoBackgroundColor}>
          <CImage src={imageData.src} objectFit={"contain"} srcSet={imageData.srcSet} ratio={220 / 100} />
        </LogoContainer>
        {!hideTitle && <Title {...d}>{title}</Title>}
        <Date {...d}>{dateReadable}</Date>

        <ButtonContainer {...d}>
          {hasImages && (
            <CButton
              //
              label={intl.formatMessage({id: `PressAppearanceViewImages`})}
              variant={`press-appearance`}
              onClick={this.showGallery}
            />
          )}
          {hasLink && (
            <CButton
              //
              label={intl.formatMessage({id: `PressAppearanceVisitWebsite`})}
              variant={`press-appearance`}
              urlTo={link}
            />
          )}
        </ButtonContainer>

        {showEditButton && <CEditInWordPress resourceId={id} label={"EDIT"} />}
      </Container>
    )
  }
}

const Container = styled.div`
  padding: 30px 40px;
  ${props => props.debug && selectors.getDebugOverlayCss(props, `Container`, `rgba(0,0,255,0.35)`)}
  position: relative;
  background-color: white;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const LogoContainer = styled.div`
  width: 100%;
  background-color: ${props => props.backgroundColor};
  ${props => props.debug && selectors.getDebugOverlayCss(props, `LogoContainer`, `rgba(255,0,255,0.35)`)}
`

const Title = styled.div`
  margin-top: 20px;
  text-align: center;
  ${props => selectors.getPersonNameFontCss(props)}
  text-transform: none;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${props => props.debug && selectors.getDebugOverlayCss(props, `Title`, `rgba(255,0,255,0.35)`)}
`

const Date = styled.div`
  margin-top: 20px;
  text-align: center;
  font-style: italic;
  font-size: 16px;
  letter-spacing: 0.1em;
  font-kerning: none;
  opacity: 0.5;
  ${props => props.debug && selectors.getDebugOverlayCss(props, `Title`, `rgba(255,0,255,0.35)`)}
`

const ButtonContainer = styled.div`
  margin-top: 20px;
  ${props => props.debug && selectors.getDebugOverlayCss(props, `ButtonContainer`, `rgba(0,0,255,0.35)`)}
`

//  eslint-disable-next-line
const mapStateToProps = state => ({})
export default injectIntl(connect(mapStateToProps)(CPressAppearance))
