import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"

import WaypointContext from "../context/WaypointContext"

// const debby = (f, d = null) => console.log("CWaypointProvider." + f, d)

class CWaypointProvider extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    children: PropTypes.any,
    onWaypointChange: PropTypes.func,
  }

  constructor(props) {
    super(props)
    this.state = {}
    this.mountedWaypoints = {} // key == id
    this.waypointIds = [] // active ones
    this.usedWaypointId = null
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  componentWillUnmount = () => {
    clearTimeout(this.updateId)
  }

  onWaypointMount = (id, themeKey, extraData) => {
    // debby(`onWaypointMount(${id}, ${themeKey})`)
    this.mountedWaypoints[id] = {themeKey, extraData}
  }

  // eslint-disable-next-line
  onWaypointEnter = (id, extraData) => {
    this.waypointIds.indexOf(id) === -1 && this.waypointIds.push(id)
    // debby(`onWaypointEnter(${id})`, {waypointIds: this.waypointIds.join(","), ...extraData})
    clearTimeout(this.updateId)
    this.updateId = setTimeout(this.updateDesignByWaypointIds, 300)
  }

  onWaypointLeave = id => {
    this.waypointIds.indexOf(id) !== -1 && this.waypointIds.splice(this.waypointIds.indexOf(id), 1)
    // debby(`onWaypointLeave(${id})`)
    clearTimeout(this.updateId)
    this.updateId = setTimeout(this.updateDesignByWaypointIds, 300)
  }

  onWaypointUnmount = id => {
    // debby(`onWaypointUnmount(${id})`)
    if (this.mountedWaypoints.hasOwnProperty(id)) {
      delete this.mountedWaypoints[id]
    }
  }

  updateDesignByWaypointIds = () => {
    const {waypointIds} = this // active ones

    let usedId = null
    for (const mountedWaypointId of Object.keys(this.mountedWaypoints)) {
      if (!usedId && waypointIds.indexOf(mountedWaypointId) != -1) {
        usedId = mountedWaypointId
      }
    }

    if (this.usedWaypointId != usedId) {
      this.usedWaypointId = usedId
      const {themeKey, extraData} = this.mountedWaypoints[usedId] || {themeKey: null}
      // debby("updateDesignByWaypointIds() ", {usedId, themeKey, ...extraData})
      const {onWaypointChange} = this.props
      !!onWaypointChange && onWaypointChange(usedId, themeKey, extraData)
    }
  }

  render = () => {
    return (
      <WaypointContext.Provider
        //
        value={{
          onEnterProvider: this.onWaypointEnter,
          onLeaveProvider: this.onWaypointLeave,
          onWaypointMount: this.onWaypointMount,
          onWaypointUnmount: this.onWaypointUnmount,
        }}>
        {this.props.children}
      </WaypointContext.Provider>
    )
  }
}

export default CWaypointProvider
