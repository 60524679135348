import SHome from "../view/screens/SHome"
import SPortfolio from "../view/screens/SPortfolio"
import SPortfolioDetails from "../view/screens/SPortfolioDetails"
import SAboutUs from "../view/screens/SAboutUs"
import SDemo from "../view/screens/SDemo"
import SPress from "../view/screens/SPress"
import SFAQs from "../view/screens/SFAQs"
import SContact from "../view/screens/SContact"
import SOther from "../view/screens/SOther"
import SLinking from "../view/screens/SLinking"
import SGalleryLanding from "../view/screens/SGalleryLanding"
import SGalleryLandingArtwork from "../view/screens/SGalleryLandingArtwork"
import SKnowledgeBase from "../view/screens/SKnowledgeBase"
import SKnowledgeBaseArticle from "../view/screens/SKnowledgeBaseArticle"

// Umbrella
import SExhibitors from "../view/screens/SExhibitors"
import SArtLovers from "../view/screens/SArtLovers"
import SUmbrella from "../view/screens/SUmbrella"

// OVR
import SOVR from "../view/screens/SOVR"

export const getScreenComponentForPageTemplate = pageTemplate => {
  switch (pageTemplate) {
    case "home": {
      return SHome
    }
    case "portfolio": {
      return SPortfolio
    }
    case "portfolio-art-galleries":
    case "portfolio-art-institutions":
    case "portfolio-artists": {
      return SPortfolioDetails
    }
    case "aboutus": {
      return SAboutUs
    }
    case "demo": {
      return SDemo
    }
    case "press": {
      return SPress
    }
    case "other-faq": {
      return SFAQs
    }
    case "contact": {
      return SContact
    }
    case "other-terms-and-conditions": {
      return SOther
    }
    case "other-privacy-policy": {
      return SOther
    }
    case "linking": {
      return SLinking
    }
    case "gallery-landing": {
      return SGalleryLanding
    }
    case "gallery-landing-artwork": {
      return SGalleryLandingArtwork
    }
    case "exhibitors": {
      return SExhibitors
    }
    case "artlovers": {
      return SArtLovers
    }
    case "umbrella": {
      return SUmbrella
    }
    case "ovr": {
      return SOVR
    }
    case "knowledgebase": {
      return SKnowledgeBase
    }
    case "knowledgebase-article": {
      return SKnowledgeBaseArticle
    }
    case undefined:
      return null
    default: {
      if (__DEV__) {
        throw new Error(`No component found for pageTemplate '${pageTemplate}'.`)
      }
      return null
    }
  }
}
