import {addLocaleData} from "react-intl" // eslint-disable-line
import messagesEn from "./messages_en.json"
import {CONFIG} from "../config" // eslint-disable-line

const dummyPluralRuleFunction = () => true

export const baseLang = CONFIG.intl.baseLang
export const langs = CONFIG.intl.langs

//  messages are being generated by scripts/poe.js
const importMessages = lang => {
  return new Promise(resolve => {
    //  prettier-ignore
    const proceedAfterImport = (imp => resolve (imp.default))
    if (lang == "en") {
      resolve(messagesEn)
    } else if (lang == "de") {
      import(/* webpackChunkName: "messages-de" */ "./messages_de.json").then(proceedAfterImport)
    } else if (lang == "es") {
      import(/* webpackChunkName: "messages-es" */ "./messages_es.json").then(proceedAfterImport)
    } else if (lang == "fr") {
      import(/* webpackChunkName: "messages-fr" */ "./messages_fr.json").then(proceedAfterImport)
    }
  })
}

export const getLangDynamic = lang => {
  return new Promise(resolve => {
    importMessages(lang).then(messages => {
      const data = {
        locale: lang,
        pluralRuleFunction: dummyPluralRuleFunction,
        messages: messages,
      }
      addLocaleData(data)
      resolve({
        messages,
        data,
      })
    })
  })
}

//  prettier-ignore
export const getCountryCodes = () => ["AC", "AD", "AE", "AF", "AG", "AI", "AL", "AM", "AO", "AQ", "AR", "AS", "AT", "AU", "AW", "AX", "AZ", "BA", "BB", "BD", "BE", "BF", "BG", "BH", "BI", "BJ", "BL", "BM", "BN", "BO", "BQ", "BR", "BS", "BT", "BW", "BY", "BZ", "CA", "CC", "CD", "CF", "CG", "CH", "CI", "CK", "CL", "CM", "CN", "CO", "CR", "CU", "CV", "CW", "CX", "CY", "CZ", "DE", "DG", "DJ", "DK", "DM", "DO", "DZ", "EA", "EC", "EE", "EG", "EH", "ER", "ES", "ET", "FI", "FJ", "FK", "FM", "FO", "FR", "GA", "GB", "GD", "GE", "GF", "GG", "GH", "GI", "GL", "GM", "GN", "GP", "GQ", "GR", "GS", "GT", "GU", "GW", "GY", "HK", "HN", "HR", "HT", "HU", "IC", "ID", "IE", "IL", "IM", "IN", "IO", "IQ", "IR", "IS", "IT", "JE", "JM", "JO", "JP", "KE", "KG", "KH", "KI", "KM", "KN", "KP", "KR", "KW", "KY", "KZ", "LA", "LB", "LC", "LI", "LK", "LR", "LS", "LT", "LU", "LV", "LY", "MA", "MC", "MD", "ME", "MF", "MG", "MH", "MK", "ML", "MM", "MN", "MO", "MP", "MQ", "MR", "MS", "MT", "MU", "MV", "MW", "MX", "MY", "MZ", "NA", "NC", "NE", "NF", "NG", "NI", "NL", "NO", "NP", "NR", "NU", "NZ", "OM", "PA", "PE", "PF", "PG", "PH", "PK", "PL", "PM", "PN", "PR", "PS", "PT", "PW", "PY", "QA", "RE", "RO", "RS", "RU", "RW", "SA", "SB", "SC", "SD", "SE", "SG", "SH", "SI", "SJ", "SK", "SL", "SM", "SN", "SO", "SR", "SS", "ST", "SV", "SX", "SY", "SZ", "TA", "TC", "TD", "TF", "TG", "TH", "TJ", "TK", "TL", "TM", "TN", "TO", "TR", "TT", "TV", "TW", "TZ", "UA", "UG", "UM", "US", "UY", "UZ", "VA", "VC", "VE", "VG", "VI", "VN", "VU", "WF", "WS", "XK", "YE", "YT", "ZA", "ZM", "ZW"]
