import React from "react"
import Loadable from "react-loadable"

// import SPortfolio from "./SPortfolio_web"
// export default SPortfolio

export default Loadable({
  loader: () => import(/* webpackChunkName: "SPortfolio" */ "./SPortfolio_web"),
  loading: () => <div />,
  // modules: ["LandingChunk"],
})
