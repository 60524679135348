import * as actionsConsts from "../actionsConsts"

export const initialState = {
  sitemap: {
    fetching: false,
    data: null,
  },
  globals: {
    fetching: false,
    data: null,
  },
}

export function routes(state = initialState, action) {
  // persistent
  switch (action.type) {
    case actionsConsts.REQUEST_SITEMAP: {
      return {...state, sitemap: {...state.sitemap, fetching: true}}
    }
    case actionsConsts.RECEIVE_SITEMAP: {
      const data = !!action.data && !!action.data.length ? action.data : [] // already includes hidden items for gallery-landing, etc.
      return {...state, sitemap: {...state.sitemap, fetching: false, data}}
    }
    case actionsConsts.REQUEST_GLOBALS: {
      return {...state, globals: {...state.globals, fetching: true}}
    }
    case actionsConsts.RECEIVE_GLOBALS: {
      return {...state, globals: {...state.globals, fetching: false, data: action.data}}
    }
    default: {
      return state
    }
  }
}
