import React, {Component} from "react"
import PropTypes, {instanceOf} from "prop-types"
import isEqual from "lodash/isEqual"
import {injectIntl} from "react-intl"
import {connect} from "react-redux"
import styled, {css} from "styled-components" // eslint-disable-line
import {withCookies, Cookies} from "react-cookie"
import * as selectors from "../../data/selectors"
const god = selectors.getObjectDeep

const COOKIE_ACCEPTED_NAME = "acceptedIazzu"

class CCookieNotice extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    globalsLocalized: PropTypes.object,
    accepted: PropTypes.bool,
    cookies: instanceOf(Cookies).isRequired,
  }
  static defaultProps = {
    debug: false && __DEV__,
  }

  componentDidMount = () => {
    const d = {debug: this.props.debug || CCookieNotice.defaultProps.debug}
    if (d.debug) {
      setTimeout(() => {
        this.props.cookies.set(COOKIE_ACCEPTED_NAME, JSON.stringify(false), {path: "/"})
      }, 500)
    }
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  onOkayClick = e => {
    !!e && e.preventDefault()
    this.props.cookies.set(COOKIE_ACCEPTED_NAME, JSON.stringify(!this.props.accepted), {path: "/"})
  }

  render = () => {
    const {debug, globalsLocalized, accepted} = this.props
    const d = {debug: debug || CCookieNotice.defaultProps.debug}
    const paragraph = god(globalsLocalized, "sectionCookieNotice.paragraph", "")
    const button = god(globalsLocalized, "sectionCookieNotice.button", "")
    if (!d.debug && accepted) {
      return <div />
    }
    if (!paragraph || !button) {
      return <div />
    }
    return (
      <Container {...d} variant={"header"} accepted={accepted}>
        <Content {...d}>{paragraph}</Content>
        <Button {...d} onClick={this.onOkayClick}>
          {button}
        </Button>
      </Container>
    )
  }
}

const Container = styled.div`
  position: fixed;
  z-index: 5;
  bottom: 0;
  right: 0;
  left: 0;
  ${props => selectors.getPaddingLeftRightByThemeAndVariant(props.theme, props.variant)}
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;
  background-color: ${props => (props.debug ? "yellow" : "#F6F6F6")};
  border-top: 1px solid #DDDDDD;
  opacity: ${props => (props.accepted ? 0.2 : 1)};
}
`

const Content = styled.div`
  flex: 1;
  align-self: stretch;
  color: ${props => props.theme.vars.colors.codGray};
  background-color: ${props => (props.debug ? "#ff000066" : "none")};
  font-style: italic;
  letter-spacing: 0.1em;
  font-kerning: none;
  line-height: 1.4;
  padding-right: 20px;
  font-size: 17px;
  ${props =>
    css`
      ${props.theme.media.md} {
        font-size: 16px;
      }
      ${props.theme.media.sm} {
        font-size: 15px;
      }
      ${props.theme.media.xs} {
        font-size: 13px;
      }
    `}
`

const Button = styled.div`
  width: auto;
  min-width: 0;
  align-self: stretch;
  border: 2px solid ${props => props.theme.vars.colors.codGray};
  color: ${props => props.theme.vars.colors.codGray};
  padding: 5px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.2em;
  font-kerning: none;
  cursor: pointer;
`

const mapStateToProps = (state, props) => ({
  intlLocale: selectors.getIntlLocale(state),
  globalsLocalized: selectors.getGlobalsLocalized(state),
  accepted: god(props.cookies, `cookies.${COOKIE_ACCEPTED_NAME}`, "false") == "true",
})
export default withCookies(injectIntl(connect(mapStateToProps)(CCookieNotice)))
