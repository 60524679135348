import React, {Component, Fragment} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import styled, {css} from "styled-components"
import {Parallax} from "react-scroll-parallax"

import * as selectors from "../../data/selectors"

const transformPx = input => (typeof input == "number" && !!input ? `${input}px` : input)

const DEBUG = false && __DEV__
import {debbify} from "../../data/selectors/helpers"
const debby = (...args) => debbify("CImage", ...args)

class CImage extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    src: PropTypes.any,
    srcSet: PropTypes.string,
    ratio: PropTypes.number,
    ratiosBreakpoints: PropTypes.object,
    parallaxRatio: PropTypes.number,
    waitTilImageLoaded: PropTypes.bool, // if true, children will only be drawn when image is loaded successfully
    parallaxController: PropTypes.object,
    heightGrows: PropTypes.bool,
    overlayColor: PropTypes.string,
    loadDelay: PropTypes.number, // ms

    //  css properties
    objectFit: PropTypes.oneOf(["cover", "contain", "none"]),
    backgroundColor: PropTypes.string,
    height: PropTypes.any,
    minHeight: PropTypes.any,
    maxHeight: PropTypes.any,
    opacity: PropTypes.number,
    align: PropTypes.oneOf(["center", "left"]),
    rounded: PropTypes.bool,

    children: PropTypes.node,
  }

  static defaultProps = {
    debug: DEBUG,
    parallaxRatio: 0,
    waitTilImageLoaded: false, // Safari Fix
    ratio: 16 / 9,
    ratiosBreakpoints: null,
    loadDelay: 0,

    //  css
    objectFit: "cover",
    backgroundColor: "rgba(0,0,0,0)", // transparent
    height: 0,
    minHeight: 0,
    maxHeight: 0,
    opacity: 1,
    align: "center",
  }

  constructor(props) {
    super(props)
    this.state = {
      drawImage: !!props.loadDelay ? false : true,
      imageReady: props.waitTilImageLoaded ? false : true,
    }
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  componentDidMount = () => {
    const {loadDelay} = this.props
    if (!!loadDelay) {
      this.loadTimeoutId = setTimeout(() => {
        this.setState({drawImage: true})
      }, loadDelay)
    }
  }

  componentWillUnmount = () => {
    clearTimeout(this.loadTimeoutId)
  }

  onImageLoad = event => {
    const d = {debug: DEBUG || this.props.debug}
    const {currentSrc, naturalWidth, naturalHeight} = event.target // eslint-disable-line
    d.debug && debby("onImageLoad()", {currentSrc, naturalWidth, naturalHeight})

    this.setState({imageReady: true}, () => {
      !!this.props.parallaxRatio && !!this.props.parallaxController && this.props.parallaxController.update()
    })
  }

  render = () => {
    const {drawImage, imageReady} = this.state
    const {src, srcSet, ratio, children, backgroundColor, objectFit, align, minHeight, maxHeight, parallaxRatio, height, opacity, heightGrows, overlayColor, ratiosBreakpoints, rounded} = this.props
    const d = {debug: DEBUG || this.props.debug}

    const useParallax = !!parallaxRatio
    let imageProps = {src, srcSet, objectFit, opacity: imageReady ? opacity : 0, useParallax}
    const backgroundStyle = {backgroundColor}

    let minMaxStyle = {minHeight: transformPx(minHeight), maxHeight: transformPx(maxHeight)}

    if (!!height) {
      minMaxStyle = {minHeight: transformPx(minHeight || height), maxHeight: transformPx(height)}
    }
    const parallaxStyleOuter = {alignItems: "stretch", justifyContent: "stretch"}

    if (drawImage && d.debug) {
      debby("render()", {imageReady, imageProps, ratio, objectFit})
    }
    return (
      <AspectRatioContainer {...d} ratio={ratio} ratiosBreakpoints={ratiosBreakpoints} heightGrows={heightGrows} {...minMaxStyle} {...backgroundStyle}>
        <AspectRatioContent heightGrows={heightGrows} type={"image"} {...d} {...minMaxStyle} {...backgroundStyle}>
          {drawImage && (
            <Fragment>
              {!useParallax && (
                <AspectRatioImage
                  //
                  {...d}
                  rounded={rounded}
                  align={align}
                  {...imageProps}
                  onLoad={this.onImageLoad}
                />
              )}
              {useParallax && (
                <ParallaxContainer>
                  <Parallax
                    y={[`-${parallaxRatio * 100}%`, `${parallaxRatio * 100}%`]}
                    styleOuter={{
                      display: "flex",
                      width: "100%",
                      height: "100%",
                      ...parallaxStyleOuter,
                    }}
                    styleInner={{
                      width: "100%",
                    }}
                  >
                    <AspectRatioImage
                      //
                      {...d}
                      rounded={rounded}
                      align={align}
                      {...imageProps}
                      onLoad={this.onImageLoad}
                    />
                  </Parallax>
                </ParallaxContainer>
              )}
            </Fragment>
          )}
        </AspectRatioContent>

        {!!overlayColor && <Overlay color={overlayColor} />}

        {!!children && (
          <AspectRatioContent heightGrows={heightGrows} type={"children"} {...minMaxStyle}>
            {children}
          </AspectRatioContent>
        )}
      </AspectRatioContainer>
    )
  }
}

const MinMaxHeight = props => `
  ${!!props.minHeight ? `min-height: ${props.minHeight};` : ""}
  ${!!props.maxHeight ? `max-height: ${props.maxHeight};` : ""}
`

const AspectRatioContainer = styled.div`
  position: relative;
  width: 100%;
  background-color: ${props => props.backgroundColor};
  ${props =>
    props.debug &&
    css`
      background-color: red;
    `};

  ${MinMaxHeight}

  ${props =>
    !props.heightGrows &&
    css`
      position: relative;
      padding-top: ${(1 / props.ratio) * 100}%;

      ${props.ratiosBreakpoints &&
      Object.keys(props.ratiosBreakpoints).length &&
      Object.keys(props.ratiosBreakpoints).map(
        breakpoint => css`
          ${props.theme.media[breakpoint]} {
            padding-top: ${(1 / props.ratiosBreakpoints[breakpoint]) * 100}%;
          }
        `,
      )}
    `}

  ${props => selectors.getDebugOverlayCss(props, "AspectRatioContainer", "rgba(0,255,255,0.35)")}
`

//  prettier-ignore
const AspectRatioContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${props => props.backgroundColor};

  ${props => props.heightGrows && props.type == "children" && css `
    z-index: 1;
    position: relative;
    width: 100%;
  `}

  ${props => props.heightGrows && props.type == "image" && css `
    z-index: 0;
  `}

  ${props => props.type == "image" && css `
    // top: -80px;
    // bottom: -80px;
    // opacity: 0.2;
  `}

  ${MinMaxHeight}
`

// prettier-ignore
const AspectRatioImage = styled.img`
  position: relative;

  max-width: 100%;
  ${props => props.align == "center" && css`
    width: 100%;
  `}

  ${props => !props.useParallax && css`
    height: 100%;
  `}
  ${props => props.useParallax && css`
    height: 120%;
    transform: translate(0%, -10%);
  `}

  object-fit: ${props => props.objectFit};
  opacity: ${props => props.opacity};
  transition: opacity 0.5s ease-in-out;
  border-radius: ${props => (props.rounded ? "1000px" : "0")};
  ${props => selectors.getDebugOverlayCss(props, "AspectRatioImage", "rgba(0,255,255,0.35)")}

  border: ${props => (props.debug ? 1 : 0)}px solid red;
`

const ParallaxContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const Overlay = styled.div`
  pointer-events: none;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: ${props => props.color};
`

export default CImage
