import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import styled, {css} from "styled-components" // eslint-disable-line

import * as selectors from "../../../data/selectors"

import CGrid from "../../../view/components/CGrid"
import CButton from "../../../view/components/CButton"

import CPressAppearance from "./CPressAppearance"

const DEBUG_DEFAULT = false && __DEV__

class CPressAppearances extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    amountInit: PropTypes.number,
    items: PropTypes.array.isRequired,
    hideTitle: PropTypes.bool,
    loadMoreItemsLabel: PropTypes.string,
    showEditButton: PropTypes.bool,
  }
  static defaultProps = {
    debug: DEBUG_DEFAULT,
    amountInit: 6,
    hideTitle: true,
  }

  constructor(props) {
    super(props)
    this.state = {
      showAllAppearances: false,
    }
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  renderPressAppearance = i => {
    const {items, hideTitle, showEditButton} = this.props
    if (!items || !items.length || items.length < i) {
      return null
    }
    const appearance = items[i]
    const {id, title, dateTimestamp, featuredImage, articleLanguage, link, externalLinks, logoBackgroundColor, additionalImages} = appearance // eslint-disable-line
    return (
      <CPressAppearance
        //
        {...appearance}
        hideTitle={hideTitle}
        showEditButton={showEditButton}
      />
    )
  }

  showAllAppearances = () => this.setState({showAllAppearances: true})

  render = () => {
    const {showAllAppearances} = this.state
    const {debug, items, amountInit, loadMoreItemsLabel} = this.props
    if (!items || !items.length) {
      return null
    }
    const d = {debug: debug || DEBUG_DEFAULT}
    const amountShown = showAllAppearances ? items.length : Math.min(amountInit, items.length)
    const moreAvailable = amountShown < items.length
    return (
      <Content {...d}>
        <CGrid
          //
          amountItems={amountShown}
          renderItem={this.renderPressAppearance}
          gutter={15} // xl
          // guttersBreakpoints={{
          //   lg: 50,
          //   md: 40,
          //   sm: 30,
          // }}
          breakpoints={{
            lg: 4,
            md: 6,
            sm: 6,
            xs: 12,
          }}
        />
        {moreAvailable && (
          <LoadMoreContainer {...d}>
            <CButton
              //
              label={loadMoreItemsLabel}
              onClick={this.showAllAppearances}
              variant={"centered"}
            />
          </LoadMoreContainer>
        )}
      </Content>
    )
  }
}

const Content = styled.div`
  padding-top: 30px;
  ${props => props.debug && selectors.getDebugOverlayCss(props, `Content`, `rgba(255,0,0,0.35)`)}
  color: ${props => props.theme.vars.colors.codGray};
`

const LoadMoreContainer = styled.div`
  padding-top: 10px;
  ${props => props.debug && selectors.getDebugOverlayCss(props, `LoadMoreContainer`, `rgba(255,255,0,0.35)`)}
`

export default CPressAppearances
