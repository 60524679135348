import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import {injectIntl} from "react-intl"
import {connect} from "react-redux"
import styled, {css, ThemeProvider} from "styled-components" // eslint-disable-line
import {Element as ScrollElement, scroller} from "react-scroll"
import {push} from "connected-react-router"

import {CONFIG} from "../../../config" // eslint-disable-line
import * as selectors from "../../../data/selectors"
import * as actions from "../../../data/actions"

import CButton from "../../../view/components/CButton"
import CSpacer from "../../../view/components/CSpacer"
// import CText from "../../../view/components/CText"
import CFloatingInput from "../../../view/components/CFloatingInput"
import CCollapse from "../../../view/components/CCollapse"

import CHomeSubheader from "../../../view/components/text/CHomeSubheader"
import CH3 from "../../../view/components/text/CH3"
import CSmallprint from "../../../view/components/text/CSmallprint"

const god = selectors.getObjectDeep // eslint-disable-line

class CHomeWhitepaper extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    header: PropTypes.string,
    subheader: PropTypes.string,
    emailPlaceholder: PropTypes.string,
    emailErrorText: PropTypes.string,
    button: PropTypes.string,
    smallprint: PropTypes.string,
    themeKey: PropTypes.string,
    defaultThemeProps: PropTypes.object,
    openTocsInNewWindow: PropTypes.bool,
    intlLocale: PropTypes.string,
    whitepaperRequestBusy: PropTypes.bool,
  }
  static defaultProps = {
    debug: false && __DEV__,
    defaultThemeProps: {
      // backgroundColor: "rgba(0,0,0,0)",
      textColor: "black",
    },
    openTocsInNewWindow: true,
  }

  constructor(props) {
    super(props)
    this.state = {
      email: "",
      formSubmittedOnce: false,
      emailValid: false,
      emailFocused: false,
      formRequestedOnce: false,
      formRequestResult: false,
    }
  }

  componentDidMount = () => {
    __DEV__ && this.setState({email: "steff@nerdybirdy.net"})
  }

  onInputWhitepaperEmailChange = email => this.setState({email})
  onInputWhitepaperEmailFocus = () => this.setState({emailFocused: true})
  onInputWhitepaperEmailBlur = () => this.setState({emailFocused: false})
  onInputWhitepaperEmailSubmit = () => this.submitForm()
  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  // onVideoClick = (index) => !!this.props.onVideoClick && this.props.onVideoClick(this.props.videos[index], index)

  getThemeOverrides = () => {
    const {themeKey} = this.props
    let themeOverrides = {}
    if (themeKey === "dark") {
      themeOverrides = {
        // backgroundColor: CONFIG.layout.colors.codGray,
        textColor: "white",
      }
    }
    return themeOverrides
  }

  validateInput = (value, name) => {
    switch (name) {
      case "email": {
        return !!value && selectors.getEmailIsValid(value)
      }
    }
    return true
  }

  submitForm = event => {
    !!event && event.preventDefault()

    const {intlLocale} = this.props
    const {email} = this.state

    const emailValid = this.validateInput(email, "email")
    this.setState({formSubmittedOnce: true, emailValid, formRequestedOnce: true})

    if (!emailValid) {
      setTimeout(() => {
        scroller.scrollTo("inputEmail", {duration: 1000, smooth: "easeInOutQuint", offset: -(80 + 20)}) // menu height + padding
        setTimeout(() => {
          !!this.inputEmail && this.inputEmail.focus()
        }, 800)
      }, 200)
    } else {
      this.props.dispatch(actions.requestWhitepaper({email, lang: intlLocale})).then(({success, error, errorIntlId}) => {
        this.setState({formRequestResult: {success, error, errorIntlId}})
      })
    }
  }

  onTocSmallprintLinkPress = hrefProps => {
    const {openTocsInNewWindow} = this.props
    if (!!hrefProps.linkTo && !openTocsInNewWindow) {
      this.props.dispatch(push(hrefProps.linkTo))
    } else {
      window.open(hrefProps.href)
    }
  }

  render = () => {
    const {email, emailValid, formSubmittedOnce, emailFocused, formRequestedOnce, formRequestResult} = this.state // eslint-disable-line
    const {debug, intl, themeKey, defaultThemeProps, header, subheader, emailPlaceholder, emailErrorText, smallprint, button, whitepaperRequestBusy} = this.props
    const d = {debug: debug || CHomeWhitepaper.defaultProps.debug}

    const formRequestSuccess = god(formRequestResult, "success", false)
    const formErrorIntlIdDefault = "FormErrorGeneric"
    const formRequestErrorIntlId = god(formRequestResult, "errorIntlId", formErrorIntlIdDefault) // eslint-disable-line
    const showResult = formSubmittedOnce && !whitepaperRequestBusy && formRequestedOnce && !!formRequestResult
    const inputsDisabledAfterSubmit = showResult && formRequestSuccess
    const disabled = whitepaperRequestBusy || inputsDisabledAfterSubmit

    return (
      <ThemeProvider theme={{...defaultThemeProps, ...this.getThemeOverrides()}}>
        <PaddedContainer {...d} variant={"content"} {...d}>
          <Content {...d}>
            <CH3 {...d} text={header} />
            <CSpacer {...d} variant={"herosections"} />
            <CHomeSubheader {...d} text={subheader} variant={`home-whitepaper`} />
            <CSpacer {...d} variant={"herosections"} />
            <EmailForm {...d} name={"whitepaper"}>
              <ScrollElement name={"inputEmail"} />
              <CFloatingInput
                {...d}
                ref={obj => (this.inputEmail = obj)}
                variant={`home-whitepaper`}
                name={"email"}
                value={email}
                placeholder={emailPlaceholder}
                type={"email"}
                required
                validateInput={this.validateInput}
                disabled={disabled}
                onFocus={this.onInputWhitepaperEmailFocus}
                onBlur={this.onInputWhitepaperEmailBlur}
                onChange={this.onInputWhitepaperEmailChange}
                onSubmit={this.onInputWhitepaperEmailSubmit}
                themeKey={themeKey} // dark
                showError={formSubmittedOnce && !emailValid}
                errorText={emailErrorText}
              />
              <SmallprintContainer {...d}>
                <CSmallprint {...d} text={smallprint} onLinkPress={this.onTocSmallprintLinkPress} />
              </SmallprintContainer>

              <CCollapse collapsed={!showResult}>
                <SubmitResultContainer>
                  {!formRequestSuccess && <RequestResult dangerouslySetInnerHTML={{__html: intl.formatMessage({id: formRequestErrorIntlId})}} />}
                  {formRequestSuccess && <RequestResult dangerouslySetInnerHTML={{__html: intl.formatMessage({id: `WhitepaperFormSubmitSuccess`})}} />}
                </SubmitResultContainer>
              </CCollapse>

              <CButton
                //  prettier-ignore
                {...d}
                label={button}
                themeKey={themeKey}
                disabled={whitepaperRequestBusy || inputsDisabledAfterSubmit}
                onClick={this.submitForm}
                variant={`home-whitepaper`}
                canBeBusy
                busy={debug || whitepaperRequestBusy}
              />
              <input type="submit" hidden onClick={this.submitForm} />
            </EmailForm>
          </Content>
        </PaddedContainer>
      </ThemeProvider>
    )
  }
}
const PaddedContainer = styled.div`
  display: flex;
  flex-direction: column; // Desktop
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  text-align: center;
  ${props => selectors.getPaddingLeftRightByThemeAndVariant(props.theme, props.variant)}

  color: ${props => props.theme.textColor};
  transition: all ${props => props.theme.vars.transitions.themeTransitionDuration}s linear;

  ${props => selectors.getDebugOverlayCss(props, "PaddedContainer", "rgba(125,0,0,0.5)")}
`

const Content = styled.div`
  padding: 100px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${props => selectors.getDebugOverlayCss(props, "Content", "rgba(0,255,0,0.25)")}
`

const EmailForm = styled.form`
  max-width: 400px;
  border: 1px solid ${props => (props.debug ? "red" : "rgba(0,0,0,0)")};
`

const SmallprintContainer = styled.div`
  padding-top: 10px;
  padding-bottom: 50px;
  ${props => selectors.getDebugOverlayCss(props, "SmallprintContainer", "rgba(125,0,0,0.5)")}
`

const SubmitResultContainer = styled.div`
  padding-bottom: 20px;
`

//  TODO: Unify with CGetInTouch
const RequestResult = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-weight: ${props => props.theme.vars.fonts.weights.regular};
  b,
  > b,
  strong,
  > strong {
    font-weight: ${props => props.theme.vars.fonts.weights.bold};
  }
`

const mapStateToProps = state => ({
  whitepaperRequestBusy: selectors.getWhitepaperRequestBusy(state), // one of all in session
  intlLocale: selectors.getIntlLocale(state),
})
export default injectIntl(connect(mapStateToProps)(CHomeWhitepaper))
