import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import styled, {css} from "styled-components" // eslint-disable-line
import {injectIntl} from "react-intl"

import * as selectors from "../../../data/selectors" // eslint-disable-line

import CIcon from "../../../view/components/CIcon"
import CCollapse from "../../../view/components/CCollapse"

import {CONFIG} from "../../../config"

const DEBUG = false && __DEV__
import {debbify, getObjectDeep as god} from "../../../data/selectors/helpers" // eslint-disable-line
const debby = (...args) => debbify("COVRFilters", ...args)

class COVRFilters extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    intl: PropTypes.object.isRequired,
    intlLocale: PropTypes.string,
    shown: PropTypes.bool,
    resetFiltersText: PropTypes.string,
    onResetClick: PropTypes.func,
    sortByHeader: PropTypes.string,
    // sortBy: PropTypes.oneOf(["atoz", "ztoa", "featured", "newest", "oldest"]),
    sortBy: PropTypes.oneOf(["newest", "oldest", "featured", "atoz", "ztoa"]),
    sortByOptions: PropTypes.array,
    onSortByChange: PropTypes.func,
    filterByHeader: PropTypes.string,
    galleriesCountries: PropTypes.object,
    countriesSelected: PropTypes.array,
    onCountryClick: PropTypes.func,
    showAmountGalleriesForCountries: PropTypes.bool,
    //
    showSearch: PropTypes.bool,
    mediaQueryClass: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]).isRequired,
    searchTextPlaceholder: PropTypes.string,
    searchQuery: PropTypes.string,
    onSearchQueryChange: PropTypes.func,
  }
  static defaultProps = {
    debug: DEBUG,
    gradientDarkestAlpha: 0.5,
    sortBy: "atoz",
    sortByOptions: [
      //
      {key: "newest", intlId: "SortByOptionNewest"},
      {key: "oldest", intlId: "SortByOptionOldest"},
      {key: "featured", intlId: "SortByOptionFeatured"},
      {key: "atoz", intlId: "SortByOptionAToZ"},
      {key: "ztoa", intlId: "SortByOptionZToA"},
    ],
    showAmountGalleriesForCountries: false || DEBUG,
  }

  constructor(props) {
    super(props)
    this.state = {
      searchQuery: this.props.searchQuery,
    }
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  UNSAFE_componentWillReceiveProps = nextProps => {
    if (nextProps.searchQuery !== this.state.searchQuery) {
      this.setState({searchQuery: nextProps.searchQuery})
    }
  }

  onResetClick = () => {
    debby("onResetClick()")
    this.props.onResetClick()
  }

  onSortByOptionClick = key => {
    debby("onSortByOptionClick()", {key})
    this.props.onSortByChange(key)
  }

  onCountryClick = country => {
    debby("onCountryClick()", {country})
    this.props.onCountryClick(country)
  }

  onSearchQueryChange = evt => this.setSearchQuery(evt.target.value)

  setSearchQuery = searchQuery => this.setState({searchQuery}, this.callbackSearchQuery)

  callbackSearchQuery = (diffMs = 500) => {
    clearTimeout(this.updateSearchResultsTimeoutId)
    this.updateSearchResultsTimeoutId = setTimeout(() => {
      if (!!this.props.onSearchQueryChange) {
        this.props.onSearchQueryChange(this.state.searchQuery)
      }
    }, diffMs)
  }

  clearSearchInput = () => {
    this.setState({searchQuery: ""}, this.callbackSearchQuery)
  }

  /*
  updateSearchResults = (diffMs = 1000) => {
    clearTimeout(this.updateSearchResultsTimeoutId)
    this.updateSearchResultsTimeoutId = setTimeout(() => {
      const {searchQuery} = this.state
      this.updateQueryProps({s: searchQuery})
    }, diffMs)
  }
  */

  render = () => {
    const {searchQuery} = this.state
    const {intl, intlLocale, mediaQueryClass, shown, resetFiltersText, sortByHeader, sortByOptions, sortBy, filterByHeader, galleriesCountries, showAmountGalleriesForCountries, countriesSelected, searchTextPlaceholder} = this.props
    const d = {debug: DEBUG || this.props.debug} // eslint-disable-line
    debby("render()", {intlLocale, shown, sortBy, galleriesCountries})
    const countries =
      !galleriesCountries || !Object.keys(galleriesCountries).length
        ? null
        : Object.keys(galleriesCountries)
            .map(country => ({country, amount: galleriesCountries[country].amount}))
            .sort((a, b) => (a.amount > b.amount ? -1 : a.amount < b.amount ? 1 : 0))

    const searchIconsScale = selectors.getMediaQueryMatching(mediaQueryClass, "<=", "md") ? 0.8 : 1
    return (
      <CCollapse collapsed={!shown}>
        <Container {...d}>
          <ResetButtonContainer {...d}>
            <ClickableButtonReset {...d} onClick={this.onResetClick}>
              <ResetIconContainer {...d}>
                <CIcon id={`ResetFilters`} scale={0.7} />
              </ResetIconContainer>
              {resetFiltersText}
            </ClickableButtonReset>
          </ResetButtonContainer>

          <SearchInputContainer {...d}>
            <SearchQueryInputContainer {...d}>
              <SearchQueryFloatingInputContainer {...d}>
                <InputContainerHorizontal {...d}>
                  <InputIconSearch {...d}>
                    <CIcon id={`KnowledgeBaseSearchMagnifyer`} color={CONFIG.layout.colors.siccoro} scale={searchIconsScale} />
                  </InputIconSearch>
                  <InputLabelContainer>
                    <SearchInput
                      //
                      {...d}
                      ref={obj => (this.inputNode = obj)}
                      onChange={this.onSearchQueryChange}
                      value={searchQuery}
                      placeholder={searchTextPlaceholder}
                      // placeholder={god(postData, "sectionSearch.inputPlaceholder")}
                    />
                  </InputLabelContainer>
                  <InputIconClear {...d} {...(!!this.state.searchQuery ? {onClick: this.clearSearchInput} : {})}>
                    {!!this.state.searchQuery && <CIcon id={`KnowledgeBaseSearchClose`} scale={searchIconsScale} color={CONFIG.layout.colors.siccoro} />}
                  </InputIconClear>
                </InputContainerHorizontal>
              </SearchQueryFloatingInputContainer>
            </SearchQueryInputContainer>
          </SearchInputContainer>
          <TwoPartContainer {...d}>
            <PartContainer {...d}>
              <ContainerSortBy {...d}>
                <OptionsHeaderLabel {...d}>{sortByHeader}</OptionsHeaderLabel>
                <OptionsContainer {...d}>
                  {sortByOptions.map(option => (
                    <OptionButton
                      //
                      key={`sortby_${option.key}`}
                      selected={sortBy == option.key}
                      onClick={() => this.onSortByOptionClick(option.key)}
                    >
                      {intl.formatMessage({id: option.intlId})}
                    </OptionButton>
                  ))}
                </OptionsContainer>
              </ContainerSortBy>
            </PartContainer>
            {!!countries && !!countries.length && (
              <PartContainer {...d}>
                <ContainerFilterBy {...d}>
                  <OptionsHeaderLabel {...d}>{filterByHeader}</OptionsHeaderLabel>
                  <OptionsContainer {...d}>
                    {countries.map(entry => (
                      <OptionButton
                        //
                        key={`country_${entry.country}`}
                        selected={!!countriesSelected && countriesSelected.indexOf(entry.country.toLowerCase()) != -1}
                        onClick={() => this.onCountryClick(entry.country)}
                      >
                        {intl.formatMessage({id: `Country${entry.country.toUpperCase()}`})}
                        {showAmountGalleriesForCountries ? ` (${entry.amount})` : ``}
                      </OptionButton>
                    ))}
                  </OptionsContainer>
                </ContainerFilterBy>
              </PartContainer>
            )}
          </TwoPartContainer>
        </Container>
      </CCollapse>
    )
  }
}

const Container = styled.div`
  background-color: ${props => props.theme.vars.colors.westar};
  ${props => selectors.getDebugOverlayCss(props, "Container", "rgba(255,0,0,0.35)")};

  padding-top: 30px;
  padding-bottom: 30px;
  margin-bottom: 40px;
  ${props => css`
    ${props.theme.media.smdown} {
      padding-top: 10px;
      padding-bottom: 10px;
      margin-bottom: 20px;
    }
  `}
`

const ResetButtonContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  background-color: ${props => props.theme.vars.colors.westar};
  ${props => selectors.getPaddingLeftRightByThemeAndVariant(props.theme, "header")}
  ${props => selectors.getDebugOverlayCss(props, "ResetButtonContainer", "rgba(0,255,0,0.35)")};
  padding-top: 10px;

  padding-bottom: 10px;
  ${props => css`
    ${props.theme.media.smdown} {
      padding-bottom: 0px;
    }
  `}
`

const SearchInputContainer = styled.div`
  display: flex;
  ${props => selectors.getDebugOverlayCss(props, "SearchInputContainer", "rgba(0,255,255,0.35)")};
  ${props => selectors.getPaddingLeftRightByThemeAndVariant(props.theme, "header")}
  padding-bottom: 15px;
`

const SearchQueryInputContainer = styled.div`
  padding: 10px 0px;
  display: flex;
  flex-direction: row;
  width: 100%;
  // max-width: 500px;
  ${props => true && props.debug && selectors.getDebugOverlayCss(props, `SearchQueryInputContainer`, `rgba(0,0,255,0.35)`)}

  ${props => css`
    ${props.theme.media.mddown} {
      padding: 10px 0px;
    }
  `}
`

const SearchQueryFloatingInputContainer = styled.div`
  flex: 1;
  align-self: "stretch";
`

const InputContainerHorizontal = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid black;
  width: 100%;
  ${props => true && props.debug && selectors.getDebugOverlayCss(props, `InputContainerHorizontal`, `rgba(0,255,255,0.35)`)}
`

const searchIconsSize = 50
const searchIconsSizeSmall = 40
const InputIconSearch = styled.div`
  width: ${searchIconsSize}px;
  height: ${searchIconsSize}px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  border: ${props => (props.debug ? 1 : 0)}px solid blue;
  ${props => css`
    ${props.theme.media.mddown} {
      justify-content: flex-start;
      width: ${searchIconsSizeSmall}px;
      height: ${searchIconsSizeSmall}px;
      margin-right: 0px;
    }
    ${props.theme.media.xs} {
      width: 30px;
    }
  `}
`

const InputLabelContainer = styled.div`
  flex: 1;
  // border: 1px solid black;
`
const SearchInput = styled.input`
  font-family: ${props => props.theme.vars.fonts.families.default};
  border: 0;

  width: 100%;
  height: 100%;

  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  outline: none;
  padding-top: 0px;
  padding-bottom: 0px;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px ${props => props.theme.backgroundColor} inset !important;
    -webkit-text-fill-color: ${props => props.theme.textColor};
  }

  font-size: 23px;
  letter-spacing: 1px;
  ${props => css`
    ${props.theme.media.smdown} {
      font-size: 15px;
      letter-spacing: 0.8px;
    }
  `}
`

const InputIconClear = styled.div`
  width: ${searchIconsSize}px;
  height: ${searchIconsSize}px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 10px;
  border: ${props => (props.debug ? 1 : 0)}px solid blue;

  ${props => css`
    ${props.theme.media.mddown} {
      width: ${searchIconsSizeSmall}px;
      height: ${searchIconsSizeSmall}px;
      margin-left: 0px;
    }
    ${props.theme.media.xs} {
      width: 30px;
    }
  `}
`

const ClickableButtonReset = styled.div`
  cursor: pointer;
  font-size: 13px;
  color: ${props => props.theme.vars.colors.codGray};
  letter-spacing: 0.15em;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
`

const ResetIconContainer = styled.div`
  margin-right: 15px;
  ${props => selectors.getBackgroundColorByProps(props, "rgba(255,0,0,0.25)")}
`

const TwoPartContainer = styled.div`
  display: flex;
  ${props => selectors.getDebugOverlayCss(props, "TwoPartContainer", "rgba(0,255,255,0.35)")};
  ${props => selectors.getPaddingLeftRightByThemeAndVariant(props.theme, "header")}
  padding-bottom: 15px;

  margin: -10px; // gutter
  padding-top: 15px;
  ${props => css`
    ${props.theme.media.smdown} {
      flex-direction: column;
      margin: 0px;
      padding-top: 10px;
      // padding-bottom: 15px;
    }
  `}
`

const PartContainer = styled.div`
  width: 100%;

  margin: 10px; // gutter
  ${props => css`
    ${props.theme.media.smdown} {
      margin: 0px;
    }
  `}
`

const ContainerSortBy = styled.div`
  flex: 1;
  align-self: stretch;
  ${props => selectors.getDebugOverlayCss(props, "ContainerSortBy", "rgba(255,0,255,0.35)")};

  padding: 15px 0px;
  ${props => css`
    ${props.theme.media.smdown} {
      padding: 0px 0px 15px 0px;
    }
  `}
`

const ContainerFilterBy = styled.div`
  flex: 1;
  align-self: stretch;
  ${props => selectors.getDebugOverlayCss(props, "ContainerFilterBy", "rgba(255,0,0,0.35)")};

  padding: 15px 0px;
  ${props => css`
    ${props.theme.media.smdown} {
      padding: 0px 0px 15px 0px;
    }
  `}
`

const BUTTON_HEIGHT_PX = 40
const BUTTON_HEIGHT_PX_SMDOWN = 30

const OptionsHeaderLabel = styled.div`
  letter-spacing: 0.06em;
  font-size: 15px;
`

const OptionsContainer = styled.div`
  ${props => selectors.getDebugOverlayCss(props, "OptionsContainer", "rgba(0,0,255,0.35)")};
  margin-top: 20px;
  overflow-y: scroll;
  background-color: white;
  padding: 10px;
  height: ${BUTTON_HEIGHT_PX * 5}px;
  ${props => css`
    ${props.theme.media.smdown} {
      height: auto;
      max-height: ${BUTTON_HEIGHT_PX_SMDOWN * 5}px;
    }
  `}
`

const OptionButton = styled.div`
  cursor: pointer;
  font-size: 15px;
  letter-spacing: 0.08em;
  padding: 0px 12px;
  display: flex;
  align-items: center;
  transition: all 0.2s;

  height: ${BUTTON_HEIGHT_PX}px;
  ${props => css`
    ${props.theme.media.smdown} {
      height: ${BUTTON_HEIGHT_PX_SMDOWN}px;
    }
  `}

  :hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

  ${props =>
    props.selected &&
    css`
      background-color: ${props.theme.vars.colors.codGray};
      color: white;

      :hover {
        background-color: ${props.theme.vars.colors.codGray};
        opacity: 0.9;
      }
    `}
`

export default injectIntl(COVRFilters)
