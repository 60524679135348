import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import styled, {css} from "styled-components" // eslint-disable-line
import {injectIntl} from "react-intl"

import CIcon from "../../view/components/CIcon"

//  eslint-disable-next-line
const debby = (funcName, data = null) => console.log("CEditInWordPress." + funcName, data)

import * as selectors from "../../data/selectors"

// const USE_DUMMY = false

class CEditInWordPress extends Component {
  static propTypes = {
    intl: PropTypes.object.isRequired,
    debug: PropTypes.bool,
    resourceId: PropTypes.number,
    label: PropTypes.string,
    fixed: PropTypes.bool,
  }
  static defaultProps = {
    debug: false && __DEV__,
    bottomOffset: 1,
    topOffset: 1,
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  render = () => {
    const {debug, intl, resourceId, label, fixed} = this.props
    const d = {debug: debug || CEditInWordPress.defaultProps.debug}
    const editLink = selectors.getWordPressEditLinkForResourceId(resourceId)
    if (!resourceId || !editLink) {
      return null
    }
    const labelUsed = !!label ? label : intl.formatMessage({id: `EditInWordPressLabel`})
    return (
      <Container {...d} fixed={fixed}>
        <Link
          //  eslint-disable-next-line
          target={"_blank"}
          href={editLink}>
          <IconContainer>
            <CIcon id={"AdminLocked"} />
          </IconContainer>
          <Label>{labelUsed}</Label>
        </Link>
      </Container>
    )
  }
}

const Container = styled.div`
  position: ${props => (props.fixed ? "fixed" : "absolute")};
  right: 0;
  bottom: 0;
  background-color: white;
  box-shadow: -2px -2px 15px rgba(0, 0, 0, 0.25);
  z-index: 10000;

  font-size: 9px;
  font-family: ${props => props.theme.vars.fonts.families.default};
  font-weight: ${props => props.theme.vars.fonts.weights.semibold};
  letter-spacing: 0.1em;
  text-transform: uppercase;

  a,
  > a {
  }

  opacity: 0.2;
  :hover {
    opacity: 1;
  }
  transition: opacity 0.3s ease-in-out;
`

const Link = styled.a`
  padding: 10px;
  display: flex;
`

const IconContainer = styled.div`
  padding-right: 10px;
`

const Label = styled.div`
  color: black !important;
`

export default injectIntl(CEditInWordPress)
