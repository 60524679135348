import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import styled, {css, ThemeProvider} from "styled-components"
import {Parallax} from "react-scroll-parallax"
import {motion} from "framer-motion"

import {CONFIG} from "../../../config"
import * as selectors from "../../../data/selectors"
import * as images from "../../../images"

import CButton from "../../../view/components/CButton"
import CSpacer from "../../../view/components/CSpacer"
import CImageFaded from "../../../view/components/CImageFaded"
import CIcon from "../../../view/components/CIcon"

import CHomeSubheader from "../../../view/components/text/CHomeSubheader"
import CH2 from "../../../view/components/text/CH2"

const DEBUG = false && __DEV__
import {debbify} from "../../../data/selectors/helpers"
const debby = (...args) => debbify("CHomeTutorials", ...args)

class CHomeTutorials extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    header: PropTypes.string,
    subheader: PropTypes.string,
    button: PropTypes.string,
    onVideoClick: PropTypes.func,
    videos: PropTypes.array,
    themeKey: PropTypes.string,
    defaultThemeProps: PropTypes.object,
    fadedImages: PropTypes.array,
    buttonUrl: PropTypes.string,
  }
  static defaultProps = {
    debug: DEBUG,
    defaultThemeProps: {
      backgroundColor: "white",
      textColor: "black",
    },
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  onVideoClick = index => !!this.props.onVideoClick && this.props.onVideoClick(this.props.videos[index], index)

  render = () => {
    const {themeKey, defaultThemeProps, header, subheader, button, videos, fadedImages, buttonUrl} = this.props
    const d = {debug: DEBUG || this.props.debug}
    const parallaxRatio = 0.1
    let themeOverrides = {}
    if (themeKey === "dark") {
      themeOverrides = {...themeOverrides, backgroundColor: CONFIG.layout.colors.codGray, textColor: "white"}
    }
    debby("render()", {fadedImages})
    return (
      <ThemeProvider theme={{...defaultThemeProps, ...themeOverrides}}>
        <PaddedContainer {...d} variant={"content"}>
          <Content>
            <ContentText {...d}>
              <ContentTextPadded {...d}>
                <CH2 {...d} text={header} />
                <CSpacer {...d} variant={"herosections"} />
                <CHomeSubheader {...d} text={subheader} />
                <CSpacer {...d} variant={"herosections"} />
                {!!videos && !!videos.length && (
                  <ThumbnailsContainer {...d}>
                    {videos.map((video, i) => {
                      const {key, label, youtubeUrl} = video // eslint-disable-line
                      const image = images[`TutorialThumbnail${selectors.getFirstCapital(key)}`]
                      const last = i == videos.length - 1
                      return (
                        <ThumbnailItem {...d} last={last} key={`tutorial-${key}`} onClick={() => this.onVideoClick(i)}>
                          <ThumbnailButton {...d} whileHover={{scale: 1.05}}>
                            <ThumbnailContainer>
                              <ThumbnailImage {...d} src={image} /*src={image.src} srcSet={image.srcSet} */ />
                              <PlayIconContainer {...d}>
                                <CIcon {...d} id={`TutorialPlay`} />
                              </PlayIconContainer>
                            </ThumbnailContainer>
                            <ThumbnailLabel {...d}>
                              <span>{label}</span>
                            </ThumbnailLabel>
                          </ThumbnailButton>
                        </ThumbnailItem>
                      )
                    })}
                  </ThumbnailsContainer>
                )}
                <CSpacer {...d} variant={"herosections"} />
                <CButton {...d} label={button} variant={`home-tutorials`} urlTo={buttonUrl} />
              </ContentTextPadded>
            </ContentText>

            <ContentImage {...d}>
              <ImageFadedResponsive {...d}>
                <Parallax y={[`-${parallaxRatio * 100}%`, `${parallaxRatio * 100}%`]} styleOuter={{display: "flex", width: "100%", height: "100%"}} styleInner={{width: "100%"}}>
                  <CImageFaded
                    //  params
                    {...d}
                    sectionKey={"homeTutorials"}
                    sourcesWp={fadedImages}
                    baseTransform={`scale(1.7)`}
                    // extraTransforms={[`scale(1.1) translate(-3%,5%)`]} // depending on layout
                  />
                </Parallax>
              </ImageFadedResponsive>
            </ContentImage>
          </Content>
        </PaddedContainer>
      </ThemeProvider>
    )
  }
}

const PaddedContainer = styled.div`
  ${props => selectors.getPaddingLeftRightByThemeAndVariant(props.theme, props.variant)}
  display: flex;
  flex-direction: row; // Desktop: 2 Cols

  min-height: 100vh;
  ${props => css`
    ${props.theme.media.smdown} {
      min-height: 0;
    }
  `}

  background-color: ${props => props.theme.backgroundColor};
  transition: all ${props => props.theme.vars.transitions.themeTransitionDuration}s linear;
`

const Content = styled.div`
  display: flex;
  align-self: stretch;
  flex: 1;

  align-items: center;
  justify-content: center;

  flex-direction: row;
  ${props => css`
    ${props.theme.media.smdown} {
      flex-direction: column-reverse;
    }
  `}

  ${props => selectors.getDebugOverlayCss(props, "PaddedContainer", "rgba(0,255,0,0.25)")}
`

const ContentText = styled.div`
  padding: 80px 0px;

  flex: 5;
  ${props => css`
    ${props.theme.media.md} {
      flex: 6;
    }
  `}

  color: ${props => props.theme.textColor};
  transition: all ${props => props.theme.vars.transitions.themeTransitionDuration}s linear;

  ${props => selectors.getDebugOverlayCss(props, "ContentText", "rgba(255,255,0,0.25)")}
`

const ContentTextPadded = styled.div`
  padding-right: 50px;

  ${props => css`
    ${props.theme.media.smdown} {
      padding-right: 0px;
    }
  `}
`

const ContentImage = styled.div`
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;

  flex: 3;
  // padding: 80px 0px;

  ${props => selectors.getDebugOverlayCss(props, "ContentImage", "rgba(0,255,255,0.25)")}
`

const ImageFadedResponsive = styled.div`
  width: 100%;
  padding: 100px 0px;
  ${props => css`
    ${props.theme.media.sm} {
      width: 40%;
      padding: 80px 0px 40px 0px;
    }
    ${props.theme.media.xs} {
      width: 60%;
      padding: 150px 0px 60px 0px;
    }
  `}
  ${props => selectors.getDebugOverlayCss(props, "ImageFadedResponsive", "rgba(255,0,0,0.35)")}
`

const ThumbnailsContainer = styled.div`
  display: flex;
  align-items: flex-start; // vertical top
  justify-content: center;
  text-align: center;
  ${props => css`
    ${props.theme.media.xs} {
      flex-direction: column;
    }
  `}

  ${props => selectors.getDebugOverlayCss(props, "ThumbnailsContainer", "rgba(255,255,0,0.35)")}
`

const ThumbnailItem = styled.div`
  flex: 1;
  position: relative;
  overflow: hidden;

  ${props =>
    !props.last &&
    css`
      padding-right: 25px;
      ${props => css`
        ${props.theme.media.lg} {
          padding-right: 15px;
        }
        ${props.theme.media.md} {
          padding-right: 10px;
        }
        ${props.theme.media.sm} {
          padding-right: 10px;
        }
        ${props.theme.media.xs} {
          padding-right: 0px;
          padding-bottom: 30px;
        }
      `}
    `}
`

const ThumbnailContainer = styled.div`
  position: relative;
`

/* eslint-disable */
const PlayIconContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  transition: transform 0.2s ease-in-out;

  :hover {
    transform: scale(1.2);
  }
`

const ThumbnailButton = styled(motion.div)`
  cursor: pointer;
`

const ThumbnailImage = styled.img`
  width: 100%;
`

const ThumbnailLabel = styled.div`
  text-align: center;
  font-weight: ${props => props.theme.vars.fonts.weights.semibold};
  text-transform: uppercase;
  letter-spacing: 0.1em;
  padding: 10px 0px;
  font-kerning: none;

  font-size: 16px;
  line-height: 1.3em;
  ${props => css`
    ${props.theme.media.lg} {
      font-size: 14px;
    }
    ${props.theme.media.md} {
      font-size: 12px;
    }
    ${props.theme.media.sm} {
      font-size: 13px;
    }
    ${props.theme.media.xs} {
      font-size: 12px;
    }
  `}
`

export default CHomeTutorials
