import React, {Component} from "react"
import PropTypes from "prop-types"
import {injectIntl} from "react-intl"
import isEqual from "lodash/isEqual"
import styled, {css} from "styled-components" // eslint-disable-line

import * as selectors from "../../data/selectors"

import CContent from "./CContent"
import CH2 from "./text/CH2"

const DEBUG = false && __DEV__
import {debbify} from "../../data/selectors/helpers"
const debby = (...args) => debbify("CNoContent", ...args)

class CNoContent extends Component {
  static propTypes = {
    intl: PropTypes.object.isRequired,
    variant: PropTypes.oneOf(["gallery-landing"]),
  }
  static defaultProps = {
    variant: "gallery-landing",
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  getContentItems = () => {
    const {intl, variant} = this.props
    const intlLocale = intl.locale
    let headers = []
    let lines = []

    switch (variant) {
      case "gallery-landing": {
        const ovrLink = intlLocale == "en" ? "/ovr" : `/ovr-${intlLocale}` // localized

        headers.push(intl.formatMessage({id: `GalleryLanding404Header`}))

        // separate paragraphs
        // lines.push(`<p>` + intl.formatMessage({id: `GalleryLanding404Line1`}) + `</p>`)
        // lines.push(`<p>` + intl.formatMessage({id: `GalleryLanding404Line2`}, {ovrLink}) + "</p>")

        // single paragraph with line-break
        lines.push(`<p>` + intl.formatMessage({id: `GalleryLanding404Line1`}) + `<br/>` + intl.formatMessage({id: `GalleryLanding404Line2`}, {ovrLink}) + `</p>`)

        break
      }
      default: {
        if (__DEV__) {
          throw new Error(`Unhandled variant '${variant}'.`)
        }
      }
    }

    return {headers, lines, amountTotal: headers.length + lines.length}
  }

  render = () => {
    const {variant} = this.props
    const d = {debug: DEBUG}
    const {amountTotal, headers, lines} = this.getContentItems() // eslint-disable-line
    debby("render()", {variant})
    if (!amountTotal) {
      return null
    }
    return (
      <Container {...d}>
        <PaddedContainer variant={"header"}>
          {headers.map((text, i) => (
            <CH2 key={`header_${i}`} text={text} />
          ))}
          {!!headers.length && <div style={{height: 20}} />}
          {lines.map((html, i) => (
            <CContent key={`item_${i}`} html={html} />
          ))}
        </PaddedContainer>
      </Container>
    )
  }
}

const Container = styled.div`
  width: 100%;
  margin: 100px 0px;
  ${props => selectors.getDebugOverlayCss(props, "Container", "rgba(0,255,0,0.25)")}
`

const PaddedContainer = styled.div`
  ${props => selectors.getPaddingLeftRightByThemeAndVariant(props.theme, props.variant)}
`

export default injectIntl(CNoContent)
