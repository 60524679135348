import PropTypes from "prop-types"
import {createSelector} from "reselect"

import {paddingHorizontalByVariant} from "../../view/Theme"
import {CONFIG} from "../../config"

export const getStageWidth = state => state.device.stageDimensions.width
export const getSessionWaypointThemeKey = state => state.session.waypointThemeKey

export const getMediaQueryClasses = () => {
  return ["xs", "sm", "md", "lg", "xl"]
}

export const getChildContextTypesGrid = () => ({
  breakpoints: PropTypes.arrayOf(PropTypes.number),
  gutterWidth: PropTypes.number,
  defaultScreenClass: PropTypes.string,
})

export const getChildContextGrid = () => {
  const {breakpoints} = CONFIG.layout
  let breakpointsArray = []
  Object.keys(breakpoints).map(bpKey => {
    const bp = breakpoints[bpKey]
    if (bp.hasOwnProperty("max")) {
      breakpointsArray.push(bp.max)
    }
  })

  return {
    breakpoints: breakpointsArray,
    gutterWidth: 0,
    defaultScreenClass: "xl",
  }
}

const getMediaQueryClassByStageWidth = stageWidth => {
  const childContextGrid = getChildContextGrid()
  const classes = getMediaQueryClasses()
  if (!stageWidth) {
    return classes[classes.length - 1] // assume largest screen size
  }

  const {breakpoints} = childContextGrid
  let indexMax = classes.length - 1 // default: xs
  for (var i = breakpoints.length - 1; i >= 0; i--) {
    const breakpointWidthMax = breakpoints[i]
    if (stageWidth < breakpointWidthMax) {
      indexMax = i
    }
  }
  return classes[indexMax]
}

export const getMediaQueryClass = createSelector([getStageWidth], stageWidth => getMediaQueryClassByStageWidth(stageWidth))

export const getPaddingHorizontalByStageWidthAndVariant = (stageWidth, variant) => {
  const mediaQuery = getMediaQueryClassByStageWidth(stageWidth)
  const paddingByVariant = paddingHorizontalByVariant[variant]
  debugger
  if (!!paddingByVariant) {
    return paddingByVariant[mediaQuery]
  }
  return 10
}

export const getWindowScrollY = () => {
  let supportPageOffset = window.pageYOffset !== undefined
  // let isCSS1Compat = (document.compatMode || "") === "CSS1Compat"
  let scroll = {
    // x: supportPageOffset ? window.pageXOffset : isCSS1Compat ? document.documentElement.scrollLeft : document.body.scrollLeft,
    // y: supportPageOffset ? window.pageYOffset : isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop,
    y: supportPageOffset ? window.pageYOffset : document.body.scrollTop,
  }
  const scrollY = scroll.y
  return scrollY
}

export const getMediaQueryMatching = (mediaQuery1, operator, mediaQuery2, options = {}) => {
  if (!mediaQuery1) {
    return false
  }

  if (options.debug) { debugger } // prettier-ignore

  const values = {xs: 1, sm: 2, md: 3, lg: 4, xl: 5}
  const value1 = values[mediaQuery1]
  const value2 = values[mediaQuery2]
  switch (operator) {
    case "<":
      return value1 < value2
    case ">":
      return value1 > value2
    case "<=":
      return value1 <= value2
    case ">=":
      return value1 >= value2
    case "=":
    case "==":
      return value1 == value2
  }
  return false
}
