import React, {Component, Fragment} from "react" // eslint-disable-line
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import styled, {css} from "styled-components" // eslint-disable-line
import Measure from "react-measure"
import ReactTooltip from "react-tooltip" // eslint-disable-line

import * as selectors from "../../../data/selectors"
const god = selectors.getObjectDeep

import {basicTheme} from "../../../view/Theme"
import CH3 from "../../../view/components/text/CH3"
import CIcon from "../../../view/components/CIcon"

import {LogoWhite} from "../../../images"

const DEBUG = false && __DEV__
import {debbify} from "../../../data/selectors/helpers"
const debby = (...args) => debbify("CExhibitorsFeatureMatrix", ...args)

class CExhibitorsFeatureMatrix extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    header: PropTypes.string,
    subHeader: PropTypes.string,
    chartKeysHeader: PropTypes.string,
    chartKeys: PropTypes.object,
    iazzuCosts: PropTypes.string,
    featureRowTexts: PropTypes.object,
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        header: PropTypes.string,
        costsLabel: PropTypes.string,
        costsPopover: PropTypes.string,
        featureRows: PropTypes.object,
      }),
    ),
    mediaQueryClass: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]).isRequired,
    chartKeysOrder: PropTypes.array,
    featureRowsOrder: PropTypes.array,
  }
  static defaultProps = {
    debug: false && __DEV__,
    chartKeysOrder: ["available", "partially", "unavailable"],
    featureRowsOrder: ["multiPlatform", "tailormade", "dataEntry", "onlineViewingRoom", "3dVisualization", "augmentedReality", "directCommunication", "reengagement", "richContentIntegration", "currenciesAndLanguages", "personalizedAnalytics", "dataPrivacy", "adFree"],
  }

  constructor(props) {
    super(props)
    this.state = {
      availWidthTable: undefined,
      shadowLeft: 0,
      shadowTop: 0,
      shadowWidth: 0,
      shadowHeight: 0,
    }
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  onFeatureTopLineResize = contentRect => {
    const availWidthTable = contentRect.bounds.width
    debby("onFeatureTopLineResize()", {availWidthTable})
    this.setState({availWidthTable}, ReactTooltip.rebuild)
  }

  onIazzuColResize = contentRect => {
    this.setState({shadowLeft: contentRect.offset.left, shadowTop: contentRect.offset.top, shadowWidth: contentRect.offset.width})
    debby("onIazzuColResize()", {contentRect})
  }

  onLastColResize = contentRect => {
    debby("onLastColResize()", {contentRect})
    this.setState({shadowHeight: contentRect.offset.top + contentRect.offset.height})
  }

  getColumnsProps = ({amountFeatureCols, availWidthTable}) => {
    const {mediaQueryClass} = this.props

    let widthLeftCol = 190
    let widthIazzuCol = 190
    let minWidthFeatureCol = 170 // -> XL: useFlex = true
    let paddingAddRight = 0

    switch (mediaQueryClass) {
      case "lg":
        widthLeftCol = 150
        // widthIazzuCol = 170
        minWidthFeatureCol = 140
        paddingAddRight = basicTheme.vars.padding.horizontal["header"][mediaQueryClass]
        break
      case "md":
        widthLeftCol = 150
        widthIazzuCol = 100
        minWidthFeatureCol = 120
        paddingAddRight = basicTheme.vars.padding.horizontal["header"][mediaQueryClass]
        break
      case "sm":
        widthLeftCol = 120
        widthIazzuCol = 80
        minWidthFeatureCol = 100
        paddingAddRight = basicTheme.vars.padding.horizontal["header"][mediaQueryClass]
        break
      case "xs":
        widthLeftCol = 120
        widthIazzuCol = 90
        minWidthFeatureCol = 100
        paddingAddRight = basicTheme.vars.padding.horizontal["header"][mediaQueryClass]
        break
    }

    let useFlex = true
    let widthFeatureCol = 0
    if (!!amountFeatureCols && !!availWidthTable) {
      widthFeatureCol = (availWidthTable - widthLeftCol - widthIazzuCol) / amountFeatureCols
      if (widthFeatureCol < minWidthFeatureCol) {
        useFlex = false
        widthFeatureCol = minWidthFeatureCol
      }
    }

    return {widthLeftCol, widthIazzuCol, useFlex, widthFeatureCol, paddingAddRight: !useFlex ? paddingAddRight : 0}
  }

  getRowsProps = () => {
    const heightHeaderRow = 80
    const extraHeightIazzuLogo = 20
    const heightFeatureRow = 60
    return {heightHeaderRow, extraHeightIazzuLogo, heightFeatureRow}
  }

  getColumns = () => {
    const {columns, featureRowsOrder} = this.props
    const amountFeatureCols = (!!columns ? columns : []).length
    let columnsFinal = !amountFeatureCols
      ? null
      : columns.map(column => {
          const {header, costsLabel, costsPopover, featureRows} = column
          return {
            header,
            costsLabel,
            costsPopover,
            featureRows: Object.keys(featureRows)
              .map(rowKey => ({key: rowKey, value: featureRows[rowKey]}))
              .sort((a, b) => {
                const keyIndexA = featureRowsOrder.indexOf(a.key)
                const keyIndexB = featureRowsOrder.indexOf(b.key)
                return keyIndexA < keyIndexB ? -1 : keyIndexA > keyIndexB ? 1 : 0
              }),
          }
        })
    return {amountFeatureCols, columnsFinal}
  }

  render = () => {
    const {availWidthTable, shadowLeft, shadowTop, shadowWidth, shadowHeight} = this.state
    const {mediaQueryClass, chartKeysOrder, featureRowsOrder, header, subHeader, chartKeysHeader, chartKeys, featureRowTexts, iazzuCosts} = this.props
    const {columnsFinal, amountFeatureCols} = this.getColumns()
    if (!columnsFinal) {
      return <div />
    }
    const d = {debug: DEBUG || this.props.debug}

    // const isSmDown = selectors.getMediaQueryMatching(mediaQueryClass, "<=", "sm")
    const isMdDown = selectors.getMediaQueryMatching(mediaQueryClass, "<=", "md")
    const {heightHeaderRow, extraHeightIazzuLogo, heightFeatureRow} = this.getRowsProps()
    const {widthLeftCol, widthIazzuCol, useFlex, widthFeatureCol, paddingAddRight} = this.getColumnsProps({amountFeatureCols, availWidthTable})
    debby("render()", {mediaQueryClass, paddingAddRight, availWidthTable, useFlex, widthLeftCol, widthIazzuCol, widthFeatureCol})
    return (
      <AllContainer {...d}>
        <PaddedContainer {...d} variant={"header"}>
          {/* Headline */}
          <HeaderContainer {...d}>
            <HeaderSubheaderContainer {...d}>
              <CH3 {...d} text={header} variant={"umbrellaFeatures"} />
              <SubHeader {...d}>{subHeader}</SubHeader>
            </HeaderSubheaderContainer>
            <ChartKeyContainer {...d}>
              <ChartKeyHeader {...d}>{chartKeysHeader}</ChartKeyHeader>
              <ChartKeyItems {...d}>
                {chartKeysOrder.map(key => (
                  <ChartKeyItem key={`chartkey_item_${key}`} {...d}>
                    <CIcon id={key == "available" ? "FeatureChartAvailable" : key == "unavailable" ? "FeatureChartUnavailable" : "FeatureChartPartiallyAvailable"} />
                    <ChartKeyLabel {...d} useFlex={useFlex}>
                      {god(chartKeys, key, "-")}
                    </ChartKeyLabel>
                  </ChartKeyItem>
                ))}
                {!useFlex && isMdDown && (
                  <ChartKeyItem {...d}>
                    <CIcon id={"FeatureChartSwipe"} />
                    <ChartKeyLabel {...d} useFlex={useFlex}>
                      {god(chartKeys, "swipeToNavigate", "-")}
                    </ChartKeyLabel>
                  </ChartKeyItem>
                )}
              </ChartKeyItems>
            </ChartKeyContainer>
          </HeaderContainer>
        </PaddedContainer>

        <PaddedContainer {...d} variant={"header"} key={`padded_container_${mediaQueryClass}`}>
          <Measure bounds onResize={this.onFeatureTopLineResize}>
            {({measureRef}) => <div ref={measureRef}></div>}
          </Measure>
        </PaddedContainer>

        {/* Table */}
        {!!availWidthTable && (
          <PaddedContainer {...d} id={"FeaturesTable"} variant={"header"} paddingAddRight={paddingAddRight}>
            <Container {...d}>
              {/* Shadow */}
              <ShadowContainer {...d}>
                {!!shadowLeft && (
                  <ShadowRectangle
                    //
                    {...d}
                    left={shadowLeft}
                    top={shadowTop}
                    width={shadowWidth}
                    height={shadowHeight}
                  />
                )}
              </ShadowContainer>

              {/* Content */}
              <ContentContainer {...d}>
                {/* 2 Left Columns */}
                <ContentLeft {...d} width={widthLeftCol + widthIazzuCol}>
                  {/* Row 1: Empty Cell, iazzu Logo ///////////////////////////////////////////////// */}
                  <RowLeft {...d} height={heightHeaderRow + extraHeightIazzuLogo} noBorderBottom>
                    <RowLeftCell {...d}>{d.debug ? "[empty]" : " "}</RowLeftCell>
                    <Measure offset onResize={this.onIazzuColResize}>
                      {({measureRef}) => (
                        <RowLeftCell ref={measureRef} backgroundColor={"#1e1e1e"} widthMdDown={widthIazzuCol}>
                          <IazzuLogoImage src={LogoWhite} />
                        </RowLeftCell>
                      )}
                    </Measure>
                  </RowLeft>
                  {/* Row 2: Costs ////////////////////////////////////////////////////////////////// */}
                  <RowLeft {...d} height={heightFeatureRow}>
                    <FlexCell {...d}>
                      <CellHeaderText left>{god(featureRowTexts, "costs.label", "")}</CellHeaderText>
                    </FlexCell>
                    <FlexCell {...d} padded justifyContent={"center"} widthMdDown={widthIazzuCol}>
                      <TextCostsRowValue {...d}>{iazzuCosts}</TextCostsRowValue>
                    </FlexCell>
                  </RowLeft>
                  {/* Rows 3-n: Features //////////////////////////////////////////////////////////// */}
                  {featureRowsOrder.map((rowKey, i) => (
                    <RowLeft {...d} key={`left_row_${i}`} height={heightFeatureRow} noBorderBottom={i == featureRowsOrder.length - 1}>
                      <FlexCell {...d} data-tip={!!featureRowTexts[rowKey].popover ? featureRowTexts[rowKey].popover.split("\r\n").join("<br/>") : ""}>
                        <FlexCell style={{paddingRight: !!featureRowTexts[rowKey].popover ? 0 : 20}}>
                          <CellHeaderText left {...d} long={selectors.getTextLong(featureRowTexts[rowKey].label)}>
                            {featureRowTexts[rowKey].label}
                          </CellHeaderText>
                        </FlexCell>
                        {!!featureRowTexts[rowKey].popover && (
                          <QuestionMarkContainer {...d}>
                            <CIcon id={`FeatureChartQuestionMark`} />
                          </QuestionMarkContainer>
                        )}
                      </FlexCell>
                      <FlexCell {...d} justifyContent={"center"} widthMdDown={widthIazzuCol}>
                        <CIcon id={`FeatureChartAvailable`} />
                      </FlexCell>
                    </RowLeft>
                  ))}
                  {/* Last Row: white background in iazzu Column //////////////////////////////////// */}
                  <RowLeftLast {...d} height={extraHeightIazzuLogo}>
                    <FlexCell {...d}> </FlexCell>
                    <Measure offset onResize={this.onLastColResize}>
                      {({measureRef}) => <FlexCell {...d} ref={measureRef} backgroundColor={"white"} widthMdDown={widthIazzuCol} />}
                    </Measure>
                  </RowLeftLast>
                </ContentLeft>

                {/* n Right Columns */}
                <ContentRight {...d}>
                  {/* Row 1: Column Headers ///////////////////////////////////////////////////////// */}
                  <RowRight
                    //
                    {...d}
                    useFlex={useFlex}
                    widthNonFlex={widthFeatureCol * amountFeatureCols + paddingAddRight}
                    height={heightHeaderRow + extraHeightIazzuLogo}
                    noBorderBottom>
                    {columnsFinal.map((column, i) => (
                      <RowRightCell
                        //
                        {...d}
                        key={`col_header_${i}`}
                        useFlex={useFlex}
                        widthNonFlex={widthFeatureCol}
                        height={heightHeaderRow}
                        borderWhite
                        backgroundColor={"#eaeaea"}>
                        <CellHeaderText {...d}>{column.header}</CellHeaderText>
                      </RowRightCell>
                    ))}
                    {!!paddingAddRight && <div style={{width: paddingAddRight}} />}
                  </RowRight>
                  {/* Row 2: Column Costs /////////////////////////////////////////////////////////// */}
                  <RowRight
                    //
                    {...d}
                    useFlex={useFlex}
                    widthNonFlex={widthFeatureCol * amountFeatureCols + paddingAddRight}
                    height={heightFeatureRow}>
                    {columnsFinal.map((column, i) => (
                      <RowRightCell
                        //
                        {...d}
                        key={`col_header_${i}`}
                        {...(!!column.costsPopover ? {["data-tip"]: column.costsPopover} : {})}
                        data-place={"bottom"}
                        useFlex={useFlex}
                        widthNonFlex={widthFeatureCol}>
                        <TextCostsRowValue {...d}>
                          {column.costsLabel} {!!column.costsPopover && <CIcon {...d} id={`FeatureChartQuestionMark`} inline ty={1} />}
                        </TextCostsRowValue>
                      </RowRightCell>
                    ))}
                    {!!paddingAddRight && <div style={{width: paddingAddRight}} />}
                  </RowRight>
                  {/* Rows 3-n: Features for individual Columns ///////////////////////////////////// */}
                  {featureRowsOrder.map((rowKey, rowIndex) => (
                    <RowRight
                      //
                      {...d}
                      key={`row_${rowKey}_${rowIndex}`}
                      useFlex={useFlex}
                      widthNonFlex={widthFeatureCol * amountFeatureCols + paddingAddRight}
                      height={heightFeatureRow}
                      noBorderBottom={rowIndex == featureRowsOrder.length - 1}>
                      {columnsFinal.map((column, colIndex) => {
                        const value = columnsFinal[colIndex].featureRows[rowIndex].value // available, unavailable, partiallyavailable
                        return (
                          <FlexCell
                            //
                            {...d}
                            key={`column_${colIndex}`}
                            justifyContent={"center"}>
                            <CIcon id={value == "available" ? "FeatureChartAvailable" : value == "unavailable" ? "FeatureChartUnavailable" : "FeatureChartPartiallyAvailable"} />
                          </FlexCell>
                        )
                      })}
                      {!!paddingAddRight && <div style={{width: paddingAddRight}} />}
                    </RowRight>
                  ))}
                </ContentRight>
              </ContentContainer>
            </Container>
            {!!paddingAddRight && <PaddingRightTransparentOverlay {...d} width={paddingAddRight} />}
          </PaddedContainer>
        )}
      </AllContainer>
    )
  }
}

const CellText = css`
  color: #1e1e1e;
  font-size: 16px;
  line-height: 27px;
  letter-spacing: 1.6px;
`
const CellBoldText = css`
  color: #1e1e1e;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0;
  font-weight: ${props => props.theme.vars.fonts.weights.bold};
`

////////////////////////////////////////////////

const AllContainer = styled.div`
  margin-top: 50px;
  margin-bottom: 50px;
  z-index: 5;
`

const HeaderContainer = styled.div`
  position: relative;
  ${props => selectors.getBackgroundColorByProps(props, "rgba(255,255,0,0.25)")}
  display: flex;

  margin-bottom: 80px;
  ${props => css`
    ${props.theme.media.md} {
      margin-bottom: 30px;
      flex-direction: column;
    }
    ${props.theme.media.smdown} {
      margin-bottom: 50px;
      flex-direction: column;
    }
  `}
`
const HeaderSubheaderContainer = styled.div`
  border: ${props => (props.debug ? 1 : 0)}px solid blue;
  flex: 1;

  padding-right: 30px;
  ${props => css`
    ${props.theme.media.mddown} {
      padding-right: 0px;
    }
  `}
`
const SubHeader = styled.p`
  text-align: left;
  margin-block-start: 0;
  margin-block-end: 0;
  border: ${props => (props.debug ? 1 : 0)}px solid green;
  ${props => true && props.debug && selectors.getDebugOverlayCss(props, `SubHeader`, `rgba(0,0,255,0.15)`)}

  padding-top: 40px;
  color: #1e1e1e;
  font-size: 20px;
  line-height: 27px;
  letter-spacing: 2px;
  ${props => css`
    ${props.theme.media.md} {
      padding-top: 20px;
      padding-bottom: 60px;
    }
    ${props.theme.media.smdown} {
      color: #282828;
      font-size: 18px;
      line-height: 27px;
      letter-spacing: 1.8px;
    }
  `}
`
const PaddedContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  ${props => selectors.getPaddingLeftRightByThemeAndVariant(props.theme, props.variant, "padding")}
  ${props => true && props.debug && selectors.getDebugOverlayCss(props, `PaddedContainer`, `rgba(0,0,255,0.15)`)}

  ${props =>
    !!props.paddingAddRight &&
    css`
      padding-right: 0px !important;
    `}
`

const ChartKeyContainer = styled.div`
  border: ${props => (props.debug ? 1 : 0)}px solid red;
  width: auto;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  ${props => true && props.debug && selectors.getDebugOverlayCss(props, `ChartKeyContainer`, `rgba(255,0,255,0.15)`)}
`
const ChartKeyHeader = styled.div`
  font-weight: ${props => props.theme.vars.fonts.weights.semibold};
  font-size: 15px;
  text-transform: uppercase;
  color: #1e1e1e;
  letter-spacing: 2.31px;

  padding-left: 30px;
  margin-bottom: 30px;
  ${props => css`
    ${props.theme.media.lg} {
      margin-bottom: 20px;
    }
    ${props.theme.media.md} {
      padding-left: 0px;
      margin-bottom: 10px;
    }
    ${props.theme.media.smdown} {
      padding-left: 0px;
      margin-bottom: 20px;
      padding-top: 40px;
    }
  `}
`
const ChartKeyItems = styled.div`
  display: flex;
  position: relative;

  ${props => css`
    ${props.theme.media.lg} {
      flex-direction: column;
    }
    ${props.theme.media.smdown} {
      flex-direction: column;
    }
  `}
`
const ChartKeyItem = styled.div`
  border: ${props => (props.debug ? 1 : 0)}px solid yellow;
  display: flex;
  align-items: flex-end;

  padding-left: 30px;
  ${props => css`
    ${props.theme.media.lg} {
      margin-top: 4px;
    }
    ${props.theme.media.md} {
      padding-left: 0px;
      padding-right: 20px;
    }
    ${props.theme.media.smdown} {
      padding-left: 0px;
      padding-top: 7px;
      padding-bottom: 8px;
    }
  `}
`

const ChartKeyLabel = styled.div`
  color: #1e1e1e;
  text-align: left;
  margin-bottom: 1px;
  margin-left: 10px;

  font-size: 16px;
  letter-spacing: 0.1em;
  line-height: 1.4em;

  ${props =>
    !props.useFlex &&
    css`
      // opacity: 0.5;

      ${props.theme.media.md} {
        font-size: 11px;
        margin-bottom: 6px;
      }
    `}
`

const Container = styled.div`
  position: relative;
  ${props => selectors.getBackgroundColorByProps(props, "rgba(255,0,0,0.25)")}
`

const ShadowContainer = styled.div`
  position: absolute;
  z-index: 1;
  // pointer-events: none;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: ${props => (props.debug ? 200 : 0)}px;
  ${props => selectors.getBackgroundColorByProps(props, "rgba(255,255,0,0.5)")}
`
const ShadowRectangle = styled.div`
  position: absolute;
  left: ${props => props.left}px;
  top: ${props => props.top}px;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  background-color: ${props => (props.debug ? "blue" : "white")};
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.2);
`

const ContentContainer = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  ${props => selectors.getBackgroundColorByProps(props, "rgba(255,0,255,0.25)")}
`
const ContentLeft = styled.div`
  width: ${props => props.width}px;
  border: ${props => (props.debug ? 1 : 0)}px solid blue;
  ${props => true && selectors.getDebugOverlayCss(props, "ContentLeft", "rgba(255,0,0,0.25)")}
`
const RowLeftCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: auto;
  ${props =>
    !!props.backgroundColor &&
    css`
      background-color: ${props.backgroundColor};
    `}

  flex: 1;
  ${props =>
    !!props.widthMdDown &&
    css`
      ${props.theme.media.mddown} {
        max-width: ${props.widthMdDown}px;
        width: ${props.widthMdDown}px;
      }
    `}
`

const IazzuLogoImage = styled.img`
  width: 100px;
  max-width: 70%;
`

const RowLeft = styled.div`
  width: 100%;
  height: ${props => props.height}px;
  display: flex;
  ${props => selectors.getBackgroundColorByProps(props, "rgba(255,0,255,0.25)")}
  border-bottom: ${props => (props.noBorderBottom ? 0 : 1)}px solid #dce0e3; // TODO: Make Variable
`

const FlexCell = styled.div`
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: ${props => (!!props.justifyContent ? props.justifyContent : "flex-start")};
  background-color: ${props => (!!props.backgroundColor ? props.backgroundColor : "none")};
  border: ${props => (props.debug ? 1 : 0)}px solid red;
  ${props => selectors.getBackgroundColorByProps(props, "rgba(255,255,0,0.25)")}

  // padding: 0px ${props => (props.padded ? 10 : 0)}px;

  flex: 1;
  max-width: 100%;
  ${props =>
    !!props.widthMdDown &&
    css`
      ${props.theme.media.mddown} {
        max-width: ${props.widthMdDown}px;
        width: ${props.widthMdDown}px;
        // border-radius: 40px;
      }
    `}
`

const TextCostsRowValue = styled.div`
  ${CellText};
  text-align: center;
  line-height: 1.4em;
  border: ${props => (props.debug ? 1 : 0)}px solid green;

  padding-left: 10px;
  padding-right: 10px;
  ${props => css`
    ${props.theme.media.md} {
      font-size: 14px;
      // padding-left: 10px;
      // padding-right: 10px;
    }
    ${props.theme.media.smdown} {
      font-size: 14px;
      letter-spacing: 0;
    }
  `}
`

const CellHeaderText = styled.div`
  border: ${props => (props.debug ? 1 : 0)}px solid blue;
  font-family: ${props => props.theme.vars.fonts.families.default};
  ${CellBoldText};
  letter-spacing: 0.05em;
  max-width: 100%;
  line-height: 1.4em;

  ${props => css`
    ${props.theme.media.smdown} {
      font-size: 12px;
    }
  `}

  ${props =>
    props.long &&
    css`
      ${props.theme.media.md} {
        font-size: 12px;
        background-color: ${props.debug ? "red" : "none"};
      }
      ${props.theme.media.smdown} {
        font-size: 11px;
        background-color: ${props.debug ? "red" : "none"};
      }
    `}

  text-align: center;
  padding: 0px 20px;
  ${props =>
    props.left &&
    css`
      text-align: left;
      padding: 0px 5px 0px 0px;
    `}
`

const QuestionMarkContainer = styled.div`
  align-self: stretch;
  align-items: center;
  display: flex;
  justify-content: center;

  border: ${props => (props.debug ? 1 : 0)}px solid green;

  width: 40px;
  ${props => css`
    ${props.theme.media.smdown} {
      width: auto;
      justify-content: flex-end;
      padding-right: 5px;
    }
  `}
`

const RowLeftLast = styled.div`
  height: ${props => props.height}px;
  width: 100%;
  display: flex;
  ${props => selectors.getBackgroundColorByProps(props, "rgba(0,0,255,0.2)")}
`

const ContentRight = styled.div`
  flex: 1;
  overflow-y: scroll;
  border: ${props => (props.debug ? 1 : 0)}px solid green;
`
const RowRight = styled.div`
  width: ${props => (props.useFlex ? `100%` : `${props.widthNonFlex}px`)};
  height: ${props => props.height}px;
  display: flex;
  align-items: flex-end;
  ${props => selectors.getBackgroundColorByProps(props, "rgba(255,0,0,0.2)")}
  border-bottom: ${props => (props.noBorderBottom ? 0 : 1)}px solid #dce0e3;
  ${props =>
    props.sticky &&
    css`
      position: -webkit-sticky;
      position: sticky;
      top: 0;
    `}
`

const RowRightCell = styled.div`
  background-color: ${props => (!!props.backgroundColor ? props.backgroundColor : "none")};
  width: ${props => (props.useFlex ? `100%` : `${props.widthNonFlex}px`)};
  display: flex;
  align-items: center;
  justify-content: center;

  ${props =>
    !!props.height &&
    css`
      height: ${props.height}px;
    `}
  ${props =>
    !props.height &&
    css`
      align-self: stretch;
    `}

  border-left: ${props => (props.borderWhite ? 1 : 0)}px solid white;
  border-right: ${props => (props.borderWhite ? 1 : 0)}px solid white;
  border: ${props => (props.debug && !props.borderWhite ? 1 : 0)}px solid red;
`

const PaddingRightTransparentOverlay = styled.div`
  pointer-events: none;
  position: absolute;
  z-index: 3;
  width: ${props => props.width}px;
  right: 0;
  top: 0;
  bottom: 0;
  border: ${props => (props.debug ? 1 : 0)}px solid blue;
  background-color: ${props => (props.debug ? "rgba(255,0,0,0.5)" : "rgba(255,255,255,0.75)")};
`

export default CExhibitorsFeatureMatrix
