import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import {motion, useViewportScroll, useTransform, AnimatePresence} from "framer-motion" // eslint-disable-line
import styled, {css, ThemeProvider} from "styled-components" // eslint-disable-line
// import {Row, Col} from "react-grid-system"
import Masonry from "react-masonry-css"
import {injectIntl} from "react-intl"

import {CONFIG} from "../../../config"
import * as selectors from "../../../data/selectors"

import CImage from "../../../view/components/CImage"
import CEditInWordPress from "../../../view/components/CEditInWordPress"
import CIcon from "../../../view/components/CIcon"
import CTestimonialButton from "../../../view/components/CTestimonialButton"

import CH3 from "../../../view/components/text/CH3"

const DEBUG = false && __DEV__
import {debbify} from "../../../data/selectors/helpers"
const debby = (...args) => debbify("CPortfolioTestimonials", ...args)

class CPortfolioTestimonials extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    intl: PropTypes.object.isRequired,
    header: PropTypes.string,
    testimonials: PropTypes.array,
    iosOrAndroid: PropTypes.bool,
    masonryBreakpoints: PropTypes.object,
    showEditButton: PropTypes.bool,
    variant: PropTypes.oneOf(["default", "umbrella", "ambassadors"]),
    mediaQueryClass: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]).isRequired,
    alreadyPadded: PropTypes.bool,
    alwaysShowImages: PropTypes.bool,
  }
  static defaultProps = {
    debug: false,
    masonryBreakpoints: {
      default: 2,
      [CONFIG.layout.breakpoints.md.max]: 1,
    },
    variant: "default",
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  renderTestimonial = (testimonial, i) => {
    const {mediaQueryClass, intl, variant, iosOrAndroid, showEditButton, alwaysShowImages} = this.props
    const d = {debug: DEBUG || this.props.debug}
    const {id, logoSrc, logoSrcSet, name, role, citation, buttonBehaviour, buttonGallery, buttonUrl, headline} = testimonial // eslint-disable-line

    const isSmDown = selectors.getMediaQueryMatching(mediaQueryClass, "<=", "sm")
    debby("renderTestimonial()", {variant, id, logoSrc, name, headline})
    return (
      <MasonryItem {...d} key={`item_${i}`}>
        <MasonryItemContent {...d} variant={variant}>
          {(variant == "default" || alwaysShowImages) && !!logoSrc && (
            <MasonryItemImageContainer>
              <CImage
                //
                {...d}
                objectFit={"contain"}
                align={"left"}
                ratio={410 / 140}
                src={logoSrc}
                srcSet={logoSrcSet}
              />
            </MasonryItemImageContainer>
          )}
          {variant == "default" && <TestimonialName {...d}>{name}</TestimonialName>}
          {variant == "default" && <TestimonialRole {...d}>{role}</TestimonialRole>}

          {(variant == "umbrella" || variant == "ambassadors") && (
            <UmbrellaHeaderRow {...d}>
              {variant == "umbrella" && <UmbrellaHeaderHeadline {...d}>{headline || " "}</UmbrellaHeaderHeadline>}
              {variant == "ambassadors" && <TestimonialName {...d}>{name}</TestimonialName>}
              <UmbrellaHeaderStars {...d}>
                {[...Array(5)].map((_, i) => (
                  <UmbrellaHeaderStar {...d} key={`star_${i}`}>
                    <CIcon id={`TestimonialStar`} scale={!isSmDown ? 1 : 0.85} />
                  </UmbrellaHeaderStar>
                ))}
              </UmbrellaHeaderStars>
            </UmbrellaHeaderRow>
          )}

          {variant == "ambassadors" && <TestimonialRole {...d}>{role}</TestimonialRole>}

          <TestimonialCitation {...d}>"{citation}"</TestimonialCitation>

          {variant == "umbrella" && <TestimonialNameBottom {...d}>{name}</TestimonialNameBottom>}

          {(variant == "default" || variant == "ambassadors") && (
            <CTestimonialButton
              //
              {...d}
              iosOrAndroid={iosOrAndroid}
              behaviour={buttonBehaviour} // if undefined or "none": don't draw
              gallery={buttonGallery}
              label={intl.formatMessage({id: `PortfolioTestimonialsRequestAccess`})}
              qrCodeLabel={intl.formatMessage({id: `TestimonialQrCodeLabel`})}
            />
          )}
          {showEditButton && <CEditInWordPress resourceId={id} label={"EDIT"} />}
        </MasonryItemContent>
      </MasonryItem>
    )
  }

  render = () => {
    const {header, testimonials, masonryBreakpoints, variant, showEditButton, alreadyPadded} = this.props
    const d = {debug: DEBUG || this.props.debug}
    if (!testimonials || !testimonials.length) {
      return null
    }
    debby("render()", {variant, header, amountTestimonials: testimonials.length, showEditButton})
    return (
      <SectionTestimonials {...d} portfolioVariant={variant}>
        <PaddedSection {...d} variant={"header"} alreadyPadded={alreadyPadded} portfolioVariant={variant}>
          {!!header && (
            <TestimonialsHeaderContainer {...d} portfolioVariant={variant}>
              <CH3 variant={variant == "umbrella" ? "umbrellaPortfolio" : CH3.defaultProps.variant} text={header} />
            </TestimonialsHeaderContainer>
          )}

          <GutterWidthTestimonialsSection>
            <Masonry
              //
              breakpointCols={masonryBreakpoints}
              className={"my-masonry-grid"} // see GlobalStyle.js
              columnClassName={"my-masonry-grid_column"}
            >
              {testimonials.map(this.renderTestimonial)}
            </Masonry>
          </GutterWidthTestimonialsSection>
        </PaddedSection>
      </SectionTestimonials>
    )
  }
}

const SectionTestimonials = styled.div`
  ${props => selectors.getDebugOverlayCss(props, "SectionTestimonials", "rgba(0,255,0,0.35)")}
  background-color: ${props => (props.portfolioVariant == "default" ? props.theme.vars.colors.gallery : "none")};
  border: ${props => (props.debug ? 1 : 0)}px solid black;
  text-align: left;
  color: ${props => props.theme.vars.colors.codGray};
`

const PaddedSection = styled.div`
  position: relative;
  ${props => !props.alreadyPadded && selectors.getPaddingLeftRightByThemeAndVariant(props.theme, props.variant)}
  padding-bottom: ${props => (props.portfolioVariant == "default" ? 90 : 0)}px;
`

const TestimonialsHeaderContainer = styled.div`
  ${props => selectors.getDebugOverlayCss(props, "TestimonialsHeaderContainer", "rgba(255,0,0,0.05)")}
  padding-top: ${props => (props.portfolioVariant == "default" ? 90 : 60)}px;
  padding-bottom: ${props => (props.portfolioVariant == "default" ? 60 : 40)}px;

  ${props =>
    props.portfolioVariant == "default" &&
    css`
      // padding-top: 60px;
    `}
`

const gutterTestimonialsWidth = 18
const GutterWidthTestimonialsSection = styled.div`
  margin: 0px -${gutterTestimonialsWidth}px;
`

const MasonryItem = styled.div`
  padding: ${gutterTestimonialsWidth}px;
  ${props => selectors.getDebugOverlayCss(props, "MasonryItem", "rgba(0,255,0,0.35)")}
`

const MasonryItemContent = styled.div`
  ${props => selectors.getDebugOverlayCss(props, "MasonryItemContent", "rgba(0,255,0,0.35)")}
  background-color: white;
  position: relative;
  ${props =>
    (props.variant == "umbrella" || props.variant == "ambassadors") &&
    css`
      box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.2);
    `}

  padding: 50px 60px;
  ${props => css`
    ${props.theme.media.smdown} {
      padding: 40px 25px;
    }
  `}
`

const MasonryItemImageContainer = styled.div`
  max-width: 400px;
  margin-bottom: 20px;
`

const UmbrellaHeaderRow = styled.div`
  border: ${props => (props.debug ? 1 : 0)}px solid red;
  ${props => true && selectors.getDebugOverlayCss(props, "UmbrellaHeaderRow", "rgba(255,0,0,0.25)")}

  display: flex;
  ${props => css`
    ${props.theme.media.smdown} {
      flex-direction: column;
    }
  `}
`

const UmbrellaHeaderHeadline = styled.div`
  flex: 1;
  min-height: 30px;

  font-size: 16px;
  line-height: 25px;
  color: #1e1e1e;
  letter-spacing: 1.6px;
  text-align: left;
  font-weight: ${props => props.theme.vars.fonts.weights.semibold};
  hyphens: auto;
  font-kerning: none;
`

const UmbrellaHeaderStars = styled.div`
  border: ${props => (props.debug ? 1 : 0)}px solid blue;
  display: flex;

  padding-left: 5px;
  padding-top: 3px;
  ${props => css`
    ${props.theme.media.smdown} {
      padding-left: 0px;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  `}
`

const UmbrellaHeaderStar = styled.div`
  border: ${props => (props.debug ? 1 : 0)}px solid red;

  margin-left: 5px;
  ${props => css`
    ${props.theme.media.smdown} {
      margin-left: 0px;
      margin-right: 7px;
    }
  `}
`

const TestimonialName = styled.div`
  ${props => selectors.getPersonNameFontCss(props)}
  ${props => selectors.getDebugOverlayCss(props, "TestimonialName", "rgba(0,255,0,0.35)")}
  padding-bottom: 10px;
  color: ${props => props.theme.vars.colors.codGray};
  flex: 1;
  hyphens: auto;
  font-kerning: none;
`

const TestimonialNameBottom = styled.div`
  padding-top: 10px;
  opacity: 0.51;
  font-size: 16px;
  line-height: 27px;
  color: #778080;
  letter-spacing: 1.6px;
  text-align: left;
  text-align: right;
  ${props => selectors.getDebugOverlayCss(props, "TestimonialNameBottom", "rgba(0,255,0,0.35)")}
`

const TestimonialRole = styled.div`
  padding-top: 0px;
  // font-size: 15px;
  font-size: 11px;
  line-height: 1.4;
  font-weight: ${props => props.theme.vars.fonts.weights.regular};
  color: ${props => props.theme.vars.colors.siccoro};
  font-style: italic;
  letter-spacing: 0.2em;
  font-kerning: none;
  text-transform: uppercase;
  ${props => selectors.getDebugOverlayCss(props, "TestimonialRole", "rgba(0,255,255,0.35)")}
  padding-bottom: 10px;
`

const TestimonialCitation = styled.div`
  padding-top: 15px;
  padding-bottom: 10px;
  font-size: 16px;
  line-height: 1.7;
  font-weight: ${props => props.theme.vars.fonts.weights.regular};
  letter-spacing: 0.1em;
  hyphens: auto;
  font-kerning: none;
  color: ${props => props.theme.vars.colors.siccoro};
  ${props => selectors.getDebugOverlayCss(props, "TestimonialCitation", "rgba(0,255,255,0.35)")}
`

export default injectIntl(CPortfolioTestimonials)
