import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import {connect} from "react-redux"
import {withRouter} from "react-router"
import {injectIntl} from "react-intl"
import * as selectors from "../selectors"

import {debbify} from "../../data/selectors/helpers"
const debby = (...args) => debbify("HOC.withScreenProps", ...args)

const withScreenProps = Comp => {
  class CompWithScreenProps extends Component {
    static propTypes = {
      dispatch: PropTypes.func.isRequired,
      intl: PropTypes.object.isRequired,
      intlLocale: PropTypes.string.isRequired,
      themeKey: PropTypes.string,
      postData: PropTypes.object,
      postDataSuccess: PropTypes.bool,
      wordPressUserId: PropTypes.number,
      wordPressResourceId: PropTypes.number,
      editInWordPressProps: PropTypes.object,
      locationPathname: PropTypes.string,
      locationHash: PropTypes.string,
      locationHashValues: PropTypes.object,
      mediaQueryClass: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
      embeddedMode: PropTypes.bool,
    }
    shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))
    render = () => {
      debby("render()")
      return <Comp {...this.props} />
    }
  }

  const mapStateToProps = (state, props) => ({
    postData: selectors.getPostData(state, props),
    postDataSuccess: selectors.getPostDataSuccess(state, props),
    pageTemplate: selectors.getPageTemplate(state, props),
    intlLocale: selectors.getIntlLocale(state),
    themeKey: selectors.getSessionWaypointThemeKey(state),
    wordPressUserId: selectors.getWordPressUserId(state),
    wordPressResourceId: selectors.getWordPressResourceId(state, props),
    wordPressEditLink: selectors.getWordPressEditLink(state, props),
    editInWordPressProps: selectors.getEditInWordPressProps(state, props),
    locationPathname: selectors.getLocationPathname(state, props),
    locationHash: selectors.getLocationHash(state, props),
    locationHashValues: selectors.getLocationHashValues(state, props),
    mediaQueryClass: selectors.getMediaQueryClass(state, props),
    embeddedMode: state.session.embeddedMode,
  })
  return injectIntl(connect(mapStateToProps)(withRouter(CompWithScreenProps)))
}

export default withScreenProps
