import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import styled, {css} from "styled-components" // eslint-disable-line

import * as selectors from "../../data/selectors"
import {CONFIG} from "../../config"

export const getHrefProps = href => {
  let isRel = false
  if (!href) {
    return {href: null, isRel: false}
  }
  let hrefIsString = !!href.toLowerCase
  if (!!href && hrefIsString) {
    const {root, folderReact} = CONFIG.server
    const hrefNeedle = root + folderReact // e.g. https://iazzu.com/
    isRel = href.toLowerCase().indexOf("http://localhost") == 0 || href.toLowerCase().indexOf(hrefNeedle) == 0
    if (isRel) {
      //  eslint-disable-next-line
      href = href.replace(/^.*\/\/[^\/]+/, "") // make URL relative to server root
    }
  }
  return {href: hrefIsString ? href : null, isRel}
}

export const getHrefRecursively = target => {
  if (target.nodeName == "A") {
    return target.href
  } else if (!!target.parentNode) {
    return getHrefRecursively(target.parentNode)
  }
  return null
}
export const getHrefByClickEvent = event => (!event ? null : getHrefRecursively(event.target))

class CText extends Component {
  static propTypes = {
    // debug: PropTypes.bool,
    className: PropTypes.string,
    html: PropTypes.string,
    text: PropTypes.string,
    tagName: PropTypes.oneOf(["div", "h1"]),
    variant: PropTypes.string,
    onLinkPress: PropTypes.func,

    // theme: PropTypes.string,
  }
  static defaultProps = {
    // debug: true && __DEV__,
    tagName: `div`,
    className: "",
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  onClick = event => {
    event.preventDefault() // always
    const href = getHrefByClickEvent(event)
    if (!href) {
      return
    }
    const {onLinkPress} = this.props
    !!onLinkPress && onLinkPress(selectors.getHrefProps(href))
  }

  render = () => {
    const {className, html, text, tagName} = this.props
    let divProps = {className: `ctext ${className}`}
    const TextTag = styled(tagName)``

    if (!!html) {
      return <TextTag onClick={this.onClick} {...divProps} dangerouslySetInnerHTML={{__html: html}} />
    }
    if (!!text) {
      return (
        <TextTag onClick={this.onClick} {...divProps}>
          {text}
        </TextTag>
      )
    }
    return null
  }
}

export default CText
