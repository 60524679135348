import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import styled, {css} from "styled-components" // eslint-disable-line
import {motion} from "framer-motion"

import * as selectors from "../../../data/selectors"
const god = selectors.getObjectDeep

import CImage from "../../../view/components/CImage"
import CSpacer from "../../../view/components/CSpacer"
import CInitMotion from "../../../view/components/CInitMotion"
import CAppStoreButton from "../../../view/components/CAppStoreButton"

const DEBUG = false && __DEV__
import {debbify} from "../../../data/selectors/helpers"
const debby = (...args) => debbify("CExhibitorsHeader", ...args)

const PERCENT_WIDTH_HEADERTEXT = 60
const HAND_IMAGE_SCALE_VERTICAL = 0.83

class CExhibitorsHeader extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    parallaxController: PropTypes.object.isRequired,
    intlLocale: PropTypes.string.isRequired,
    featuredImage: PropTypes.object,
    headerText: PropTypes.string,
    handImage: PropTypes.object,
    stageWidth: PropTypes.number,
    mediaQueryClass: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
  }
  static defaultProps = {
    debug: false && __DEV__,
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  getHandImageProps = () => {
    const {stageWidth, handImage} = this.props
    const handImageAspectRatio = god(handImage, "sizes.500.width", 1) / god(handImage, "sizes.500.height", 1)
    const handImageWidth = stageWidth * (1 - PERCENT_WIDTH_HEADERTEXT / 100) * HAND_IMAGE_SCALE_VERTICAL
    const handImageDimensions = {width: handImageWidth, height: handImageWidth / handImageAspectRatio}
    const marginTextRight = handImageDimensions.width
    return {handImageDimensions, marginTextRight}
  }

  getHeaderImageProps = () => {
    const {mediaQueryClass} = this.props

    // xl
    let ratio = 16 / 9.6
    let heightGrows = false
    let minHeight = 0
    // others
    switch (mediaQueryClass) {
      case "xs":
      case "sm":
        ratio = undefined
        heightGrows = true
        minHeight = 900 // src, srcSet
        break
    }
    return {ratio, heightGrows, minHeight}
  }

  render = () => {
    const {mediaQueryClass, parallaxController, featuredImage, headerText, handImage, intlLocale} = this.props // eslint-disable-line
    const d = {debug: DEBUG || this.props.debug}
    if (!featuredImage || !headerText) {
      return <div />
    }
    const {handImageDimensions, marginTextRight} = this.getHandImageProps()
    const headerImageProps = this.getHeaderImageProps() // {ratio, heightGrows}

    const heroImageSrcData = selectors.getSrcAndSrcSetByWordpressImage(featuredImage, {minHeight: headerImageProps.minHeight})
    const handImageSrcData = selectors.getSrcAndSrcSetByWordpressImage(handImage)

    debby("render()", {mediaQueryClass, headerImageProps, heroImageSrcData})
    return (
      <SectionHeader>
        <CImage
          //
          {...d}
          src={heroImageSrcData.src}
          srcSet={heroImageSrcData.srcSet}
          {...headerImageProps}
          parallaxRatio={0.25}
          parallaxController={parallaxController}
          overlayColor={"rgba(0,0,0,0.4)"}>
          {/* Header Text, App Store Buttons */}
          <PaddedHeaderContainer {...d}>
            <HeaderContainer {...d} variant={`header`} marginRight={marginTextRight}>
              <CSpacer {...d} variant={"headermenu"} />
              <HeaderText {...d}>{headerText}</HeaderText>
              <AppStoreButtonsContainer {...d}>
                <AppStoreButton {...d} intlLocale={intlLocale} delay={200} platform={"ios"} marginRight />
                <AppStoreButton {...d} intlLocale={intlLocale} delay={300} platform={"android"} />
              </AppStoreButtonsContainer>
            </HeaderContainer>
          </PaddedHeaderContainer>

          {/* Hand Image */}
          <HandImageContainer {...d}>
            <HandImageSizer {...d} {...handImageDimensions}>
              <HandImage {...d} src={handImageSrcData.src} srcSet={handImageSrcData.srcSet} />
            </HandImageSizer>
          </HandImageContainer>
        </CImage>
      </SectionHeader>
    )
  }
}

const SectionHeader = styled.div`
  position: relative;
`

const PaddedHeaderContainer = styled.div`
  overflow: hidden;
  color: white;
  text-align: ${props => (props.align == "center" ? "center" : "left")};
  border: ${props => (props.debug ? 1 : 0)}px solid blue;

  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  ${props => css`
    ${props.theme.media.smdown} {
      position: relative;
      height: auto;
    }
  `}
`

const HeaderContainer = styled.div`
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  ${props => selectors.getPaddingMarginByThemeAndVariant(props.theme, props.variant, "margin", "left")}

  margin-right: ${props => props.marginRight}px;
  padding-right: 30px;
  ${props => css`
    ${props.theme.media.smdown} {
      position: relative;
      margin-right: 0;
      padding-right: 0;
      margin-top: 100px;
      ${selectors.getPaddingMarginByThemeAndVariant(props.theme, props.variant, "margin", "right")}
    }
  `}

  border: ${props => (props.debug ? 3 : 0)}px solid red;
  ${props => false && props.debug && selectors.getDebugOverlayCss(props, `HeaderContainer`, `rgba(255,0,0,0.15)`)}
`

const HeaderText = styled.div`
  font-weight: ${props => props.theme.vars.fonts.weights.bold};
  font-family: ${props => props.theme.vars.fonts.families.default};
  letter-spacing: 0.1em;
  line-height: 1.4em;
  ${props => props.debug && selectors.getDebugOverlayCss(props, `HeaderText`, `rgba(255,255,0,0.15)`)}

  font-size: 35px;
  margin-bottom: 70px;
  ${props => css`
    ${props.theme.media.lg} {
      font-size: 25px;
      margin-bottom: 40px;
    }
    ${props.theme.media.md} {
      font-size: 19px;
      margin-bottom: 40px;
    }
    ${props.theme.media.smdown} {
      font-size: 22px;
      margin-bottom: 40px;
    }
  `}
`

const AppStoreButtonsContainer = styled.div`
  ${props => selectors.getDebugOverlayCss(props, "AppStoreButtonsContainer", "rgba(0,0,255,0.25)")}
  display: flex;

  flex-direction: row;
  ${props => css`
    ${props.theme.media.smdown} {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  `}
`

const AppStoreButton = ({delay, platform, debug, marginRight, intlLocale}) => (
  <AppStoreButtonSingle marginRight={marginRight}>
    <CInitMotion delay={delay}>
      <motion.div whileHover={{scale: 1.05}} style={{width: "100%", marginRight: 23}}>
        <CAppStoreButton intlLocale={intlLocale} platform={platform} debug={debug} />
      </motion.div>
    </CInitMotion>
  </AppStoreButtonSingle>
)
AppStoreButton.propTypes = {debug: PropTypes.bool, delay: PropTypes.number, platform: PropTypes.string, marginRight: PropTypes.bool, intlLocale: PropTypes.string.isRequired}

const AppStoreButtonSingle = styled.div`
  width: 40%;
  max-width: 150px;
  padding-right: ${props => (props.marginRight ? 20 : 0)}px;
  ${props => css`
    ${props.theme.media.smdown} {
      width: 100%;
      max-width: 200px;
      padding-right: 0;
      margin-bottom: 20px;
    }
  `}
`

const HandImageContainer = styled.div`
  border: ${props => (props.debug ? 2 : 0)}px solid blue;

  position: absolute;
  right: 0;
  bottom: 0;
  ${props => css`
    ${props.theme.media.smdown} {
      position: relative;
      display: flex;
      margin-top: 40px;
      align-items: flex-end;
      justify-content: flex-end;
    }
  `}
`

const HandImageSizer = styled.div`
  position: relative;

  width: ${props => props.width}px;
  height: ${props => props.height}px;
  ${props => css`
    ${props.theme.media.smdown} {
      width: 80%;
      height: auto;
    }
  `}
`

const HandImage = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  border: ${props => (props.debug ? 1 : 0)}px solid white;
`

export default CExhibitorsHeader
