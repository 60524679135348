import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import Slider from "react-slick"
import styled, {css} from "styled-components" // eslint-disable-line

import {CONFIG} from "../../../config"
import * as selectors from "../../../data/selectors"

import CImage from "../../../view/components/CImage"

import CH3 from "../../../view/components/text/CH3"

const DEBUG = false && __DEV__
import {debbify} from "../../../data/selectors/helpers"
const debby = (...args) => debbify("CHomePartners", ...args)

class CHomePartners extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    variant: PropTypes.oneOf(["default", "umbrella"]),
    header: PropTypes.string,
    partners: PropTypes.array,
    mediaQueryClass: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]).isRequired,
  }
  static defaultProps = {
    debug: false,
    variant: "default",
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  getSliderSettings = () => ({
    dots: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    // className: "cratings-slider",
    arrows: false,
    draggable: true,
    // width: "100%",
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: false,
    swipeToSlide: true,
    pauseOnDotsHover: false,
    pauseOnFocus: false,
    responsive: [
      {
        breakpoint: CONFIG.layout.breakpoints.xs.max - 1,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: CONFIG.layout.breakpoints.sm.max - 1,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: CONFIG.layout.breakpoints.md.max - 1,
        settings: {
          slidesToShow: 4,
        },
      },
    ],
    // speed: 1000,
    // centerMode: true,
    // centerPadding: 0, // remove edges left and right
    // adaptiveHeight: true,
  })

  renderPartnerItem = (item, i) => {
    const {intl, debug, partners, variant} = this.props // eslint-disable-line
    const {logoImage} = item
    const imageData = selectors.getSrcAndSrcSetByWordpressImage(logoImage)
    const d = {debug: DEBUG || this.props.debug}
    // if (i == 4) { debugger } // prettier-ignore
    debby("renderPartnerItem()", {i, logoImage})
    return (
      <PartnerContainer {...d} key={`item-${i}`}>
        <PartnerImageContainer {...d}>
          <CImage
            //
            {...d}
            // debug={i == 4}
            ratio={3 / 2}
            loadDelay={2000 + i * 200}
            src={imageData.src}
            // srcSet={imageData.srcSet}
            objectFit={"contain"}>
            {d.debug && <div>PARTNER #${i}!</div>}
          </CImage>
        </PartnerImageContainer>
      </PartnerContainer>
    )
  }

  render = () => {
    const {mediaQueryClass, header, partners, variant} = this.props
    const d = {debug: this.props.debug || DEBUG}
    const sliderSettings = this.getSliderSettings()
    if (!partners || !partners.length) {
      return <div />
    }
    const isSmDown = selectors.getMediaQueryMatching(mediaQueryClass, "<=", "sm")
    debby("render()", {variant, isSmDown, header, amount: partners.length})
    return (
      <Container {...d}>
        <PaddedContainer {...d} variant={"header"} {...d}>
          {(variant != "umbrella" || !isSmDown) && (
            <HeaderContainer variant={variant}>
              {variant == "default" && <Header {...d} variant={variant} dangerouslySetInnerHTML={{__html: header}} />}
              {variant == "umbrella" && !isSmDown && <CH3 text={header} variant={"umbrellaPartners"} />}
            </HeaderContainer>
          )}
          <SliderContainer {...d}>
            <Slider {...sliderSettings}>{partners.map(this.renderPartnerItem)}</Slider>
          </SliderContainer>
        </PaddedContainer>
      </Container>
    )
  }
}

const Container = styled.div`
  ${props => selectors.getDebugOverlayCss(props, "CHomePartners.Container", "rgba(0,255,0,0.35)")}
  border: ${props => (props.debug ? 1 : 0)}px solid blue;

  padding: 30px 0px;
  ${props => css`
    ${props.theme.media.xs} {
      padding: 10px 0px;
    }
  `}
`

const PaddedContainer = styled.div`
  ${props => selectors.getPaddingLeftRightByThemeAndVariant(props.theme, props.variant)}
  ${props => selectors.getBackgroundColorByProps(props, "rgba(0,0,255,0.25)")}
`

const HeaderContainer = styled.div`
  display: flex;
  align-items: ${props => (props.variant == "default" ? "center" : "flex-start")};
  flex-direction: column;
  padding: 0px ${props => (props.variant == "default" ? 30 : 0)}px;
  padding-bottom: ${props => (props.variant == "umbrella" ? 30 : 0)}px;
`

const Header = styled.div`
  padding-top: 30px;
  font-style: italic;
  font-weight: ${props => props.theme.vars.fonts.weights.regular};
  letter-spacing: 2px;
  text-align: center;
  padding-bottom: 30px;
  // max-width: 650px;

  font-size: 25px;
  line-height: 36px;
  ${props => css`
    ${props.theme.media.xs} {
      font-size: 20px;
      line-height: 30px;
    }
  `}

  ${props => selectors.getDebugOverlayCss(props, "Header", "rgba(0,255,255,0.35)")}
`

const PartnerContainer = styled.div`
  width: 100%;
`

const PartnerImageContainer = styled.div`
  margin: 10px;
  border: ${props => (props.debug ? 2 : 0)}px solid black;
`

const SliderContainer = styled.div`
  padding-top: 20px;
  outline: none !important;

  * > {
    outline: none !important;
  }

  ${props => selectors.getDebugOverlayCss(props, "SliderContainer", "rgba(255,0,255,0.35)")}
`

export default CHomePartners
