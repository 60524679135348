import React, {Component, Fragment} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import {connect} from "react-redux"
// import {createSelector} from "reselect"
import styled, {ThemeProvider} from "styled-components"
import {withController} from "react-scroll-parallax"

import withScreenProps from "../../../data/hocs/withScreenProps" // {postData, ..}
import * as selectors from "../../../data/selectors"
const god = selectors.getObjectDeep

import CFooter from "../../../view/components/CFooter"
import CImage from "../../../view/components/CImage"
import CEditInWordPress from "../../../view/components/CEditInWordPress"
import CSpacer from "../../../view/components/CSpacer"
import CButton from "../../../view/components/CButton" // eslint-disable-line
import CContent from "../../../view/components/CContent"

import CH2 from "../../../view/components/text/CH2"
import CHomeSubheader from "../../../view/components/text/CHomeSubheader"

const debby = (funcName, data = null) => console.log("SOther." + funcName, data) // eslint-disable-line
const debbyWarn = (funcName, data = null) => console.warn("SOther." + funcName, data) // eslint-disable-line

class SOther extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    sitemapData: PropTypes.array,
    mapOptions: PropTypes.object,
    parallaxController: PropTypes.object.isRequired,

    //  withScreenProps
    dispatch: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    intlLocale: PropTypes.string.isRequired,
    themeKey: PropTypes.string,
    postData: PropTypes.object,
    editInWordPressProps: PropTypes.object,
    pageTemplate: PropTypes.string,
  }
  static defaultProps = {
    debug: false && __DEV__,
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  render = () => {
    const {debug, postData, editInWordPressProps, parallaxController} = this.props
    const d = {debug}
    debby("render()", {props: this.props})
    const parallaxRatio = 0.1
    const heroImageSrcData = selectors.getSrcAndSrcSetByWordpressImage(god(postData, "featuredImage"), {useCropped: !!parallaxRatio})
    const subheader = god(postData, "sectionTop.subheader", "")
    return (
      <ThemeProvider theme={{}}>
        <Container {...d}>
          <CImage
            //
            src={heroImageSrcData}
            srcSet={heroImageSrcData.srcSet}
            overlayColor={`rgba(0,0,0,0.25)`}
            parallaxRatio={parallaxRatio}
            parallaxController={parallaxController}
            heightGrows>
            <PaddedContainer {...d} variant={`content`}>
              <HeaderContainer>
                <CH2 {...d} text={god(postData, "sectionTop.header", "")} />
                {!!subheader && (
                  <Fragment>
                    <CSpacer {...d} variant={`herosections`} />
                    <CHomeSubheader {...d} text={subheader} variant={`home-demo`} />
                  </Fragment>
                )}
              </HeaderContainer>
            </PaddedContainer>
          </CImage>

          <ContentContainer {...d}>
            <CContent html={god(postData, "contentFilteredNew")} />
          </ContentContainer>

          <CFooter />

          <CEditInWordPress fixed {...editInWordPressProps} />
        </Container>
      </ThemeProvider>
    )
  }
}

const Container = styled.div`
  position: relative;
  overflow-x: hidden; // because of huge images
  background-color: ${props => props.theme.backgroundColor};
  transition: all ${props => props.theme.vars.transitions.themeTransitionDuration}s linear;
`

const PaddedContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  transition: all ${props => props.theme.vars.transitions.themeTransitionDuration}s linear;
  ${props => selectors.getPaddingLeftRightByThemeAndVariant(props.theme, props.variant)}
  ${props => false && props.debug && selectors.getDebugOverlayCss(props, `PaddedContainer`, `rgba(0,0,255,0.15)`)}
  color: white;
  text-align: ${props => (props.align == "center" ? "center" : "left")};
`

const HeaderContainer = styled.div`
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  ${props => props.debug && selectors.getDebugOverlayCss(props, `HeaderContainer`, `rgba(255,0,0,0.15)`)}
`

const ContentContainer = styled.div`
  padding-top: 80px;
  padding-bottom: 50px;
  ${props => selectors.getPaddingLeftRightByThemeAndVariant(props.theme, "content")}
  ${props => props.debug && selectors.getDebugOverlayCss(props, `ContentContainer`, `rgba(0,0,255,0.15)`)}
`

////////////////////////////////////////////////////////////////////////

const mapStateToProps = state => ({
  sitemapData: selectors.getSitemapData(state),
})
export default withController(withScreenProps(connect(mapStateToProps)(SOther)))
