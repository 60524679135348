import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import styled, {css} from "styled-components" // eslint-disable-line

import {DemoPhoneWithCode} from "../../../images"
import * as selectors from "../../../data/selectors"
import BugReportManager from "../../../data/utils/BugReportManager"
// const god = selectors.getObjectDeep

import CImage from "../../../view/components/CImage"
import CButton from "../../../view/components/CButton"
import CLink from "../../../view/components/CLink"
import CCollapse from "../../../view/components/CCollapse"

import CH1 from "../../../view/components/text/CH1"

class CQRCodeContainer extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    header: PropTypes.string,
    qrHeader: PropTypes.string,
    qrSteps: PropTypes.array,
    qrCodeImageUrl: PropTypes.string,
    mobileHeader: PropTypes.string,
    mobileOr: PropTypes.string,
    mobileTodo: PropTypes.string,
    qrCodeBranchUrl: PropTypes.string,
    mobileButtonLabel: PropTypes.string,
    iosOrAndroid: PropTypes.bool, // QR code is clickable and opens its link
    copiedToClipboardLabel: PropTypes.string,
  }
  static defaultProps = {
    debug: false && __DEV__,
  }

  constructor(props) {
    super(props)
    this.state = {
      copiedToClipboard: false,
    }
  }
  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  onQrCodeClick = () => {}

  componentWillUnmount = () => {
    clearTimeout(this.hideCopiedToClipboardTimeoutId)
  }

  onQrLinkFocus = event => {
    const {qrCodeBranchUrl} = this.props
    !!event && !!event.target && event.target.select()
    try {
      document.execCommand("copy")
      this.setState({copiedToClipboard: true})
      this.hideCopiedToClipboardTimeoutId = setTimeout(() => {
        this.setState({copiedToClipboard: false})
      }, 3000)
    } catch (error) {
      BugReportManager.sendMessage(`Copy to Clipboard failed for URL '${qrCodeBranchUrl}'.`)
    }
  }

  render = () => {
    const {copiedToClipboard} = this.state
    const {debug, header, qrHeader, qrSteps, qrCodeImageUrl, mobileHeader, mobileOr, mobileTodo, qrCodeBranchUrl, mobileButtonLabel, iosOrAndroid, copiedToClipboardLabel} = this.props
    const d = {debug: debug || CQRCodeContainer.defaultProps.debug}
    const clickable = iosOrAndroid
    return (
      <QrCodeContainer {...d}>
        <PaddedContainer {...d} variant={`header`}>
          {!!header && (
            <HeaderContainer>
              <CH1 text={header} />
            </HeaderContainer>
          )}

          <QrDesktopContainer {...d}>
            <QrDesktopContent>
              <Instructions {...d}>
                <QrDesktopHeader>
                  <QrDesktopIconHeader>
                    <img src={DemoPhoneWithCode} />
                  </QrDesktopIconHeader>
                  <QrDesktopTextHeader {...d}>{iosOrAndroid ? mobileHeader : qrHeader}</QrDesktopTextHeader>
                </QrDesktopHeader>
                {!iosOrAndroid && (
                  <QrDesktopSteps {...d}>
                    <ol>{!!qrSteps && qrSteps.map((step, i) => <li key={`step_${i}`}>{step}</li>)}</ol>
                  </QrDesktopSteps>
                )}
              </Instructions>
              <CLink url={clickable ? qrCodeBranchUrl : null}>
                <QrDesktopQrCode {...d} clickable={clickable}>
                  <CImage src={qrCodeImageUrl} ratio={1} />
                </QrDesktopQrCode>
                {!iosOrAndroid && (
                  <div style={{marginTop: 25, backgroundColor: debug ? "yellow" : "none"}}>
                    <Input
                      // values
                      {...d}
                      value={qrCodeBranchUrl}
                      multiline
                      name={"gallery_url"}
                      id={"gallery_url"}
                      type={"text"}
                      onFocus={this.onQrLinkFocus}
                      readOnly={true}
                    />
                    <CCollapse collapsed={!copiedToClipboard}>
                      <CopiedToClipboardText>{copiedToClipboardLabel}</CopiedToClipboardText>
                    </CCollapse>
                  </div>
                )}
              </CLink>
            </QrDesktopContent>
          </QrDesktopContainer>

          <QrMobileContainer {...d}>
            {!iosOrAndroid && <QrMobileOr {...d}>{mobileOr}</QrMobileOr>}
            {!iosOrAndroid && <QrMobileTodo {...d}>{mobileTodo}</QrMobileTodo>}
            <QrMobileButton {...d}>
              <CButton
                //
                urlTo={qrCodeBranchUrl}
                label={mobileButtonLabel}
              />
            </QrMobileButton>
          </QrMobileContainer>
        </PaddedContainer>
      </QrCodeContainer>
    )
  }
}

const Input = styled.input`
  width: 100%;
  box-sizing: border-box;
  padding-left: ${props => props.theme.paddingHorizonal}px;
  padding-right: ${props => props.theme.paddingHorizonal}px;

  background-color: rgba(0, 0, 0, 0);
  border: none;
  outline: none;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;

  font-family: ${props => props.theme.vars.fonts.families.default};
  font-weight: ${props => props.theme.vars.fonts.weights.medium};
  font-size: 11px;
  line-height: 1.3;
  // letter-spacing: 0.02em;
  // font-kerning: none;
  color: ${props => props.theme.vars.colors.codGray};

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px ${props => props.theme.backgroundColor} inset !important;
    -webkit-text-fill-color: ${props => props.theme.textColor};
  }

  ${props => props.focused && css``}

  ${props =>
    props.debug &&
    css`
      border: 1px solid green;
    `}
`

const CopiedToClipboardText = styled.div`
  padding-bottom: 10px;
  text-align: center;
  font-family: ${props => props.theme.vars.fonts.families.regular};
  font-weight: ${props => props.theme.vars.fonts.weights.bold};
  font-size: 12px;
  line-height: 1.3;
  // letter-spacing: 0.02em;
  font-kerning: none;
  color: black;
`

const PaddedContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  ${props => selectors.getPaddingLeftRightByThemeAndVariant(props.theme, props.variant)}
  ${props => true && props.debug && selectors.getDebugOverlayCss(props, `PaddedContainer`, `rgba(0,255,255,0.35)`)}
`

const QrCodeContainer = styled.div`
  color: ${props => props.theme.vars.colors.trout};
  ${props => selectors.getDebugOverlayCss(props, "QrCodeContainer", "rgba(0,255,0,0.15)")}
`

const HeaderContainer = styled.div`
  text-align: center;
  padding: 100px 0px 50px 0px;
  color: ${props => props.theme.vars.colors.codGray};
`

const QrDesktopContainer = styled.div`
  display: flex;
  width: 100%;
  ${props => selectors.getDebugOverlayCss(props, "QrDesktopContainer", "rgba(255,255,0,0.15)")}
`

const QrDesktopContent = styled.div`
  background-color: white;
  margin: 0px -40px;
  padding: 40px;
  flex: 1;
  display: flex;
  ${props => css`
    ${props.theme.media.smdown} {
      flex-direction: column;
    }
  `}
`

const Instructions = styled.div`
  flex: 1;
  padding-right: 100px;
  ${props => selectors.getDebugOverlayCss(props, "Instructions", "rgba(0,0,255,0.15)")}
  ${props => css`
    ${props.theme.media.smdown} {
      padding-right: 0;
    }
  `}
`

const QrDesktopHeader = styled.div`
  display: flex;
  ${props => selectors.getDebugOverlayCss(props, "QrDesktopHeader", "rgba(0,0,255,0.15)")}
`

const QrDesktopIconHeader = styled.div`
  ${props => selectors.getDebugOverlayCss(props, "QrDesktopIconHeader", "rgba(0,0,255,0.15)")}
  img {
    width: 50px;
    margin-right: 20px;
  }
`

const QrDesktopTextHeader = styled.div`
  flex: 1;
  align-self: stretch;
  line-height: 1.3;
  letter-spacing: 0.09em;
  font-kerning: none;
  font-weight: ${props => props.theme.vars.fonts.weights.medium};
  display: flex;
  align-items: center; // verticacl
  ${props => selectors.getDebugOverlayCss(props, "QrDesktopTextHeader", "rgba(255,0,255,0.15)")}
  font-size: 27px;
  ${props => css`
    ${props.theme.media.smdown} {
      font-size: 23px;
    }
  `}
`

const QrDesktopSteps = styled.div`
  padding-top: 40px;
  ${props => selectors.getDebugOverlayCss(props, "QrDesktopSteps", "rgba(0,0,255,0.15)")}

  ol {
    list-style: none;
    // list-style-position: outside;
    counter-reset: item;
    margin: 0;
    padding: 0;
    display: table;
    border-spacing: 10px;
    margin: 0px -10px;
    border-collapse: separate;

    li {
      counter-increment: item;
      font-size: 20px;
      line-height: 1.4;
      letter-spacing: 0.05em;
      font-kerning: none;
      display: table-row;
      // margin-left: -10px;
    }

    li:before {
      display: table-cell;
      // background-color: red;
      content: counter(item) ".";
      width: 15px;
    }
  }
`

const QrDesktopQrCode = styled.div`
  width: 300px;
  margin: -25px;
  cursor: ${props => (props.clickable ? "pointer" : "default")};
  ${props => selectors.getDebugOverlayCss(props, "QrDesktopQrCode", "rgba(0,0,255,0.15)")}
  ${props => css`
    ${props.theme.media.smdown} {
      margin: 0 auto;
      padding-top: 30px;
    }
  `}
`

const QrMobileContainer = styled.div`
  ${props => selectors.getDebugOverlayCss(props, "QrMobileContainer", "rgba(255,255,0,0.15)")}
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 40px;
`

const QrMobileOr = styled.div`
  font-size: 15px;
  letter-spacing: 0.05em;
  font-kerning: none;
`

const QrMobileTodo = styled.div`
  font-size: 15px;
  letter-spacing: 0.05em;
  font-kerning: none;
  padding: 20px 0px;
`
const QrMobileButton = styled.div`
  padding: 10px 0px;
`

export default CQRCodeContainer
