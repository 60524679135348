import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import styled, {css} from "styled-components" // eslint-disable-line
import {motion, useViewportScroll, useTransform, AnimatePresence} from "framer-motion" // eslint-disable-line

import * as selectors from "../../data/selectors"

import CIcon from "../../view/components/CIcon"

class CCaret extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    expanded: PropTypes.bool,
    disabled: PropTypes.bool, // unused
  }
  static defaultProps = {
    debug: false && __DEV__,
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  render = () => {
    const {debug, expanded, disabled} = this.props
    const d = {debug: debug || CCaret.defaultProps.debug}
    return (
      <Caret
        //  props
        {...d}
        disabled={disabled}
        variants={{normal: {rotate: 0, y: -2}, active: {rotate: 180, y: 2}}}
        animate={expanded ? "active" : "normal"}>
        <CIcon id={`HeaderMenuCaretDown`} />
      </Caret>
    )
  }
}

const Caret = styled(motion.div)`
  width: 12px; // size for CIcon
  margin-right: 10px;
  ${props => selectors.getBackgroundColorByProps(props, "rgba(255,255,0,0.75)")}
  ${props =>
    props.disabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}
`

export default CCaret
