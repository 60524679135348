import AnalyticsManager from "../utils/AnalyticsManager"
import BugReportManager from "../utils/BugReportManager"
import FacebookPixelManager from "../utils/FacebookPixelManager"

export const sendAnalyticsPageView = path => () => {
  AnalyticsManager.sendPageView(path)
  BugReportManager.captureBreadcrumb("routing", "sendAnalyticsPageView()", {path})
  FacebookPixelManager.pageView()
}

export const sendAnalyticsEvent = (event, value) => () => {
  AnalyticsManager.sendEvent(event, value)
  BugReportManager.captureBreadcrumb("routing", "sendAnalyticsEvent()", {event, value})
}
