import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import {injectIntl} from "react-intl"
import {connect} from "react-redux"
import styled, {css} from "styled-components" // eslint-disable-line
import {push} from "connected-react-router"

import * as selectors from "../../../data/selectors"

import CButton from "../../../view/components/CButton"

import CSmallprint from "../../../view/components/text/CSmallprint"
import CH3 from "../../../view/components/text/CH3"

import CPackageDetailCard from "./CPackageDetailCard"

class CPortfolioDetailsPackages extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    packages: PropTypes.array,
    smallprint: PropTypes.string,
    intlLocale: PropTypes.string.isRequired,
    customPricingInfo: PropTypes.shape({
      labelHeader: PropTypes.string,
      labelSubheader: PropTypes.string,
      buttonLabel: PropTypes.string,
    }),
    onLinkPress: PropTypes.func,
    openFaqsInNewWindow: PropTypes.bool,

    currenciesAvailable: PropTypes.array.isRequired,
    currencySelected: PropTypes.string,
    onCurrencySelectedChanged: PropTypes.func,
    showEditButton: PropTypes.bool,
  }
  static defaultProps = {
    debug: false && __DEV__,
    openFaqsInNewWindow: false,
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  onFaqsLinkPress = hrefProps => {
    const {openFaqsInNewWindow, onLinkPress} = this.props
    !!onLinkPress && onLinkPress(hrefProps)
    if (!!hrefProps.linkTo && !openFaqsInNewWindow) {
      this.props.dispatch(push(hrefProps.linkTo))
    } else {
      window.open(hrefProps.href)
    }
  }

  onElementMeasure = (index, height) => {
    console.log(`height of package #${index}: ${height}px`)
  }

  onCurrencySelectedChanged = code => !!this.props.onCurrencySelectedChanged && this.props.onCurrencySelectedChanged(code)

  render = () => {
    const {debug, intl, packages, smallprint, customPricingInfo, currenciesAvailable, currencySelected, showEditButton, intlLocale} = this.props
    const d = {debug: debug || CPortfolioDetailsPackages.defaultProps.debug}
    return (
      <PaddedContainer {...d} variant={"header"} {...d}>
        {!!packages && packages.length && (
          <PackagesContainer {...d}>
            <CurrencySelectionContainer {...d}>
              <CurrencySelectionItems {...d}>
                {currenciesAvailable.map(currencyCode => {
                  return (
                    <CurrencyButton
                      //
                      key={`currency_button_${currencyCode}`}
                      active={currencyCode == currencySelected}
                      onClick={() => this.onCurrencySelectedChanged(currencyCode)}
                    >
                      {currencyCode}
                    </CurrencyButton>
                  )
                })}
              </CurrencySelectionItems>
            </CurrencySelectionContainer>

            {packages.map((galleryPackage, i) => {
              const {id, type, features, description, priceMonth, priceMonthFloat, hasFixedSetupCosts, fixedSetupCosts, prices} = galleryPackage
              const title = intl.formatMessage({id: getIdForGalleryPackageType(type)})
              return (
                <Package {...d} key={`package_${galleryPackage.type}`}>
                  <PackagePadded {...d}>
                    <CPackageDetailCard
                      //
                      // {...d}
                      id={id}
                      intl={intl}
                      index={i}
                      title={title}
                      titlePostfix={intl.formatMessage({id: `GalleryPackageTitlePostfix`})}
                      titlePostfixIsActuallyPrefix={intlLocale == "es" || intlLocale == "fr"}
                      description={description} // optional
                      priceMonth={priceMonth} // Euro
                      priceMonthFloat={priceMonthFloat} // Euro
                      priceInterval={"monthly"}
                      billingPeriod={"annually"}
                      hasFixedSetupCosts={hasFixedSetupCosts}
                      fixedSetupCosts={fixedSetupCosts} // Euro
                      features={features}
                      onElementMeasure={this.onElementMeasure}
                      currency={currencySelected}
                      prices={prices}
                      showEditButton={showEditButton}
                    />
                  </PackagePadded>
                </Package>
              )
            })}
            {!!smallprint && (
              <Smallprint {...d}>
                <CSmallprint
                  //
                  {...d}
                  text={"* " + smallprint}
                  variant={"gallery-packages"}
                  onLinkPress={this.onFaqsLinkPress}
                />
              </Smallprint>
            )}
          </PackagesContainer>
        )}
        {!!customPricingInfo && (
          <CustomPricingInfoContainer {...d}>
            <Header {...d}>
              <CH3 {...d} text={customPricingInfo.labelHeader} />
            </Header>
            <Subheader {...d} dangerouslySetInnerHTML={{__html: customPricingInfo.labelSubheader}} />
            <CButton linkTo={`/contact`} {...d} label={customPricingInfo.buttonLabel} />
          </CustomPricingInfoContainer>
        )}
      </PaddedContainer>
    )
  }
}

const getIdForGalleryPackageType = packageType => {
  let galleryPackageTitleIntlId = null
  switch (packageType) {
    case "standard":
      galleryPackageTitleIntlId = "GalleryPackageTypeStandardTitle"
      break
    case "medium":
      galleryPackageTitleIntlId = "GalleryPackageTypeMediumTitle"
      break
    case "premium":
      galleryPackageTitleIntlId = "GalleryPackageTypePremiumTitle"
      break
    case "individual":
      galleryPackageTitleIntlId = "GalleryPackageTypeIndividualTitle"
      break
    default:
      if (__DEV__) {
        throw new Error(`Can't determine intl id for packageType '${packageType}'.`)
      }
      galleryPackageTitleIntlId = "GalleryPackageTypeStandardTitle"
      break
  }
  return galleryPackageTitleIntlId
}

const gutterWidth = 30

const PaddedContainer = styled.div`
  position: relative;
  ${props => selectors.getPaddingLeftRightByThemeAndVariant(props.theme, props.variant)}
  ${props => selectors.getDebugOverlayCss(props, "PaddedContainer", "rgba(0,0,255,0.35)")}
  background-color: ${props => props.theme.vars.colors.gallery};
  padding-top: 60px;
  padding-bottom: 30px;
`

const PackagesContainer = styled.div`
  text-align: center;
  margin: 0 auto;
  max-width: 1000px;
  ${props => selectors.getDebugOverlayCss(props, "PackagesContainer", "rgba(0,255,255,0.35)")}
`

const CurrencySelectionContainer = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  ${props => selectors.getDebugOverlayCss(props, "CurrencySelectionContainer", "rgba(255,0,0,0.35)")}
  ${props => css`
    ${props.theme.media.smdown} {
      justify-content: center;
    }
  `}
`
const CurrencySelectionItems = styled.div`
  margin: 0px 15px;
  display: flex;
  flex-direction: row;
  ${props => selectors.getDebugOverlayCss(props, "CurrencySelectionItems", "rgba(0,255,0,0.35)")}
`

const CurrencyButton = styled.div`
  border: 2px solid ${props => props.theme.vars.colors.codGray};
  padding: 10px 10px;
  font-family: ${props => props.theme.vars.fonts.families.semibold};
  letter-spacing: 0.2em;
  margin-left: 10px;
  cursor: ${props => (props.active ? "default" : "pointer")};
  color: ${props => (props.active ? "white" : props.theme.vars.colors.codGray)};
  background-color: ${props => (props.active ? props.theme.vars.colors.codGray : "none")};
`

const Package = styled.div`
  display: inline-block;
  vertical-align: top;
  ${props => selectors.getDebugOverlayCss(props, "Package", "rgba(255,255,0,0.35)")}
  border: 0;
  width: 50%;
  max-width: 500px;
  min-width: 300px;
  ${props => css`
    ${props.theme.media.smdown} {
      width: 100%;
    }
  `}
`

const PackagePadded = styled.div`
  padding: 0px ${gutterWidth / 2}px;
`

const Smallprint = styled.div`
  padding: 20px;
  ${props => selectors.getDebugOverlayCss(props, "Smallprint", "rgba(255,255,0,0.35)")}
`

const CustomPricingInfoContainer = styled.div`
  padding-top: 40px;
  padding-bottom: 80px;
  ${props => selectors.getDebugOverlayCss(props, "Smallprint", "rgba(255,255,0,0.35)")}
`

const Header = styled.div`
  padding-bottom: 20px;
`

const Subheader = styled.div`
  font-size: 18px;
  line-height: 1.7;
  letter-spacing: 0.1em;
  max-width: 800px;

  p {
    padding: 0px;
    margin-bottom: 40px;
    ${props => selectors.getBackgroundColorByProps(props, "rgba(0,0,255,0.15)")}
  }
`

const mapStateToProps = () => ({
  // contactRequestBusy: selectors.getContactRequestBusy(state), // one of all in session
})

export default injectIntl(connect(mapStateToProps)(CPortfolioDetailsPackages))
