import React, {Component} from "react"
import PropTypes from "prop-types"
import styled, {css, ThemeProvider} from "styled-components"
import TextareaAutosize from "react-textarea-autosize"
import isEqual from "lodash/isEqual"

import {CONFIG} from "../../config"
import * as selectors from "../../data/selectors"

import CCollapse from "./CCollapse"
import CCaret from "./CCaret"

import {debbify} from "../../data/selectors/helpers"
const debby = (...args) => debbify("CFloatingInput", ...args)

const getThemeOverrides = (themeKey, variant, size = "regular") => {
  let themeOverrides = {}
  if (themeKey == "dark") {
    themeOverrides = {...themeOverrides, backgroundColor: CONFIG.layout.colors.codGray, textColor: "white", borderBottomColor: "rgba(255,255,255,0.5)"}
  }
  if (variant == "home-whitepaper") {
    themeOverrides = {...themeOverrides, textAlign: "center"}
  }
  if (size == "small") {
    themeOverrides = {
      ...themeOverrides,
      fontSize: 13, // -3
      containerHeight: 30, // -10
      placeholderContainerTop: 15, // -5
      placeholderFloatingBottom: 21, // -5
      paddingHorizonal: 0,
    }
  } else if (size == "umbrella-newsletter") {
    themeOverrides = {
      ...themeOverrides,
      paddingHorizonal: 0,
    }
  }
  return themeOverrides
}

const defaultThemeProps = {
  backgroundColor: "white",
  textColor: "black",
  borderBottomColor: "rgba(0,0,0,0.25)",
  textErrorColor: "red",
  textAlign: "left",

  // themeOverrides
  fontSize: 16,
  floatingFontSize: 11,
  containerHeight: 40,
  inputPaddingBottom: 5, // spacing between input and horizontal line
  inputLetterSpacing: 0.1,
  placeholderFloatingBottom: 26,
  paddingHorizonal: 5,
  placeholderContainerTop: 20,

  optionItemHeight: 40,
}

class CFloatingInputLabelError extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    text: PropTypes.string,
    shown: PropTypes.bool,
    themeKey: PropTypes.string,
    defaultThemeProps: PropTypes.object,
    variant: PropTypes.string,
    size: PropTypes.oneOf(["regular", "small", "umbrella-newsletter"]),
  }
  static defaultProps = {
    debug: false && __DEV__,
    shown: true,
    defaultThemeProps,
    variant: "",
    size: "regular",
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  render = () => {
    const {debug, text, themeKey, variant, shown, size} = this.props
    const d = {debug: debug || CFloatingInputLabelError.defaultProps.debug, variant}
    if (!text) {
      return null
    }
    debby("CFloatingInputLabelError.render()", {variant})
    return (
      <ThemeProvider theme={{...defaultThemeProps, ...getThemeOverrides(themeKey, variant, size)}}>
        <CCollapse collapsed={!shown}>
          <ErrorContainer {...d}>
            <ErrorLabel {...d} size={size}>
              {text}
            </ErrorLabel>
          </ErrorContainer>
        </CCollapse>
      </ThemeProvider>
    )
  }
}

class CFloatingInput extends Component {
  static LabelError = CFloatingInputLabelError

  static propTypes = {
    debug: PropTypes.bool,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    defaultThemeProps: PropTypes.object,
    onFocus: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    onSubmit: PropTypes.func,
    variant: PropTypes.oneOf(["", "home-whitepaper"]),
    errorText: PropTypes.string,
    showError: PropTypes.bool,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    validateInput: PropTypes.func,
    themeKey: PropTypes.string,
    multiline: PropTypes.bool,
    minRows: PropTypes.number,
    maxRows: PropTypes.number,

    autoCapitalize: PropTypes.oneOf(["none", "sentences", "words", "characters"]),
    readOnly: PropTypes.bool,
    type: PropTypes.oneOf(["text", "password", "url", "search", "email", "tel", "number"]),
    autoComplete: PropTypes.bool,

    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
    optionsPosition: PropTypes.oneOf(["relative", "absolute"]),
    optionsNothingFoundText: PropTypes.string,
    alwaysShowAllOptions: PropTypes.bool,
    size: PropTypes.oneOf(["regular", "small", "umbrella-newsletter"]),
  }

  static defaultProps = {
    debug: false && __DEV__,
    name: "default-cfloatinginput",
    type: "text",
    variant: "",
    optionsNothingFoundText: "-",
    optionsPosition: "absolute",
    minRows: 1,
    maxRows: 10,
    size: "regular",
    defaultThemeProps,
  }

  constructor(props) {
    super(props)
    this.state = {
      floating: false,
      focused: false,
      optionsShown: false,
      valueTyping: props.value,
      valueValid: !!props.validateInput && props.required ? props.validateInput(props.value, props.name) : true,
    }
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  UNSAFE_componentWillReceiveProps = nextProps => {
    if (nextProps.value != this.state.valueTyping) {
      this.setState({valueTyping: nextProps.value})
    }
  }

  onChange = evt => {
    const {disabled, options, validateInput, required, name, onChange} = this.props
    const value = evt.target.value
    if (disabled) {
      return
    }

    const valueValid = !!validateInput && required ? validateInput(value, name) : true

    if (!!options && !!options.length) {
      if (this.state.focused) {
        this.setState({
          valueTyping: value,
          valueValid,
        })
        return
      }
    } else {
      this.setState({
        valueValid,
        valueTyping: value,
      })
    }

    !!onChange && onChange(value, this.props.name)
  }

  onFocus = evt => {
    const {disabled, onFocus, options} = this.props
    debby("onFocus()")
    if (disabled) {
      this.setState({focused: false})
      return
    }
    const value = evt.target.value
    this.setState({focused: true, valueTyping: value, optionsShown: !!options && options.length})
    !!onFocus && onFocus(evt)

    if (!!options && !!options.length) {
      setTimeout(() => {
        !!this.inputNode && this.inputNode.setSelectionRange(0, this.inputNode.value.length)
      }, 200)
    }
  }

  onBlur = evt => {
    debby("onBlur()")
    const {onBlur} = this.props

    clearTimeout(this.blurTimeoutId)
    this.blurTimeoutId = setTimeout(() => {
      !!onBlur && onBlur(evt)
      this.setState({focused: false, optionsShown: false})
    }, 300)
  }

  onTextKeyPress = evt => {
    debby("onTextKeyPress()")
    if (!this.props.multiline && evt.key === "Enter") {
      this.onSubmitClick(evt.target.value)
    }
  }

  focusInput = () => !!this.inputNode && this.inputNode.focus()

  // eslint-disable-next-line
  onOptionClick = (value, label) => {
    // this.onBlur()
    debby("onOptionClick()")
    this.setState({optionsShown: false})
    !!this.props.onSubmit && this.props.onSubmit(value, this.props.name)
    !!this.props.onChange && this.props.onChange(value, this.props.name)
    !!this.inputNode && this.inputNode.blur()
  }

  onSubmitClick = valueRaw => {
    debby("onSubmitClick()")
    const {valueTyping} = this.state

    let value = (valueRaw || "").trim()

    //  first, check if this value is valid or not, in case of given options
    const {options} = this.props

    if (!!value) {
      if (!!options && !!options.length) {
        const optionsExact = this.getOptionsFiltered(options, {forceFilter: true, exact: value}) // by value
        const optionsStarting = this.getOptionsFiltered(options, {forceFilter: true, startsWith: valueTyping}) // by label
        const optionsContaining = this.getOptionsFiltered(options, {forceFilter: true, contains: valueTyping}) // by label

        if (!!optionsExact.length) {
          value = optionsExact[0].value
        } else if (!!optionsStarting.length) {
          value = optionsStarting[0].value
        } else if (!!optionsContaining.length) {
          value = optionsContaining[0].value
        } else {
          value = this.props.value // reset to old one
        }
      }
    }

    !!this.inputNode && this.inputNode.blur()
    !!this.props.onSubmit && this.props.onSubmit(value, this.props.name)
    !!this.props.onChange && this.props.onChange(value, this.props.name)
  }

  getOptionsFiltered = (optionsAll, filters) => {
    const {alwaysShowAllOptions} = this.props
    const forceFilter = !!filters && filters.hasOwnProperty("forceFilter") ? filters.forceFilter : false
    if (!optionsAll || !optionsAll.length) {
      return []
    }

    if (!filters) {
      return optionsAll
    }

    const filterExact = filters.hasOwnProperty("exact") ? filters.exact : null
    const filterStartsWith = filters.hasOwnProperty("startsWith") ? filters.startsWith : null
    const filterContains = filters.hasOwnProperty("contains") ? filters.contains : null
    const hasFilter = filterExact || filterStartsWith || filterContains

    const optionsWithSearchRank = optionsAll.map(option => {
      let searchRank = hasFilter ? 0 : 1 // no filter set -> return all
      const optionLabelLower = option.label.toLowerCase()
      const optionValue = option.value

      if (!!filterExact) {
        if (optionValue == filters.exact) {
          searchRank += 3
        }
      }
      if (filterStartsWith) {
        if (optionLabelLower.startsWith(filterStartsWith.toLowerCase())) {
          searchRank += 2
        }
      }
      if (filterContains) {
        if (optionLabelLower.indexOf(filterContains.toLowerCase()) !== -1) {
          searchRank += 1
        }
      }
      return {
        ...option,
        searchRank,
      }
    })

    let optionsSortedBySearchRank = [...optionsWithSearchRank]
    // if (!alwaysShowAllOptions && !filterExact) {
    // optionsSortedBySearchRank = optionsWithSearchRank.sort((a, b) => b.searchRank - a.searchRank)
    // }

    let optionsFiltered = [...optionsSortedBySearchRank]
    if (!alwaysShowAllOptions || forceFilter) {
      optionsFiltered = optionsSortedBySearchRank.filter(option => !!option.searchRank)
    }

    const optionsFilteredClean = optionsFiltered.map(option => ({
      label: option.label,
      value: option.value,
    }))

    return optionsFilteredClean
  }

  focus = () => {
    !!this.inputNode && this.inputNode.focus()
  }

  render = () => {
    const {focused, optionsShown, valueTyping, valueValid} = this.state // eslint-disable-line
    const {debug, placeholder, errorText, showError, value, disabled, required, themeKey, defaultThemeProps, variant, options, optionsNothingFoundText, optionsPosition, multiline, minRows, maxRows, size} = this.props
    const {autoCapitalize, autoComplete} = this.props
    const d = {debug: debug || CFloatingInput.defaultProps.debug, variant}

    // return <div />

    let showErrorFinal = showError && !valueValid
    // if (debug && !!value) {
    //   debugger
    // }

    const nameLabel = `${name}`

    let labelByOptions = value
    if (!!options && options.length) {
      const optionByValue = options.find(o => o.value == value)
      if (!!optionByValue) {
        labelByOptions = optionByValue.label
      }

      if (focused) {
        labelByOptions = valueTyping
      }
    }

    if (multiline) {
      labelByOptions = this.state.valueTyping
    }

    const valueShown = labelByOptions || ""

    const floating = (!!valueShown && !!valueShown.length) || focused

    const optionsByValueShown = this.getOptionsFiltered(options, {contains: valueShown})

    const optionsExpanded = optionsShown && !!options && !!options.length
    // const optionsExpanded = !!options && !!options.length

    debby("render()", {variant, size})
    return (
      <ThemeProvider theme={{...defaultThemeProps, ...getThemeOverrides(themeKey, variant, size)}}>
        <Container {...d} multiline={JSON.stringify(multiline)}>
          <InputCollapseContainer {...d}>
            <InputContainer {...d} lookDisabled={JSON.stringify(!!disabled)} multiline={JSON.stringify(multiline)}>
              <Label
                {...d}
                // disabled={true}
                disabled={disabled}
                htmlFor={nameLabel}>
                <PlaceholderContainer {...d} floating={floating}>
                  <Placeholder {...d} floating={floating}>
                    {placeholder + (required ? "*" : "")}
                  </Placeholder>
                </PlaceholderContainer>
                {!multiline && (
                  <Input
                    // values
                    {...d}
                    ref={obj => (this.inputNode = obj)}
                    value={valueShown}
                    name={nameLabel}
                    // id={this.props.name}
                    id={this.props.name}
                    type={this.props.type}
                    // onKeyPress={this.onTextKeyPress}
                    onFocus={this.onFocus}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    placeholder={" "}
                    // required={this.props.required}
                    autocapitalize={autoCapitalize}
                    disabled={disabled}
                    readOnly={disabled}
                    // autoComplete={autoComplete ? "on" : "off"}
                    autoComplete={autoComplete ? "on" : `iazzu-${this.props.name}`} // https://stackoverflow.com/questions/30053167/autocomplete-off-vs-false
                    // autoComplete={autoComplete ? "on" : `new-password`} // https://stackoverflow.com/questions/30053167/autocomplete-off-vs-false
                  />
                )}
                {multiline && (
                  <TextArea
                    //  values
                    value={valueShown}
                    onFocus={this.onFocus}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    minRows={minRows}
                    maxRows={maxRows}
                  />
                )}
              </Label>
              {!!options && options.length && (
                <CaretContainer>
                  <CCaret expanded={optionsExpanded} />
                </CaretContainer>
              )}
            </InputContainer>
          </InputCollapseContainer>

          <CFloatingInputLabelError
            //
            shown={showErrorFinal}
            {...d}
            text={errorText}
            themeKey={themeKey}
            variant={variant}
            size={size}
          />

          {!!options && !!options.length && (
            <OptionsCollapseContainer {...d}>
              <OptionsCollapseContent position={optionsPosition}>
                <CCollapse collapsed={!optionsExpanded}>
                  <OptionsContainer {...d} amountItems={optionsByValueShown.length + ""}>
                    {optionsByValueShown.map((option, i) => {
                      return (
                        <OptionContainer
                          key={`options_${option.value}`}
                          index={i}
                          disabled={JSON.stringify(disabled)}
                          // first={JSON.stringify(i == 0)}
                          active={focused && option.label == valueShown}
                          onClick={() => {
                            this.onOptionClick(option.value, option.label)
                          }}>
                          <OptionLabel>{option.label}</OptionLabel>
                        </OptionContainer>
                      )
                    })}
                    {!optionsByValueShown.length && (
                      <OptionContainer first={true} disabled={JSON.stringify(disabled)}>
                        <OptionLabel>{optionsNothingFoundText}</OptionLabel>
                      </OptionContainer>
                    )}
                  </OptionsContainer>
                </CCollapse>
              </OptionsCollapseContent>
            </OptionsCollapseContainer>
          )}
        </Container>
      </ThemeProvider>
    )
  }
}

const InputCollapseContainer = styled.div`
  position: relative;
  ${props => selectors.getDebugOverlayCss(props, ".", "rgba(125,0,0,0.15)")}
`

const FontProps = props => `
  font-family: ${props.theme.vars.fonts.families.default};
  font-size: ${props.theme.fontSize}px;
  color: ${props.theme.textColor};
  text-align: ${props.theme.textAlign};
  letter-spacing: ${props.theme.inputLetterSpacing}px;
  border: 0;
  // line-height: ${Math.round(props.theme.fontSize * 1.3)}px;
`

const Container = styled.div`
  background-color: ${props => props.theme.backgroundColor};
  color: ${props => props.theme.textColor};
  transition: all ${props => props.theme.vars.transitions.themeTransitionDuration}s linear;
  position: relative; // for collapse-container
  ${props => false && selectors.getDebugOverlayCss(props, "CFloatingInput.Container", "rgba(125,0,0,0.15)")}
  ${props => selectors.getBackgroundColorByProps(props, "rgba(125,0,0,0.15)")}
`

const InputContainer = styled.div`
  position: relative;
  display: table;
  width: 100%;
  height: ${props => props.theme.containerHeight}px;
  padding-bottom: ${props => props.theme.inputPaddingBottom}px;
  border-bottom: 1px solid ${props => props.theme.borderBottomColor};
  opacity: ${props => (props.lookDisabled == "true" ? 0.8 : 1)};
`

const Label = styled.label`
  display: table-cell;
  vertical-align: bottom;
  position: relative;
`

const PlaceholderContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  // top: 20px;
  top: ${props => props.theme.placeholderContainerTop}px;
  padding-left: ${props => props.theme.paddingHorizonal}px;
  padding-right: ${props => props.theme.paddingHorizonal}px;
  pointer-events: none;

  display: flex;
  justify-content: ${props => (props.theme.textAlign == "center" ? "center" : "flex-start")};

  ${props => selectors.getBackgroundColorByProps(props, "rgba(255,0,0,0.5)")}
`

const Placeholder = styled.span`
  // position: absolute;
  opacity: 0.5;
  font-weight: 400;
  z-index: 1;
  pointer-events: none;

  transition: all 0.2s ease;

  ${FontProps}
  bottom: 1px;

  transform: none;
  transform-origin: center ${props => props.theme.textAlign};
  ${props =>
    props.floating &&
    css`
      transform: translate3d(0px, ${-props.theme.placeholderFloatingBottom}px, 0px) scale(${props.theme.floatingFontSize / props.theme.fontSize}) translate3d(0px, 5px, 0px);
    `}

  ${props => selectors.getBackgroundColorByProps(props, "rgba(0,0,255,0.15)")}
`

const Input = styled.input`
  width: 100%;
  box-sizing: border-box;
  padding-left: ${props => props.theme.paddingHorizonal}px;
  padding-right: ${props => props.theme.paddingHorizonal}px;

  ${FontProps}

  background-color: rgba(0, 0, 0, 0);
  border: none;
  outline: none;
  padding-top: 0px;
  padding-bottom: 0px;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px ${props => props.theme.backgroundColor} inset !important;
    -webkit-text-fill-color: ${props => props.theme.textColor};
  }

  ${props => props.focused && css``}

  border: ${props => (props.debug ? 1 : 0)}px solid green;
`

const TextArea = styled(TextareaAutosize)`
  font-family: ${props => props.theme.vars.fonts.families.default};
  font-size: 20px;
  width: 100%;
  outline: none;
  position: relative;
  margin-top: 19px;
  padding-left: ${props => props.theme.paddingHorizonal}px;
  padding-right: ${props => props.theme.paddingHorizonal}px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0);
  resize: none;
  margin-bottom: 0;
  padding-bottom: 0;
  margin-bottom: -5px;

  ${FontProps}
`

const CaretContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: ${props => props.theme.inputPaddingBottom}px;
  pointer-events: none;
  width: 40px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`

const OptionsCollapseContainer = styled.div`
  // pointer-events: none;
  ${props => selectors.getDebugOverlayCss(props, "OptionsCollapseContainer", "rgba(125,0,0,0.15)")}// position: absolute;
`

const OptionsCollapseContent = styled.div`
  position: relative;
  background-color: white;
  ${props =>
    props.position == "absolute" &&
    css`
      position: absolute;
      z-index: 200;
      right: 0;
      top: 0;
      left: 0;
    `};
`

const OptionsContainer = styled.div`
  cursor: default;
  height: ${props => Math.min(200, Math.min(props.amountItems + 0.5, 4) * (props.theme.optionItemHeight + 1))}px; // +1px for border
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  border-left: 1px solid ${props => props.theme.borderBottomColor};
  border-right: 1px solid ${props => props.theme.borderBottomColor};
  border-bottom: 1px solid ${props => props.theme.borderBottomColor};
  ${props => selectors.getDebugOverlayCss(props, "OptionsContainer", "rgba(125,0,0,0.15)")}// position: absolute;
`

const OptionContainer = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding-left: ${props => props.theme.paddingHorizonal}px;
  padding-right: ${props => props.theme.paddingHorizonal}px;
  text-align: left;
  height: ${props => props.theme.optionItemHeight}px;
  display: flex;
  align-items: center;
  cursor: pointer;

  opacity: 0.5;
  ${props =>
    props.active &&
    css`
      background-color: rgba(0, 0, 0, 0.05);
      opacity: 1;
    `}

  ${props =>
    props.disabled == "false" &&
    css`
      :hover {
        background-color: rgba(0, 0, 0, 0.15);
        opacity: 0.75;
      }
    `}
`

const OptionLabel = styled.div`
  font-size: 20px;
  ${FontProps}
`

const ErrorContainer = styled.div`
  padding-top: 2px;
  padding-bottom: 0px;
  margin: 0 auto;
  ${props => selectors.getDebugOverlayCss(props, "ErrorContainer", "rgba(125,0,0,0.25)")}
`

const ErrorLabel = styled.div`
  padding: 5px ${props => props.theme.paddingHorizonal}px;

  border: ${props => (props.debug ? 1 : 0)}px solid blue;
  font-size: 14px;
  ${props =>
    props.size == "small" &&
    css`
      font-size: 12px;
      padding: 5px 0px;
    `}

  line-height: 1.2;
  border-radius: 4px;
  font-weight: 500;
  color: ${props => props.theme.textErrorColor};
  text-align: ${props => props.theme.textAlign};
`

export default CFloatingInput
