import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import {injectIntl} from "react-intl"
import {connect} from "react-redux"
import styled, {css, ThemeProvider} from "styled-components" // eslint-disable-line

import {CONFIG} from "../../../config" // eslint-disable-line
import * as selectors from "../../../data/selectors"

import CIcon from "../../../view/components/CIcon"

const DEBUG = true && __DEV__

class CArticlesOnlyEnglish extends Component {
  static propTypes = {
    intl: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    onCloseClick: PropTypes.func.isRequired,
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  onCloseClick = () => !!this.props.onCloseClick && this.props.onCloseClick()

  render = () => {
    const {intl} = this.props
    const d = {debug: DEBUG}
    return (
      <Content {...d}>
        <NoteText {...d}>{intl.formatMessage({id: `KnowledgeBaseNoteOnlyInEnglish`})}</NoteText>
        <CloseContainer {...d} onClick={this.onCloseClick}>
          <CIcon id={`ArticlesOnlyEnglishCloseCross`} />
        </CloseContainer>
      </Content>
    )
  }
}

const Content = styled.div`
  padding: 10px 0px;
  display: flex;
  width: 100%;
  background-color: #eaeaea;
  ${props => selectors.getDebugOverlayCss(props, "Content", "rgba(0,255,0,0.25)")}

  flex-direction: row;
  ${props => css`
    ${props.theme.media.smdown} {
      flex-direction: column-reverse; // "x" first
    }
  `}
`

const NoteText = styled.div`
  flex: 1;
  align-self: stretch;
  display: flex;
  align-items: center;
  ${props => selectors.getDebugOverlayCss(props, "NoteText", "rgba(0,255,255,0.25)")}

  font-family: ${props => props.theme.vars.fonts.families.default};
  font-size: 16px;
  color: #1e1e1e;
  letter-spacing: 1.6px;
  text-align: left;
  line-height: 27px;

  padding: 5px 40px;
  ${props => css`
    ${props.theme.media.smdown} {
      padding: 0px 20px 10px 20px;
    }
  `}
`

const CloseContainer = styled.div`
  cursor: pointer;
  width: 60px;
  min-height: 60px;
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;
  ${props => selectors.getDebugOverlayCss(props, "CloseContainer", "rgba(255,0,0,0.25)")}

  ${props => css`
    ${props.theme.media.smdown} {
      align-self: flex-end;
    }
  `}
`

// eslint-disable-next-line
const mapStateToProps = state => ({
  // contactRequestBusy: selectors.getContactRequestBusy(state), // one of all in session
})
export default injectIntl(connect(mapStateToProps)(CArticlesOnlyEnglish))
