import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import {injectIntl} from "react-intl"
import {connect} from "react-redux"
import styled, {css} from "styled-components" // eslint-disable-line

import * as selectors from "../../../data/selectors"

import CButton from "../../../view/components/CButton"
import CSpacer from "../../../view/components/CSpacer"
import CContent from "../../../view/components/CContent"

import CH2 from "../../../view/components/text/CH2"
import CH3 from "../../../view/components/text/CH3"
import CHomeSubheader from "../../../view/components/text/CHomeSubheader"

const DEBUG_DEFAULT = false && __DEV__

class CPressKit extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    intl: PropTypes.object.isRequired,
    header: PropTypes.string,
    subheader: PropTypes.string,
    brandMaterialsHeader: PropTypes.string,
    brandMaterialsSubheader: PropTypes.string,
    files: PropTypes.array,
  }
  static defaultProps = {
    debug: DEBUG_DEFAULT,
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  render = () => {
    const {debug, intl, header, subheader, brandMaterialsHeader, brandMaterialsSubheader, files} = this.props
    const d = {debug: debug || DEBUG_DEFAULT}
    return (
      <div>
        <HeaderContainer {...d}>
          <CH2 text={header} />
          <CSpacer {...d} variant={`presskit-header`} />
          <CHomeSubheader {...d} text={subheader} variant={`home-demo`} />
        </HeaderContainer>

        <ContentContainer {...d}>
          <CH3 text={brandMaterialsHeader} />

          <SubheaderContainer {...d}>
            <CContent html={brandMaterialsSubheader} />
          </SubheaderContainer>

          <ButtonsContainer {...d}>
            {!!files &&
              files.map(file => (
                <ButtonContainer {...d} key={`button_${file.type}`}>
                  <CButton
                    //
                    variant={`press-kit`}
                    label={intl.formatMessage({id: getIdForPressKitFileType(file.type)})}
                    urlTo={file.url}
                    stretched
                    canBeBusy // slightly increases width
                  />
                </ButtonContainer>
              ))}
          </ButtonsContainer>
        </ContentContainer>
      </div>
    )
  }
}

export const getIdForPressKitFileType = type => {
  let typeIntlId = null
  switch (type) {
    case "logos":
      typeIntlId = "PressKitKeyLogos"
      break
    case "photos":
      typeIntlId = "PressKitKeyPhotos"
      break
    case "video":
      typeIntlId = "PressKitKeyVideos"
      break
    default:
      typeIntlId = "FormErrorGeneric"
      break
  }
  return typeIntlId
}

const HeaderContainer = styled.div`
  text-align: center;
  color: white;
  ${props => props.debug && selectors.getDebugOverlayCss(props, `HeaderContainer`, `rgba(0,255,255,0.35)`)}
  background-color: ${props => props.theme.vars.colors.codGray};

  padding: 120px 0px;
  ${props =>
    css`
      ${props.theme.media.smdown} {
        padding: 50px 0px;
      }
    `}
`

const ContentContainer = styled.div`
  padding: 90px 0px;
  ${props =>
    css`
      ${props.theme.media.smdown} {
        padding: 50px 0px;
      }
    `}
  ${props => selectors.getPaddingLeftRightByThemeAndVariant(props.theme, "content")}
  ${props => props.debug && selectors.getDebugOverlayCss(props, `ContentContainer`, `rgba(0,255,255,0.35)`)}
  background-color: white;

`

const SubheaderContainer = styled.div`
  padding: 30px 0px;
  max-width: 700px;
  ${props => props.debug && selectors.getDebugOverlayCss(props, `SubheaderContainer`, `rgba(0,0,255,0.35)`)}
`

const ButtonsContainer = styled.div`
  display: flex;
  ${props => props.debug && selectors.getDebugOverlayCss(props, `ButtonsContainer`, `rgba(255,0,255,0.35)`)}

  ${props =>
    css`
      ${props.theme.media.smdown} {
        flex-direction: column;
        // background-color: red;
        align-items: center;
      }
    `}
`

const ButtonContainer = styled.div`
  margin-right: 20px; // button spacing
  ${props => props.debug && selectors.getDebugOverlayCss(props, `ButtonContainer`, `rgba(255,0,255,0.35)`)}

  ${props =>
    css`
      ${props.theme.media.smdown} {
        // margin-right: 0;
        width: 250px;
        margin: 10px 0px;
      }
    `}
`
//  eslint-disable-next-line
const mapStateToProps = state => ({})
export default injectIntl(connect(mapStateToProps)(CPressKit))
