import {sendAnalyticsPageView, sendAnalyticsEvent} from "./AAnalytics"
import {sendContactRequest, requestPostData, requestWhitepaper, addNewsletterSubscriber, requestSuggestedCurrency, requestPublicWallsForGallery, requestKnowledgeBaseArticles, requestGalleryStats} from "./AAPI"
import {requestBranchLinkingKeyData, createShareLink} from "./ABranch"
import {setStageDimensions, handleUrl, setTabVisible} from "./ADevice"
import {requestSitemap, requestGlobals} from "./ARoutes"
import {setWaypointThemeKey, requestApiData, setIntlLocale, showAlert, showVideoOverlay, closeVideoOverlay, showLightbox, closeLightbox, setReferrerCode, closeReferrerCodePopup, setEmbeddedModeActive, showImageOverlay, closeImageOverlay} from "./ASession"
import {requestWordpressUser} from "./AUser"

export {
  //  Analytics
  sendAnalyticsPageView,
  sendAnalyticsEvent,
  //  API
  sendContactRequest,
  requestPostData,
  requestWhitepaper,
  addNewsletterSubscriber,
  requestSuggestedCurrency,
  requestPublicWallsForGallery,
  requestKnowledgeBaseArticles,
  requestGalleryStats,
  //  Branch
  requestBranchLinkingKeyData,
  createShareLink,
  //  Device
  setStageDimensions,
  handleUrl,
  setTabVisible,
  //  Routes
  requestSitemap,
  requestGlobals,
  //  Session
  setWaypointThemeKey,
  requestApiData,
  setIntlLocale,
  showAlert,
  showVideoOverlay,
  closeVideoOverlay,
  showLightbox,
  closeLightbox,
  setReferrerCode,
  closeReferrerCodePopup,
  setEmbeddedModeActive,
  showImageOverlay,
  closeImageOverlay,
  //  User
  requestWordpressUser,
}
