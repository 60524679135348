import React, {Component} from "react"
import PropTypes from "prop-types"
import {connect} from "react-redux"
import {injectIntl} from "react-intl"
import isEqual from "lodash/isEqual"
import {replace} from "connected-react-router"

import * as selectors from "../../../data/selectors"

class SNotFound extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    dispatch: PropTypes.func,
    intlLocale: PropTypes.string.isRequired,
    location: PropTypes.object.isRequired,
  }
  static defaultProps = {
    debug: true && __DEV__,
  }

  componentDidMount = () => {
    // !__DEV__ &&
    setTimeout(() => {
      this.props.dispatch(replace("/"))
    }, 500)
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  render = () => {
    const {debug, location} = this.props // eslint-disable-line
    return (
      <div>
        SNotFound_web.js, <br />
        <b>location:</b> <pre>{JSON.stringify(location, null, 2)}</pre>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  intlLocale: selectors.getIntlLocale(state),
})
export default injectIntl(connect(mapStateToProps)(SNotFound))
