import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import {connect} from "react-redux"
import {withController} from "react-scroll-parallax"
import styled, {ThemeProvider, css} from "styled-components" // eslint-disable-line

import * as selectors from "../../../data/selectors"
import withScreenProps from "../../../data/hocs/withScreenProps" // {postData, pageTemplate, ..}

import CExhibitorsHeader from "../SExhibitors/CExhibitorsHeader"
import CExhibitorsDownload from "../SExhibitors/CExhibitorsDownload"
import CExhibitorsFeatures from "../SExhibitors/CExhibitorsFeatures"
import CPortfolioTestimonials from "../SPortfolio/CPortfolioTestimonials"
import CHomePartners from "../SHome/CHomePartners"
import CExhibitorsAppDownload from "../SExhibitors/CExhibitorsAppDownload"
import CExhibitorsNewsletter from "../SExhibitors/CExhibitorsNewsletter"

import CEditInWordPress from "../../../view/components/CEditInWordPress"
import CFooter from "../../../view/components/CFooter"

const DEBUG = false && __DEV__
import {debbify} from "../../../data/selectors/helpers"
const debby = (...args) => debbify("SArtLovers", ...args)
const bgd = (c, cregular = "none") => ({backgroundColor: DEBUG ? c : cregular}) // eslint-disable-line

const god = selectors.getObjectDeep

class SArtLovers extends Component {
  static propTypes = {
    parallaxController: PropTypes.object.isRequired,

    stageWidth: PropTypes.number,
    sitemapData: PropTypes.array,
    fixedSectionsItems: PropTypes.array,
    defaultThemeProps: PropTypes.object,

    //  withScreenProps
    dispatch: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    intlLocale: PropTypes.string.isRequired,
    themeKey: PropTypes.string,
    postData: PropTypes.object,
    wordPressUserId: PropTypes.number,
    editInWordPressProps: PropTypes.object,
    pageTemplate: PropTypes.string,
    mediaQueryClass: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
  }
  static defaultProps = {
    defaultThemeProps: {
      backgroundColor: "white",
      textColor: "black",
    },
  }

  constructor(props) {
    super(props)
    this.state = {
      availWidthFeatureMatrix: undefined,
    }
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  render = () => {
    const {defaultThemeProps, mediaQueryClass, intl, intlLocale, sitemapData, postData, fixedSectionsItems, stageWidth, themeKey, parallaxController, wordPressUserId, editInWordPressProps, pageTemplate} = this.props // eslint-disable-line
    const d = {debug: DEBUG}
    const themeOverrides = {}
    debby("render()", {mediaQueryClass, wordPressUserId, postData})
    return (
      <ThemeProvider theme={{...defaultThemeProps, ...themeOverrides}}>
        <Container {...d}>
          {/* Header */}
          {true && (
            <CExhibitorsHeader
              //
              {...d}
              intlLocale={intlLocale}
              stageWidth={stageWidth}
              parallaxController={parallaxController}
              featuredImage={god(postData, "featuredImage")}
              {...god(postData, "sectionTop", {})} // headerText, handImage
              mediaQueryClass={mediaQueryClass}
            />
          )}

          {/* Download */}
          {true && (
            <SectionDownloadContainer {...d}>
              <PaddedContainer {...d} variant={`header`}>
                <CExhibitorsDownload
                  //
                  {...d}
                  {...god(postData, "sectionDownloadTop", {})}
                />
              </PaddedContainer>
            </SectionDownloadContainer>
          )}

          {/* Features - PaddedContainer is in Component itself */}
          {true && (
            <SectionFeaturesContainer {...d}>
              <CExhibitorsFeatures
                //
                {...d}
                videoKey={"artlovers"}
                {...god(postData, "sectionFeatures", {})}
                mediaQueryClass={mediaQueryClass}
              />
              <PaddedContainer {...d} variant={`header`}></PaddedContainer>
            </SectionFeaturesContainer>
          )}

          {/* Testimonials - PaddedContainer is in Component itself */}
          {true && (
            <TestimonialsContainer {...d}>
              <CPortfolioTestimonials
                {...d}
                //
                variant={"umbrella"}
                header={god(postData, "sectionTestimonials.header")}
                testimonials={god(postData, "sectionTestimonials.entries")}
                showEditButton={!!wordPressUserId}
                mediaQueryClass={mediaQueryClass}
              />
            </TestimonialsContainer>
          )}

          {/* Partners - PaddedContainer is in Component itself */}
          {true && (
            <PartnersContainer {...d}>
              <CHomePartners
                //
                {...d}
                variant={"umbrella"}
                header={god(postData, "sectionPartners.header")}
                partners={god(postData, `sectionPartners.partners`)}
                mediaQueryClass={mediaQueryClass}
              />
            </PartnersContainer>
          )}

          {/* App Download - PaddedContainer is in Component itself */}
          {true && (
            <AppDownloadContainer {...d}>
              <CExhibitorsAppDownload
                //
                {...d}
                {...god(postData, "sectionAppDownload", {})}
                intlLocale={intlLocale}
                contactButtonLabel={god(postData, "sectionDownloadTop.contactLabel")}
              />
            </AppDownloadContainer>
          )}

          {/* Newsletter */}
          {true && (
            <NewsletterContainer {...d}>
              <PaddedContainer {...d} variant={`header`}>
                <CExhibitorsNewsletter
                  //
                  {...d}
                  {...god(postData, "sectionNewsletter", {})}
                />
              </PaddedContainer>
            </NewsletterContainer>
          )}

          <CFooter variant={"umbrella"} />

          <CEditInWordPress fixed {...editInWordPressProps} />

          {d.debug && false && (
            <div style={{position: "fixed", pointerEvents: "none", fontSize: 9, left: 0, bottom: 0, zIndex: 20000, color: "blue", backgroundColor: "rgba(255,255,255,0.5)", padding: 10}}>
              <div>SArtLovers_web.js</div>
              <div>themeKey: {themeKey}</div>
              <div>
                location: <pre>{JSON.stringify(location, null, 2)}</pre>
              </div>
              <div>wordPressUserId: {wordPressUserId}</div>
              <div>
                editInWordPressProps: <pre>{JSON.stringify(editInWordPressProps, null, 2)}</pre>
              </div>
            </div>
          )}
        </Container>
      </ThemeProvider>
    )
  }
}

const Container = styled.div`
  position: relative;
  overflow-x: hidden; // because of huge images
  background-color: ${props => props.theme.backgroundColor};
  transition: all ${props => props.theme.vars.transitions.themeTransitionDuration}s linear;
`

const PaddedContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  transition: all ${props => props.theme.vars.transitions.themeTransitionDuration}s linear;
  ${props => selectors.getPaddingLeftRightByThemeAndVariant(props.theme, props.variant, "padding")}
  ${props => true && props.debug && selectors.getDebugOverlayCss(props, `PaddedContainer`, `rgba(0,0,255,0.15)`)}
  text-align: ${props => (props.align == "center" ? "center" : "left")};
`

const SectionDownloadContainer = styled.div`
  padding: 30px 0px;
  border: ${props => (props.debug ? 3 : 0)}px solid red;
  ${props => true && props.debug && selectors.getDebugOverlayCss(props, `SectionDownloadContainer`, `rgba(0,0,255,0.35)`)}
`

const SectionFeaturesContainer = styled.div`
  padding: 30px 0px;
  border: ${props => (props.debug ? 3 : 0)}px solid green;
  ${props => true && props.debug && selectors.getDebugOverlayCss(props, `SectionFeaturesContainer`, `rgba(0,255,255,0.35)`)}
`

const TestimonialsContainer = styled.div`
  border: ${props => (props.debug ? 3 : 0)}px solid brown;
  ${props => true && props.debug && selectors.getDebugOverlayCss(props, `TestimonialsContainer`, `rgba(255,0,255,0.15)`)}

  padding: 30px 0px;
  ${props => css`
    ${props.theme.media.smdown} {
      padding: 100px 0px 0px 0px;
    }
  `}
`

const PartnersContainer = styled.div`
  border: ${props => (props.debug ? 3 : 0)}px solid green;
  ${props => true && props.debug && selectors.getDebugOverlayCss(props, `PartnersContainer`, `rgba(255,0,255,0.15)`)}

  padding: 30px 0px;
  ${props => css`
    ${props.theme.media.smdown} {
      padding: 0px;
    }
  `}
`
const AppDownloadContainer = styled.div`
  padding: 30px 0px;
  border: ${props => (props.debug ? 3 : 0)}px solid blue;
  ${props => true && props.debug && selectors.getDebugOverlayCss(props, `AppDownloadContainer`, `rgba(255,0,255,0.15)`)}
`
const NewsletterContainer = styled.div`
  border: ${props => (props.debug ? 3 : 0)}px solid yellow;
  ${props => true && props.debug && selectors.getDebugOverlayCss(props, `NewsletterContainer`, `rgba(255,0,255,0.15)`)}
  padding: 30px 0px 80px 0px;
`

const getIntl = (state, props) => props.intl // eslint-disable-line

const mapStateToProps = state => ({
  stageWidth: state.device.stageDimensions.width,
  sitemapData: selectors.getSitemapData(state),
  // fixedSectionsItems: getFixedSectionsItems(state, props),
})
export default withController(withScreenProps(connect(mapStateToProps)(SArtLovers)))
