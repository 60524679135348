import React from "react"
import Loadable from "react-loadable"

// import SContact from "./SContact_web"
// export default SContact

export default Loadable({
  loader: () => import(/* webpackChunkName: "SContact" */ "./SContact_web"),
  loading: () => <div />,
  // modules: ["LandingChunk"],
})
