import React, {Component} from "react"
import PropTypes from "prop-types"
import {connect} from "react-redux"
import {createSelector} from "reselect"
import {injectIntl} from "react-intl"
import isEqual from "lodash/isEqual"
import styled, {css} from "styled-components" // eslint-disable-line
import {motion, useViewportScroll, useTransform, AnimatePresence} from "framer-motion" // eslint-disable-line
import Lightbox from "react-image-lightbox"

import * as selectors from "../../data/selectors"
import * as actions from "../../data/actions"
import CIcon from "../../view/components/CIcon"

const god = selectors.getObjectDeep
import {debbify} from "../../data/selectors/helpers"
const debby = (...args) => debbify("CLightbox", ...args)

class CLightbox extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    shown: PropTypes.bool,
    header: PropTypes.string,
  }
  static defaultProps = {
    debug: false && __DEV__,
  }

  constructor(props) {
    super(props)
    this.state = {
      imageIndex: 0,
    }
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  onCloseClick = () => this.props.dispatch(actions.closeLightbox())

  render = () => {
    const {imageIndex} = this.state
    const {debug, shown, header, youtubeUrl, imageUrls} = this.props // eslint-disable-line
    const d = {debug: debug || CLightbox.defaultProps.debug}
    if (!shown) {
      return null
    }
    debby("render()", {shown})
    return (
      <PresenceContainer>
        {false && (
          <HeaderContainer {...d}>
            <TitleContainer {...d}>IMAGE TITLE</TitleContainer>
            <CloseContainer {...d} onClick={this.onCloseClick}>
              <CIcon id={`MobileMenuClose`} color={"white"} />
            </CloseContainer>
          </HeaderContainer>
        )}

        <LightboxContainer {...d} id={"lightboxcontainer"}>
          <Lightbox
            mainSrc={imageUrls[imageIndex]}
            nextSrc={imageUrls[(imageIndex + 1) % imageUrls.length]}
            prevSrc={imageUrls[(imageIndex + imageUrls.length - 1) % imageUrls.length]}
            onCloseRequest={this.onCloseClick}
            onMovePrevRequest={() =>
              this.setState({
                imageIndex: (imageIndex + imageUrls.length - 1) % imageUrls.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                imageIndex: (imageIndex + 1) % imageUrls.length,
              })
            }
            // reactModalProps={{appElement: document.querySelector("#lightboxcontainer")}}
            // reactModalStyle={{zIndex: 200000}}
          />
        </LightboxContainer>
      </PresenceContainer>
    )
  }
}

const PresenceContainer = styled(motion.div)`
  position: fixed;
  // z-index: 20001; // in front of CHeaderMenu
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  ${props => selectors.getPaddingLeftRightByThemeAndVariant(props.theme, "header")}// background-color: ${props => props.theme.vars.colors.codGray};
`

const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  ${props => false && props.debug && selectors.getDebugOverlayCss(props, `HeaderContainer`, `rgba(255,0,255,0.35)`)}
`

const TitleContainer = styled.div`
  flex: 1;
  align-self: stretch;
  padding-bottom: 40px;
  color: white;
  letter-spacing: 0.1em;
  font-size: 16px;
  ${props => props.debug && selectors.getDebugOverlayCss(props, `TitleContainer`, `rgba(255,0,255,0.35)`)}
`

const CloseContainer = styled.div`
  width: 50px;
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0.5;
  :hover {
    opacity: 1;
  }
  transition: opacity 0.2s ease-in-out;
  ${props => props.debug && selectors.getDebugOverlayCss(props, `CloseContainer`, `rgba(255,255,0,0.35)`)}
`

const LightboxContainer = styled.div`
  position: relative;
  min-height: 300px;
  width: 100%;
  ${props => false && props.debug && selectors.getDebugOverlayCss(props, `LightboxContainer`, `rgba(255,0,255,0.35)`)}
`

const getStateLightbox = state => state.session.lightbox

const getImageUrls = createSelector([getStateLightbox], stateLightbox => {
  if (!stateLightbox || !stateLightbox.wpImages || !stateLightbox.wpImages.length) {
    return null
  }
  let imageUrls = []
  stateLightbox.wpImages.map(wpImage => {
    const wpImageUrl2000 = god(wpImage, "sizes.2000.url")
    imageUrls.push(wpImageUrl2000)
  })
  return !!imageUrls.length ? imageUrls : null
})

// eslint-disable-next-line
const mapStateToProps = (state, props) => ({
  shown: god(state, "session.lightbox.shown", false),
  imageUrls: getImageUrls(state, props),
})
export default injectIntl(connect(mapStateToProps)(CLightbox))
