import React, {Component} from "react"
import PropTypes from "prop-types"
import {injectIntl} from "react-intl"
import {connect} from "react-redux"
import isEqual from "lodash/isEqual"
import {motion} from "framer-motion"
import styled, {css} from "styled-components"

import * as selectors from "../../../data/selectors"
import * as actions from "../../../data/actions"

import CInitMotion from "../../../view/components/CInitMotion"
import CAppStoreButton from "../../../view/components/CAppStoreButton"
import CSpacer from "../../../view/components/CSpacer"
import CImageFaded from "../../../view/components/CImageFaded"
import CFreeTrialButton from "../../../view/components/CFreeTrialButton"

import CHomeLia2020Info from "./CHomeLia2020Info"

import CH1 from "../../../view/components/text/CH1"
import CHomeSubheader from "../../../view/components/text/CHomeSubheader"

import CHomeHeroVideoButton from "./CHomeHeroVideoButton" // also used in SFAQs_web.js

const DEBUG = false && __DEV__
import {debbify} from "../../../data/selectors/helpers"
const debby = (...args) => debbify("CHomeHeroOverlay", ...args)

class CHomeHeroOverlay extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    dispatch: PropTypes.func,
    intlLocale: PropTypes.string,
    fadedImages: PropTypes.array,
    header: PropTypes.string,
    subheader: PropTypes.string,
    button: PropTypes.string,
    onButtonClick: PropTypes.func.isRequired,
    relative: PropTypes.bool,
    sitemapData: PropTypes.array,
    //
    showLia2020: PropTypes.bool,
  }
  static defaultProps = {
    debug: DEBUG,
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  onFreeTrialButtonClick = () => {
    const {dispatch, intlLocale, sitemapData} = this.props
    const linkTo = selectors.getPathnameLocalized("/contact", intlLocale, sitemapData)
    dispatch(actions.handleUrl(linkTo, {debug: true, postfix: "#freetrial"})) // scroll down to Contact Form
  }

  render = () => {
    const {header, subheader, button, onButtonClick, relative, intlLocale, fadedImages} = this.props
    const d = {debug: DEBUG || this.props.debug}

    debby("render()", {fadedImages})
    return (
      <PaddedContainer relative={relative} {...d} variant={"header"} {...d}>
        <CSpacer {...d} variant={"headermenu"} />
        <TwoPartContent {...d}>
          <ContentText {...d}>
            <ContentTextRespectingImage>
              <CHomeLia2020Info />
              <CInitMotion delay={0}>
                <CH1 {...d} text={header} variant={`home-hero`} />
              </CInitMotion>

              <CSpacer {...d} variant={"herosections"} />

              <CInitMotion delay={100}>
                <CHomeSubheader {...d} text={subheader} variant={`home-hero`} />
              </CInitMotion>

              <CSpacer {...d} variant={"herosections"} />

              <AppStoreButtonsContainer {...d}>
                <AppStoreButton {...d} intlLocale={intlLocale} delay={200} platform={"ios"} marginRight />
                <AppStoreButton {...d} intlLocale={intlLocale} delay={300} platform={"android"} />
              </AppStoreButtonsContainer>

              <CSpacer {...d} variant={"herosections"} />

              <CInitMotion delay={400}>
                <CHomeHeroVideoButton
                  //
                  {...d}
                  label={button}
                  onClick={onButtonClick}
                />
              </CInitMotion>
            </ContentTextRespectingImage>
          </ContentText>

          <ContentImage {...d}>
            <ImageFadedResponsive>
              <CImageFaded
                //
                {...d}
                sectionKey={`homeTop`}
                sourcesWp={fadedImages}
                baseTransform={`translate(1%,-3%) scale(1.48)`} // so if fits its container
                extraTransforms={[`scale(1.1) translate(-3%,5%)`]} // depending on layout
                onFreeTrialButtonClick={this.onFreeTrialButtonClick}
              />
            </ImageFadedResponsive>
          </ContentImage>

          {false && (
            <FreeTrialButtonContainer {...d}>
              <CFreeTrialButton onClick={this.onFreeTrialButtonClick} />
            </FreeTrialButtonContainer>
          )}
        </TwoPartContent>
      </PaddedContainer>
    )
  }
}

//  prettier-ignore
const PaddedContainer = styled.div`
  ${props => props.relative && css`
    position: relative;
    min-height: 100vh;
  `}

  ${props => !props.relative && css`
    position: absolute; left: 0; right: 0; top: 0; bottom: 0;
  `}

  display: flex;
  ${props => css`
    ${props.theme.media.smdown} {
      display: block; // normal rows
    }
  `}

  flex-direction: column; // so it's vertical, for spacer

  ${props => selectors.getPaddingLeftRightByThemeAndVariant(props.theme, props.variant)}
  ${props => selectors.getBackgroundColorByProps(props, "rgba(0,0,255,0.25)")}
`

const TwoPartContent = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  ${props => selectors.getBackgroundColorByProps(props, "rgba(255,0,0,0.25)")}

  flex-direction: row; // left, right
  ${props => css`
    ${props.theme.media.smdown} {
      flex-direction: column; // top, bottom
    }
  `}
`

const ContentText = styled.div`
  min-width: 0;
  flex: 1;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 50px;
  padding-bottom: 50px;

  ${props => selectors.getDebugOverlayCss(props, "ContentText", "rgba(0,255,255,0.35)")}
`

const ContentTextRespectingImage = styled.div`
  padding-right: 15%; // depending on CImageFaded transform props..
  ${props => css`
    ${props.theme.media.smdown} {
      padding-right: 0;
    }
    ${props.theme.media.smdown} {
      max-width: 400px;
      align-self: center;
    }
  `}
`

const AppStoreButtonsContainer = styled.div`
  ${props => selectors.getDebugOverlayCss(props, "AppStoreButtonsContainer", "rgba(0,0,255,0.25)")}

  display: flex;
  flex-direction: row; // 2 cols

  ${props => css`
    ${props.theme.media.smdown} {
      justify-content: center;
    }
  `}
`

const FreeTrialButtonContainer = styled.div`
  display: none;

  ${props => selectors.getBackgroundColorByProps(props, "rgba(0,0,255,0.25)")}

  ${props =>
    css`
      ${props.theme.media.smdown} {
        display: block;
      }
    `}
`

const AppStoreButton = ({delay, platform, debug, marginRight, intlLocale}) => (
  <AppStoreButtonSingle marginRight={marginRight}>
    <CInitMotion delay={delay}>
      <motion.div whileHover={{scale: 1.05}} style={{width: "100%", marginRight: 23}}>
        <CAppStoreButton intlLocale={intlLocale} platform={platform} debug={debug} />
      </motion.div>
    </CInitMotion>
  </AppStoreButtonSingle>
)
AppStoreButton.propTypes = {debug: PropTypes.bool, delay: PropTypes.number, platform: PropTypes.string, marginRight: PropTypes.bool, intlLocale: PropTypes.string.isRequired}

const AppStoreButtonSingle = styled.div`
  width: 40%;
  max-width: 150px;
  ${props =>
    props.marginRight &&
    css`
      padding-right: 20px;
    `}
`

const ContentImage = styled.div`
  min-width: 0;
  pointer-events: none;
  z-index: 2000;

  flex: 1;
  align-self: stretch;

  display: flex;
  align-items: center;
  justify-content: center;
  ${props => selectors.getDebugOverlayCss(props, "ContentImage", "rgba(255,255,0,0.35)")}
`

const ImageFadedResponsive = styled.div`
  width: 100%;
  padding: 40px 0px;
  ${props => css`
    ${props.theme.media.sm} {
      width: 50%;
    }
    ${props.theme.media.xs} {
      width: 70%;
    }
  `}
`

const mapStateToProps = state => ({
  intlLocale: selectors.getIntlLocale(state),
  sitemapData: state.routes.sitemap.data,
})
export default injectIntl(connect(mapStateToProps)(CHomeHeroOverlay))
