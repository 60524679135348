import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import {injectIntl} from "react-intl"
import {connect} from "react-redux"
import styled, {css, ThemeProvider} from "styled-components" // eslint-disable-line
// import {Element as ScrollElement, scroller} from "react-scroll"
// import {push} from "connected-react-router"

import {CONFIG} from "../../../config" // eslint-disable-line
import * as selectors from "../../../data/selectors"

// import CButton from "../../../view/components/CButton"
import CSpacer from "../../../view/components/CSpacer"
// import CText from "../../../view/components/CText"
// import CFloatingInput from "../../../view/components/CFloatingInput"

import CHomeSubheader from "../../../view/components/text/CHomeSubheader"
import CH3 from "../../../view/components/text/CH3"
// import CSmallprint from "../../../view/components/text/CSmallprint"

class CPortfolioDetailsBelowPricing extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    header: PropTypes.string,
    subheader: PropTypes.string,
    themeKey: PropTypes.string,
    defaultThemeProps: PropTypes.object,
  }
  static defaultProps = {
    debug: false && __DEV__,
    defaultThemeProps: {
      // textColor: "black",
      backgroundColor: CONFIG.layout.colors.codGray,
      textColor: "white",
    },
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  getThemeOverrides = () => {
    const {themeKey} = this.props
    let themeOverrides = {}
    if (themeKey === "dark") {
      themeOverrides = {
        // backgroundColor: CONFIG.layout.colors.codGray,
        textColor: "white",
      }
    }
    return themeOverrides
  }

  render = () => {
    const {debug, defaultThemeProps, header, subheader} = this.props
    const d = {debug: debug || CPortfolioDetailsBelowPricing.defaultProps.debug}
    return (
      <ThemeProvider theme={{...defaultThemeProps, ...this.getThemeOverrides()}}>
        <PaddedContainer {...d} variant={"content"} {...d}>
          <Content {...d}>
            <CH3 {...d} text={header} />
            <CSpacer {...d} variant={"herosections"} />
            <CHomeSubheader {...d} text={subheader} variant={`home-whitepaper`} />
          </Content>
        </PaddedContainer>
      </ThemeProvider>
    )
  }
}

const PaddedContainer = styled.div`
  display: flex;
  flex-direction: column; // Desktop
  padding: 60px 0px;
  align-items: center;
  justify-content: center;
  text-align: center;
  ${props => selectors.getPaddingLeftRightByThemeAndVariant(props.theme, props.variant)}

  color: ${props => props.theme.textColor};
  transition: all ${props => props.theme.vars.transitions.themeTransitionDuration}s linear;

  ${props => selectors.getDebugOverlayCss(props, "PaddedContainer", "rgba(125,0,0,0.5)")}
  background-color: ${props => props.theme.backgroundColor};
`

const Content = styled.div`
  padding: 100px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${props => selectors.getDebugOverlayCss(props, "Content", "rgba(0,255,0,0.25)")}
`

const mapStateToProps = state => ({
  contactRequestBusy: selectors.getContactRequestBusy(state), // one of all in session
})
export default injectIntl(connect(mapStateToProps)(CPortfolioDetailsBelowPricing))
