import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import {injectIntl} from "react-intl"
import {connect} from "react-redux"
import styled, {css} from "styled-components" // eslint-disable-line

import * as selectors from "../../../data/selectors"
import {LogoWhite, LogoDarkgray} from "../../../images"

import CButton from "../../../view/components/CButton"
import CContent from "../../../view/components/CContent"

import CH3 from "../../../view/components/text/CH3"

const DEBUG_DEFAULT = true && __DEV__

class CLogoGuidelines extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    intl: PropTypes.object.isRequired,
    header: PropTypes.string,
    subheader: PropTypes.string,
    styleguideUrl: PropTypes.string,
    rules: PropTypes.array,
  }
  static defaultProps = {
    debug: DEBUG_DEFAULT,
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  render = () => {
    const {debug, intl, header, subheader, rules, styleguideUrl} = this.props
    const d = {debug: debug || DEBUG_DEFAULT}
    return (
      <Container {...d}>
        <ContentLeft {...d}>
          <CH3 text={header} />

          <SubheaderContainer>
            <CContent html={subheader} />
          </SubheaderContainer>

          <RulesContainer {...d}>
            {!!rules.length &&
              rules.map((rule, i) => {
                const {header, subheader} = rule
                return (
                  <RuleContainer {...d} key={`rule_${i}`}>
                    <RuleHeader>{header}</RuleHeader>
                    <RuleSubheader>
                      <CContent html={subheader} />
                    </RuleSubheader>
                  </RuleContainer>
                )
              })}
          </RulesContainer>
          <DownloadGuidelinesButtonContainer>
            <CButton
              //
              variant={`press-kit`}
              label={intl.formatMessage({id: `PressBrandUsageButtonDownloadStyleGuide`})}
              urlTo={styleguideUrl}
            />
          </DownloadGuidelinesButtonContainer>
        </ContentLeft>
        <ContentRight {...d}>
          <LogoGuideline>
            <LogoImageContainer {...d} ratio={350 / 160} type={"dark"}>
              <LogoContainer>
                <img src={LogoWhite} style={{width: "100%"}} />
              </LogoContainer>
            </LogoImageContainer>
            <LogoSubtext>{intl.formatMessage({id: `PressBrandUsageLogoOnDarkBackground`})}</LogoSubtext>
          </LogoGuideline>

          <LogoGuideline>
            <LogoImageContainer {...d} ratio={350 / 160} type={"light"}>
              <LogoContainer>
                <img src={LogoDarkgray} style={{width: "100%"}} />
              </LogoContainer>
            </LogoImageContainer>
            <LogoSubtext>{intl.formatMessage({id: `PressBrandUsageLogoOnLightBackground`})}</LogoSubtext>
          </LogoGuideline>
        </ContentRight>
      </Container>
    )
  }
}

const Container = styled.div`
  display: flex;
  width: 100%;

  ${props =>
    css`
      ${props.theme.media.smdown} {
        flex-direction: column;
      }
    `}
`

const ContentLeft = styled.div`
  flex: 3;
  ${props => props.debug && selectors.getDebugOverlayCss(props, `ContentLeft`, `rgba(0,255,255,0.35)`)}
  padding-right: 100px;
  ${props =>
    css`
      ${props.theme.media.smdown} {
        padding-right: 0px;
      }
    `}
`

const SubheaderContainer = styled.div`
  padding: 30px 0px;
`

const RulesContainer = styled.div`
  max-width: 500px;
  ${props => props.debug && selectors.getDebugOverlayCss(props, `RulesContainer`, `rgba(0,255,255,0.35)`)}

  padding: 50px 0px;
  ${props =>
    css`
      ${props.theme.media.smdown} {
        padding: 20px 0px;
      }
    `}
`

const DownloadGuidelinesButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${props =>
    css`
      ${props.theme.media.smdown} {
        padding-bottom: 20px;
      }
    `}
`

const RuleContainer = styled.div`
  ${props => props.debug && selectors.getDebugOverlayCss(props, `RuleContainer`, `rgba(255,0,255,0.35)`)}
`

const RuleHeader = styled.div`
  ${props => selectors.getPersonNameFontCss(props)}
  text-transform: none;
`

const RuleSubheader = styled.div`
  font-size: 15px;
`

const ContentRight = styled.div`
  flex: 2;
  ${props => props.debug && selectors.getDebugOverlayCss(props, `ContentRight`, `rgba(255,0,255,0.35)`)}
`

const LogoGuideline = styled.div`
  margin-bottom: 30px;
`

const LogoImageContainer = styled.div`
  width: 100%;
  position: relative;
  padding-top: ${props => (1 / props.ratio) * 100}%;
  ${props => props.debug && selectors.getDebugOverlayCss(props, `LogoImageContainer`, `rgba(255,0,255,0.35)`)}
  background-color: ${props => (props.type == "dark" ? props.theme.vars.colors.codGray : props.theme.vars.colors.westar)};
`

const LogoContainer = styled.div`
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0% 25%;
  color: white;
`

const LogoSubtext = styled.div`
  text-align: center;
  font-size: 16px;
  letter-spacing: 0.1em;
  font-kerning: none;
  padding: 15px 0px;
`

//  eslint-disable-next-line
const mapStateToProps = state => ({})
export default injectIntl(connect(mapStateToProps)(CLogoGuidelines))
