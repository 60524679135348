import React, {Component} from "react"
import PropTypes from "prop-types"
import {connect} from "react-redux"
import {injectIntl} from "react-intl"
import isEqual from "lodash/isEqual"
import styled, {css} from "styled-components" // eslint-disable-line
import {motion, useViewportScroll, useTransform, AnimatePresence} from "framer-motion" // eslint-disable-line
// import ReactPlayer from "react-player"

import * as selectors from "../../data/selectors"
import * as actions from "../../data/actions"
import CIcon from "../../view/components/CIcon"
import CButton from "../../view/components/CButton"

const debby = (funcName, data = null) => console.log("SHome." + funcName, data) // eslint-disable-line
const god = selectors.getObjectDeep

class CReferrerCodePopup extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    shown: PropTypes.bool,
    codeData: PropTypes.object,
    intlLocale: PropTypes.string.isRequired,
    sitemapData: PropTypes.array,
  }
  static defaultProps = {
    debug: false && __DEV__,
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  onCloseClick = () => this.props.dispatch(actions.closeReferrerCodePopup())

  onButtonClick = () => {
    const {dispatch, intlLocale, sitemapData} = this.props
    dispatch(actions.closeReferrerCodePopup({clickedButton: true}))
    const linkTo = selectors.getPathnameLocalized("/contact", intlLocale, sitemapData)
    dispatch(actions.handleUrl(linkTo, {debug: false, postfix: "#form"})) // scroll down to Contact Form
  }

  onError = error => {
    throw error
  }

  render = () => {
    const {debug, shown, codeData} = this.props
    const d = {debug: debug || CReferrerCodePopup.defaultProps.debug}
    const header = god(codeData, "popupHeader", "")
    const text = god(codeData, "popupText", "")
    const buttonLabel = god(codeData, "popupButtonLabel", "")
    return (
      <AnimatePresence>
        {shown && !!header && !!text && (
          <PresenceContainer initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}}>
            {/* Header Text & Close Button */}
            <HeaderContainer {...d}>
              <TitleContainer {...d}>{header}</TitleContainer>
              <CloseContainer {...d} onClick={this.onCloseClick}>
                <CIcon id={`MobileMenuClose`} color={"white"} />
              </CloseContainer>
            </HeaderContainer>
            {/* Text */}
            <TextContainer {...d}>{text}</TextContainer>
            {/* Text */}
            <ButtonContainer {...d}>
              <CButton
                //
                label={buttonLabel}
                variant={`home-tutorials`} // left
                // variant={`referrer-popup`} // centered
                stretched={false}
                onClick={this.onButtonClick}
              />
            </ButtonContainer>
          </PresenceContainer>
        )}
      </AnimatePresence>
    )
  }
}

const PresenceContainer = styled(motion.div)`
  position: fixed;
  z-index: 20001; // in front of CHeaderMenu
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  ${props => selectors.getPaddingLeftRightByThemeAndVariant(props.theme, "header")}
  background-color: ${props => props.theme.vars.colors.codGray};
`

const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 500px;
  ${props => false && props.debug && selectors.getDebugOverlayCss(props, `HeaderContainer`, `rgba(255,0,255,0.35)`)}
`

const TitleContainer = styled.div`
  flex: 1;
  align-self: stretch;
  padding-bottom: 20px;
  padding-top: 10px;
  color: white;
  letter-spacing: 0.1em;
  font-size: 20px;
  font-weight: ${props => props.theme.vars.fonts.weights.semibold};
  font-family: ${props => props.theme.vars.fonts.families.default};

  ${props => props.debug && selectors.getDebugOverlayCss(props, `TitleContainer`, `rgba(255,0,255,0.35)`)}
`

const CloseContainer = styled.div`
  width: 50px;
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  padding-top: 10px;
  justify-content: center;
  cursor: pointer;
  opacity: 0.5;
  :hover {
    opacity: 1;
  }
  transition: opacity 0.2s ease-in-out;
  ${props => props.debug && selectors.getDebugOverlayCss(props, `CloseContainer`, `rgba(255,255,0,0.15)`)}
`

const TextContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 500px;
  color: white;
  letter-spacing: 0.1em;
  line-height: 1.5em;
  ${props => props.debug && selectors.getDebugOverlayCss(props, `TextContainer`, `rgba(255,0,255,0.35)`)}
`

const ButtonContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 500px;
  padding-top: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  ${props => props.debug && selectors.getDebugOverlayCss(props, `ButtonContainer`, `rgba(255,0,0,0.35)`)}
`

const mapStateToProps = state => ({
  shown: god(state, "session.referrerCodePopup.shown", false),
  codeData: selectors.getReferrerPopupDataLocalized(state),
  intlLocale: selectors.getIntlLocale(state),
  sitemapData: selectors.getSitemapData(state),
})
export default injectIntl(connect(mapStateToProps)(CReferrerCodePopup))
