import axios from "axios"
// import shortid from "shortid"
import * as actionsConsts from "../../data/actionsConsts"
import BugReportManager from "../../data/utils/BugReportManager"
import AnalyticsManager from "../../data/utils/AnalyticsManager"

import {getObjectDeep} from "../selectors/helpers"
import {getApiUrl} from "../selectors/api"

export const requestWordpressUser = devMode => async dispatch => {
  dispatch({type: actionsConsts.REQUEST_WORDPRESS_USER})
  const serverResponse = await axios.get(getApiUrl("/wpuid"))
  let userId = getObjectDeep(serverResponse, "data.response.payload.wpuid")
  let userLogin = getObjectDeep(serverResponse, "data.response.payload.wpUsername")
  if (__DEV__ && devMode) {
    userId = 2
    userLogin = "steff"
  }
  if (!!userId) {
    BugReportManager.setUserContext({WordpressUserId: userId, WordpressUsername: userLogin})
    AnalyticsManager.setUserProperty("WordpressUserId", userId)
    AnalyticsManager.setUserProperty("WordpressUsername", userLogin)
  }
  dispatch({type: actionsConsts.RECEIVE_WORDPRESS_USER, userId, userLogin})
  return {userId, userLogin}
}
