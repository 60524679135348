import {CONFIG} from "../config"

const {breakpoints, colors} = CONFIG.layout

const paddingHorizontalByVariant = {
  content: {
    xs: 30,
    sm: 80,
    md: 100,
    lg: 130,
    xl: 180,
  },
  header: {
    xl: 135,
    lg: 100,
    md: 80,
    sm: 40,
    xs: 30,
  },
}

const basicTheme = {
  breakpoints,
  select: {
    fontSize: 20,
    fontFamily: "Raleway",
    fontWeight: 500, // medium
  },
  media: {
    xl: `@media only screen and (min-width: ${breakpoints.xl.min}px)`,
    lg: `@media only screen and (min-width: ${breakpoints.lg.min}px) and (max-width: ${breakpoints.xl.min - 1}px)`,
    md: `@media only screen and (min-width: ${breakpoints.md.min}px) and (max-width: ${breakpoints.lg.min - 1}px)`,
    sm: `@media only screen and (min-width: ${breakpoints.sm.min}px) and (max-width: ${breakpoints.md.min - 1}px)`,
    xs: `@media only screen and (max-width: ${breakpoints.sm.min - 1}px)`,

    lgdown: `@media only screen and (max-width: ${breakpoints.xl.min - 1}px)`,
    mddown: `@media only screen and (max-width: ${breakpoints.lg.min - 1}px)`,
    smdown: `@media only screen and (max-width: ${breakpoints.md.min - 1}px)`,
    // xl: `@media only screen and (min-width: ${breakpoints.xl.min}px)`,
    // lg: `@media (max-width: ${breakpoints.xl.min - 1}px) `,
    // md: `@media (max-width: ${breakpoints.lg.min - 1}px) `,
    // sm: `@media (max-width: ${breakpoints.md.min - 1}px) `,
    // xs: `@media (max-width: ${breakpoints.sm.min - 1}px) `,
  },
  vars: {
    padding: {
      horizontal: paddingHorizontalByVariant,
    },
    fonts: {
      families: {
        default: "Raleway",
        numerals: "Rawline",
        mono: "Fira Code",
      },
      weights: {
        default: 400, // <- default here
        light: 300,
        regular: 400,
        medium: 500,
        semibold: 600,
        bold: 700,
        extrabold: 800,
        black: 900,
      },
    },
    colors,
    transitions: {
      themeTransitionDuration: CONFIG.layout.waypointThemeTransitionDuration,
    },
  },
  colorBg: "rgba(0,0,0,0.5)",
  colorBgSpecial: "yellow",
  colorBgSpecial2: "blue",
}

export {basicTheme, paddingHorizontalByVariant}
