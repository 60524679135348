import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import styled, {css} from "styled-components" // eslint-disable-line
import * as selectors from "../../data/selectors"

class CSpacer extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    direction: PropTypes.oneOf(["vertical"]),
    variant: PropTypes.oneOf(["headermenu", "herosections", "home-hero-bottom", "presskit-header", "headermenu-embedded"]).isRequired,
  }
  static defaultProps = {
    debug: false && __DEV__,
    direction: "vertical",
    variant: "content",
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  render = () => {
    const {debug, variant} = this.props
    const d = {debug: debug || CSpacer.defaultProps.debug}
    return <Spacer {...d} variant={variant} />
  }
}

const Spacer = styled.div`
  position: relative;

  ${props =>
    props.variant == "headermenu-embedded" &&
    css`
      min-height: 70px; // see also: CHeaderEmbeddedMenu.js
      ${props => css`
        ${props.theme.media.mddown} {
          min-height: 70px;
          display: none; // hide generally
        }
      `}
    `}

  ${props =>
    props.variant == "headermenu" &&
    css`
      min-height: 80px;
      ${props => css`
        ${props.theme.media.md} {
          min-height: 80px;
        }
        ${props.theme.media.sm} {
          min-height: 55px;
        }
        ${props.theme.media.xs} {
          min-height: 55px;
        }
      `}
    `}

  ${props =>
    props.variant == "herosections" &&
    css`
      min-height: 40px;
    `}

  ${props =>
    props.variant == "home-hero-bottom" &&
    css`
      min-height: 100px;
    `}

  ${props =>
    props.variant == "presskit-header" &&
    css`
      min-height: 10px;
    `}

  ${props => selectors.getDebugOverlayCss(props, `Spacer${props.variant ? `.${props.variant}` : ``}`, "rgba(0,0,0,0.05)")}
`

export default CSpacer
