import React, {Component} from "react"
import PropTypes from "prop-types"
import {injectIntl} from "react-intl"
import {connect} from "react-redux"
import isEqual from "lodash/isEqual"
import {motion, useViewportScroll, useTransform, AnimatePresence} from "framer-motion" // eslint-disable-line
import styled, {css, ThemeProvider} from "styled-components"

import * as selectors from "../../data/selectors"

import CEmbeddedShareIcons from "../../view/screens/SGalleryLanding/CEmbeddedShareIcons"

const DEBUG = false && __DEV__

class CFooterEmbeddedMode extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    intl: PropTypes.object.isRequired,
    defaultThemeProps: PropTypes.object,
    locationPathname: PropTypes.string,
    embeddedMode: PropTypes.bool,
    galleryTitle: PropTypes.string,
  }
  static defaultProps = {
    debug: DEBUG,
    defaultThemeProps: {
      // backgroundColor: "white",
      textColor: "black",
    },
    maxHeightDescription: 200,
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  render = () => {
    const {debug, intl, defaultThemeProps, embeddedMode, seoData, locationPathname, galleryTitle} = this.props // eslint-disable-line
    const d = {debug: DEBUG || this.props.debug}
    if (!embeddedMode) {
      return null
    }
    // if (!!galleryTitle) { debugger } // prettier-ignore
    return (
      <ThemeProvider theme={{...defaultThemeProps}}>
        <PaddedContainer {...d} variant={"header"}>
          <Content
            //
            {...d}>
            {!!seoData && (
              <ShareContainer {...d}>
                <ShareButton {...d}>{intl.formatMessage({id: `WallEditorShareWallButton`})}</ShareButton>
                <CEmbeddedShareIcons
                  //
                  {...d}
                  shown
                  seoData={seoData}
                  galleryTitle={galleryTitle}
                />
              </ShareContainer>
            )}
          </Content>
        </PaddedContainer>
      </ThemeProvider>
    )
  }
}

const PaddedContainer = styled.div`
  ${props => selectors.getPaddingLeftRightByThemeAndVariant(props.theme, props.variant)}
  padding-top: 10px;
  padding-bottom: 10px;
  background: #ffffff;

  ${props => false && props.debug && selectors.getDebugOverlayCss(props, "CFooterEmbeddedMode.PaddedContainer", "rgba(0,0,255,0.35)")}
  transition: all ${props => props.theme.vars.transitions.themeTransitionDuration}s linear;

  display: none;
  ${props => css`
    ${props.theme.media.mddown} {
      display: block;
    }
  `}
`

const Content = styled.div`
  width: 100%;
  ${props => selectors.getDebugOverlayCss(props, "Content", "rgba(0,255,0,0.35)")}
`

const ShareContainer = styled.div`
  flex: 1;
  align-self: stretch;
  align-items: center;
  justify-content: center;
  ${props => true && selectors.getDebugOverlayCss(props, "ShareContainer", "rgba(255,0,255,0.25)")};
  display: none;
  ${props => css`
    ${props.theme.media.mddown} {
      display: flex;
    }
  `}
`

const ShareButton = styled.div`
  font-size: 13px;
  font-weight: ${props => props.theme.vars.fonts.weights.default};
  letter-spacing: 0.2em;
  font-kerning: none;
  text-transform: uppercase;
  background-color: ${props => (props.debug ? "green" : "none")};
  padding: 10px;
  // margin-right: -10px;
  cursor: pointer;
`

const mapStateToProps = (state, props) => ({
  seoData: selectors.getSeoDataByLocationPathname(state, props), // routes.js
  embeddedMode: state.session.embeddedMode,
})
export default injectIntl(connect(mapStateToProps)(CFooterEmbeddedMode))
