import CHomeBenefits from "./CHomeBenefits"
import CHomeHeroOverlay from "./CHomeHeroOverlay"
import CHomeBrandMap from "./CHomeBrandMap"
import CHomeRightAngle from "./CHomeRightAngle"
import CHomePartners from "./CHomePartners"
import CHomeTailorMade from "./CHomeTailorMade"
import CHomeTutorials from "./CHomeTutorials"
import CHomeWhitepaper from "./CHomeWhitepaper"
import CHomeDemo from "./CHomeDemo"
import CHomeHeroVideoButton from "./CHomeHeroVideoButton"

export {CHomeBenefits, CHomeHeroOverlay, CHomeBrandMap, CHomeRightAngle, CHomePartners, CHomeTailorMade, CHomeTutorials, CHomeWhitepaper, CHomeDemo, CHomeHeroVideoButton}
