import React, {Component} from "react"
import PropTypes from "prop-types"
import {injectIntl} from "react-intl"
import {connect} from "react-redux" // eslint-disable-line
import {createSelector} from "reselect"
import isEqual from "lodash/isEqual"
import {motion, useViewportScroll, useTransform, AnimatePresence} from "framer-motion" // eslint-disable-line
import styled, {css, ThemeProvider} from "styled-components" // eslint-disable-line
import {withRouter} from "react-router-dom"

import {CONFIG} from "../../config" // eslint-disable-line
import * as selectors from "../../data/selectors"
const god = selectors.getObjectDeep

import CLink from "../../view/components/CLink"
import CIcon from "../../view/components/CIcon"

import {LogoWhite} from "../../images"

const DEBUG = false && __DEV__
import {debbify} from "../../data/selectors/helpers"
const debby = (...args) => debbify("CFooterUmbrella", ...args) // eslint-disable-line

class CFooterUmbrella extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    intl: PropTypes.object.isRequired,
    defaultThemeProps: PropTypes.object,
    globalsLocalized: PropTypes.object,
    menuItems: PropTypes.array,
  }
  static defaultProps = {
    debug: DEBUG,
    defaultThemeProps: {
      // backgroundColor: "white",
      textColor: "black",
    },
    maxHeightDescription: 200,
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  render = () => {
    const {intl, globalsLocalized, menuItems} = this.props
    const d = {debug: DEBUG || this.props.debug}
    const logoIconSrc = selectors.getObjectDeep(globalsLocalized, "logoIcon.sizes.500c.url")
    return (
      <Container {...d} paddingVariant={"header"}>
        <Content {...d}>
          <LogoIconImg {...d} src={logoIconSrc} />
          <br />
          <LogoImg {...d} src={LogoWhite} />
          <LogoSubheader {...d} dangerouslySetInnerHTML={{__html: globalsLocalized.logoSubheader}} />
          <MenuItems {...d}>
            {menuItems.map((item, i) => {
              return (
                <MenuItemContainer {...d} key={`menu_item_${i}`}>
                  <CLink to={item.linkTo} preloadPostData={false}>
                    <MenuItemLabel
                      //
                      {...d}
                      active={item.active}
                      dangerouslySetInnerHTML={{__html: intl.formatMessage({id: item.intlId})}}
                    />
                  </CLink>
                </MenuItemContainer>
              )
            })}
          </MenuItems>
          <SocialIcons {...d}>
            {!!globalsLocalized.contact.twitter && <CSocialIcon href={globalsLocalized.contact.twitter} iconId={"FooterTwitter"} iconColor={"#778080"} />}
            {!!globalsLocalized.contact.facebook && <CSocialIcon href={globalsLocalized.contact.facebook} iconId={"FooterFacebook"} iconColor={"#778080"} />}
            {!!globalsLocalized.contact.instagram && <CSocialIcon href={globalsLocalized.contact.instagram} iconId={"FooterInstagram"} iconColor={"#778080"} />}
            {!!globalsLocalized.contact.linkedin && <CSocialIcon href={globalsLocalized.contact.linkedin} iconId={"FooterLinkedin"} iconColor={"#778080"} />}
            {!!globalsLocalized.contact.email && <CSocialIcon href={`mailto:${globalsLocalized.contact.email}`} iconId={"FooterEmail"} iconColor={"#778080"} />}
          </SocialIcons>
          <Copyright>{god(globalsLocalized, "copyright", "-")}</Copyright>
        </Content>
      </Container>
    )
  }
}

const Container = styled.div`
  position: relative;
  z-index: 4;
  padding-top: 50px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  background-color: ${props => props.theme.vars.colors.codGray};
  color: white;
  ${props => selectors.getBackgroundColorByProps(props, "rgba(0,0,0,0.85)")}
  ${props => selectors.getPaddingLeftRightByThemeAndVariant(props.theme, props.paddingVariant)}
`
const Content = styled.div`
  ${props => selectors.getBackgroundColorByProps(props, "rgba(255,0,0,0.25)")}
`

const LogoIconImg = styled.img`
  width: 100px;
  border: ${props => (props.debug ? 2 : 0)}px solid red;
  margin-bottom: 20px;

  ${props => css`
    ${props.theme.media.smdown} {
      // height: 45px;
    }
  `}
`

const LogoImg = styled.img`
  ${props => selectors.getBackgroundColorByProps(props, "rgba(255,255,0,0.5)")}
  width: 140px;
  ${props =>
    css`
      ${props.theme.media.mddown} {
        // width: 100px;
      }
    `}
`
const LogoSubheader = styled.div`
  padding-top: 15px;
  color: #ffffff;
  letter-spacing: 1.6px;
  text-align: center;

  font-size: 16px;
  line-height: 27px;
  ${props => css`
    ${props.theme.media.smdown} {
      font-size: 14px;
      line-height: 23px;
    }
  `}
`

const MenuItems = styled.div`
  padding-top: 30px;
  border: ${props => (props.debug ? 1 : 0)}px solid white;
  display: flex;
  justify-content: center;

  flex-direction: row;
  ${props => css`
    ${props.theme.media.smdown} {
      flex-direction: column;
    }
  `}
`
const MenuItemContainer = styled.div`
  border: ${props => (props.debug ? 1 : 0)}px solid yellow;
`
const MenuItemLabel = styled.div`
  color: ${props => (props.active ? "white" : props.theme.vars.colors.siccoro)};
  font-size: 14px;
  padding: 10px 20px;
`

const SocialIcons = styled.div`
  padding-top: 30px;
  border: ${props => (props.debug ? 1 : 0)}px solid white;
  display: flex;
  flex-direction: row;
  justify-content: center;
`

const CSocialIcon = ({href, iconId, iconColor}) => (
  <SocialIcon href={href} target={"_blank"}>
    <CIcon scale={1.3} id={iconId} color={iconColor} />
  </SocialIcon>
)
CSocialIcon.propTypes = {href: PropTypes.string, iconId: PropTypes.string, iconColor: PropTypes.string}

const SocialIcon = styled.a`
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 10px;
  padding-left: 10px;
`

const Copyright = styled.div`
  padding-top: 50px;
  text-align: center;
  color: ${props => props.theme.vars.colors.siccoro};
  font-size: 11px;
  border: ${props => (props.debug ? 1 : 0)}px solid yellow;
  padding-bottom: 20px;
`

// prettier-ignore
const MENU_ITEMS = [
  {key: "faq",              intlId: `FooterFAQ`,              linkTo: "/other/faq"},
  {key: "contact",          intlId: `FooterContact`,          linkTo: "/contact"},
  {key: "privacy-policy",   intlId: `FooterPrivacyPolicy`,    linkTo: "/other/privacy-policy"},
  {key: "terms-conditions", intlId: `FooterTermsConditions`,  linkTo: "/other/terms-conditions"},
]

const getMenuItemsWithActive = createSelector([selectors.getLocationPathname, selectors.getSitemapData], (locationPathname, sitemapData) => {
  const actPath = selectors.getPathnameLocalized(locationPathname, "en", sitemapData)
  const menuItemsWithActive = MENU_ITEMS.map(item => ({...item, active: item.linkTo == actPath})) // active can never be true
  return menuItemsWithActive
})

const mapStateToProps = (state, props) => ({
  globalsLocalized: selectors.getGlobalsLocalized(state),
  menuItems: getMenuItemsWithActive(state, props),
})
export default withRouter(injectIntl(connect(mapStateToProps)(CFooterUmbrella)))
