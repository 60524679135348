import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import {injectIntl} from "react-intl"
import {connect} from "react-redux"
import styled, {css} from "styled-components" // eslint-disable-line

import * as actions from "../../../data/actions"
import * as selectors from "../../../data/selectors"
const god = selectors.getObjectDeep

import CFloatingInput from "../../../view/components/CFloatingInput"
import CButton from "../../../view/components/CButton"
import CCollapse from "../../../view/components/CCollapse"

import CSmallprint from "../../../view/components/text/CSmallprint"

const DEBUG = false && __DEV__
import {debbify} from "../../../data/selectors/helpers"
const debby = (...args) => debbify("CExhibitorsNewsletter", ...args)

class CExhibitorsNewsletter extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    intl: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    // SExhibitors
    header: PropTypes.string,
    emailLabel: PropTypes.string,
    buttonLabel: PropTypes.string,
    // connect
    globalsLocalized: PropTypes.object,
    sitemapData: PropTypes.array,
    intlLocale: PropTypes.string,
    subscribeRequestBusy: PropTypes.bool,
  }
  static defaultProps = {
    debug: false,
  }

  constructor(props) {
    super(props)
    this.state = {
      // email: "",
      email: DEBUG ? "skizzo@backlab" : "", // DEBUG
      formValid: false,
      formSubmittedOnce: false,
      formRequestedOnce: false,
      formRequestResult: null,
      requestPerformedInDryMode: undefined,
    }
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  onInputChange = email => this.setState({email})

  validateInput = (value, name) => {
    switch (name) {
      case "email": {
        return !!value && selectors.getEmailIsValid(value)
      }
    }
    return true
  }

  getFormIsValid = () => {
    const formValid = this.validateInput(this.state.email, "email")
    this.setState({formValid})
    return formValid
  }

  submitForm = event => {
    !!event && event.preventDefault()
    const {dispatch, intl} = this.props
    this.setState({formSubmittedOnce: true, formRequestedOnce: true}, () => {
      if (!this.getFormIsValid()) {
        return
      }
      const fields = {
        lang: intl.locale,
        email: this.state.email,
      }
      dispatch(actions.addNewsletterSubscriber(fields)).then(({success, error, errorIntlId, dryMode}) => {
        this.setState({formRequestResult: {success, error, errorIntlId}, requestPerformedInDryMode: dryMode})
      })
    })
  }

  onPrivacyPolicyLinkPress = hrefProps => window.open(hrefProps.href)

  render = () => {
    const {email, formSubmittedOnce, formRequestedOnce, formRequestResult} = this.state
    const {header, emailLabel, buttonLabel} = this.props
    const {intl, globalsLocalized, sitemapData, intlLocale, subscribeRequestBusy} = this.props
    const d = {debug: DEBUG || this.props.debug}
    if (!header || !emailLabel || !buttonLabel) {
      return <div />
    }

    const formRequestSuccess = god(formRequestResult, "success", false)
    const showResult = formSubmittedOnce && !subscribeRequestBusy && formRequestedOnce && !!formRequestResult
    const inputsDisabledAfterSubmit = showResult && formRequestSuccess
    const disabled = subscribeRequestBusy || inputsDisabledAfterSubmit

    const hrefProps = selectors.getHrefProps("/other/privacy-policy", {sitemapData, locale: intlLocale})
    const text = god(globalsLocalized, "sectionNewsletter.privacyPolicy", "")
    const privacyPolicyHtml = text.split(`<link>`).join(`<a href='${hrefProps.linkToWithBasePath}'>`).split("</link>").join("</a>") // prettier-ignore

    debby("render()", {formRequestSuccess})
    return (
      <Container {...d}>
        <Content {...d}>
          <CCollapse collapsed={formRequestSuccess}>
            <Header {...d}>{header}</Header>
            <FormContainer {...d}>
              <InputContainer {...d}>
                <CFloatingInput
                  //
                  {...d}
                  placeholder={emailLabel}
                  name={"email"}
                  size={"umbrella-newsletter"} // no horizontal padding for Input
                  value={email}
                  type={"email"}
                  validateInput={this.validateInput}
                  required
                  onChange={this.onInputChange}
                  errorText={intl.formatMessage({id: `FormErrorInvalidEmail`})}
                  showError={formSubmittedOnce}
                  onSubmit={this.submitForm}
                  disabled={disabled}
                />
              </InputContainer>
              <Button {...d}>
                <CButton
                  //
                  {...d}
                  label={buttonLabel}
                  onClick={this.submitForm}
                  canBeBusy
                  stretched
                  disabled={disabled}
                  // busy={d.debug || subscribeRequestBusy}
                  busy={subscribeRequestBusy}
                  // themeKey={"transparent-light"}
                  // size={"small"}
                  noHoverScale // weirdly enough, there is some hiddden overflow somewhere
                />
              </Button>
            </FormContainer>

            <PrivacyPolicyContainer {...d}>
              <CSmallprint
                //
                text={privacyPolicyHtml}
                onLinkPress={this.onPrivacyPolicyLinkPress}
              />
            </PrivacyPolicyContainer>
          </CCollapse>

          <CCollapse collapsed={!formRequestSuccess}>
            <ThankYouNote {...d} dangerouslySetInnerHTML={{__html: god(globalsLocalized, "sectionNewsletter.thankYou", "")}} />
          </CCollapse>
        </Content>
      </Container>
    )
  }
}

const Container = styled.div`
  border: ${props => (props.debug ? 1 : 0)}px solid red;
  display: flex;
  justify-content: center;
`

const Content = styled.div`
  max-width: 600px;
  width: 100%;
  border: ${props => (props.debug ? 1 : 0)}px solid green;
`

const Header = styled.div`
  font-size: 15px;
  text-transform: uppercase;
  color: #1e1e1e;
  letter-spacing: 2.31px;
  font-weight: ${props => props.theme.vars.fonts.weights.semibold};
  border: ${props => (props.debug ? 1 : 0)}px solid blue;
`

const FormContainer = styled.div`
  display: flex;
  width: 100%;
  padding-top: 10px;
  border: ${props => (props.debug ? 1 : 0)}px solid black;

  flex-direction: row;
  ${props => css`
    ${props.theme.media.smdown} {
      flex-direction: column;
    }
  `}
`

const InputContainer = styled.div`
  flex: 1;
  padding-top: 18px; // align with Button bottom
  ${props => false && props.debug && selectors.getDebugOverlayCss(props, `InputContainer`, `rgba(255,0,255,0.35)`)}

  padding-right: 10px;
  ${props => css`
    ${props.theme.media.smdown} {
      padding-right: 0;
    }
  `}
`

const Button = styled.div`
  padding-top: 10px; // must match <CButton size="small"/>
  ${props => props.debug && selectors.getDebugOverlayCss(props, `Button`, `rgba(0,255,255,0.35)`)}
`

const PrivacyPolicyContainer = styled.div`
  padding-top: 20px;
`

const ThankYouNote = styled.div`
  text-align: center;
  letter-spacing: 0.1em;
  padding: 30px 0px;
  border: ${props => (props.debug ? 1 : 0)}px solid red;
`

const mapStateToProps = state => ({
  intlLocale: selectors.getIntlLocale(state),
  sitemapData: selectors.getSitemapData(state),
  globalsLocalized: selectors.getGlobalsLocalized(state),
  subscribeRequestBusy: selectors.getSubscribeRequestBusy(state), // one of all in session
})
export default injectIntl(connect(mapStateToProps)(CExhibitorsNewsletter))
