import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import {connect} from "react-redux"
import {createSelector} from "reselect"
import styled from "styled-components"

import withScreenProps from "../../../data/hocs/withScreenProps"
import * as selectors from "../../../data/selectors"
const god = selectors.getObjectDeep

import CFooter from "../../../view/components/CFooter"
import CSpacer from "../../../view/components/CSpacer"
import CGetInTouch from "../../../view/components/CGetInTouch"
import CEditInWordPress from "../../../view/components/CEditInWordPress"

import {CQRCodeContainer} from "./components"

import {debbify} from "../../../data/selectors/helpers"
const debby = (...args) => debbify("SDemo", ...args) // eslint-disable-line

class SDemo extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    sitemapData: PropTypes.array,
    gallery: PropTypes.object,
    iosOrAndroid: PropTypes.bool,

    //  withScreenProps
    dispatch: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    intlLocale: PropTypes.string.isRequired,
    themeKey: PropTypes.string,
    postData: PropTypes.object,
    editInWordPressProps: PropTypes.object,
    pageTemplate: PropTypes.string,
  }
  static defaultProps = {
    debug: false && __DEV__,
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  render = () => {
    const {debug, intl, postData, editInWordPressProps, pageTemplate, intlLocale, sitemapData, gallery, iosOrAndroid} = this.props
    const d = {debug}
    const privacySmallprintHtml = selectors.getPrivacySmallprintHtml(intl, intlLocale, sitemapData) // for CGetInTouch
    const copiedToClipboardLabel = intl.formatMessage({id: `CopiedToClipboardSuccess`})
    return (
      <Container {...d}>
        <CSpacer {...d} variant={`headermenu`} />

        <CQRCodeContainer
          //
          header={god(postData, "sectionTop.header")}
          qrHeader={god(postData, "sectionQrCode.header")}
          qrSteps={god(postData, "sectionQrCode.steps")}
          qrCodeImageUrl={!!gallery ? gallery.qrCodeUrl : null}
          qrCodeBranchUrl={!!gallery ? gallery.branchUrl : null}
          iosOrAndroid={iosOrAndroid} // QR code is clickable and opens its link
          mobileHeader={god(postData, "sectionQrMobile.header")}
          mobileOr={god(postData, "sectionQrMobile.or")}
          mobileTodo={god(postData, "sectionQrMobile.todo")}
          mobileButtonLabel={god(postData, "sectionQrMobile.buttonLabel")}
          copiedToClipboardLabel={copiedToClipboardLabel}
        />

        <GetInTouchContainer {...d}>
          <CGetInTouch
            //  labels
            {...d}
            pageTemplate={pageTemplate}
            header={god(postData, "sectionGetInTouch.labelHeader")}
            button={god(postData, "sectionGetInTouch.labelButton")}
            teamMember={god(postData, "sectionGetInTouch.teamMember")}
            smallprint={privacySmallprintHtml}
            onLinkPress={this.onLinkPress}
          />
        </GetInTouchContainer>

        <CFooter />
        <CEditInWordPress fixed {...editInWordPressProps} />
      </Container>
    )
  }
}

const Container = styled.div`
  position: relative;
  background-color: ${props => props.theme.vars.colors.westar};
  transition: all ${props => props.theme.vars.transitions.themeTransitionDuration}s linear;
`

const GetInTouchContainer = styled.div`
  ${props => selectors.getDebugOverlayCss(props, "GetInTouchContainer", "rgba(255,0,0,0.15)")}
  background-color: white;
  padding: 90px 0px;
`

const getGallery = createSelector([selectors.getPostData], postData => {
  const galleries = god(postData, "sectionQrCode.galleries")
  if (!!galleries) {
    const randomGallery = galleries[Math.floor(Math.random() * galleries.length)]
    return randomGallery
  }
  return null
})

const mapStateToProps = (state, props) => ({
  sitemapData: selectors.getSitemapData(state),
  gallery: getGallery(state, props),
  iosOrAndroid: selectors.getIsIosOrAndroid(),
})
export default withScreenProps(connect(mapStateToProps)(SDemo))
