import {push} from "connected-react-router"

import * as actionsConsts from "../../data/actionsConsts"

import {getHrefProps, getObjectDeep} from "../../data/selectors/helpers"

export const setStageDimensions = (width, height) => dispatch => {
  dispatch({type: actionsConsts.SET_STAGE_DIMENSIONS, width, height})
}

export const handleUrl = (url, options = null) => (dispatch, getState) => {
  let result = {
    url,
    action: null,
  }
  if (!url) {
    return result
  }
  const debug = getObjectDeep(options, "debug", false)
  const postfix = getObjectDeep(options, "postfix", "") // e.g. "#form"
  const openInNewWindow = getObjectDeep(options, "openInNewWindow", false)
  const search = getObjectDeep(options, "search", "")
  const hash = getObjectDeep(options, "hash", undefined)

  const routes = getState().routes
  const sitemapData = !!routes ? routes.sitemap.data : null
  const hrefProps = getHrefProps(url, {sitemapData, debug})
  if (debug) { debugger } // prettier-ignore

  if (!!hrefProps.linkToWithBasePath && !openInNewWindow) {
    // dispatch(push(hrefProps.linkTo + postfix + search))
    dispatch(push({pathname: hrefProps.linkTo + search, hash: hash || postfix}))
    result.action = "RouterPushed"
    result.to = "hrefProps.linkTo"
    // } else if (!!url.toLowerCase().indexOf("mailto:") == 0) {
    //   window.open(url, "_blank")
  } else {
    window.open(url + search, "_blank")
    result.action = "WindowOpen"
  }
  return result
}

export const setTabVisible = visible => dispatch => {
  dispatch({type: actionsConsts.SET_TAB_VISIBLE, visible})
}
