import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import moment from "moment"
import styled, {ThemeProvider, css} from "styled-components" // eslint-disable-line

import CLink from "../../../view/components/CLink"
import CImage from "../../../view/components/CImage"

import * as selectors from "../../../data/selectors"

const DEBUG = false && __DEV__
import {debbify} from "../../../data/selectors/helpers"
const debby = (...args) => debbify("CArticleMasonryItem", ...args)

class CArticleMasonryItem extends Component {
  static propTypes = {
    masonryClassName: PropTypes.string.isRequired,
    intlLocale: PropTypes.string.isRequired,
    pathLocalized: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    featuredImageUrl: PropTypes.string.isRequired,
    dateTimestamp: PropTypes.number.isRequired,
    dateFormat: PropTypes.string.isRequired,
    contentExcerpt: PropTypes.string,
    readMoreButtonLabel: PropTypes.string.isRequired,
    isInMasonry: PropTypes.bool,
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  render = () => {
    const {masonryClassName, isInMasonry, intlLocale, pathLocalized, title, featuredImageUrl, dateTimestamp, dateFormat, readMoreButtonLabel, contentExcerpt} = this.props
    const d = {debug: DEBUG}
    const dateReadable = moment(dateTimestamp * 1000).locale(intlLocale).format(dateFormat) // prettier-ignore
    debby("CArticleMasonryItem.render()", {intlLocale, pathLocalized})
    const classNameProps = isInMasonry ? {className: `grid-item-${masonryClassName} grid-item-${masonryClassName}`} : {}
    return (
      <div {...classNameProps}>
        <CLink to={pathLocalized}>
          <MasonryItemContainer {...d}>
            {!!featuredImageUrl && <CImage src={featuredImageUrl} ratio={1385 / 1200} />}
            <Title {...d}>{title}</Title>
            {!!dateTimestamp && <Date {...d}>{dateReadable}</Date>}
            <Excerpt {...d}>{contentExcerpt} ...</Excerpt>
            <ReadMore {...d}>{readMoreButtonLabel}</ReadMore>
          </MasonryItemContainer>
        </CLink>
      </div>
    )
  }
}

const MasonryItemContainer = styled.div`
  padding: 15px;
  :hover {
    div {
      opacity: 1;
    }
  }
  div {
    // overlay
    // opacity: ${props => (props.debug ? 0.5 : 0)};
  }
  margin-bottom: 50px;
`

const Title = styled.div`
  margin-top: 30px;
  font-family: ${props => props.theme.vars.fonts.families.default};
  font-weight: ${props => props.theme.vars.fonts.weights.bold};
  color: #1e1e1e;
  letter-spacing: 3px;
  text-align: left;
  ${props => true && props.debug && selectors.getDebugOverlayCss(props, `Title`, `rgba(0,0,255,0.15)`)}

  font-size: 28px;
  line-height: 36px;
  min-height: 108px; // 3 lines
  ${props => css`
    ${props.theme.media.md} {
      font-size: 24px;
      line-height: 31px;
      letter-spacing: 2px;
      min-height: 93px; // 3 lines
    }
    ${props.theme.media.sm} {
      font-size: 22px;
      line-height: 1.3;
      letter-spacing: 2px;
      min-height: 0;
    }
    ${props.theme.media.xs} {
      font-size: 20px;
      line-height: 1.3;
      letter-spacing: 2px;
      min-height: 0;
    }
  `}
`

const Date = styled.div`
  padding-top: 20px;
  padding-bottom: 10px;
  font-family: ${props => props.theme.vars.fonts.families.default};
  font-style: italic;
  font-size: 16px;
  color: #778080;
  letter-spacing: 1.6px;
  text-align: left;
  line-height: 27px;
  ${props => true && props.debug && selectors.getDebugOverlayCss(props, `Date`, `rgba(0,255,0,0.15)`)}
`

const Excerpt = styled.div`
  font-family: ${props => props.theme.vars.fonts.families.default};
  font-size: 16px;
  color: #778080;
  letter-spacing: 1.6px;
  text-align: left;
  line-height: 27px;
  ${props => true && props.debug && selectors.getDebugOverlayCss(props, `Excerpt`, `rgba(255,0,0,0.15)`)}
  margin-bottom: 20px;
`

const ReadMore = styled.span`
  font-family: ${props => props.theme.vars.fonts.families.default};
  text-transform: uppercase;
  font-size: 12px;
  color: #1e1e1e;
  letter-spacing: 2.4px;
  text-align: center;
  line-height: 20px;
  padding-bottom: 5px;
  padding-left: 1px;
  padding-right: 1px;
  border-bottom: 1px solid #1e1e1e;
`

export default CArticleMasonryItem
