import React, {Component} from "react"
import PropTypes, {instanceOf} from "prop-types"
import isEqual from "lodash/isEqual"
import {connect} from "react-redux"
import {createSelector} from "reselect"
import {withController} from "react-scroll-parallax"
import styled, {ThemeProvider, css} from "styled-components" // eslint-disable-line
import {withCookies, Cookies} from "react-cookie"
import queryString from "query-string"
import {replace} from "connected-react-router"
import Masonry from "react-masonry-component"
import {Container, Row, Col} from "react-grid-system"

import * as selectors from "../../../data/selectors"
import * as actions from "../../../data/actions"
import withScreenProps from "../../../data/hocs/withScreenProps" // {postData, pageTemplate, ..}

import CExhibitorsAppDownload from "../SExhibitors/CExhibitorsAppDownload"
import CExhibitorsNewsletter from "../SExhibitors/CExhibitorsNewsletter"
import CButton from "../../../view/components/CButton"
import CIcon from "../../../view/components/CIcon"

import CEditInWordPress from "../../../view/components/CEditInWordPress"
import CFooter from "../../../view/components/CFooter"
import CSpacer from "../../../view/components/CSpacer"

import CH2 from "../../../view/components/text/CH2"
import CH3 from "../../../view/components/text/CH3"

import CArticlesOnlyEnglish from "./CArticlesOnlyEnglish"
import CArticleMasonryItem from "./CArticleMasonryItem"
import CAdvancedSupport from "./CAdvancedSupport"

const DEBUG = false && __DEV__
import {debbify} from "../../../data/selectors/helpers"
const debby = (...args) => debbify("SKnowledgeBase", ...args)
const bgd = (c, cregular = "none") => ({backgroundColor: DEBUG ? c : cregular}) // eslint-disable-line

import {CONFIG} from "../../../config"

const god = selectors.getObjectDeep

const COOKIE_ARTICLES_ENGLISH_ONLY_ACCEPTED_NAME = "iazzuAcceptedKbArticlesEnglishOnly"
const USE_MASONRY_INSTEAD_OF_GRID = false

class SKnowledgeBase extends Component {
  static propTypes = {
    parallaxController: PropTypes.object.isRequired,
    queryProps: PropTypes.object,
    searchQuery: PropTypes.string,

    stageWidth: PropTypes.number,
    sitemapData: PropTypes.array,
    fixedSectionsItems: PropTypes.array,
    defaultThemeProps: PropTypes.object,
    articlesEnglishOnlyAccepted: PropTypes.bool,
    articles: PropTypes.array,
    showingAllArticles: PropTypes.bool,

    //  withScreenProps
    dispatch: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    intlLocale: PropTypes.string.isRequired,
    themeKey: PropTypes.string,
    postData: PropTypes.object,
    wordPressUserId: PropTypes.number,
    editInWordPressProps: PropTypes.object,
    pageTemplate: PropTypes.string,
    mediaQueryClass: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),

    // postData
    readMoreButtonLabel: PropTypes.string,

    // withCookies
    cookies: instanceOf(Cookies).isRequired,
  }
  static defaultProps = {
    defaultThemeProps: {
      backgroundColor: "white",
      textColor: "black",
    },
  }

  constructor(props) {
    super(props)
    this.state = {
      searchQuery: props.searchQuery,
    }
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  componentDidMount = () => {
    this.props.dispatch(actions.requestKnowledgeBaseArticles())
    const d = {debug: DEBUG}
    if (d.debug) {
      setTimeout(() => this.props.cookies.set(COOKIE_ARTICLES_ENGLISH_ONLY_ACCEPTED_NAME, JSON.stringify(false), {path: "/"}), 5000)
    }
    this.updateSearchResults()
  }

  componentWillUnmount = () => {
    clearTimeout(this.updateSearchResultsTimeoutId)
  }

  onSearchQueryChange = evt => this.setSearchQuery(evt.target.value)

  setSearchQuery = searchQuery => this.setState({searchQuery}, this.updateSearchResults)

  submitSearchQuery = () => this.updateSearchResults(0) // diffMs == 0 -> update instantly

  clearSearchInput = () =>
    this.setState({searchQuery: ""}, () => {
      this.updateSearchResults(0) // instantly clear
      !!this.inputNode && this.inputNode.focus()
    })

  updateSearchResults = (diffMs = 1000) => {
    clearTimeout(this.updateSearchResultsTimeoutId)
    this.updateSearchResultsTimeoutId = setTimeout(() => {
      const {searchQuery} = this.state
      this.updateQueryProps({s: searchQuery})
    }, diffMs)
  }

  updateQueryProps = (propsShortKeyed = {}) => {
    let propsNext = {...this.props.queryProps, ...propsShortKeyed}
    let propsFiltered = {}
    for (const queryKey of Object.keys(propsNext)) {
      switch (queryKey) {
        case "s": {
          propsFiltered[queryKey] = propsNext[queryKey]
          if (!propsFiltered[queryKey]) {
            delete propsFiltered[queryKey]
          }
          break
        }
        default: {
          if (__DEV__) {
            throw new Error(`updateQueryProps(): Unhandled queryKey '${queryKey}'.`)
          }
        }
      }
    }
    this.props.dispatch(replace({search: queryString.stringify(propsFiltered, {encode: true})}))
  }

  onCloseArticlesOnlyEnglishClick = () => this.props.cookies.set(COOKIE_ARTICLES_ENGLISH_ONLY_ACCEPTED_NAME, JSON.stringify(true), {path: "/"})

  renderArticleItem = (article, masonryClassName, d) => (
    <CArticleMasonryItem
      //
      {...d}
      key={`masonry-item-${article.slug}`}
      isInMasonry={USE_MASONRY_INSTEAD_OF_GRID}
      intlLocale={this.props.intlLocale}
      masonryClassName={masonryClassName}
      dateFormat={this.props.intl.formatMessage({id: `KnowledgeBaseArticleDateFormat`})}
      readMoreButtonLabel={this.props.readMoreButtonLabel}
      {...article}
    />
  )

  render = () => {
    const {searchQuery} = this.state
    const {defaultThemeProps, mediaQueryClass, intl, intlLocale, postData, wordPressUserId, editInWordPressProps, articlesEnglishOnlyAccepted, articles} = this.props
    const d = {debug: DEBUG}
    const themeOverrides = {}
    const masonryClassName = "knowledgebase-articles" // see _masonry.scss
    const rowProps = {width: "100%", height: "100%", gutter: 0}

    let resultsHeaderText = god(postData, "sectionResults.headerNoSearchQuery")
    let resultsSubHeaderText = ""
    let showAllArticlesButton = false
    if (!!this.props.searchQuery) {
      if (!!articles && !!articles.length) {
        resultsHeaderText = god(postData, "sectionResults.headerWithSearchQuery", "{query}").split("{query}").join(this.props.searchQuery)
        if (articles.length == 1) {
          resultsSubHeaderText = god(postData, "sectionResults.amountResultsSingular")
        } else {
          resultsSubHeaderText = god(postData, "sectionResults.amountResultsPlural", "{amount}").split("{amount}").join(articles.length)
        }
      } else {
        resultsHeaderText = god(postData, "sectionResults.headerNoResults")
        resultsSubHeaderText = god(postData, "sectionResults.amountResultsNone")
        showAllArticlesButton = true
      }
    }

    const searchIconsScale = selectors.getMediaQueryMatching(mediaQueryClass, "<=", "md") ? 0.8 : 1
    debby("render()", {mediaQueryClass, wordPressUserId, postData, searchQuery, searchIconsScale})

    return (
      <ThemeProvider theme={{...defaultThemeProps, ...themeOverrides}}>
        <CSpacer {...d} variant={`headermenu`} />
        <ContentContainer {...d}>
          <PaddedContainer {...d} variant={`header`}>
            <HeaderContainer {...d}>
              <CH2 text={god(postData, "title")} variant={"knowledgebase"} />
            </HeaderContainer>
            <SubHeaderText {...d}>{god(postData, "sectionTop.subheaderText")}</SubHeaderText>

            <SearchQueryInputContainer {...d}>
              <SearchQueryFloatingInputContainer {...d}>
                <InputContainerHorizontal {...d}>
                  <InputIconSearch {...d}>
                    <CIcon id={`KnowledgeBaseSearchMagnifyer`} color={CONFIG.layout.colors.siccoro} scale={searchIconsScale} />
                  </InputIconSearch>
                  <InputLabelContainer>
                    <SearchInput
                      //
                      {...d}
                      ref={obj => (this.inputNode = obj)}
                      onChange={this.onSearchQueryChange}
                      value={searchQuery}
                      placeholder={god(postData, "sectionSearch.inputPlaceholder")}
                    />
                  </InputLabelContainer>
                  <InputIconClear {...d} {...(!!this.state.searchQuery ? {onClick: this.clearSearchInput} : {})}>
                    {!!this.state.searchQuery && <CIcon id={`KnowledgeBaseSearchClose`} scale={searchIconsScale} color={CONFIG.layout.colors.siccoro} />}
                  </InputIconClear>
                </InputContainerHorizontal>
              </SearchQueryFloatingInputContainer>
              {false && (
                <div>
                  <CButton
                    //
                    {...d}
                    label={god(postData, "sectionSearch.buttonLabel")}
                    onClick={this.submitForm}
                    canBeBusy
                    busy={__DEV__}
                    // stretched
                    disabled={!searchQuery.trim()}
                    // busy={d.debug || subscribeRequestBusy}
                    // busy={subscribeRequestBusy}
                    // themeKey={"transparent-light"}
                    // size={"small"}
                    noHoverScale // weirdly enough, there is some hiddden overflow somewhere
                  />
                </div>
              )}
            </SearchQueryInputContainer>

            {intlLocale != "en" && intlLocale != "de" && !articlesEnglishOnlyAccepted && <CArticlesOnlyEnglish onCloseClick={this.onCloseArticlesOnlyEnglishClick} />}

            <ResultsHeaderContainer {...d}>
              <CH3 noUpperCase text={resultsHeaderText} />
            </ResultsHeaderContainer>

            {!!resultsSubHeaderText && <ResultsSubHeader {...d}>{resultsSubHeaderText}</ResultsSubHeader>}

            {showAllArticlesButton && (
              <ShowAllArticlesContainer {...d} onClick={this.clearSearchInput}>
                <CIcon id={"AllKbArticlesCaretLeft"} />
                <ShowAllArticlesButtonLabel {...d}>{intl.formatMessage({id: `KnowledgeBaseAllTutorialsButtonLabel`})}</ShowAllArticlesButtonLabel>
              </ShowAllArticlesContainer>
            )}

            {!!articles && !!articles.length && (
              <ArticlesContainer {...d}>
                <MasonryContainerNegativeMargin>
                  {USE_MASONRY_INSTEAD_OF_GRID && (
                    <Masonry
                      // ref={obj => (this.masonryRef = obj)}
                      elementType={"div"}
                      options={{
                        // transitionDuration: 500,
                        transitionDuration: 0,
                        percentPosition: true,
                        columnWidth: `.grid-sizer-${masonryClassName}`,
                        itemSelector: `.grid-item-${masonryClassName}`,
                      }}
                      disableImagesLoaded={false} // default false
                      updateOnEachImageLoad={true} // default false and works only if disableImagesLoaded is false
                    >
                      <div className={`grid-sizer-${masonryClassName}`} />
                      {articles.map(article => this.renderArticleItem(article, masonryClassName, d))}
                    </Masonry>
                  )}
                  {!USE_MASONRY_INSTEAD_OF_GRID && (
                    <Container>
                      <Row>
                        {articles.map(article => (
                          <Col key={`article_${article.slug}`} xl={4} lg={4} md={6} sm={6} xs={12}>
                            {this.renderArticleItem(article, masonryClassName, d)}
                          </Col>
                        ))}
                      </Row>
                    </Container>
                  )}
                </MasonryContainerNegativeMargin>
              </ArticlesContainer>
            )}

            <CAdvancedSupport
              //
              headerText={god(postData, "sectionAdvancedSupport.headerText")}
              subheaderText={god(postData, "sectionAdvancedSupport.subheaderText")}
              openTicketButtonLabel={god(postData, "sectionAdvancedSupport.openTicketButtonLabel")}
              openTicketEmail={god(postData, "sectionAdvancedSupport.openTicketEmail")}
            />

            {/* App Download, Newsletter */}
            <Container style={{width: "100%", marginBottom: 60}}>
              <Row {...rowProps}>
                <Col xl={5}>
                  <AppDownloadContainer {...d}>
                    <CExhibitorsAppDownload
                      //
                      {...d}
                      {...god(postData, "sectionAppDownload", {})}
                      intlLocale={intlLocale}
                      contactButtonLabel={god(postData, "sectionDownloadTop.contactLabel")}
                      noPaddingHorizontal
                      noPaddingVertical
                      noBackgroundColor
                    />
                  </AppDownloadContainer>
                </Col>
                <Col xl={7}>
                  <NewsletterContainer {...d}>
                    <CExhibitorsNewsletter {...d} {...god(postData, "sectionNewsletter", {})} />
                  </NewsletterContainer>
                </Col>
              </Row>
            </Container>
          </PaddedContainer>

          <CFooter variant={"umbrella"} />

          <CEditInWordPress fixed {...editInWordPressProps} />

          {d.debug && (
            <div style={{position: "fixed", pointerEvents: "none", fontSize: 9, left: 0, bottom: 0, zIndex: 20000, color: "blue", backgroundColor: "rgba(255,255,255,0.5)", padding: 10}}>
              <div>SKnowledgeBase_web.js</div>
              <div>this.state.searchQuery: {this.state.searchQuery}</div>
              <div>this.props.searchQuery: {this.props.searchQuery}</div>
              <div>intlLocale: {intlLocale}</div>
              <div>wordPressUserId: {wordPressUserId}</div>
              <div>articlesEnglishOnlyAccepted: {JSON.stringify(articlesEnglishOnlyAccepted)}</div>
              <div>
                editInWordPressProps: <pre>{JSON.stringify(editInWordPressProps, null, 2)}</pre>
              </div>
            </div>
          )}
        </ContentContainer>
      </ThemeProvider>
    )
  }
}

const ContentContainer = styled.div`
  position: relative;
  overflow-x: hidden; // because of huge images
  background-color: ${props => props.theme.backgroundColor};
  transition: all ${props => props.theme.vars.transitions.themeTransitionDuration}s linear;
`

const PaddedContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  transition: all ${props => props.theme.vars.transitions.themeTransitionDuration}s linear;
  ${props => selectors.getPaddingLeftRightByThemeAndVariant(props.theme, props.variant, "padding")}
  ${props => true && props.debug && selectors.getDebugOverlayCss(props, `PaddedContainer`, `rgba(0,0,255,0.15)`)}
  text-align: ${props => (props.align == "center" ? "center" : "left")};
`

const HeaderContainer = styled.div`
  ${props => true && props.debug && selectors.getDebugOverlayCss(props, `HeaderContainer`, `rgba(0,255,0,0.35)`)};
  padding-bottom: 15px;

  padding-top: 180px;
  ${props => css`
    ${props.theme.media.mddown} {
      padding-top: 120px;
    }
  `}
`

const SubHeaderText = styled.div`
  font-weight: ${props => props.theme.vars.fonts.weights.regular};
  font-family: ${props => props.theme.vars.fonts.families.default};
  color: ${CONFIG.layout.colors.codGray};

  letter-spacing: 2.5px;
  text-align: center;
  line-height: 1.6;

  max-width: 1000px;
  margin: 0 auto;

  ${props => true && props.debug && selectors.getDebugOverlayCss(props, `SubHeaderText`, `rgba(255,0,0,0.35)`)};

  font-size: 26px;
  padding: 50px 0px;
  ${props => css`
    ${props.theme.media.mddown} {
      font-size: 24px;
      padding: 30px 0px;
    }
    ${props.theme.media.xs} {
      font-size: 22px;
    }
  `}
`

const SearchQueryInputContainer = styled.div`
  padding: 40px 120px;
  display: flex;
  flex-direction: row;
  ${props => true && props.debug && selectors.getDebugOverlayCss(props, `SearchQueryInputContainer`, `rgba(0,0,255,0.35)`)}

  ${props => css`
    ${props.theme.media.mddown} {
      padding: 40px 0px;
    }
  `}
`

const SearchQueryFloatingInputContainer = styled.div`
  flex: 1;
`

const InputContainerHorizontal = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid black;
  ${props => true && props.debug && selectors.getDebugOverlayCss(props, `InputContainerHorizontal`, `rgba(0,255,255,0.35)`)}
`

const searchIconsSize = 50
const searchIconsSizeSmall = 40
const InputIconSearch = styled.div`
  width: ${searchIconsSize}px;
  height: ${searchIconsSize}px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  border: ${props => (props.debug ? 1 : 0)}px solid blue;
  ${props => css`
    ${props.theme.media.mddown} {
      justify-content: flex-start;
      width: ${searchIconsSizeSmall}px;
      height: ${searchIconsSizeSmall}px;
      margin-right: 0px;
    }
    ${props.theme.media.xs} {
      width: 30px;
    }
  `}
`
const InputLabelContainer = styled.div`
  flex: 1;
  // border: 1px solid black;
`
const SearchInput = styled.input`
  font-family: ${props => props.theme.vars.fonts.families.default};
  border: 0;

  width: 100%;
  height: 100%;

  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  outline: none;
  padding-top: 0px;
  padding-bottom: 0px;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px ${props => props.theme.backgroundColor} inset !important;
    -webkit-text-fill-color: ${props => props.theme.textColor};
  }

  font-size: 23px;
  letter-spacing: 1px;
  ${props => css`
    ${props.theme.media.smdown} {
      font-size: 15px;
      letter-spacing: 0.8px;
    }
  `}
`

const InputIconClear = styled.div`
  width: ${searchIconsSize}px;
  height: ${searchIconsSize}px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 10px;
  border: ${props => (props.debug ? 1 : 0)}px solid blue;

  ${props => css`
    ${props.theme.media.mddown} {
      width: ${searchIconsSizeSmall}px;
      height: ${searchIconsSizeSmall}px;
      margin-left: 0px;
    }
    ${props.theme.media.xs} {
      width: 30px;
    }
  `}
`

const MasonryContainerNegativeMargin = styled.div`
  position: relative;
  display: block;
  margin-left: -15px;
  margin-right: -15px;
`

const ResultsSubHeader = styled.div`
  font-size: 16px;
  color: #1e1e1e;
  letter-spacing: 1.6px;
  text-align: left;
  line-height: 27px;
`

const ResultsHeaderContainer = styled.div`
  padding-top: 30px;
  padding-bottom: 40px;
`

const ShowAllArticlesContainer = styled.div`
  display: flex;
  width: auto;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  padding: 10px 0px;
  // margin-top: 30px;
  ${props => true && props.debug && selectors.getDebugOverlayCss(props, `ShowAllArticlesContainer`, `rgba(0,0,255,0.15)`)}
`

const ShowAllArticlesButtonLabel = styled.div`
  font-size: 20px;
  color: #1e1e1e;
  letter-spacing: 2.5px;
  text-align: left;
  line-height: 27px;
  // font-weight: ${props => props.theme.vars.fonts.weights.bold};
  padding-left: 10px;
`

const ArticlesContainer = styled.div`
  // padding: 20px 0px;
  ${props => true && props.debug && selectors.getDebugOverlayCss(props, `ArticlesContainer`, `rgba(0,255,255,0.35)`)}
`

const AppDownloadContainer = styled.div`
  padding: 60px 0px;
  border: ${props => (props.debug ? 3 : 0)}px solid blue;
  ${props => true && props.debug && selectors.getDebugOverlayCss(props, `AppDownloadContainer`, `rgba(255,0,255,0.15)`)}
`

const NewsletterContainer = styled.div`
  padding: 60px 0px;
  border: ${props => (props.debug ? 3 : 0)}px solid yellow;
  ${props => true && props.debug && selectors.getDebugOverlayCss(props, `NewsletterContainer`, `rgba(255,0,255,0.15)`)}// padding: 30px 0px 80px 0px;
`

const getSearchQuery = createSelector([selectors.getQueryProps], queryProps => {
  const searchQuery = god(queryProps, "s", "")
  return searchQuery
})

const getFilteredKnowledgeBaseArticles = createSelector([selectors.getKnowledgeBaseArticles, getSearchQuery], (articles, searchQuery) => {
  if (!articles || !articles.length) {
    return null
  }
  if (!searchQuery || !searchQuery.trim()) {
    return articles
  }
  const searchQueryWords = searchQuery.split(" ").filter(word => !!word && !!word.trim).map(word => word.toLowerCase()) // prettier-ignore
  const articlesWithMatchPoints = articles
    .map(article => {
      const titleLower = article.title.toLowerCase()
      const contentLower = article.content.toLowerCase()
      const phraseFoundInTitle = titleLower.indexOf(searchQueryWords.join(" ")) != -1
      const phraseFoundInContent = titleLower.indexOf(searchQueryWords.join(" ")) != -1
      const wordsFoundInTitle = searchQueryWords.filter(wordLower => titleLower.indexOf(wordLower) != -1)
      const wordsFoundInContent = searchQueryWords.filter(wordLower => contentLower.indexOf(wordLower) != -1)
      const matchPoints = (phraseFoundInTitle ? 20 : 0) + (phraseFoundInContent ? 10 : 0) + (wordsFoundInTitle.length / searchQueryWords.length) * 10 + (wordsFoundInContent.length / searchQueryWords.length) * 5
      return {
        _matchPoints: matchPoints,
        ...article,
      }
    })
    .filter(article => !!article._matchPoints)
    .sort((a, b) => (a._matchPoints > b._matchPoints ? -1 : a._matchPoints < b._matchPoints ? 1 : 0))
  return articlesWithMatchPoints
})

const getShowingAllArticles = createSelector([selectors.getKnowledgeBaseArticles, getFilteredKnowledgeBaseArticles], (articles, articlesFiltered) => {
  if (!articles || !articles.length) {
    return false
  }
  return articles.length != articlesFiltered
})

const getReadMoreButtonLabel = createSelector([selectors.getPostData], postData => god(postData, "sectionResults.readMoreButtonLabel", ""))

const mapStateToProps = (state, props) => ({
  sitemapData: selectors.getSitemapData(state),
  articlesEnglishOnlyAccepted: god(props.cookies, `cookies.${COOKIE_ARTICLES_ENGLISH_ONLY_ACCEPTED_NAME}`, "false") == "true", // message on top
  // articles: getKnowledgeBaseArticles(state, props), // all
  articles: getFilteredKnowledgeBaseArticles(state, props), // filtered by search query
  showingAllArticles: getShowingAllArticles(state, props),
  // query
  queryProps: selectors.getQueryProps(null, props),
  searchQuery: getSearchQuery(null, props),
  // postData
  readMoreButtonLabel: getReadMoreButtonLabel(state, props),
})
export default withCookies(withController(withScreenProps(connect(mapStateToProps)(SKnowledgeBase))))
