import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import {injectIntl} from "react-intl"
import {connect} from "react-redux"
import styled, {css, ThemeProvider} from "styled-components"
import {Row, Col} from "react-grid-system"
import {Element as ScrollElement, scroller} from "react-scroll"
import {push} from "connected-react-router"

// import {CONFIG} from "../../config"
import * as actions from "../../data/actions"
import * as selectors from "../../data/selectors"
const god = selectors.getObjectDeep

import {getCountryCodes} from "../../lang/langsDynamic"

import CFloatingInput from "../../view/components/CFloatingInput"
import CRecaptcha from "../../view/components/CRecaptcha"
import CButton from "../../view/components/CButton"
import CCollapse from "../../view/components/CCollapse"
import CPerson from "../../view/components/CPerson"

import CH2 from "../../view/components/text/CH2"
import CSmallprint from "../../view/components/text/CSmallprint"

import {debbify} from "../../data/selectors/helpers"
const debby = (...args) => debbify("CGetInTouch", ...args)

const DEBUG_DEFAULT = false && __DEV__ // because of injectIntl

let INIT_STATE = {
  vName: "",
  vWebsite: "",
  vEmail: "",
  vOrg: "",
  vOrgType: "",
  vPhone: "",
  vCountry: "",
  vMessage: "",
}

// eslint-disable-next-line
if (__DEV__ && false) {
  INIT_STATE.vName = "Stephan Müller"
  INIT_STATE.vWebsite = "https://nerdybirdy.net"
  INIT_STATE.vEmail = "steff@nerdybirdy.net"
  INIT_STATE.vOrg = "nerdybirdy"
  INIT_STATE.vOrgType = "museum"
  INIT_STATE.vPhone = "+34692050996"
  INIT_STATE.vCountry = "ES"
  INIT_STATE.vMessage = "Hey guys. \nJust checkin' if this works!"
  INIT_STATE.vReason = "own-app"
  INIT_STATE.formRecaptchaValid = true
}

const getThemeOverrides = themeKey => {
  let themeOverrides = {}
  if (themeKey === "dark") {
    themeOverrides = {
      ...themeOverrides,
      textColor: "white",
    }
  }
  return themeOverrides
}

let codeDataHandledOnce = false

class CGetInTouch extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,

    pageTemplate: PropTypes.string.isRequired,
    header: PropTypes.string,
    button: PropTypes.string,
    smallprint: PropTypes.string,
    teamMember: PropTypes.shape({
      person: PropTypes.object,
      showEmail: PropTypes.bool,
    }),

    themeKey: PropTypes.string,
    defaultThemeProps: PropTypes.object,
    orgTypes: PropTypes.array,
    reasons: PropTypes.array,
    onLinkPress: PropTypes.func,
    openPrivacyPolicyInNewWindow: PropTypes.bool,
    hasHeader: PropTypes.bool,

    initReason: PropTypes.string,

    //  connect
    contactRequestBusy: PropTypes.bool,
    referrerCode: PropTypes.string,
    codeData: PropTypes.object,
  }
  static defaultProps = {
    debug: DEBUG_DEFAULT,
    hasHeader: true,
    defaultThemeProps: {
      textColor: "black",
      fontSize: 16,
    },
    //  prettier-ignore
    orgTypes: [
      {key: "art-gallery",          intlId: `ContactOrgTypeArtGallery`},
      {key: "art-fair",             intlId: `ContactOrgTypeArtFair`}, // new
      {key: "artist",               intlId: `ContactOrgTypeArtist`},
      {key: "collector",            intlId: `ContactOrgTypeCollector`}, // new
      {key: "museum",               intlId: `ContactOrgTypeMuseum`}, // new
      {key: "corporate-collection", intlId: `ContactOrgTypeCorporateCollection`}, // new
      {key: "press",                intlId: `ContactOrgTypePress`}, // new
      {key: "other",                intlId: `ContactOrgTypeOther`},
    ],
    //  prettier-ignore
    reasons: [
      {key: "schedule-demo",        intlId: `ContactReasonScheduleDemo`},
      // {key: "free-trial",           intlId: `ContactReasonFreeTrial`}, // removed by request from Ro, Jan 16 2023
      {key: "pricing-request",      intlId: `ContactReasonPricingRequest`},
      {key: "own-app",              intlId: `ContactReasonOwnApp`},
      {key: "other",                intlId: `ContactReasonOther`},
    ],
    openPrivacyPolicyInNewWindow: true,
  }

  constructor(props) {
    super(props)
    this.state = {
      formValid: false,
      formRecaptchaValid: false,
      formSubmittedOnce: false,
      formRequestedOnce: false,
      formRequestResult: null,
      ...INIT_STATE,
      vReason: props.initReason,
      subtitleIntlId: props.initReason == "free-trial" ? "NoCreditCardDetailsRequired" : null,
    }
  }

  componentDidMount = () => {
    this.timeoutIdValidateForm = setTimeout(this.validateForm, 500)
    this.handleProps(this.props, true)
  }

  handleProps = nextProps => {
    if (!!nextProps.referrerCode && !!nextProps.codeData && !!nextProps.codeData.formPrefilledMessage && !codeDataHandledOnce) {
      codeDataHandledOnce = true
      this.setState({vMessage: nextProps.codeData.formPrefilledMessage})
    }
  }

  componentWillUnmount = () => {
    clearTimeout(this.timeoutIdValidateForm)
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  onPrivacyPolicyLinkPress = hrefProps => {
    const {openPrivacyPolicyInNewWindow, onLinkPress} = this.props
    !!onLinkPress && onLinkPress(hrefProps)
    if (!!hrefProps.linkTo && !openPrivacyPolicyInNewWindow) {
      this.props.dispatch(push(hrefProps.linkTo))
    } else {
      window.open(hrefProps.href)
    }
  }

  getCountryOptions = () =>
    getCountryCodes().map(code => ({
      label: this.props.intl.formatMessage({id: `Country${code.toUpperCase()}`}),
      value: code,
    }))

  getOrgTypeOptions = () =>
    this.props.orgTypes.map(orgType => ({
      label: this.props.intl.formatMessage({id: orgType.intlId}),
      value: orgType.key,
    }))

  getReasonOptions = () =>
    this.props.reasons.map(reason => ({
      label: this.props.intl.formatMessage({id: reason.intlId}),
      value: reason.key,
    }))

  onInputChange = (value, name) => {
    const stateKey = `v${selectors.getFirstCapital(name, false)}` // e.g. "vOrgType"
    this.setState({[stateKey]: value})
    clearTimeout(this.validateFormTimeoutId)
    this.validateFormTimeoutId = setTimeout(this.validateForm, 500)
  }

  validateInput = (value, name) => {
    switch (name) {
      case "name": {
        return !!(value || "").trim()
      }
      case "email": {
        return !!value && selectors.getEmailIsValid(value)
      }
    }
    return true
  }

  validateForm = (doActions = false) => {
    const nameValid = this.validateInput(this.state.vName, "name")
    const emailValid = this.validateInput(this.state.vEmail, "email")
    const formValid = nameValid && emailValid
    this.setState({formValid})

    if (!formValid && doActions) {
      let elemName = null
      if (!nameValid) {
        elemName = "inputNameGetInTouch"
      } else if (!emailValid) {
        elemName = "inputEmailGetInTouch"
      } else if (!this.state.formRecaptchaValid) {
        elemName = "inputRecaptchaGetInTouch"
      }
      if (!!elemName) {
        setTimeout(() => {
          scroller.scrollTo(elemName, {duration: 1000, delay: 100, smooth: "easeInOutQuint", offset: -80}) // menu height
        }, 200)
      }
    }
    return formValid
  }

  onRecaptchaValidChange = valid => this.setState({formRecaptchaValid: valid})

  submitForm = event => {
    !!event && event.preventDefault()
    const {formRecaptchaValid} = this.state
    const {dispatch, intl} = this.props

    this.setState({formSubmittedOnce: true, formRequestedOnce: true}, () => {
      const formValid = this.validateForm(true) // show errors and scroll
      if (!formValid || !formRecaptchaValid) {
        return
      }
      const fields = {
        lang: intl.locale,
        name: this.state.vName,
        email: this.state.vEmail,
        org: this.state.vOrg,
        orgType: this.state.vOrgType,
        website: this.state.vWebsite,
        phone: this.state.vPhone,
        country: this.state.vCountry,
        message: this.state.vMessage,
        reason: this.state.vReason,
        referrerCode: this.props.referrerCode,
      }
      dispatch(actions.sendContactRequest(fields)).then(({success, error, errorIntlId}) => {
        this.setState({formRequestResult: {success, error, errorIntlId}})
      })
    })
  }

  render = () => {
    const {vName, vWebsite, vEmail, vOrg, vPhone, vCountry, vMessage, vOrgType, vReason, subtitleIntlId} = this.state
    const {formSubmittedOnce, formValid, formRecaptchaValid} = this.state // client-side validation
    const {formRequestedOnce, formRequestResult} = this.state // server result
    const {debug, intl, themeKey, defaultThemeProps, header, smallprint, button, contactRequestBusy, hasHeader, teamMember} = this.props
    const d = {debug: debug || DEBUG_DEFAULT}

    const countryOptions = this.getCountryOptions()
    const orgTypeOptions = this.getOrgTypeOptions()
    const reasonOptions = this.getReasonOptions()

    const formRequestSuccess = god(formRequestResult, "success", false)
    const formErrorIntlIdDefault = "FormErrorGeneric"
    const formRequestErrorIntlId = god(formRequestResult, "errorIntlId", formErrorIntlIdDefault)
    const showResult = formSubmittedOnce && !contactRequestBusy && formRequestedOnce && !!formRequestResult
    const inputsDisabledAfterSubmit = showResult && formRequestSuccess
    const disabled = contactRequestBusy || inputsDisabledAfterSubmit

    const paddingVariant = !!teamMember ? "header" : "content" // horizontal page padding
    const inputProps = {
      disabled,
      onChange: this.onInputChange,
      validateInput: this.validateInput,
    }
    const itemProps = {
      hasTeamMemberBefore: !!teamMember,
    }
    const person = god(teamMember, "person", {})
    const rowProps = {
      type: "flex",
      // align: "start", // vertical
      align: "stretch", // vertical
      // align: "center", // vertical

      // justify: "center", // horizontal
      justify: "start", // horizontal
      width: "100%",
      height: "100%",
      gutter: 0,
    }

    debby("render()", {vMessage})
    return (
      <ThemeProvider theme={{...defaultThemeProps, ...getThemeOverrides(themeKey)}}>
        <PaddedContainer {...d} variant={paddingVariant}>
          {hasHeader && (
            <HeaderContainer {...d}>
              <CH2 {...d} text={header} />
              {!!subtitleIntlId && <SubTitle>{intl.formatMessage({id: subtitleIntlId})}</SubTitle>}
            </HeaderContainer>
          )}
        </PaddedContainer>
        <PaddedContainer {...d} variant={paddingVariant}>
          {!!teamMember && (
            <ContentTeamMember {...d}>
              <PersonContainer {...d}>
                <CPerson {...d} {...person} lineBelowImage={teamMember.lineBeforeForm} />
              </PersonContainer>
            </ContentTeamMember>
          )}

          <ContentForm {...d} name={"get-in-touch"} autocomplete={"off"}>
            <StyledRow {...rowProps}>
              <StyledCol md={6}>
                <ScrollElement name={"inputNameGetInTouch"} />
                <Item {...d} {...itemProps}>
                  <CFloatingInput
                    //
                    name={"name"}
                    value={vName}
                    required
                    {...inputProps}
                    placeholder={intl.formatMessage({id: `ContactKeyName`})}
                    errorText={intl.formatMessage({id: `FormErrorInvalidName`})}
                    showError={formSubmittedOnce}
                  />
                </Item>
              </StyledCol>

              <StyledCol md={6}>
                <ScrollElement name={"inputEmailGetInTouch"} />
                <Item {...d} {...itemProps}>
                  <CFloatingInput
                    //
                    name={"email"}
                    value={vEmail}
                    type={"email"}
                    required
                    {...inputProps}
                    placeholder={intl.formatMessage({id: `ContactKeyEmail`})}
                    errorText={intl.formatMessage({id: `FormErrorInvalidEmail`})}
                    showError={formSubmittedOnce}
                  />
                </Item>
              </StyledCol>

              <StyledCol md={6}>
                <Item {...d} {...itemProps}>
                  <CFloatingInput
                    //
                    name={"org"}
                    value={vOrg}
                    {...inputProps}
                    placeholder={intl.formatMessage({id: `ContactKeyOrg`})}
                  />
                </Item>
              </StyledCol>

              <StyledCol md={6}>
                <Item {...d} {...itemProps}>
                  <CFloatingInput
                    //
                    name={"orgType"}
                    value={vOrgType}
                    options={orgTypeOptions}
                    {...inputProps}
                    placeholder={intl.formatMessage({id: `ContactKeyOrgType`})}
                    optionsNothingFoundText={intl.formatMessage({id: `NothingFound`})}
                    alwaysShowAllOptions
                    disableAutocomplete
                  />
                </Item>
              </StyledCol>

              <StyledCol md={6}>
                <Item {...d} {...itemProps}>
                  <CFloatingInput
                    //
                    name={"website"}
                    value={vWebsite}
                    type={"url"}
                    {...inputProps}
                    placeholder={intl.formatMessage({id: `ContactKeyWebsite`})}
                  />
                </Item>
              </StyledCol>

              <StyledCol md={6}>
                <Item {...d} {...itemProps}>
                  <CFloatingInput
                    //
                    name={"phone"}
                    value={vPhone}
                    type={"tel"}
                    {...inputProps}
                    placeholder={intl.formatMessage({id: `ContactKeyPhone`})}
                  />
                </Item>
              </StyledCol>

              <StyledCol md={6}>
                <Item {...d} {...itemProps}>
                  <CFloatingInput
                    //
                    name={"country"}
                    value={vCountry}
                    options={countryOptions}
                    {...inputProps}
                    placeholder={intl.formatMessage({id: `ContactKeyCountry`})}
                    optionsNothingFoundText={intl.formatMessage({id: `NothingFound`})}
                  />
                </Item>
              </StyledCol>

              <StyledCol md={6}>
                <Item {...d} {...itemProps}>
                  <CFloatingInput
                    //
                    name={"reason"}
                    value={vReason}
                    options={reasonOptions}
                    {...inputProps}
                    placeholder={intl.formatMessage({id: `ContactKeyReason`})}
                    optionsNothingFoundText={intl.formatMessage({id: `NothingFound`})}
                    alwaysShowAllOptions
                    disableAutocomplete
                  />
                </Item>
              </StyledCol>
            </StyledRow>

            <StyledRow {...rowProps} justify={"center"}>
              <StyledCol lg={9} md={12}>
                <Item {...d} {...itemProps}>
                  <CFloatingInput
                    //
                    name={"message"}
                    value={vMessage}
                    {...inputProps}
                    placeholder={intl.formatMessage({id: `ContactKeyMessage`})}
                    multiline
                    minRows={1}
                  />
                </Item>
              </StyledCol>

              <StyledCol lg={9} md={12}>
                <Item {...d} {...itemProps}>
                  <CSmallprint
                    //
                    {...d}
                    text={smallprint}
                    onLinkPress={this.onPrivacyPolicyLinkPress}
                  />
                </Item>
              </StyledCol>

              <StyledCol lg={9} md={12}>
                <CCollapse collapsed={!showResult}>
                  <Item {...d} {...itemProps}>
                    {!formRequestSuccess && <RequestResult dangerouslySetInnerHTML={{__html: intl.formatMessage({id: formRequestErrorIntlId})}} />}
                    {formRequestSuccess && <RequestResult dangerouslySetInnerHTML={{__html: intl.formatMessage({id: `ContactFormSubmitSuccess`})}} />}
                  </Item>
                </CCollapse>
              </StyledCol>

              <StyledCol lg={9} md={12}>
                <ScrollElement name={"inputRecaptchaGetInTouch"} />
                <Item {...d} {...itemProps}>
                  <SubmitContainer
                    //
                    {...d}
                    //  "content": only when no team member
                    hasTeamMemberBefore={!!teamMember}
                  >
                    <SubmitRecaptchaContainer {...d}>
                      <CRecaptcha
                        //
                        onValidChange={this.onRecaptchaValidChange}
                        disabled={!formValid || contactRequestBusy || inputsDisabledAfterSubmit}
                        showValitidyDebug={__DEV__ && debug}
                      />
                      <CFloatingInput.LabelError
                        //
                        shown={formSubmittedOnce && !formRecaptchaValid && formValid}
                        text={intl.formatMessage({id: `FormErrorInvalidCaptcha`})}
                      />
                    </SubmitRecaptchaContainer>
                    <SubmitButtonContainer {...d}>
                      <CButton
                        //
                        {...d}
                        label={button}
                        disabled={contactRequestBusy || inputsDisabledAfterSubmit}
                        onClick={this.submitForm}
                        variant={!teamMember ? `right-aligned` : `centered`}
                        canBeBusy
                        busy={debug || contactRequestBusy}
                      />
                    </SubmitButtonContainer>
                  </SubmitContainer>
                </Item>
              </StyledCol>
            </StyledRow>
            <input type="submit" hidden onClick={this.submitForm} />
          </ContentForm>
        </PaddedContainer>
      </ThemeProvider>
    )
  }
}

const PaddedContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${props => selectors.getPaddingLeftRightByThemeAndVariant(props.theme, props.variant)}

  flex-direction: row;
  ${props =>
    css`
      ${props.theme.media.mddown} {
        flex-direction: column;
      }
    `}

  ${props => props.debug && selectors.getDebugOverlayCss(props, "CGetInTouch.PaddedContainer", "rgba(0,0,255,0.35)")}
  transition: all ${props => props.theme.vars.transitions.themeTransitionDuration}s linear;
`

const ContentTeamMember = styled.div`
  align-self: stretch;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  ${props => props.debug && selectors.getDebugOverlayCss(props, "ContentTeamMember", "rgba(0,255,255,0.35)")}
`

const PersonContainer = styled.div`
  max-width: 300px; // image size
`

const ContentForm = styled.form`
  flex: 1;
  max-width: 800px;
  ${props => selectors.getDebugOverlayCss(props, "ContentForm", "rgba(0,255,0,0.35)")}
`

const HeaderContainer = styled.div`
  ${props => props.debug && selectors.getDebugOverlayCss(props, "HeaderContainer", "rgba(0,255,255,0.35)")}
  color: ${props => props.theme.textColor};
  transition: all ${props => props.theme.vars.transitions.themeTransitionDuration}s linear;
  text-align: center;
  padding-bottom: 50px;
`

const SubTitle = styled.div`
  letter-spacing: 0.1em;
  padding-top: 10px;
  font-size: 16px;

  ${props =>
    css`
      ${props.theme.media.md} {
        font-size: 15px;
      }
      ${props.theme.media.sm} {
        font-size: 14px;
      }
      ${props.theme.media.xs} {
        font-size: 12px;
      }
    `}
`

const StyledRow = styled(Row)`
  // opacity: 0.2;
`

const StyledCol = styled(Col)`
  align-items: stretch;
  justify-content: center;
`

const Item = styled.div`
  text-align: center;

  padding-left: 23px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 13px;

  ${props =>
    css`
      ${props.theme.media.md} {
        padding-top: 20px;
        padding-bottom: 20px;
      }
      ${props.theme.media.sm} {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    `}

  ${props =>
    props.hasTeamMemberBefore &&
    css`
      ${props.theme.media.md} {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    `}

  ${props => selectors.getDebugOverlayCss(props, "Item", "rgba(255,255,0,0.35)")}
`

const SubmitContainer = styled.div`
  display: flex;
  flex-direction: row;

  ${props =>
    css`
      ${props.theme.media.xs} {
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    `}

  ${props =>
    props.hasTeamMemberBefore &&
    css`
      flex-direction: column;
      align-items: center;
      justify-content: center;
    `}

  ${props => props.debug && selectors.getDebugOverlayCss(props, "SubmitContainer", "rgba(0,0,255,0.15)")}
`
const SubmitRecaptchaContainer = styled.div`
  padding-bottom: 15px;
`
const SubmitButtonContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-start; // vertical

  align-self: stretch;
  padding-left: 15px;
  ${props =>
    css`
      ${props.theme.media.xs} {
        padding-top: 20px;
        padding-left: 0px;
        align-self: center;
      }
    `}
  ${props => props.debug && selectors.getDebugOverlayCss(props, "SubmitButtonContainer", "rgba(0,255,255,0.15)")}

  padding-bottom: 15px;
`

//  TODO: Unify with CHomeWhitepaper
const RequestResult = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-weight: ${props => props.theme.vars.fonts.weights.regular};
  b,
  > b,
  strong,
  > strong {
    font-weight: ${props => props.theme.vars.fonts.weights.bold};
  }
`

const mapStateToProps = (state, props) => ({
  contactRequestBusy: selectors.getContactRequestBusy(state), // one of all in session
  referrerCode: state.session.referrerCode,
  codeData: selectors.getReferrerPopupDataLocalized(state, props),
})
export default injectIntl(connect(mapStateToProps)(CGetInTouch))
