import React, {Component, Fragment} from "react"
import PropTypes from "prop-types"
import {connect} from "react-redux"
import {createSelector} from "reselect"
import isEqual from "lodash/isEqual"
import styled from "styled-components"
import moment from "moment"

// import {CONFIG} from "../../../config"
import * as actions from "../../../data/actions"
import * as selectors from "../../../data/selectors"
import {getObjectDeep as god} from "../../../data/selectors/helpers"
import withScreenProps from "../../../data/hocs/withScreenProps"

import CSpacer from "../../../view/components/CSpacer"
import CFooter from "../../../view/components/CFooter"
import CButton from "../../../view/components/CButton"
import CHeaderEmbeddedMode from "../../../view/components/CHeaderEmbeddedMode"
import CNoContent from "../../../view/components/CNoContent"

import CSectionGalleryBasics from "./CSectionGalleryBasics"
import CSectionGalleryPublicWalls from "./CSectionGalleryPublicWalls"
import CSectionGalleryArtworks from "./CSectionGalleryArtworks"
import CSectionGalleryDemo from "./CSectionGalleryDemo"
import CSectionGalleryStats from "./CSectionGalleryStats"

const DEBUG = false && __DEV__
import {debbify} from "../../../data/selectors/helpers"
const debby = (...args) => debbify("SGalleryLanding", ...args)

class SGalleryLanding extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    location: PropTypes.object.isRequired,
    gallerySlug: PropTypes.string.isRequired,
    postDataGalleryLanding: PropTypes.object,
    galleryEmbedEnabled: PropTypes.bool,
    galleryBranchShareInfos: PropTypes.object,
    gallerySecretCode: PropTypes.string,
    galleryStats: PropTypes.object,

    //  withScreenProps
    dispatch: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    intlLocale: PropTypes.string.isRequired,
    embeddedMode: PropTypes.bool,
    themeKey: PropTypes.string,
    postData: PropTypes.object,
    postDataSuccess: PropTypes.bool,
    wordPressUserId: PropTypes.number,
    editInWordPressProps: PropTypes.object,
    pageTemplate: PropTypes.string,
    locationPathname: PropTypes.string,
  }
  static defaultProps = {
    debug: DEBUG,
  }

  componentDidMount = async () => {
    const {dispatch, embeddedMode, gallerySlug, wordPressUserId, gallerySecretCode} = this.props
    debby("componentDidMount()", {gallerySlug})
    if (embeddedMode) {
      dispatch(actions.sendAnalyticsEvent(`embedded_site_visit`, {GallerySlug: gallerySlug}))
      dispatch(actions.sendAnalyticsEvent(`embedded_site_visit_landing`, {GallerySlug: gallerySlug}))
    }
    if (!!wordPressUserId && !!gallerySecretCode) {
    }
    await Promise.all([
      dispatch(actions.requestPostData("/g")), // gallery-landing
      dispatch(actions.requestPostData("/p")), // gallery-landing-artwork
      dispatch(actions.requestPublicWallsForGallery(gallerySlug)),
      dispatch(actions.createShareLink("gallery", {slug: gallerySlug})), // -> this.props.galleryBranchShareInfos
    ])
    this.handleProps(this.props, true)
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  UNSAFE_componentWillReceiveProps = nextProps => this.handleProps(nextProps, false)

  handleProps = (nextProps, initial = false) => {
    if (initial || nextProps.wordPressUserId != this.props.wordPressUserId || nextProps.gallerySecretCode != this.props.gallerySecretCode) {
      if (!!nextProps.wordPressUserId && !!nextProps.gallerySecretCode) {
        debby(`handleProps(): request gallery stats for '${nextProps.gallerySecretCode}'`)
        this.props.dispatch(actions.requestGalleryStats(nextProps.gallerySecretCode))
      }
    }
  }

  render = () => {
    const {intl, intlLocale, postDataSuccess, galleryBranchShareInfos, location, locationPathname, gallerySlug, galleryTitle, gallerySecretCode, galleryEmbedEnabled, postData, artworks, postDataGalleryLanding, embeddedMode, publicWalls, publicWallsFetching, galleryStats, wordPressUserId} = this.props // eslint-disable-line
    const d = {debug: DEBUG}
    const showNoEmbedPlaceholder = !!galleryTitle && embeddedMode && !galleryEmbedEnabled && postDataSuccess == true
    const appLinkUrl = god(galleryBranchShareInfos, "url")
    debby("render()", {wordPressUserId, gallerySecretCode, galleryStats, intlLocale, postDataGalleryLanding, postDataSuccess, publicWallsFetching, showNoEmbedPlaceholder, appLinkUrl})
    return (
      <Container {...d}>
        <CSpacer {...d} variant={embeddedMode ? `headermenu-embedded` : `headermenu`} />
        {embeddedMode && (
          <CHeaderEmbeddedMode
            //
            locationPathname={locationPathname}
            shareButtonLabel={intl.formatMessage({id: `WallEditorShareWallButton`})}
            galleryTitle={galleryTitle}
          />
        )}
        {showNoEmbedPlaceholder && (
          <NoEmbedPlaceholderContainer {...d}>
            <NoEmbedPlaceholderText {...d} dangerouslySetInnerHTML={{__html: intl.formatMessage({id: `PlaceholderEmbeddedVersionNotAvailable`}).split("\n").join("<br/>")}} />
            {!!appLinkUrl && (
              <CButton
                //
                urlTo={appLinkUrl}
                variant={"centered"}
                label={intl.formatMessage({id: `ButtonLabelEmbeddedVersionNotAvailable`})}
              />
            )}
          </NoEmbedPlaceholderContainer>
        )}
        {!!postData && !showNoEmbedPlaceholder && (
          <Fragment>
            {!embeddedMode && (
              <CSectionGalleryBasics
                //
                {...d}
                postData={postData}
                galleryBranchShareInfos={galleryBranchShareInfos}
              />
            )}
            <CSectionGalleryPublicWalls
              //
              {...d}
              intl={intl}
              intlLocale={intlLocale}
              publicWalls={publicWalls}
              loadMoreButtonLabel={intl.formatMessage({id: `GalleryLandingSectionArtworksLoadMoreButtonLabel`})}
              gallerySlug={gallerySlug}
              galleryTitle={galleryTitle}
              gallerySecretCode={gallerySecretCode}
              labelHeader={intl.formatMessage({id: `GalleryLandingSectionPublicWallsLabelHeader`})}
              labelSubheader={intl.formatMessage({id: `GalleryLandingSectionPublicWallsLabelSubheader`})}
              makeYourOwnGalleryButtonLabel={intl.formatMessage({id: `GalleryLandingSectionPublicWallsMakeYourOwnGalleryButtonLabel`})}
              noWallsYetLabel={intl.formatMessage({id: `GalleryLandingSectionPublicWallsNoWallsYetLabel`})}
              noBottomSeparatorMobile // because next section is grey
              fetching={publicWallsFetching}
              galleryBranchShareInfos={galleryBranchShareInfos} // shows QR code when in embedded mode
            />
            <CSectionGalleryArtworks
              //
              {...d}
              postData={postData}
              artworks={artworks}
              gallerySlug={gallerySlug}
              labelHeader={intl.formatMessage({id: `GalleryLandingSectionArtworksLabelHeader`})}
              loadMoreButtonLabel={intl.formatMessage({id: `GalleryLandingSectionArtworksLoadMoreButtonLabel`})}
              artworkDetailsByLabel={intl.formatMessage({id: `ArtworkDetailsBy`})}
            />
            {!!wordPressUserId && (
              <CSectionGalleryDemo
                //
                {...d}
                galleryTitle={galleryTitle} // for gallery title
                gallerySecretCode={gallerySecretCode}
                infoParagraph={intl.formatMessage({id: `GalleryLandingSectionDemoInfoParagraph`})}
                buttonLabel={intl.formatMessage({id: `GalleryLandingSectionDemoButtonLabel`})}
                bottomLabel={intl.formatMessage({id: `GalleryLandingSectionFooterBottomLabel`})}
              />
            )}
            {!!galleryStats && !!galleryStats.thisMonth && !!galleryStats.lastMonth && (
              <CSectionGalleryStats
                //
                gallerySlug={gallerySlug}
                thisMonth={galleryStats.thisMonth}
                lastMonth={galleryStats.lastMonth}
              />
            )}
          </Fragment>
        )}

        {!postData && postDataSuccess == false && <CNoContent variant={"gallery-landing"} />}

        <CFooter
          //
          galleryTitle={galleryTitle}
          locationPathname={locationPathname}
        />
      </Container>
    )
  }
}

const Container = styled.div`
  position: relative;
  transition: all ${props => props.theme.vars.transitions.themeTransitionDuration}s linear;
`

const NoEmbedPlaceholderContainer = styled.div`
  ${props => selectors.getPaddingLeftRightByThemeAndVariant(props.theme, "header")}
  ${props => selectors.getDebugOverlayCss(props, "NoEmbedPlaceholderContainer", "rgba(0,255,255,0.35)")};
  padding-top: 40px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const NoEmbedPlaceholderText = styled.div`
  font-size: 17px;
  line-height: 1.4;
  text-align: center;
  letter-spacing: 0.08em;
  max-width: 400px;
  margin: 0 auto;
  margin-bottom: 50px; // possible CButton below
`

const getBranchLinksState = state => state.branchLinks
const getGalleryBranchShareInfosKey = createSelector([selectors.getIntlLocale, selectors.getGallerySlugByMatch], (lang, gallerySlug) => (!!gallerySlug ? selectors.getBranchShareLinkKey({type: "gallery", lang, slug: gallerySlug}) : null))
const getGalleryBranchShareInfos = createSelector([getBranchLinksState, getGalleryBranchShareInfosKey], (branchLinksState, galleryBranchShareInfosKey) => {
  const qrData = !galleryBranchShareInfosKey ? null : god(branchLinksState, [galleryBranchShareInfosKey, "qrData"])
  return !qrData
    ? null
    : {
        url: god(qrData, "targetUrl"),
        qrImageUrl: god(qrData, "url"),
      }
})

const getStateApiGalleryStats = state => state.api.galleryStats

const getGalleryStats = createSelector([selectors.getGallerySecretCodeByMatch, getStateApiGalleryStats], (secretCode, galleryStatsBySecretCode) => {
  const yearMonthThisMonth = moment().format("YYYY/MM")
  const yearMonthLastMonth = moment().add(-1, "months").format("YYYY/MM")
  const stats = {
    thisMonth: god(galleryStatsBySecretCode, [secretCode, yearMonthThisMonth]),
    lastMonth: god(galleryStatsBySecretCode, [secretCode, yearMonthLastMonth]),
  }
  return stats
})

const mapStateToProps = (state, props) => ({
  gallerySlug: selectors.getGallerySlugByMatch(state, props),
  galleryTitle: selectors.getGalleryTitleByMatch(state, props),
  gallerySecretCode: selectors.getGallerySecretCodeByMatch(state, props),
  galleryEmbedEnabled: selectors.getGalleryEmbedEnabledByMatch(state, props),
  galleryBranchShareInfos: getGalleryBranchShareInfos(state, props),
  postDataGalleryLanding: selectors.getPostDataGalleryLanding(state, props),
  artworks: selectors.getGalleryArtworks(state, props),
  publicWalls: selectors.getGalleryPublicWalls(state, props),
  publicWallsFetching: selectors.getGalleryPublicWallsFetching(state, props),
  galleryStats: getGalleryStats(state, props),
})
export default withScreenProps(connect(mapStateToProps)(SGalleryLanding))
