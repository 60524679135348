import React, {Component, Fragment} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import styled, {css} from "styled-components" // eslint-disable-line

import * as selectors from "../../../data/selectors" // eslint-disable-line
import {SectionTryOurDemo} from "../../../images"

import CImage from "../../../view/components/CImage"
import CSpacer from "../../../view/components/CSpacer"
import CButton from "../../../view/components/CButton"
import CLink from "../../../view/components/CLink"
import CAppStoreButton from "../../../view/components/CAppStoreButton"

import CH2 from "../../../view/components/text/CH2"
import CHomeSubheader from "../../../view/components/text/CHomeSubheader" // eslint-disable-line

class CHomeDemo extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    header: PropTypes.string,
    subheader: PropTypes.string,
    button: PropTypes.string,
    onButtonClick: PropTypes.func,
    parallaxController: PropTypes.object,
    showAppStoreButtons: PropTypes.bool,
    intlLocale: PropTypes.string.isRequired,
  }
  static defaultProps = {
    debug: false && __DEV__,
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  render = () => {
    const {debug, header, subheader, button, onButtonClick, parallaxController, showAppStoreButtons, intlLocale} = this.props
    const d = {debug: debug || CHomeDemo.defaultProps.debug}
    return (
      <Container>
        <CImage
          //  props
          {...d}
          src={SectionTryOurDemo}
          opacity={0.8}
          overlayColor={`rgba(0,0,0,0.25)`}
          // parallaxRatio={0.1}
          parallaxController={parallaxController}
          heightGrows>
          <PaddedContainer {...d} variant={`content`}>
            <ContentText {...d}>
              <Header>
                <CH2 {...d} text={header} variant={`home-demo`} />
              </Header>
              {!!subheader && (
                <Fragment>
                  <CSpacer {...d} variant={`herosections`} />
                  <Subheader>
                    <CHomeSubheader {...d} text={subheader} variant={`home-demo`} />
                  </Subheader>
                </Fragment>
              )}
              <CSpacer {...d} variant={`herosections`} />
              <ButtonsContainer>
                {!showAppStoreButtons && (
                  <Button>
                    <CLink to={`/demo`} debug={debug}>
                      <CButton {...d} variant={`home-demo`} label={button} onClick={onButtonClick} />
                    </CLink>
                  </Button>
                )}
                {showAppStoreButtons && (
                  <AppStoreContainerButtons>
                    <AppStoreButton>
                      <CAppStoreButton intlLocale={intlLocale} platform={"ios"} />
                    </AppStoreButton>
                    <AppStoreButton>
                      <CAppStoreButton intlLocale={intlLocale} platform={"android"} />
                    </AppStoreButton>
                  </AppStoreContainerButtons>
                )}
              </ButtonsContainer>
            </ContentText>
          </PaddedContainer>
        </CImage>
      </Container>
    )
  }
}

const Container = styled.div`
  background-color: black;
  width: 100%;
`

const PaddedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  transition: all ${props => props.theme.vars.transitions.themeTransitionDuration}s linear;
  ${props => selectors.getPaddingLeftRightByThemeAndVariant(props.theme, props.variant)}
  ${props => props.debug && selectors.getDebugOverlayCss(props, `HomeDemo.PaddedContainer`, `rgba(0,0,255,0.35)`)}
`

const ContentText = styled.div`
  padding: 150px 0px;
  width: 100%;
  ${props => props.debug && selectors.getDebugOverlayCss(props, `ContentText`, `rgba(0,255,255,0.35)`)}
`

const Header = styled.div`
  ${props => props.debug && selectors.getDebugOverlayCss(props, `Header`, `rgba(255,0,0,0.35)`)}
`
const Subheader = styled.div`
  ${props => props.debug && selectors.getDebugOverlayCss(props, `Header`, `rgba(0,255,0,0.35)`)}
`

const ButtonsContainer = styled.div`
  padding-top: 20px;
  ${props => props.debug && selectors.getDebugOverlayCss(props, `ButtonsContainer`, `rgba(255,0,0,0.35)`)}
`

const Button = styled.div`
  ${props => props.debug && selectors.getDebugOverlayCss(props, `Header`, `rgba(255,255,0,0.35)`)}
`

const AppStoreContainerButtons = styled.div`
  // padding-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const AppStoreButton = styled.div`
  margin-right: 15px;
  width: 100%;
  max-width: 140px;

  ${props =>
    css`
      ${props.theme.media.xs} {
        margin-left: 15px;
      }
    `}
`

export default CHomeDemo
