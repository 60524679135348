import React, {Component} from "react"
import PropTypes from "prop-types"
import {connect} from "react-redux"
// import {injectIntl} from "react-intl"
import isEqual from "lodash/isEqual"
import {createSelector} from "reselect"
import styled, {ThemeProvider} from "styled-components" // eslint-disable-line

import {CONFIG} from "../../../config"
import withScreenProps from "../../../data/hocs/withScreenProps" // {postData, ..}
import * as actions from "../../../data/actions"
import * as selectors from "../../../data/selectors"
const god = selectors.getObjectDeep

import CEditInWordPress from "../../../view/components/CEditInWordPress"
import CFooter from "../../../view/components/CFooter"
import CGetInTouch from "../../../view/components/CGetInTouch"

import {CPortfolioDetailsHeroOverlay, CPortfolioDetailsPackages, CPortfolioDetailsBelowPricing} from "./components"
import {CHomeDemo} from "../SHome/components"

const DEBUG = false && __DEV__
import {debbify} from "../../../data/selectors/helpers"
const debby = (...args) => debbify("SPortfolioDetails", ...args)

class SPortfolioDetails extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    sectionKeys: PropTypes.array,
    defaultThemeProps: PropTypes.object,

    galleryPackages: PropTypes.array,
    sitemapData: PropTypes.array,
    sectionKey: PropTypes.string,

    currenciesAvailable: PropTypes.array,
    suggestedCurrency: PropTypes.string,

    //  withScreenProps
    dispatch: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    intlLocale: PropTypes.string.isRequired,
    themeKey: PropTypes.string,
    postData: PropTypes.object,
    wordPressUserId: PropTypes.number,
    editInWordPressProps: PropTypes.object,
    pageTemplate: PropTypes.string,
    mediaQueryClass: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
  }
  static defaultProps = {
    debug: DEBUG,
    sectionKeys: selectors.getPortfolioMainSectionKeys(), // routes.js
    defaultThemeProps: {
      backgroundColor: "white",
      textColor: CONFIG.layout.colors.codGray,
    },
    currenciesAvailable: ["EUR", "CHF", "USD"],
  }

  constructor(props) {
    super(props)
    this.state = {
      currencySelected: props.suggestedCurrency || "EUR",
    }
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  componentWillReceiveProps = nextProps => {
    if (!this.props.suggestedCurrency && !!nextProps.suggestedCurrency) {
      this.setState({currencySelected: nextProps.suggestedCurrency})
    }
  }

  onLinkPress = url => {
    debby("onLinkPress()", {url})
    this.props.dispatch(actions.handleUrl(url))
  }

  onCurrencySelectedChanged = code => this.setState({currencySelected: code})

  render = () => {
    const {currencySelected} = this.state
    const {defaultThemeProps, intl, editInWordPressProps, postData, galleryPackages, intlLocale, sitemapData, pageTemplate, currenciesAvailable, mediaQueryClass, sectionKey} = this.props
    const d = {debug: DEBUG || this.props.debug}
    const privacySmallprintHtml = selectors.getPrivacySmallprintHtml(intl, intlLocale, sitemapData)
    const galleryPackagesSmallprintHtml = selectors.getGalleryPackagesSmallprintHtml(intl, intlLocale, sitemapData)
    let themeOverrides = {}
    return (
      <ThemeProvider theme={{...defaultThemeProps, ...themeOverrides}}>
        <Container>
          <CPortfolioDetailsHeroOverlay
            //
            {...d}
            header={god(postData, "sectionTop.labelHeader")}
            subheader={god(postData, "sectionTop.labelSubheader")}
            features={god(postData, "sectionTop.featuresNew")}
            uncheckedFeaturedPostfix={god(postData, "sectionTop.uncheckedFeaturedPostfix")}
            fadedImages={god(postData, "sectionTop.fadedImages")}
            sectionKey={sectionKey}
            mediaQueryClass={mediaQueryClass}
          />
          <CPortfolioDetailsPackages
            //
            {...d}
            packages={galleryPackages} // optional
            intlLocale={intlLocale}
            smallprint={sectionKey != "artists" ? galleryPackagesSmallprintHtml : null} // only relevant if packages are shown
            customPricingInfo={god(postData, "sectionCustomPricingInfo.show", false) ? god(postData, "sectionCustomPricingInfo.info") : null}
            currenciesAvailable={currenciesAvailable}
            currencySelected={currencySelected} // e.g. "CHF"
            onCurrencySelectedChanged={this.onCurrencySelectedChanged}
            showEditButton={!!editInWordPressProps.userId}
          />
          <CPortfolioDetailsBelowPricing
            //
            header={god(postData, "sectionBelowPricing.labelHeader")}
            subheader={god(postData, "sectionBelowPricing.labelSubheader")}
          />
          <CHomeDemo
            //
            header={god(postData, "sectionDemo.labelHeader")}
            subheader={god(postData, "sectionDemo.labelSubheader")}
            showAppStoreButtons
            intlLocale={intlLocale}
          />
          <GetInTouchContainer>
            <CGetInTouch
              //  labels
              {...d}
              pageTemplate={pageTemplate}
              // hasHeader={false} // because CPortfolioGetInTouch
              header={god(postData, "sectionGetInTouch.labelHeader")}
              button={god(postData, "sectionGetInTouch.labelButton")}
              teamMember={god(postData, "sectionGetInTouch.teamMember")}
              smallprint={privacySmallprintHtml}
              onLinkPress={this.onLinkPress}
            />
          </GetInTouchContainer>
          <CFooter />
          <CEditInWordPress fixed {...editInWordPressProps} />
        </Container>
      </ThemeProvider>
    )
  }
}

const Container = styled.div`
  position: relative;
  overflow-x: hidden; // because of huge images
  background-color: ${props => props.theme.backgroundColor};
  transition: all ${props => props.theme.vars.transitions.themeTransitionDuration}s linear;
`

const GetInTouchContainer = styled.div`
  padding: 60px 0px;
`

const getSectionKey = createSelector([selectors.getSitemapData, selectors.getIntlLocale, selectors.getLocationPathname], (sitemapData, intlLocale, locationPathname) => {
  const pathEn = selectors.getPathnameLocalized(locationPathname, "en", sitemapData) // same as key
  if (!!pathEn) {
    const pathParts = pathEn.split("/").filter(p => !!p)
    if (pathParts[0] == `portfolio`) {
      return pathParts[1]
    }
  }
  return null
})

const getGalleryPackages = createSelector([selectors.getIntlLocale, selectors.getPostData], (intlLocale, postData) => {
  const packages = god(postData, "galleryPackages")
  if (!!packages && !!packages.length) {
    const galleryPackages = packages.map(pack => {
      const {id, type, priceMonth, priceMonthFloat, priceYear, hasFixedSetupCosts, fixedSetupCosts, otherCurrencies} = pack
      const description = god(pack, `languageVariants.${intlLocale}.description`, pack.description)
      let features = [
        //
        {key: "surfaces", value: pack.surfaces},
        {key: "artworks", value: pack.artworks, influencesFixedSetupCosts: true},
        {key: "artists", value: pack.artists, influencesFixedSetupCosts: true},
        {key: "qrCodes", value: pack.qrCodes},
      ]
      if (type == "individual") {
        features = null
      }
      const prices = {
        EUR: {
          month: priceMonth,
          monthFloat: priceMonthFloat,
          fixed: fixedSetupCosts,
        },
        CHF: {
          month: otherCurrencies.pricePerMonthChf,
          monthFloat: otherCurrencies.pricePerMonthChfFloat,
          fixed: otherCurrencies.fixedCostsChf,
        },
        USD: {
          month: otherCurrencies.pricePerMonthUsd,
          monthFloat: otherCurrencies.pricePerMonthUsdFloat,
          fixed: otherCurrencies.fixedCostsUsd,
        },
      }
      return {
        id,
        type, // -> title via intl id
        features,
        description,
        priceMonth,
        priceMonthFloat,
        priceYear,
        hasFixedSetupCosts,
        fixedSetupCosts,
        prices,
      }
    })
    return galleryPackages
  }
  return null
})

const mapStateToProps = (state, props) => ({
  sectionKey: getSectionKey(state, props),
  galleryPackages: getGalleryPackages(state, props),
  sitemapData: selectors.getSitemapData(state),
  suggestedCurrency: state.api.suggestedCurrency,
})
export default withScreenProps(connect(mapStateToProps)(SPortfolioDetails))
