import React, {Component} from "react"
import PropTypes from "prop-types"
import {injectIntl} from "react-intl"
import {connect} from "react-redux"
import isEqual from "lodash/isEqual"
import styled, {css, ThemeProvider} from "styled-components" // eslint-disable-line

import {CONFIG} from "../../../config" // eslint-disable-line
import * as selectors from "../../../data/selectors"
import {BranchManager} from "../../../data/utils"

import {BenefitsAugmentedRealityDark} from "../../../images"

import CButton from "../../../view/components/CButton"

const DEBUG = true && __DEV__
const debby = (funcName, data = null) => DEBUG && console.log("CSectionGalleryDemo." + funcName, data) // eslint-disable-line

class CSectionGalleryDemo extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    intl: PropTypes.object.isRequired,
    defaultThemeProps: PropTypes.object,

    infoParagraph: PropTypes.string,
    buttonLabel: PropTypes.string,
    bottomLabel: PropTypes.string,
    galleryTitle: PropTypes.string,
    gallerySecretCode: PropTypes.string,
    ownGalleryUrl: PropTypes.string,

    // connect
    embeddedMode: PropTypes.bool,
  }
  static defaultProps = {
    debug: false && __DEV__,
    defaultThemeProps: {
      // backgroundColor: "white",
      textColor: "black",
    },
    maxHeightDescription: 200,
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  render = () => {
    // const {amountShown} = this.state
    const {debug, defaultThemeProps, infoParagraph, buttonLabel, bottomLabel, galleryTitle, gallerySecretCode, ownGalleryUrl} = this.props
    const d = {debug: debug || CSectionGalleryDemo.defaultProps.debug}
    const buttonUrlTo = !!ownGalleryUrl ? ownGalleryUrl : BranchManager.getBranchUrl(gallerySecretCode)
    return (
      <ThemeProvider theme={{...defaultThemeProps}}>
        <PaddedContainer {...d} variant={"header"}>
          <Content
            //
            {...d}>
            <IconContainer {...d}>
              <Icon src={BenefitsAugmentedRealityDark} />
            </IconContainer>
            <InfoParagraph {...d}>{infoParagraph}</InfoParagraph>
            <CButton
              //
              label={buttonLabel}
              variant={"gallery-landing-demo"}
              urlTo={buttonUrlTo}
              openInNewWindow={true}
            />
            {!!bottomLabel && (
              <BottomLabel
                //
                {...d}>
                {bottomLabel.split("[galleryTitle]").join(galleryTitle)}
              </BottomLabel>
            )}
          </Content>
        </PaddedContainer>
      </ThemeProvider>
    )
  }
}

const PaddedContainer = styled.div`
  ${props => selectors.getPaddingLeftRightByThemeAndVariant(props.theme, props.variant)}
  padding-top: 40px;

  ${props => false && props.debug && selectors.getDebugOverlayCss(props, "CSectionGalleryDemo.PaddedContainer", "rgba(0,0,255,0.35)")}
  transition: all ${props => props.theme.vars.transitions.themeTransitionDuration}s linear;

  background: #ffffff;
  background: linear-gradient(180deg, #ffffff 0%, #efefef 100%);
`

const Content = styled.div`
  ${props => selectors.getDebugOverlayCss(props, "Content", "rgba(0,255,0,0.35)")}

  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
`

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${props => selectors.getDebugOverlayCss(props, "IconContainer", "rgba(255,255,0,0.35)")}
`

const Icon = styled.img`
  width: 150px;
`

const InfoParagraph = styled.div`
  font-size: 16px;
  line-height: 23px;
  font-weight: ${props => props.theme.vars.fonts.weights.default};
  text-align: center;
  letter-spacing: 0.15em;
  color: #282828;
  padding-top: 30px;
  padding-bottom: 30px;
  max-width: 600px;
  margin: 0 auto;
  ${props => selectors.getDebugOverlayCss(props, "InfoParagraph", "rgba(255,0,255,0.35)")}
`

const BottomLabel = styled.div`
  font-size: 12px;
  font-weight: ${props => props.theme.vars.fonts.weights.default};
  text-align: center;
  letter-spacing: 0.15em;
  color: #282828;
  opacity: 0.5;
  padding-top: 70px;
  ${props => selectors.getDebugOverlayCss(props, "BottomLabel", "rgba(0,255,255,0.35)")}
`

const mapStateToProps = state => ({
  embeddedMode: state.session.embeddedMode,
})
export default injectIntl(connect(mapStateToProps)(CSectionGalleryDemo))
