import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import styled, {css} from "styled-components" // eslint-disable-line
import * as selectors from "../../../data/selectors" // eslint-disable-line

class CH1 extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    text: PropTypes.string,
    variant: PropTypes.oneOf(["default", "home-hero"]),
  }
  static defaultProps = {
    debug: false && __DEV__,
    variant: "default",
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  render = () => {
    const {debug, text, variant} = this.props
    const d = {debug: debug || CH1.defaultProps.debug}
    return <H1 variant={variant} {...d} dangerouslySetInnerHTML={{__html: text}} />
  }
}

//  prettier-ignore
const H1 = styled.h1`
  font-weight: ${props => props.theme.vars.fonts.weights.extrabold};
  font-family: ${props => props.theme.vars.fonts.families.default};
  letter-spacing: 0.2em;
  font-kerning: none;
  text-transform: uppercase;
  padding: 0;
  margin: 0;

  span {
    font-weight: ${props => props.theme.vars.fonts.weights.regular};
    font-style: italic;
  }

  font-size: 50px;
  line-height: 1.2;
  // text-align: left;
  ${props => css`
    ${props.theme.media.lg} {
      font-size: 45px;
    }
    ${props.theme.media.md} {
      font-size: 40px;
    }
    ${props.theme.media.smdown} {
      text-align: center;
      margin: 0px 20px;
    }
    ${props.theme.media.sm} {
      font-size: 35px;
    }
    ${props.theme.media.xs} {
      font-size: 26px;
    }
  `}

  ${props => selectors.getDebugOverlayCss(props, "H1", "rgba(255,0,0,0.25)")}
`

export default CH1
