import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import styled, {css} from "styled-components" // eslint-disable-line
import {Player, ControlBar, BigPlayButton} from "video-react"

import BugReportManager from "../../../data/utils/BugReportManager"
import * as selectors from "../../../data/selectors"
const god = selectors.getObjectDeep // eslint-disable-line

import CUmbrellaContactButton from "../../../view/components/CUmbrellaContactButton"
import CIcon from "../../../view/components/CIcon"

import CH3 from "../../../view/components/text/CH3"

import {basicTheme} from "../../../view/Theme"
import {VideoPlayButton} from "../../../images"
import {CONFIG} from "../../../config"

const DEBUG = false && __DEV__
import {debbify} from "../../../data/selectors/helpers"
const debby = (...args) => debbify("CExhibitorsFeatures", ...args)

// prettier-ignore
const VIDEOS = {
  exhibitors: {
    half:       {url: `https://iazzu.com/vid/html5/Final_Exhibitors_Half`,        dimensions: {w: 747, h: 1080}},
    halfMobile: {url: `https://iazzu.com/vid/html5/Final_Exhibitors_Mobile_Half`, dimensions: {w: 498, h: 1080}}
  },
  artlovers: {
    half:       {url: `https://iazzu.com/vid/html5/Final_ArtLovers_Half`,         dimensions: {w: 747, h: 1080}},
    halfMobile: {url: `https://iazzu.com/vid/html5/Final_ArtLovers_Mobile_Half`,  dimensions: {w: 498, h: 1080}}
  },
}

class CExhibitorsFeatures extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    header: PropTypes.string,
    featureList: PropTypes.array,
    contactLabel: PropTypes.string,
    videoCoverImage: PropTypes.object,
    videoCoverImageMobile: PropTypes.object,
    videoKey: PropTypes.oneOf(["exhibitors", "artlovers"]).isRequired,
    mediaQueryClass: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
  }
  static defaultProps = {
    debug: false && __DEV__,
  }

  constructor(props) {
    super(props)
    this.state = {
      videoCanPlay: false,
      videoPlaying: false,
    }
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  onVideoLoadedMetadata = () => debby("onVideoLoadedMetadata()")
  onVideoCanPlay = () => {
    debby("onVideoCanPlay()")
    this.setState({videoCanPlay: true})
  }
  onVideoWaiting = () => debby("onVideoWaiting()")
  onVideoPlay = () => this.setState({videoPlaying: true})
  onVideoPause = () => this.setState({videoPlaying: false})

  toggleVideoPlayPause = () => {
    debby("toggleVideoPlayPause()")
    if (!this.videoPlayer) {
      BugReportManager.captureMessage("Video Player not found.")
      return
    }
    if (!this.state.videoPlaying) {
      this.videoPlayer.play()
    } else {
      this.videoPlayer.pause()
    }
  }

  onVideoError = error => {
    debby("onVideoError()")
    __DEV__ && console.error(error)
    // BugReportManager.captureException(error)
  }

  render = () => {
    const {videoCanPlay, videoPlaying} = this.state
    const {header, featureList, contactLabel, videoCoverImage, videoCoverImageMobile, videoKey, mediaQueryClass} = this.props
    const d = {debug: DEBUG || this.props.debug}
    if (!header) {
      return <div />
    }
    const isSmDown = selectors.getMediaQueryMatching(mediaQueryClass, "<=", "sm")
    const isMd = selectors.getMediaQueryMatching(mediaQueryClass, "==", "md")
    const videoSubKey = !isSmDown ? "half" : "halfMobile"
    const coverImage = !isSmDown ? videoCoverImage : videoCoverImageMobile // eslint-disable-line
    const iconScale = isMd ? 0.9 : 1
    const videoUrlPostfix = `?v=${CONFIG.redux.storePersistPhrase}`
    debby("render()", {mediaQueryClass, iconScale, videoKey, videoSubKey, videoUrlPostfix})
    return (
      <PaddedContainerMdUp {...d} variant={"header"}>
        <Container {...d}>
          <FeatureText {...d} variant={"header"}>
            <CH3 text={header} variant={"umbrellaFeatures"} />
            <FeaturesListContainer {...d}>
              {featureList.map((featureText, i) => (
                <FeatureContainer {...d} key={`feature_${i}`}>
                  <FeatureIconContainer {...d}>
                    <CIcon id={`UmbrellaFeature`} scale={iconScale} color={basicTheme.vars.colors.ciCyan} />
                  </FeatureIconContainer>
                  <FeatureTextContainer {...d}>{featureText}</FeatureTextContainer>
                </FeatureContainer>
              ))}
            </FeaturesListContainer>
            {!!contactLabel && <CUmbrellaContactButton debug={d.debug} align={!isSmDown ? "left" : "center"} label={contactLabel} />}
          </FeatureText>
          <FeatureVideo {...d} key={`vid_${videoSubKey}`}>
            <PlayerContainer {...d} canPlay={videoCanPlay}>
              <Player
                //
                ref={obj => (this.videoPlayer = obj)}
                aspectRatio={`${VIDEOS[videoKey][videoSubKey].dimensions.w}:${VIDEOS[videoKey][videoSubKey].dimensions.h}`} // String, e.g. "16:9"
                onError={this.onVideoError}
                onLoadedMetadata={this.onVideoLoadedMetadata}
                onWaiting={this.onVideoWaiting}
                onCanPlay={this.onVideoCanPlay}
                onPlay={this.onVideoPlay}
                onPause={this.onVideoPause}
                // autoPlay={false}
                autoPlay // otherwise not working on iOS
                // autoPlay={isSmDown}
                poster={god(coverImage, "sizes.1000.url")}
                loop
                playsInline
                muted>
                <source src={`${VIDEOS[videoKey][videoSubKey].url}.m4v${videoUrlPostfix}`} type={"video/mp4"} />
                <source src={`${VIDEOS[videoKey][videoSubKey].url}.webm${videoUrlPostfix}`} type={"video/webm"} />
                <source src={`${VIDEOS[videoKey][videoSubKey].url}.ogv${videoUrlPostfix}`} type={"video/ogg"} />
                <BigPlayButton position={"center"} disabled />
                <ControlBar disableCompletely />
              </Player>
            </PlayerContainer>
            <PlayButtonContainer
              //
              {...d}
              canPlay={videoCanPlay}
              isPlaying={videoPlaying}
              onClick={videoCanPlay ? this.toggleVideoPlayPause : undefined}>
              <img src={VideoPlayButton} style={{width: "20%"}} />
            </PlayButtonContainer>
          </FeatureVideo>
        </Container>
      </PaddedContainerMdUp>
    )
  }
}

const PaddedContainerMdUp = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  transition: all ${props => props.theme.vars.transitions.themeTransitionDuration}s linear;

  ${props => selectors.getPaddingLeftRightByThemeAndVariant(props.theme, props.variant)}
  ${props => css`
    ${props.theme.media.smdown} {
      padding-left: 0px;
      padding-right: 0px;
    }
  `}
  ${props => true && props.debug && selectors.getDebugOverlayCss(props, `PaddedContainer`, `rgba(0,0,255,0.15)`)}
`

const Container = styled.div`
  display: flex;
  border: ${props => (props.debug ? 1 : 0)}px solid blue;
  ${props => true && props.debug && selectors.getDebugOverlayCss(props, `CExhibitorsFeatures`, `rgba(255,255,0,0.35)`)}

  flex-direction: row;
  ${props => css`
    ${props.theme.media.smdown} {
      flex-direction: column;
    }
  `}
`
const FeatureText = styled.div`
  border: ${props => (props.debug ? 1 : 0)}px solid red;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center; // vertical
  ${props => true && props.debug && selectors.getDebugOverlayCss(props, `FeatureText`, `rgba(255,0,255,0.35)`)}

  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 0px;
  padding-right: 0px;
  ${props => css`
    ${props.theme.media.smdown} {
      padding-top: 40px;
      padding-bottom: 0px;
      ${selectors.getPaddingLeftRightByThemeAndVariant(props.theme, props.variant, "margin")}
    }
  `}
`

const FeaturesListContainer = styled.div`
  ${props => true && selectors.getDebugOverlayCss(props, "FeaturesListContainer", "rgba(255,0,0,0.25)")}
  padding-top: 20px;

  ${props => css`
    ${props.theme.media.lg} {
      padding-top: 0px;
    }
  `}
`

const FeatureContainer = styled.div`
  display: flex;

  margin: 55px 0px;
  ${props => css`
    ${props.theme.media.lg} {
      margin: 30px 0px;
    }
    ${props.theme.media.md} {
      margin: 25px 0px;
    }
    ${props.theme.media.smdown} {
      margin: 30px 0px;
    }
  `}
`

const FeatureIconContainer = styled.div`
  align-self: stretch;
  padding-top: 2px;
  background-color: ${props => (props.debug ? "rgba(255,0,0,0.2)" : "none")};
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  width: 50px;
  ${props => css`
    ${props.theme.media.md} {
      width: 40px;
    }
    ${props.theme.media.lg} {
      width: 50px;
    }
  `}
`

const FeatureTextContainer = styled.div`
  flex: 1;
  align-self: stretch;
  color: #282828;
  text-align: left;
  padding-top: 2px;

  font-size: 20px;
  line-height: 1.4em;
  letter-spacing: 2px;
  letter-spacing: 0.1em;
  ${props => css`
    ${props.theme.media.md} {
      font-size: 16px;
    }
    ${props.theme.media.smdown} {
      font-size: 18px;
    }
  `}
`

const FeatureVideo = styled.div`
  position: relative;
  border: ${props => (props.debug ? 3 : 0)}px solid green;
  border-radius: ${props => (props.debug ? 50 : 0)}px;
  overflow: hidden;
  // display: flex;

  flex: 1.2;
  align-self: stretch;
  ${props => css`
    ${props.theme.media.md} {
      flex: 1.1;
    }
  `}
`

const PlayerContainer = styled.div`
  position: relative;
  transition: all 2.5s ease-in-out;
`

const PlayButtonContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  ${props =>
    props.debug &&
    css`
      border: 3px solid red;
      background-color: rgba(255, 255, 0, 0.25);
    `}

  opacity: ${props => (props.canPlay && !props.isPlaying ? 1 : 0)};
  transition: all 0.5s ease-in-out;
  pointer-events: ${props => (props.canPlay ? "none" : "auto")};

  img {
    opacity: 0.7;
    transform: scale(0.8);
    transition: all 0.2s ease-in-out;
  }
  :hover {
    img {
      opacity: 1;
      transform: scale(1);
    }
  }
`

export default CExhibitorsFeatures
