import React, {Component} from "react"
import PropTypes from "prop-types"
import styled, {css, ThemeProvider} from "styled-components" // eslint-disable-line

import CIcon from "../../../view/components/CIcon"
import CCollapse from "../../../view/components/CCollapse"

import * as selectors from "../../../data/selectors"

class CExpandableContent extends Component {
  static propTypes = {
    header: PropTypes.string,
    debug: PropTypes.bool,
    html: PropTypes.string,
    collapsed: PropTypes.bool,
    collapsedHeight: PropTypes.number,
    maxHeightDescription: PropTypes.number,
  }
  static defaultProps = {
    debug: false && __DEV__,
  }

  constructor(props) {
    super(props)
    this.state = {
      collapsed: true,
      needsCollapsing: true,
    }
  }

  toggleExpandCollapse = () => this.setState({collapsed: !this.state.collapsed})

  onDescriptionContentHeightChanged = height => {
    const needsCollapsing = height > this.props.collapsedHeight
    this.setState({needsCollapsing})
  }

  render = () => {
    const {collapsed, needsCollapsing} = this.state
    const {debug, header, html, /*collapsed, */ collapsedHeight} = this.props
    const d = {debug: debug || CExpandableContent.defaultProps.debug}
    const collapsedFinal = collapsed && needsCollapsing
    return (
      <div style={{border: d.debug ? "1px solid blue" : "none"}}>
        <div style={{position: "relative", backgroundColor: "white"}}>
          {header && <GalleryDescriptionHeader {...d}>{header}</GalleryDescriptionHeader>}
          <CCollapse
            //
            // heightTransition={`0.6s cubic-bezier(1, 0, 0, 1)`} // expo
            heightTransition={`0.6s cubic-bezier(0.83, 0, 0.17, 1)`} // cubic
            // heightTransition={`0.6s cubic-bezier(0.33, 1, 0.68, 1)`}
            collapsed={collapsedFinal}
            collapsedHeight={collapsedHeight}
            onContentHeightChanged={this.onDescriptionContentHeightChanged}>
            <GalleryDescription dangerouslySetInnerHTML={{__html: html}} />
          </CCollapse>
          {collapsedFinal && <GalleryDescriptionGradient />}
        </div>

        {needsCollapsing && (
          <GalleryDescriptionExpandCollapseButton
            //
            onClick={this.toggleExpandCollapse}>
            <div style={{width: 20}}>
              <CIcon
                //
                id={`HeaderMenuCaretDown`}
                rotationDegrees={collapsedFinal ? 0 : 180}
              />
            </div>
          </GalleryDescriptionExpandCollapseButton>
        )}
      </div>
    )
  }
}

const GalleryDescriptionHeader = styled.div`
  font-size: 17px;
  font-weight: ${props => props.theme.vars.fonts.weights.medium};
  text-transform: uppercase;
  font-kerning: none;
  letter-spacing: 0.15em;
  ${props => props.debug && selectors.getDebugOverlayCss(props, "CSectionGalleryBasics.PaddedContainer", "rgba(0,0,255,0.35)")}
  ${props => css`
    ${props.theme.media.mddown} {
      text-align: center;
    }
  `}
  padding-bottom: 10px;
`

const GalleryDescription = styled.div`
  font-size: 15px;
  letter-spacing: 0.1em;
  line-height: 24px;
  ${props => css`
    ${props.theme.media.mddown} {
      text-align: center;
    }
  `}

  p {
    background-color: ${props => (props.debug ? "red" : "none")};
    margin-bottom: 0;
    margin-block-end: 5px;
    margin-block-start: 5px;
  }

  a,
  p > a {
    color: #282828;
  }
`

const GalleryDescriptionGradient = styled.div`
  pointer-events: none;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 40px;
  background: rgb(255, 255, 255);
  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
`

const GalleryDescriptionExpandCollapseButton = styled.div`
  // background-color: rgba(0, 255, 0, 0.5);
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

export default CExpandableContent
