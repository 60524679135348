import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import styled, {css} from "styled-components" // eslint-disable-line
import * as selectors from "../../../data/selectors" // eslint-disable-line

import {debbify} from "../../../data/selectors/helpers"
const debby = (...args) => debbify("CH3", ...args)

class CH3 extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    text: PropTypes.string,
    variant: PropTypes.oneOf(["default", "umbrellaAppDownload", "umbrellaFeatures", "umbrellaPartners", "umbrellaPortfolio", "umbrellaCategories"]), // TODO: Renamve "umbrellaFeatures" (used in Feature Matrix too)
    noWordWrap: PropTypes.bool,
    noUpperCase: PropTypes.bool,
  }
  static defaultProps = {
    debug: false && __DEV__,
    variant: "default",
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  render = () => {
    const {debug, text, variant, noWordWrap, noUpperCase} = this.props
    const d = {debug: debug || CH3.defaultProps.debug}
    debby("render()", {variant, noUpperCase, text})
    return (
      <H3
        //
        {...d}
        variant={variant}
        noWordWrap={noWordWrap}
        noUpperCase={noUpperCase}
        dangerouslySetInnerHTML={{__html: text}}
      />
    )
  }
}

const fontSizeXl = 28
const scaleLg = 0.9
const scaleMd = 0.75
const scaleSm = 0.66

const H3 = styled.h3`
  font-weight: ${props => props.theme.vars.fonts.weights.extrabold};
  font-family: ${props => props.theme.vars.fonts.families.default};
  letter-spacing: 0.15em;
  font-kerning: none;
  padding: 0;
  margin: 0;

  ${props =>
    !props.noWordWrap &&
    css`
      hyphens: auto;
      word-wrap: break-word;
      white-space: break-word;
    `}
  ${props =>
    props.noWordWrap &&
    css`
      hyphens: unset;
      word-wrap: auto;
      white-space: auto;
    `}

  border: ${props => (props.debug ? 1 : 0)}px solid red;
  line-height: 1.5em;
  ${props => css`
    ${props.theme.media.lg} {
      font-size: ${fontSizeXl * scaleLg}px;
    }
    ${props.theme.media.md} {
      font-size: ${fontSizeXl * scaleMd}px;
    }
    ${props.theme.media.smdown} {
      font-size: ${fontSizeXl * scaleSm}px;
    }
  `}

  font-size: ${fontSizeXl}px;

  ${props =>
    !props.noUpperCase &&
    css`
      text-transform: uppercase;
    `}

  ${props =>
    (props.variant == "umbrellaFeatures" || props.variant == "umbrellaPartners" || props.variant == "umbrellaPortfolio" || props.variant == "umbrellaCategories") &&
    css`
      ${props.theme.media.smdown} {
        text-align: center;
        font-size: 20px;
        // line-height: 36px;
        color: #1e1e1e;
        letter-spacing: 4px;
      }
    `}

  ${props =>
    props.variant == "umbrellaAppDownload" &&
    css`
      text-align: center;
      letter-spacing: 0.08em;
      color: #1d1f1e;
      text-transform: none;
      font-size: ${fontSizeXl * 1.3}px;

      ${props.theme.media.smdown} {
        // font-weight: ${props.theme.vars.fonts.weights.bold};
        font-size: 22px;
        text-align: center;
      }
    `}

  ${props => selectors.getDebugOverlayCss(props, "H3", "rgba(0,0,255,0.25)")}
`

export default CH3
