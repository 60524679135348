import {createSelector} from "reselect"
import {getObjectDeep as god, getArrayShuffledWithSeed, getArrayOrNull} from "./helpers"
import {getIntlLocale} from "./intl"
import {CONFIG} from "../../config"

const getMatchFixed = match => {
  let matchFixed = match
  if (!!matchFixed && matchFixed.indexOf("?") != -1) {
    matchFixed = matchFixed.substr(0, matchFixed.indexOf("?"))
  }
  return matchFixed
}

export const getGallerySlugByMatch = (state, props) => getMatchFixed(god(props, "match.params.gallerySlug"))
export const getArtworkSlugByMatch = (state, props) => getMatchFixed(god(props, "match.params.artworkSlug"))
export const getLinkingKeyByMatch = (state, props) => getMatchFixed(god(props, "match.params.linkingKey"))

const getStatePostData = state => state.api.postData

export const getPostDataGalleryLanding = createSelector([getStatePostData], statePostData => {
  if (!!statePostData && statePostData.hasOwnProperty("/g")) {
    return statePostData["/g"].data
  }
  return null
})

export const getPostDataGalleryLandingArtwork = createSelector([getStatePostData], statePostData => {
  if (!!statePostData && statePostData.hasOwnProperty("/p")) {
    return statePostData["/p"].data
  }
  return null
})

// const getPostDataLastUpdated = state => state.api.postDataLastUpdated
export const getGalleryDataByMatch = createSelector([getStatePostData, getGallerySlugByMatch], (statePostData, gallerySlug) => {
  const path = `/g/${gallerySlug}`
  if (!!statePostData && statePostData.hasOwnProperty(path)) {
    return statePostData[path].data
  }
  return null
})

export const getGalleryTitleByMatch = createSelector([getGalleryDataByMatch], galleryData => {
  return god(galleryData, "title")
})

export const getGalleryContactEmailByMatch = createSelector([getGalleryDataByMatch], galleryData => {
  return god(galleryData, "contactEmail")
})

export const getGallerySecretCodeByMatch = createSelector([getGalleryDataByMatch], galleryData => {
  return god(galleryData, "secretCode")
})

export const getGalleryEmbedEnabledByMatch = createSelector([getGalleryDataByMatch], galleryData => {
  return god(galleryData, "embedEnabled", false)
})

export const getGalleryArtworks = createSelector([getGalleryDataByMatch, getIntlLocale], (galleryData, intlLocale) => {
  const relatedArtworks = god(galleryData, "relatedArtworks")
  const relatedArtists = god(galleryData, "relatedArtists")

  let galleryDefaultCurrency = god(galleryData, "defaultCurrency", "EUR") // eslint-disable-line
  // const gallerySlug = god(galleryData, "slug")
  const galleryCode = god(galleryData, "secretCode") // eslint-disable-line

  const dontShowArtworkPrices = god(galleryData, "dontShowArtworkPrices", false)

  if (!!relatedArtworks) {
    const imageSize = `500`
    const imageSizeWide = `1000`
    const imageSizeDetails = `1500`

    const artworks = relatedArtworks
      .filter(a => !a.status || a.status != "draft")
      .map(artwork => {
        // if (intlLocale != "en") { debugger } // prettier-ignore
        let imageUrl = god(artwork, `featuredImage.sizes.${imageSize}.url`)
        let imageWidth = god(artwork, `featuredImage.sizes.${imageSize}.width`, 100)
        let imageHeight = god(artwork, `featuredImage.sizes.${imageSize}.height`, 100)
        const aspectRatio = imageWidth / imageHeight // 16/9
        const aspectRatioInv = imageHeight / imageWidth
        if (aspectRatio >= 2 || aspectRatioInv >= 2) {
          imageUrl = god(artwork, `featuredImage.sizes.${imageSizeWide}.url`)
          imageWidth = god(artwork, `featuredImage.sizes.${imageSizeWide}.width`, 100)
          imageHeight = god(artwork, `featuredImage.sizes.${imageSizeWide}.height`, 100)
        }
        const priceEuro = !dontShowArtworkPrices ? god(artwork, "price") : 0 // can be 0
        const priceAllCurrencies = god(artwork, "priceAllCurrencies", {EUR: priceEuro})
        const purchaseLink = god(artwork, "purchaseLink")

        const {year, yearStarted, variants} = artwork

        let additionalImages = []
        if (!!artwork.additionalImages && !!artwork.additionalImages.length) {
          // for (const image of artwork.additionalImages) {
          //   if (!image) {
          //     console.warn(`Invalid image found in Artwork '${artwork.slug}' for Gallery with code '${galleryCode}'.`)
          //     debugger
          //   }
          // }
          additionalImages = artwork.additionalImages.filter(image => !!image && !image.isDraft)
        }
        additionalImages = getArrayOrNull(additionalImages)

        return {
          slug: artwork.slug,
          slugByTitle: artwork.slugByTitle || artwork.slug,
          title: artwork.title,
          artistsNames: artwork.artists.map(artistSlug => god(relatedArtists.find(artist => artist.slug == artistSlug), "title", "")), // prettier-ignore
          imageMasonry: {
            url: imageUrl,
            width: imageWidth,
            height: imageHeight,
          },
          imageDetails: {
            url: god(artwork, `featuredImage.sizes.${imageSizeDetails}.url`),
            width: god(artwork, `featuredImage.sizes.${imageSizeDetails}.width`, 100),
            height: god(artwork, `featuredImage.sizes.${imageSizeDetails}.height`, 100),
          },
          dimensionsReadable: god(artwork, `languageVariants.${intlLocale}.dimensionsReadable`, artwork.dimensionsReadable),
          techniquesAmount: god(artwork, "techniques", []).length,
          techniquesReadable: god(artwork, `languageVariants.${intlLocale}.techniquesReadable`, artwork.techniquesReadable),
          materialsAmount: god(artwork, "materials", []).length,
          materialsReadable: god(artwork, `languageVariants.${intlLocale}.materialsReadable`, artwork.materialsReadable),
          priceEuro,
          priceAllCurrencies,
          galleryDefaultCurrency,
          // content: god(artwork, "contentFiltered"),
          content: god(artwork, `languageVariants.${intlLocale}.contentFiltered`, god(artwork, `contentFiltered`)),
          availabilityStatus: artwork.availabilityStatus,
          authCertificate: artwork.authCertificate,
          additionalImages,
          yearStarted: !!yearStarted ? parseInt(yearStarted) : null,
          yearCreated: !!year ? parseInt(year) : null,
          purchaseLink,
          variants,
        }
      })
    const day = new Date().getUTCDate() + 1
    const artworksShuffled = getArrayShuffledWithSeed(artworks, day)
    return !!artworksShuffled.length ? artworksShuffled : null
  }
})

const getStateApiPublicWalls = state => state.api.publicWalls

export const getGalleryPublicWalls = createSelector(
  //
  [getStateApiPublicWalls, getGallerySlugByMatch, getGalleryArtworks],
  (stateApiPublicWalls, gallerySlug, galleryArtworks) => {
    const publicWallsRaw = god(stateApiPublicWalls, `${gallerySlug}.data`)
    const urlPrefix = CONFIG.server.root + CONFIG.server.folderWalls // https://iazzu.com/wp/wp-content/plugins/iazzu-plugin/api/uploads/walls/
    let publicWalls = !publicWallsRaw
      ? null
      : publicWallsRaw.map(wall => {
          const {id, lastImageUploadHash, artworkSlug} = wall
          const imageSizeSquare = `_1000c`
          const imageSizeFull = ``
          const artwork = !galleryArtworks ? {} : galleryArtworks.find(a => a.slug == artworkSlug)
          return {
            ...wall,
            imageUrlSquare: `${urlPrefix}${id}_${lastImageUploadHash}${imageSizeSquare}.jpg`,
            imageUrlFull: `${urlPrefix}${id}_${lastImageUploadHash}${imageSizeFull}.jpg`,
            artwork,
          }
        })

    if (!!publicWalls) {
      publicWalls = publicWalls.sort((a, b) => b.created - a.created)
    }

    return !!publicWalls && !!publicWalls.length ? publicWalls : null
  },
)

const getStateApiPublicWallsFetchState = state => state.api.publicWallsFetchState

export const getGalleryPublicWallsFetching = createSelector(
  //
  [getStateApiPublicWallsFetchState, getGallerySlugByMatch],
  (stateApiPublicWallsFetchState, gallerySlug) => {
    const fetching = god(stateApiPublicWallsFetchState, `${gallerySlug}.busy`, false)
    return fetching
  },
)
