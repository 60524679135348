import "react-app-polyfill/ie9"
import "react-app-polyfill/stable"
import React from "react"
import ReactDOM from "react-dom"

import App from "./App"
import BugReportManager from "./data/utils/BugReportManager"
import * as selectors from "./data/selectors" // eslint-disable-line
import * as serviceWorker from "./serviceWorker"

import "moment"
import "moment/locale/de.js"
import "moment/locale/es.js"
import "moment/locale/fr.js"

import "./styles/index.scss"

BugReportManager.init()

ReactDOM.render(<App />, document.getElementById("root"))

// const urlParams = selectors.getAllUrlParams(window.location.href)
// window.debugMode = false
// if (!!urlParams && urlParams.hasOwnProperty("debugkey") && urlParams["debugkey"] == "steff") {
//   removeLogsAndWarnings = false // to show console output in Release version, also set "dropConsoleForDeploy" (webpack-iazzu.config.js) to false and MUTE_DEBBY to false (without the " || !__DEV__")
//   window.debugMode = true
// }

let removeLogsAndWarnings = true && !__DEV__ // eslint-disable-line
if (removeLogsAndWarnings) {
  console.log = () => {}
  console.warn = () => {}
}

// console.log ("__DEV__: " + JSON.stringify (__DEV__))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
