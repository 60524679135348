/* xeslint-disable */

import React, {Component} from "react"
import PropTypes, {instanceOf} from "prop-types"
import isEqual from "lodash/isEqual"
import {injectIntl} from "react-intl"
import {connect} from "react-redux"
import styled, {css} from "styled-components" // eslint-disable-line
import {withCookies, Cookies} from "react-cookie"
import * as selectors from "../../../data/selectors"
import * as actions from "../../../data/actions"
const god = selectors.getObjectDeep

const COOKIE_ACCEPTED_NAME = "acceptedLia2020"

class CHomeLia2020Info extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    dispatch: PropTypes.func,
    globalsLocalized: PropTypes.object,
    accepted: PropTypes.bool,
    cookies: instanceOf(Cookies).isRequired,
  }
  static defaultProps = {
    debug: false && __DEV__,
  }

  componentDidMount = () => {
    // const d = {debug: this.props.debug || CHomeLia2020Info.defaultProps.debug}
    // if (d.debug) {
    //   setTimeout(() => {
    //     this.props.cookies.set(COOKIE_ACCEPTED_NAME, JSON.stringify(false), {path: "/"})
    //   }, 500)
    // }
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  // onOkayClick = e => {
  //   !!e && e.preventDefault()
  //   this.props.cookies.set(COOKIE_ACCEPTED_NAME, JSON.stringify(!this.props.accepted), {path: "/"})
  // }

  onButtonClick = () => {
    this.props.dispatch(actions.sendAnalyticsEvent("luxuy_award_button_clicked"))
  }

  render = () => {
    const {debug, globalsLocalized, accepted} = this.props
    const d = {debug: debug || CHomeLia2020Info.defaultProps.debug}

    // const paragraph = god(globalsLocalized, "sectionCookieNotice.paragraph", "")
    // const button = god(globalsLocalized, "sectionCookieNotice.button", "")
    const show = god(globalsLocalized, "sectionLIA2020.show", false)
    const text = god(globalsLocalized, "sectionLIA2020.text")
    const moreButtonLabel = god(globalsLocalized, "sectionLIA2020.moreButtonLabel")
    const moreButtonLink = god(globalsLocalized, "sectionLIA2020.moreButtonLink")

    if (!d.debug && accepted) {
      return <div />
    }
    if (!show || !text) {
      return <div />
    }
    return (
      <Container {...d}>
        <Text dangerouslySetInnerHTML={{__html: text}} />
        <ButtonContainer href={moreButtonLink} target={"_blank"} onClick={this.onButtonClick}>
          {moreButtonLabel}
        </ButtonContainer>
      </Container>
    )
  }
}

const Container = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  background-color: ${props => (props.debug ? "yellow" : "rgba(0,0,0,0.6)")};
  border-top: 1px solid #DDDDDD;
  margin-bottom: 20px;
  border-radius: 5px;
}
`
const Text = styled.div`
  flex: 1;
  align-self: stretch;
  color: ${props => props.theme.vars.colors.codGray};
  color: white;
  background-color: ${props => (props.debug ? "#ff000066" : "none")};
  letter-spacing: 0.1em;
  font-kerning: none;
  line-height: 1.4;
  padding-right: 20px;
  font-size: 11px;
  ${props =>
    css`
      ${props.theme.media.md} {
        // font-size: 16px;
      }
      ${props.theme.media.sm} {
        // font-size: 15px;
      }
      ${props.theme.media.xs} {
        // font-size: 13px;
      }
    `}

  p {
    // opacity: 0.2;
    margin-top: 0;
    margin-bottom: 0;
  }
`

const ButtonContainer = styled.a`
  width: auto;
  min-width: 0;
  align-self: flex-start;
  border: 1px solid ${props => props.theme.vars.colors.codGray};
  border: 1px solid white;
  color: ${props => props.theme.vars.colors.codGray};
  color: white;
  padding: 5px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.2em;
  font-kerning: none;
  cursor: pointer;
  font-size: 12px;
  text-transform: uppercase;
  margin-top: 10px;

  text-decoration: none !important;

  a {
    text-decoration: none !important;
  }
`

const mapStateToProps = (state, props) => ({
  intlLocale: selectors.getIntlLocale(state),
  globalsLocalized: selectors.getGlobalsLocalized(state),
  accepted: god(props.cookies, `cookies.${COOKIE_ACCEPTED_NAME}`, "false") == "true",
})
export default withCookies(injectIntl(connect(mapStateToProps)(CHomeLia2020Info)))
