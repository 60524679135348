import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import styled, {css} from "styled-components" // eslint-disable-line
import Measure from "react-measure"

import * as selectors from "../../../data/selectors"

import CEditInWordPress from "../../../view/components/CEditInWordPress"
import CLink from "../../../view/components/CLink"

class CPackageDetailCard extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    id: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired, // order
    intl: PropTypes.object.isRequired,
    title: PropTypes.string,
    titlePostfix: PropTypes.string,
    titlePostfixIsActuallyPrefix: PropTypes.bool, // show postfix before
    description: PropTypes.string,
    priceMonth: PropTypes.number,
    priceMonthFloat: PropTypes.number,
    priceInterval: PropTypes.oneOf(["monthly"]),
    billingPeriod: PropTypes.oneOf(["annually"]),
    hasFixedSetupCosts: PropTypes.bool,
    fixedSetupCosts: PropTypes.number,
    features: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        value: PropTypes.any.isRequired,
        influencesFixedSetupCosts: PropTypes.bool,
      }),
    ),
    onElementMeasure: PropTypes.func,
    currency: PropTypes.oneOf(["EUR", "CHF", "USD"]),
    prices: PropTypes.object,
    showEditButton: PropTypes.bool,
  }
  static defaultProps = {
    debug: false && __DEV__,
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  onElementMeasure = height => !!this.props.onElementMeasure && this.props.onElementMeasure(this.props.index, height)

  render = () => {
    const {debug, id, index, intl, title, titlePostfix, description, priceInterval, billingPeriod, hasFixedSetupCosts, fixedSetupCosts, features, currency, prices, showEditButton, titlePostfixIsActuallyPrefix} = this.props // eslint-disable-line
    const d = {debug: debug || CPackageDetailCard.defaultProps.debug}

    let setupCosts = hasFixedSetupCosts ? fixedSetupCosts : 0

    let {priceMonth, priceMonthFloat} = this.props
    let currencySymbol = "€"
    if (currency != "EUR") {
      priceMonth = prices[currency].month
      priceMonthFloat = prices[currency].monthFloat
      setupCosts = prices[currency].fixed
      if (currency == "USD") {
        currencySymbol = "$"
      } else if (currency == "CHF") {
        currencySymbol = "CHF"
      }
    }
    const showAfterComma = priceMonth != priceMonthFloat
    let setupCostsReadable = !!setupCosts ? setupCosts + " " + currencySymbol : null

    let titleHtml = `${title}${!!titlePostfix ? `<br/>${titlePostfix}` : ``}`
    if (titlePostfixIsActuallyPrefix) {
      titleHtml = `${!!titlePostfix ? `${titlePostfix}<br/>` : ``}${title}`
    }

    const showNoVatInfo = currency === "CHF"

    return (
      <div>
        <Container {...d}>
          <PaddedContent {...d}>
            <Title {...d} dangerouslySetInnerHTML={{__html: titleHtml}} />
            {!!description && (
              <Measure
                //
                onResize={contentRect => this.onElementMeasure(contentRect.bounds.height)}
                bounds
              >
                {({measureRef}) => (
                  <Description ref={measureRef} {...d}>
                    {description || ""}
                  </Description>
                )}
              </Measure>
            )}
            <PriceContainer {...d}>
              <PriceBigContainer {...d}>
                <CPriceBig
                  {...d}
                  //
                  showAfterComma={showAfterComma}
                  priceFloat={priceMonthFloat}
                  currencySymbol={currencySymbol}
                />
              </PriceBigContainer>
              <PricePeriod {...d}>
                <PricePeriodPriceInterval>
                  <span>{intl.formatMessage({id: getIdForPriceInterval(priceInterval)})},</span>
                </PricePeriodPriceInterval>
                <PricePeriodSpacer {...d} />
                <PricePeriodBillingPeriod>
                  <span>
                    {intl.formatMessage({id: getIdForBillingPeriod(billingPeriod)})}
                    {showNoVatInfo ? " **" : ""}
                  </span>
                </PricePeriodBillingPeriod>
              </PricePeriod>
            </PriceContainer>
            <FixedSetupCostsContainer {...d}>
              <span>+ </span>
              <span>{!hasFixedSetupCosts && intl.formatMessage({id: `GalleryPackageHasNoFixedSetupCosts`})}</span>
              <span>{hasFixedSetupCosts && intl.formatMessage({id: `GalleryPackageFixedSetupCosts`}, {costs: setupCostsReadable})}</span>
              {showNoVatInfo ? " **" : ""}
            </FixedSetupCostsContainer>
            {!!features && (
              <FeaturesContainer {...d}>
                {features.map(feature => {
                  const {key, value, influencesFixedSetupCosts} = feature
                  return (
                    <FeatureContainer key={`feature_${key}`} {...d}>
                      <FeatureText {...d}>{intl.formatMessage({id: getIdForFeatureKey(key)})}</FeatureText>
                      <FeatureValue {...d}>{value}</FeatureValue>
                      <FeatureIndicator {...d}>{influencesFixedSetupCosts ? "*" : ""}</FeatureIndicator>
                    </FeatureContainer>
                  )
                })}
              </FeaturesContainer>
            )}
          </PaddedContent>
          <CLink to={"/contact"}>
            <ButtonContact>
              <span>{intl.formatMessage({id: getIdForContactButton(hasFixedSetupCosts)})}</span>
            </ButtonContact>
          </CLink>

          {showEditButton && <CEditInWordPress resourceId={id} label={"EDIT"} />}
        </Container>
        {showNoVatInfo && <PriceWithoutVat>** {intl.formatMessage({id: "PriceWithoutVat"})}</PriceWithoutVat>}
      </div>
    )
  }
}

const PriceWithoutVat = styled.div`
  text-align: center;
  padding: 0px 10px 10px 10px;
  letter-spacing: 0.1em;
  color: ${props => props.theme.vars.colors.codGray};
  opacity: 0.75;
  font-size: 14px;
`

class CPriceBig extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    showAfterComma: PropTypes.bool,
    priceFloat: PropTypes.number,
    currencySymbol: PropTypes.string,
  }
  static defaultProps = {
    debug: false && __DEV__,
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  render = () => {
    const {debug, priceFloat, showAfterComma, currencySymbol} = this.props
    const d = {debug: debug || CPriceBig.defaultProps.debug}
    const priceBeforeComma = parseInt(Math.floor(priceFloat))
    let afterComma = ""
    if (showAfterComma) {
      let afterCommaValue = Math.round((priceFloat - priceBeforeComma) * 100)
      afterComma = afterCommaValue
    }
    return (
      <PriceBig {...d}>
        <PriceBigBeforeComma {...d}>
          <span>{priceBeforeComma}</span>
        </PriceBigBeforeComma>
        {!!afterComma && (
          <PriceAfterComma {...d}>
            <span>'{afterComma}</span>
          </PriceAfterComma>
        )}
        <PriceBigBeforeComma {...d} isCurrencySymbol>
          <span>{currencySymbol}</span>
        </PriceBigBeforeComma>
      </PriceBig>
    )
  }
}

const PriceBig = styled.div`
  display: flex;
  font-family: ${props => props.theme.vars.fonts.families.numerals};
  font-weight: ${props => props.theme.vars.fonts.weights.extrabold};
`

const PriceBigBeforeComma = styled.div`
  letter-spacing: 0.1em;
  ${props => selectors.getBackgroundColorByProps(props, "rgba(0,0,255,0.25)")} // padding-bottom: 5px;  
  margin-left: ${props => (props.isCurrencySymbol ? "5px" : "0")};
  font-size: 50px;
  ${props => css`
    ${props.theme.media.md} {
      font-size: 40px;
    }
  `}
`

const PriceAfterComma = styled.div`
  font-size: 23px;
  padding-top: 14px;
  letter-spacing: 0.15em;
  margin-left: -3px;
  ${props => selectors.getBackgroundColorByProps(props, "rgba(0,255,0,0.25)")}// padding-bottom: 5px;
`

const getIdForPriceInterval = priceInterval => {
  let priceIntervalIntlId = null
  switch (priceInterval) {
    case "monthly":
      priceIntervalIntlId = "BillingPriceIntervalMonthly"
      break
    default:
      priceIntervalIntlId = "BillingPriceIntervalMonthly"
      break
  }
  return priceIntervalIntlId
}

const getIdForBillingPeriod = billingPeriod => {
  let billingPeriodIntlId = null
  switch (billingPeriod) {
    case "annually":
      billingPeriodIntlId = "BillingPeriodAnnually"
      break
    default:
      billingPeriodIntlId = "BillingPeriodAnnually"
      break
  }
  return billingPeriodIntlId
}

const getIdForFeatureKey = featureKey => {
  let featureIntlId = null
  switch (featureKey) {
    case "surfaces":
      featureIntlId = "GalleryPackageFeatureSurfaces"
      break
    case "artworks":
      featureIntlId = "GalleryPackageFeatureArtworks"
      break
    case "artists":
      featureIntlId = "GalleryPackageFeatureArtists"
      break
    case "qrCodes":
      featureIntlId = "GalleryPackageFeatureQrCodes"
      break
    default:
      if (__DEV__) {
        throw new Error(`Can't determine intl id for featureKey '${featureKey}'.`)
      }
      featureIntlId = "GalleryPackageFeatureSurfaces"
      break
  }
  return featureIntlId
}

const getIdForContactButton = hasFixedSetupCosts => {
  let contactButtonIntlId = null
  if (hasFixedSetupCosts) {
    contactButtonIntlId = "GalleryPackageContactButtonHasFixedSetupCosts"
  } else {
    contactButtonIntlId = "GalleryPackageContactButtonHasNoFixedSetupCosts"
  }
  return contactButtonIntlId
}

const Container = styled.div`
  width: 100%;
  margin: 20px 0px;
  ${props => selectors.getDebugOverlayCss(props, "Container", "rgba(0,0,255,0.35)")}
  background-color: white;
  color: ${props => props.theme.vars.colors.codGray};
  text-align: left;
`

const PaddedContent = styled.div`
  ${props => selectors.getDebugOverlayCss(props, "PaddedContent", "rgba(0,0,255,0.35)")}
  padding: 50px 45px 40px 45px;
  ${props => css`
    ${props.theme.media.md} {
      padding: 30px 25px 20px 25px;
    }
    ${props.theme.media.sm} {
      padding: 30px 35px 20px 35px;
    }
    ${props.theme.media.xs} {
      padding: 25px 30px 20px 30px;
    }
  `}
`

const Title = styled.div`
  font-size: 28px;
  font-weight: ${props => props.theme.vars.fonts.weights.extrabold};
  letter-spacing: 0.2em;
  line-height: 1.3;
  text-transform: uppercase;
  font-kerning: none;
  ${props => selectors.getBackgroundColorByProps(props, "rgba(0,0,255,0.25)")}// padding-bottom: 5px;
`

const Description = styled.div`
  font-size: 18px;
  line-height: 1.8;
  letter-spacing: 0.05em;
  ${props => selectors.getBackgroundColorByProps(props, "rgba(255,0,0,0.25)")}
  padding-top: 20px;
  min-height: 70px;
`

const PriceContainer = styled.div`
  display: flex;
  ${props => selectors.getDebugOverlayCss(props, "PriceContainer", "rgba(255,255,0,0.35)")}
  padding-top: 20px;
  padding-bottom: 0px;
`
const PriceBigContainer = styled.div`
  ${props => selectors.getBackgroundColorByProps(props, "rgba(0,0,255,0.25)")}
  height: 60px;
  display: flex;
  align-items: center;
  padding-right: 10px;
  ${props => css`
    ${props.theme.media.md} {
      height: 50px;
    }
  `}
`
const PricePeriod = styled.div`
  flex: 1;
  align-self: stretch;
  ${props => selectors.getBackgroundColorByProps(props, "rgba(0,255,0,0.25)")}
  display: flex;
  flex-direction: column;
  font-size: 14px;
`

const PricePeriodPriceInterval = styled.div`
  margin-top: 14px;
  ${props => selectors.getBackgroundColorByProps(props, "rgba(255,0,0,0.25)")}
`

const PricePeriodSpacer = styled.div`
  flex: 1;
  ${props => selectors.getBackgroundColorByProps(props, "rgba(255,255,255,0.25)")}
`

const PricePeriodBillingPeriod = styled.div`
  ${props => selectors.getBackgroundColorByProps(props, "rgba(255,0,255,0.25)")}
  margin-bottom: 5px;
`

const FixedSetupCostsContainer = styled.div`
  padding-top: 15px;
  padding-bottom: 50px;
  font-size: 15px;
  line-height: 1.4;
  opacity: 0.5;
  ${props => selectors.getDebugOverlayCss(props, "FixedSetupCostsContainer", "rgba(0,255,255,0.35)")}
`

const FeaturesContainer = styled.div`
  padding: 20px 0px;
  ${props => selectors.getDebugOverlayCss(props, "FeaturesContainer", "rgba(0,255,0,0.35)")}
`

const FeatureContainer = styled.div`
  display: flex;
  padding: 5px 0px;
  margin-right: -10px;
`

const FeatureText = styled.div`
  flex: 1;
  opacity: 0.75;
  letter-spacing: 0.05em;
  font-weight: ${props => props.theme.vars.fonts.weights.regular};
`

const FeatureValue = styled.div`
  padding-left: 10px;
  ${props => selectors.getBackgroundColorByProps(props, "rgba(255,0,255,0.25)")}
`

const FeatureIndicator = styled.div`
  width: 10px;
  ${props => selectors.getBackgroundColorByProps(props, "rgba(255,0,0,0.25)")}
  text-align: right;
`

const ButtonContact = styled.div`
  background-color: ${props => props.theme.vars.colors.codGray};
  padding: 15px;
  font-size: 13px;
  line-height: 1.6;
  text-align: center;
  color: white;
  text-transform: uppercase;
  letter-spacing: 0.2em;
`

export default CPackageDetailCard
