import React, {Component, Fragment} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import {connect} from "react-redux"
import {injectIntl} from "react-intl"
import {Link as SectionLink} from "react-scroll" // eslint-disable-line
import {motion, useViewportScroll, useTransform, AnimatePresence} from "framer-motion" // eslint-disable-line
import {scroller} from "react-scroll"
import styled, {css, ThemeProvider} from "styled-components" // eslint-disable-line

import {CONFIG} from "../../config" // eslint-disable-line
import * as selectors from "../../data/selectors"
import CInitMotion from "../../view/components/CInitMotion"

//  eslint-disable-next-line
const debby = (funcName, data = null) => console.log("CFixedSections." + funcName, data)

class CFixedSections extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    items: PropTypes.array.isRequired,
    itemActiveKey: PropTypes.string,
    activeItemKey: PropTypes.string,
    theme: PropTypes.string,
    defaultThemeProps: PropTypes.object,
  }
  static defaultProps = {
    debug: false && __DEV__,
    defaultThemeProps: {
      backgroundColor: "rgba(0,0,0,0)",
      textColor: "black",
      backgroundColorMobile: "rgba(255,255,255,0.85)",
    },
  }

  constructor(props) {
    super(props)
    this.state = {}
  }

  // componentWillMount = () => {}
  // componentDidMount = () => {}

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  // eslint-disable-next-line
  onSectionClick = (scrollElementName, i, key) => {
    scroller.scrollTo(scrollElementName, {duration: 1000, delay: 100, smooth: "easeInOutQuint"})
  }

  render = () => {
    const {debug, theme, intl, items, activeItemKey, defaultThemeProps} = this.props
    const d = {debug}
    if (!items || !items.length) {
      return null
    }
    let themeOverrides = {}
    if (theme == "dark") {
      themeOverrides = {
        textColor: "white",
        backgroundColorMobile: "rgba(30,30,30,0.85)", // codGray
      }
    }

    return (
      <ThemeProvider theme={{...defaultThemeProps, ...themeOverrides}}>
        <Container {...d}>
          <ScrollableContent {...d}>
            {items.map((item, i) => {
              const {key, intlId, scrollElementName} = item
              const active = key == activeItemKey
              const label = intl.formatMessage({id: intlId})
              return (
                <Fragment key={`item_${key}`}>
                  {/* eslint-disable-line */}
                  <SectionItem {...d} whileHover={{scale: 1.05}} onClick={() => this.onSectionClick(scrollElementName, i, key)}>
                    <CInitMotion delay={300 + i * 100}>
                      <LabelContainer {...d} active={active}>
                        <Label {...d} active={active} dangerouslySetInnerHTML={{__html: label}} />
                      </LabelContainer>
                    </CInitMotion>
                  </SectionItem>
                  {i < items.length && <SectionSpacer />}
                </Fragment>
              )
            })}
          </ScrollableContent>
        </Container>
      </ThemeProvider>
    )
  }
}

// const ScrollToEnabled =

//  prettier-ignore
const Container = styled.div`
  z-index: 1; // lower than CHeaderMenu

  position: fixed;
  width: ${props => props.theme.vars.padding.horizontal.header.xl}px;
  ${props => Object.keys(props.theme.media).map((mq) => css`
    ${props.theme.media[mq]} {
      width: ${props.theme.vars.padding.horizontal.header[mq]}px;
    }
  `)}

  flex-direction: column;

  transition: background-color ${props => props.theme.vars.transitions.themeTransitionDuration}s linear;
  ${props => css`
    ${props.theme.media.md} {
      width: ${props.theme.vars.padding.horizontal.content.md}px;
    }
  `}

  display: flex;
  left: 0;
  top: 0;
  height: 100%;
  bottom: 0;
  overflow: scroll;
  ${props => css`
    ${props.theme.media.smdown} {
      display: block;
      top: auto;
      height: auto;
      width: 100%;
      background-color: ${props => props.theme.backgroundColorMobile};
    }
  `}

  background-color: ${props => props.theme.backgroundColor};
  ${props => selectors.getBackgroundColorByProps(props, "rgba(255,0,255,0.35)")}
`

const ScrollableContent = styled.div`
  width: 100%;
  flex: 1 1 auto;
  overflow-y: auto;
  padding-top: 90px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  min-height: 0px;

  ${props => css`
    ${props.theme.media.smdown} {
      white-space: nowrap;
      display: block;
      width: 100%;
      padding: 0px 10px;
      overflow-x: scroll;
      -webkit-overflow-scrolling: touch;
    }
  `}
`

const SectionItem = styled(motion.div)`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: ${props => props.theme.vars.fonts.weights.bold};
  font-kerning: none;
  letter-spacing: 0.3em;
  cursor: pointer;

  ${props => css`
    ${props.theme.media.smdown} {
      flex: 0;
      display: inline-block;
      padding: 0px 10px;
    }
  `}

  ${props => selectors.getBackgroundColorByProps(props, "rgba(255,0,255,0.35)")}
`

const SectionSpacer = styled.div`
  flex: 1;
  align-self: stretch;

  ${props => css`
    ${props.theme.media.smdown} {
      display: inline-block;
    }
  `}
`
//  Item Label

const LabelContainer = styled.div`
  padding: 20px 5px;
  white-space: nowrap;
  align-self: stretch;

  writing-mode: vertical-rl;
  transform: rotate(-180deg);
  ${props => css`
    ${props.theme.media.smdown} {
      writing-mode: horizontal-tb;
      transform: rotate(0deg);
    }
  `}

  opacity ${props => (props.active ? 1 : 0.5)};
  color: ${props => props.theme.textColor};
  transition: opacity ${props => props.theme.vars.transitions.themeTransitionDuration}s linear, color ${props => props.theme.vars.transitions.themeTransitionDuration}s linear;
`

const Label = styled.span`
  border-left: 1px solid ${props => (props.active ? props.theme.textColor : "rgba(0, 0, 0, 0.01)")};
  padding: 0px 5px;

  ${props => css`
    ${props.theme.media.smdown} {
      padding: 5px 0px;
      border-left: 1px solid rgba(0, 0, 0, 0.01);
      border-bottom: 1px solid ${props => (props.active ? props.theme.textColor : "rgba(0, 0, 0, 0.01)")};
    }
  `}
  transition: all ${props => props.theme.vars.transitions.themeTransitionDuration}s linear;
`

const mapStateToProps = state => ({
  intlLocale: selectors.getIntlLocale(state),
})
export default injectIntl(connect(mapStateToProps)(CFixedSections))
