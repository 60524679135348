import React, {Component, Fragment} from "react"
import PropTypes from "prop-types"
import {injectIntl} from "react-intl"
import {connect} from "react-redux"
import isEqual from "lodash/isEqual"
import {motion, useViewportScroll, useTransform, AnimatePresence} from "framer-motion" // eslint-disable-line
import styled, {css, ThemeProvider} from "styled-components"
import {Row, Col} from "react-grid-system"

import * as actions from "../../../data/actions"
import * as selectors from "../../../data/selectors"
import {getObjectDeep as god} from "../../../data/selectors/helpers"
import {CONFIG} from "../../../config"

import CImage from "../../../view/components/CImage"
import CButton from "../../../view/components/CButton"
import CLink from "../../../view/components/CLink"
import CIcon from "../../../view/components/CIcon"
import CBranchQrCode from "../../../view/components/CBranchQrCode"

import CH3 from "../../../view/components/text/CH3"

import CExpandableContent from "../SGalleryLanding/CExpandableContent"

const DEBUG = false && __DEV__
import {debbify} from "../../../data/selectors/helpers"
const debby = (...args) => debbify("CSectionGalleryArtworkDetails", ...args)

class CSectionGalleryArtworkDetails extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    defaultThemeProps: PropTypes.object,
    artwork: PropTypes.object.isRequired,
    gallerySlug: PropTypes.string.isRequired,
    galleryContactEmail: PropTypes.string.isRequired,
    artworkUrlForEmail: PropTypes.string,
    salesInquiryButtonLabel: PropTypes.string,
    backToGalleryLabel: PropTypes.string,
    artworkBranchShareInfos: PropTypes.object,

    // connect
    embeddedMode: PropTypes.bool,
  }
  static defaultProps = {
    debug: DEBUG,
    defaultThemeProps: {
      // backgroundColor: "white",
      textColor: "black",
    },
    maxHeightDescription: 200,
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  showArtworkLightbox = () => {
    debby("showArtworkLightbox()")
    this.props.dispatch(
      actions.showImageOverlay({
        //
        title: this.props.artwork.title,
        subtitle: this.props.artwork.artistsNames.join(", "),
        url: this.props.artwork.imageDetails.url,
      }),
    )
  }

  onSalesInquiryClick = () => {
    this.props.dispatch(actions.sendAnalyticsEvent(`saley_inquiry_click`, {GallerySlug: this.props.gallerySlug, ArtworkSlug: this.props.artwork.slug}))
  }

  onPurchaseLinkClick = () => {
    this.props.dispatch(actions.sendAnalyticsEvent(`purchase_link_click`, {GallerySlug: this.props.gallerySlug, ArtworkSlug: this.props.artwork.slug, PurchaseLink: this.props.artwork.purchaseLink}))
  }

  onAdditionalImageClick = (image, i) => {
    debby("onAdditionalImageClick()", {image, i})
    this.props.dispatch(
      actions.showImageOverlay({
        //
        title: "",
        subtitle: "",
        url: image.sizes["1500"].url,
      }),
    )
  }

  render = () => {
    // const {amountShown} = this.state
    const {debug, intl, artworkBranchShareInfos, embeddedMode, gallerySlug, galleryContactEmail, defaultThemeProps, artwork, artworkUrlForEmail, salesInquiryButtonLabel, backToGalleryLabel} = this.props
    const d = {debug: debug || CSectionGalleryArtworkDetails.defaultProps.debug}

    const rowProps = {
      type: "flex",
      align: "stretch",
      justify: "start",
      width: "100%",
      height: "100%",
      gutter: 0,
    }

    const showPrice = !!artwork && !!artwork.priceEuro

    let priceReadable = intl.formatMessage({id: `PriceOnRequestLabel`})

    let pricesVariantsReadable = [] // Variants
    if (showPrice) {
      const priceInGalleryDefaultCurrency = god(artwork, `priceAllCurrencies.${artwork.galleryDefaultCurrency}`, null)

      if (artwork.galleryDefaultCurrency != "EUR" && !!priceInGalleryDefaultCurrency) {
        priceReadable = `${formatMoney(priceInGalleryDefaultCurrency.value)} ${artwork.galleryDefaultCurrency}`
      } else if (artwork.galleryDefaultCurrency == "EUR") {
        priceReadable = `${formatMoney(artwork.priceEuro)} €`
      }

      if (!!artwork.variants && !!artwork.variants.length) {
        for (const variant of artwork.variants) {
          let priceVariantReadable = null
          const priceVariantInGalleryDefaultCurrency = god(variant, `priceAllCurrencies.${artwork.galleryDefaultCurrency}`, null)
          if (artwork.galleryDefaultCurrency != "EUR" && !!priceVariantInGalleryDefaultCurrency) {
            priceVariantReadable = `${formatMoney(priceVariantInGalleryDefaultCurrency)} ${artwork.galleryDefaultCurrency}`
          } else if (artwork.galleryDefaultCurrency == "EUR") {
            priceVariantReadable = `${formatMoney(variant.price)} €`
          }
          pricesVariantsReadable.push(priceVariantReadable)
        }
      }
    }

    const intlIdForArtworkPriceProp = selectors.getIntlIdForArtworkPriceProp({status: artwork.availabilityStatus, price: artwork.priceEuro})

    let priceLabel = !!intlIdForArtworkPriceProp ? intl.formatMessage({id: intlIdForArtworkPriceProp}) : priceReadable
    if (!!pricesVariantsReadable.length) {
      priceLabel = priceLabel + "<br/>" + pricesVariantsReadable.join("<br/>")
    }

    const intlIdForAvailabilityStatus = selectors.getCC(`AvailabilityStatus-${artwork.availabilityStatus}`)

    const showDimensions = !!artwork && !!artwork.dimensionsReadable
    const showMaterials = !!artwork && !!artwork.materialsReadable
    const showTechniques = !!artwork && !!artwork.techniquesReadable
    const showCreated = !!artwork && !!artwork.yearCreated
    const showPurchaseLink = !!artwork && !!artwork.purchaseLink

    if (!artwork) {
      return <div />
    }

    let dimensionsReadable = showDimensions ? artwork.dimensionsReadable : ""
    if (!!artwork.variants) {
      let dimensionsVariantsReadable = []
      for (const variant of artwork.variants) {
        dimensionsVariantsReadable.push(
          intl.formatMessage(
            {id: "ArtworkDimensions"},
            {
              widthcm: parseFloat(variant.widthCm.toFixed(2)),
              heightcm: parseFloat(variant.heightCm.toFixed(2)),
            },
          ),
        )
      }
      dimensionsReadable += `<br/>` + dimensionsVariantsReadable.join("<br/>")
    }

    const salesInquiryEmailSubject = !artwork ? "" : selectors.getSubjectSalesInquiryEmail(artwork.availabilityStatus, artwork.priceEuro, !!artwork.purchaseLink)

    debby("render()", {additionalImages: artwork.additionalImages})
    return (
      <ThemeProvider theme={{...defaultThemeProps}}>
        <PaddedContainer
          //
          {...d}
          variant={"header"}
          variantSmall={null}
        >
          <Content {...d}>
            {/* Image & Infos */}
            <Row {...rowProps}>
              <Col lg={6} md={12}>
                <AspectContainer {...d} ratio={1 / 1}>
                  <AspectContent onClick={this.showArtworkLightbox}>
                    {!!artwork.imageDetails && !!artwork.imageDetails.url && (
                      <ArtworkImage
                        //
                        src={artwork.imageDetails.url}
                      />
                    )}
                  </AspectContent>
                </AspectContainer>
              </Col>
              <Col lg={6} md={12}>
                <InfosCol {...d}>
                  <ArtistName {...d}>{artwork.artistsNames.join(", ")}</ArtistName>
                  <ArtworkTitle {...d}>{artwork.title}</ArtworkTitle>
                  {/* Dimensions */}
                  {showDimensions && (
                    <Fragment>
                      <ArtworkPropKey {...d}>{selectors.getFirstCapital(intl.formatMessage({id: `ArtworkDetailsDimensionsKey`}))}</ArtworkPropKey>
                      <ArtworkPropValue {...d} dangerouslySetInnerHTML={{__html: dimensionsReadable}} />
                    </Fragment>
                  )}
                  {/* Technique(s) */}
                  {showTechniques && (
                    <Fragment>
                      <ArtworkPropKey {...d}>{selectors.getFirstCapital(intl.formatMessage({id: `ArtworkDetailsTechniquesKey`}, {amount: artwork.techniquesAmount}))}</ArtworkPropKey>
                      <ArtworkPropValue {...d}>{artwork.techniquesReadable}</ArtworkPropValue>
                    </Fragment>
                  )}
                  {/* Material(s) */}
                  {showMaterials && (
                    <Fragment>
                      <ArtworkPropKey {...d}>{selectors.getFirstCapital(intl.formatMessage({id: `ArtworkDetailsMaterialsKey`}, {amount: artwork.materialsAmount}))}</ArtworkPropKey>
                      <ArtworkPropValue {...d}>{artwork.materialsReadable}</ArtworkPropValue>
                    </Fragment>
                  )}
                  {/* Created */}
                  {showCreated && (
                    <Fragment>
                      <ArtworkPropKey {...d}>{selectors.getFirstCapital(intl.formatMessage({id: `ArtworkDetailsYearKey`}))}</ArtworkPropKey>
                      <ArtworkPropValue {...d}>{!!artwork.yearStarted ? `${artwork.yearStarted} – ${artwork.yearCreated}` : `${artwork.yearCreated}`}</ArtworkPropValue>
                    </Fragment>
                  )}
                  {/* Price */}
                  {true && (
                    <Fragment>
                      <ArtworkPropKey {...d}>{selectors.getFirstCapital(intl.formatMessage({id: `ArtworkDetailsPriceKey`}))}</ArtworkPropKey>
                      {false && <ArtworkPropValue {...d}>{priceLabel}</ArtworkPropValue>}
                      <ArtworkPropValue {...d} dangerouslySetInnerHTML={{__html: priceLabel}} />
                    </Fragment>
                  )}
                  {/* "Sales Inquiry" / "Purchase" Button */}
                  <SalesInquiryButtonContainer {...d}>
                    <div style={{alignSelf: "stretch", flex: 1, alignItems: "flex-end", display: "flex", flexDirection: "column"}}>
                      <div style={{flex: 1, alignSelf: "stretch"}}>
                        <div>
                          <ArtworkPropKey {...d}>{selectors.getFirstCapital(intl.formatMessage({id: `ArtworkDetailsAvailabilityHeader`}))}</ArtworkPropKey>
                          <ArtworkPropValue {...d}>{selectors.getFirstCapital(intl.formatMessage({id: intlIdForAvailabilityStatus}))}</ArtworkPropValue>
                        </div>
                        {artwork.authCertificate && (
                          <div>
                            <ArtworkPropKey {...d}>{selectors.getFirstCapital(intl.formatMessage({id: `ArtworkEditorInputAuthCertificatePlaceholder`}))}</ArtworkPropKey>
                            <ArtworkPropValue {...d}>{selectors.getFirstCapital(intl.formatMessage({id: `IsEditionStatusYes`}))}</ArtworkPropValue>
                          </div>
                        )}
                        {true && <InfosSpacer {...d} />}
                      </div>
                      {!showPurchaseLink && !!galleryContactEmail && !!artworkUrlForEmail && (
                        <CButton
                          //
                          {...d}
                          label={salesInquiryButtonLabel}
                          onClick={this.onSalesInquiryClick}
                          urlTo={`mailto:${galleryContactEmail}?subject=${encodeURI(salesInquiryEmailSubject + ": " + artwork.title + " - " + artwork.artistsNames.join(", "))}&body=${encodeURI("\n\n\nvia: " + artworkUrlForEmail)}`}
                          variant={"gallery-landing-sales-inquiry"} // centered < md
                        />
                      )}
                      {showPurchaseLink && (
                        <CButton
                          //
                          {...d}
                          label={intl.formatMessage({id: `WebArtworkDetailsButtonPurchaseLinkLabel`})}
                          onClick={this.onPurchaseLinkClick}
                          urlTo={artwork.purchaseLink}
                          openInNewWindow
                          variant={"gallery-landing-sales-inquiry"} // centered < md
                        />
                      )}
                    </div>
                    {!!artworkBranchShareInfos && (
                      <BranchQrCodeContainer {...d}>
                        <CBranchQrCode
                          //
                          label={intl.formatMessage({id: `GalleryLandingViewAtHome`})}
                          qrImageUrl={artworkBranchShareInfos.qrImageUrl}
                        />
                      </BranchQrCodeContainer>
                    )}
                  </SalesInquiryButtonContainer>
                </InfosCol>
              </Col>
            </Row>

            {DEBUG && false && <pre>{JSON.stringify(artwork, null, 2)}</pre>}

            {/* < Back to Gallery */}
            <CLink
              //
              search={embeddedMode ? "?embed=true" : ""}
              to={`/g/${gallerySlug}`}
            >
              <BackToGalleryButtonSection {...d}>
                <BackToGalleryButton {...d}>
                  <BackToGalleryButtonIcon {...d}>
                    <CIcon id={"CaretBack"} color={CONFIG.layout.colors.codGray} />
                  </BackToGalleryButtonIcon>
                  <BackToGalleryButtonLabel {...d}>{backToGalleryLabel}</BackToGalleryButtonLabel>
                </BackToGalleryButton>
              </BackToGalleryButtonSection>
            </CLink>
          </Content>
        </PaddedContainer>

        {!!artwork.additionalImages && (
          <PaddedContainer
            //
            {...d}
            // style={{paddingTop: 50}}
            variant={"header"}
            variantSmall={"header"}
          >
            <H3Container {...d}>
              <CH3 text={intl.formatMessage({id: `ArtworkDetailsHeaderMedia`})} />
            </H3Container>

            <ItemsContainer>
              <Row {...rowProps}>
                {artwork.additionalImages.map((image, i) => {
                  const url500 = god(image, "sizes.500.url")
                  return (
                    <Col key={`addimae_${i}`} lg={3} md={4} sm={6} xs={6}>
                      <ItemContainer>
                        <div onClick={() => this.onAdditionalImageClick(image, i)}>
                          <CImage
                            //
                            src={url500}
                            ratio={1}
                            //
                          />
                        </div>
                      </ItemContainer>
                    </Col>
                  )
                })}
              </Row>
            </ItemsContainer>

            {false && (
              <div style={{textAlign: "center"}}>
                {artwork.additionalImages.map((image, i) => {
                  const url500 = god(image, "sizes.500.url")
                  return (
                    <div key={`addimage_${i}`} style={{display: "inline-block", border: "1px solid blue", width: "33%"}}>
                      {false && <img style={{width: 190, height: 190, objectFit: "cover", paddingLeft: 5, paddingTop: 5}} src={url500} />}
                      <CImage
                        //
                        src={url500}
                        ratio={1}

                        //
                      />
                    </div>
                  )
                })}
              </div>
            )}
          </PaddedContainer>
        )}

        {!!artwork.content && (
          <PaddedContainer
            //
            {...d}
            variant={"header"}
            variantSmall={"content"}
            style={{paddingTop: 50}}
          >
            <CExpandableContent
              //
              header={intl.formatMessage({id: `ArtworkDetailsAdditionalInformation`})}
              html={artwork.content}
              // collapsed={descriptionCollapsedFinal}
              collapsedHeight={300}
              // collapsedHeight={2000}
              // maxHeightDescription={}
            />
          </PaddedContainer>
        )}
      </ThemeProvider>
    )
  }
}

// eslint-disable-next-line
const getNumberFormatted = (num, separatorThousand = ".", separatorZero = ".") => {
  let p = num.toFixed(3).split(".")
  const zeroes = true

  const ret =
    p[0]
      .split("")
      .reverse()
      .reduce((acc, num, i) => {
        return num == "-" ? acc : num + (i && !(i % 3) ? separatorThousand : "") + acc
      }, "") + (zeroes ? separatorZero + p[1] : "")

  return ret
}

const formatMoney = (number, decPlacesP = 2, decSepP = ".", thouSepP = ",") => {
  let decPlaces = isNaN((decPlacesP = Math.abs(decPlacesP))) ? 2 : decPlacesP
  let decSep = typeof decSepP === "undefined" ? "." : decSepP
  let thouSep = typeof thouSepP === "undefined" ? "," : thouSepP

  var sign = number < 0 ? "-" : ""
  var i = String(parseInt((number = Math.abs(Number(number) || 0).toFixed(decPlaces))))
  var j = (j = i.length) > 3 ? j % 3 : 0

  return (
    sign +
    (j ? i.substr(0, j) + thouSep : "") +
    i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep) +
    (decPlaces
      ? decSep +
        Math.abs(number - i)
          .toFixed(decPlaces)
          .slice(2)
      : "")
  )
}

const PaddedContainer = styled.div`
  ${props => !!props.variant && selectors.getPaddingLeftRightByThemeAndVariant(props.theme, props.variant)}
  ${props =>
    css`
      ${props.theme.media.mddown} {
        ${props => selectors.getPaddingLeftRightByThemeAndVariant(props.theme, props.variantSmall)}
      }
    `}

  ${props => props.debug && selectors.getDebugOverlayCss(props, "CSectionGalleryArtworkDetails.PaddedContainer", "rgba(0,0,255,0.35)")}
  transition: all ${props => props.theme.vars.transitions.themeTransitionDuration}s linear;
  background-color: white;
  // background-color: #ebebeb;
`

const Content = styled.div`
  width: 100%;
  ${props => true && selectors.getDebugOverlayCss(props, "Content", "rgba(0,255,0,0.35)")}

  padding-top: 40px;
  ${props => css`
    ${props.theme.media.mddown} {
      padding-top: 0px;
      // padding: 15px 0px;
    }
  `}
`

const AspectContainer = styled.div`
  position: relative;

  ${props => props.debug && selectors.getDebugOverlayCss(props, `AspectContainer`, `rgba(255,0,255,0.35)`)}
  max-height: 80vh;

  background: #ebebeb;
  background: linear-gradient(180deg, #ffffff 0%, #d8d8d8 100%);
  padding-top: ${props => (1 / props.ratio) * 100}%;
  ${props => css`
    ${props.theme.media.mddown} {
      padding-top: 80vh;
    }
  `}
`
const AspectContent = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
  ${props => css`
    ${props.theme.media.mddown} {
      padding: 15px 20px;
    }
  `}
`
const ArtworkImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`

const InfosCol = styled.div`
  display: flex;
  flex-direction: column;
  ${props => props.debug && selectors.getDebugOverlayCss(props, `InfosCol`, `rgba(255,255,0,0.35)`)}
  padding-left: 60px;
  height: 100%;
  transition: all ${props => props.theme.vars.transitions.themeTransitionDuration}s linear;
  ${props => css`
    ${props.theme.media.mddown} {
      padding-top: 50px;
      padding-left: 0px;
      ${props => selectors.getPaddingLeftRightByThemeAndVariant(props.theme, "header")}
      height: auto;
      background: #ebebeb;
      background: linear-gradient(180deg, #ffffff 0%, #ebebeb 100%);
    }
  `}
`

const ArtistName = styled.div`
  font-size: 14px;
  font-weight: ${props => props.theme.vars.fonts.weights.default};
  letter-spacing: 0.15em;
  font-kerning: none;
  color: ${props => props.theme.vars.colors.codGray};
  ${props => selectors.getDebugOverlayCss(props, "ArtistName", "rgba(255,0,0,0.35)")}
`

const ArtworkTitle = styled.div`
  font-size: 20px;
  font-weight: ${props => props.theme.vars.fonts.weights.extrabold};
  letter-spacing: 0.15em;
  font-kerning: none;
  color: ${props => props.theme.vars.colors.codGray};
  ${props => selectors.getDebugOverlayCss(props, "ArtworkTitle", "rgba(255,0,255,0.35)")}
  padding-top: 10px;
  padding-bottom: 30px;
`

const ArtworkPropKey = styled.div`
  font-size: 13px;
  font-weight: ${props => props.theme.vars.fonts.weights.default};
  letter-spacing: 0.15em;
  font-kerning: none;
  color: ${props => props.theme.vars.colors.trout};
  ${props => selectors.getDebugOverlayCss(props, "ArtworkPropKey", "rgba(255,0,0,0.35)")}
  padding-top: 20px;
`

const ArtworkPropValue = styled.div`
  font-size: 16px;
  font-weight: ${props => props.theme.vars.fonts.weights.default};
  letter-spacing: 0.15em;
  font-kerning: none;
  color: ${props => props.theme.vars.colors.codGray};
  ${props => selectors.getBackgroundColorByProps(props, "rgba(255,0,255,0.4)")}
  padding-top: 5px;
`

const InfosSpacer = styled.div`
  ${props => selectors.getDebugOverlayCss(props, "InfosSpacer", "rgba(255,0,0,0.35)")}
  flex: 1;
  min-height: 20px;
`

const SalesInquiryButtonContainer = styled.div`
  ${props => selectors.getDebugOverlayCss(props, "SalesInquiryButtonContainer", "rgba(0,255,0,0.35)")}
  height: auto;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  ${props => css`
    ${props.theme.media.mddown} {
      // padding-bottom: 20px;
      // margin-top: 30px;
    }
  `}
`

const BranchQrCodeContainer = styled.div`
  width: 200px;
  ${props => selectors.getDebugOverlayCss(props, "BranchQrCodeContainer", "rgba(255,0,0,0.35)")}
  ${props => css`
    ${props.theme.media.mddown} {
      display: none;
    }
  `}
`

const BackToGalleryButtonSection = styled.div`
  display: inline-block;
  ${props => css`
    ${props.theme.media.mddown} {
      ${props => false && selectors.getPaddingLeftRightByThemeAndVariant(props.theme, "header")}
      // margin: 0 auto;
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      background-color: #ebebeb;
      padding-bottom: 40px;
    }
  `}
  ${props => selectors.getDebugOverlayCss(props, "BackToGalleryButtonSection", "rgba(255,0,0,0.35)")}
`

const BackToGalleryButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px 0px;
  align-self: stretch;
  ${props => selectors.getDebugOverlayCss(props, "BackToGalleryButton", "rgba(255,0,0,0.35)")}
  opacity: 0.8;
  :hover {
    opacity: 1;
  }
  transition: all 0.3s;
  margin-top: 20px;
`
const BackToGalleryButtonIcon = styled.div`
  width: 12px;
  padding-right: 10px;
  transform: translate3d(0px, 2px, 0px);
  align-self: stretch;
  display: flex;
  ${props => selectors.getBackgroundColorByProps(props, "rgba(0,0,255,0.4)")}
`
const BackToGalleryButtonLabel = styled.div`
  font-size: 13px;
  color: ${props => props.theme.vars.colors.codGray};
  font-weight: ${props => props.theme.vars.fonts.weights.default};
  letter-spacing: 0.2em;
  font-kerning: none;
  text-transform: uppercase;
`

// eslint-disable-next-line
const ContentFilteredContainer = styled.div`
  ${props => !!props.variant && selectors.getPaddingLeftRightByThemeAndVariant(props.theme, props.variant)}
  ${props =>
    css`
      ${props.theme.media.mddown} {
        ${props => selectors.getPaddingLeftRightByThemeAndVariant(props.theme, props.variantSmall)}
      }
    `}

  ${props => selectors.getDebugOverlayCss(props, "ContentFilteredContainer", "rgba(0,255,0,0.35)")}
  padding-top: 20px;
  padding-bottom: 20px;
`

const H3Container = styled.div`
  padding-top: 30px;
  padding-bottom: 15px;
  ${props => props.debug && selectors.getDebugOverlayCss(props, "H3Container", "rgba(0,0,255,0.35)")}
  ${props => css`
    ${props.theme.media.mddown} {
      text-align: center;
    }
  `}
`

const ItemsContainer = styled.div`
  margin-left: -5px;
  margin-right: -5px;
`
const ItemContainer = styled.div`
  padding: 5px;
  cursor: pointer;
`

const mapStateToProps = state => ({
  embeddedMode: state.session.embeddedMode,
})
export default injectIntl(connect(mapStateToProps)(CSectionGalleryArtworkDetails))
