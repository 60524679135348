import React, {Component} from "react" // eslint-disable-line
import PropTypes from "prop-types"
import {motion} from "framer-motion"

const DISABLE_INIT_MOTION = true

const CInitMotion = ({children, delay}) =>
  !DISABLE_INIT_MOTION ? (
    <motion.div
      initial={{rotate: -1.5, scale: 0.97, opacity: 0}}
      animate={{rotate: 0, scale: 1, opacity: 1}}
      transition={{
        delay: delay / 1000,
        type: "spring",
        // stiffness: 330,
        // damping: 15,
      }}>
      {!!children ? children : <div />}
    </motion.div>
  ) : (
    <div>{children}</div>
  )
CInitMotion.propTypes = {
  children: PropTypes.any,
  delay: PropTypes.number, // in ms
}
CInitMotion.defaultProps = {
  delay: 0,
}

export default CInitMotion
