import * as actionsConsts from "../../data/actionsConsts"
import {sitemapData} from "../dummies"
import {getApiUrl} from "../selectors/api"
import {getObjectDeep} from "../selectors/helpers"
import axios from "axios" // eslint-disable-line

const wait = ms => new Promise(resolve => setTimeout(resolve, ms))

export const requestSitemap = () => async dispatch => {
  dispatch({type: actionsConsts.REQUEST_SITEMAP})

  const USE_DUMMY_DATA = false
  if (USE_DUMMY_DATA) {
    console.warn("ARoutes.requestSitemap (): USING DUMMY DATA!")
    await wait(2000)
    const sitemapDataServer = [...sitemapData]
    dispatch({type: actionsConsts.RECEIVE_SITEMAP, data: sitemapDataServer})
  } else {
    const apiUrl = getApiUrl("/sitemap") // https://iazzu.com/api/v3/sitemap
    const serverResponse = await axios.get(apiUrl, {params: {includehidden: "true", version: "2.5.0"}}) // 2.4.0 includes /ovr, 2.5.0 includes /docs
    const sitemapDataServer = getObjectDeep(serverResponse, "data.response.payload")
    dispatch({type: actionsConsts.RECEIVE_SITEMAP, data: sitemapDataServer})
  }
}

export const requestGlobals = () => async dispatch => {
  dispatch({type: actionsConsts.REQUEST_GLOBALS})
  const serverResponse = await axios.get(getApiUrl("/globals")) // https://iazzu.com/api/v3/globals
  const data = getObjectDeep(serverResponse, "data.response.payload")
  dispatch({type: actionsConsts.RECEIVE_GLOBALS, data})
  return data
}
