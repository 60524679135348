var CryptoJS = require("crypto-js")

const NBCrypt = {
  salt: "OurSuperSecretSalt",
  iv: "1111111111111111",
  iterations: "999",

  encrypt(passphrase, plainText) {
    var key = getKey(passphrase, NBCrypt.salt)
    var encrypted = CryptoJS.AES.encrypt(plainText, key, {
      iv: CryptoJS.enc.Utf8.parse(NBCrypt.iv),
    })
    return encrypted.ciphertext.toString(CryptoJS.enc.Base64)
  },

  decrypt(passphrase, encryptedText) {
    var key = getKey(passphrase, NBCrypt.salt)
    var decrypted = CryptoJS.AES.decrypt(encryptedText, key, {
      iv: CryptoJS.enc.Utf8.parse(NBCrypt.iv),
    })
    return decrypted.toString(CryptoJS.enc.Utf8)
  },
}

const getKey = (passphrase, salt) => {
  var key = CryptoJS.PBKDF2(passphrase, salt, {
    hasher: CryptoJS.algo.SHA256,
    keySize: 64 / 8,
    iterations: NBCrypt.iterations,
  })
  return key
}

export default NBCrypt
