import React, {Component} from "react"
import PropTypes from "prop-types"
import {connect} from "react-redux"
import {createSelector} from "reselect"
import {injectIntl} from "react-intl"
import isEqual from "lodash/isEqual"
import styled, {css} from "styled-components" // eslint-disable-line
import {motion, useViewportScroll, useTransform, AnimatePresence} from "framer-motion" // eslint-disable-line
import {CONFIG} from "../../config"

import * as selectors from "../../data/selectors"
import * as actions from "../../data/actions"

import CIcon from "../../view/components/CIcon"
import CBranchQrCode from "../../view/components/CBranchQrCode"

const DEBUG = false && __DEV__

const god = selectors.getObjectDeep
import {debbify} from "../../data/selectors/helpers"
const debby = (...args) => debbify("CImageOverlay", ...args)

class CImageOverlay extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    //
    shown: PropTypes.bool,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    url: PropTypes.string,
    extraData: PropTypes.object,
    branchShareInfos: PropTypes.object,
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  onBackgroundClick = () => this.onCloseClick()
  onCloseClick = () => this.props.dispatch(actions.closeImageOverlay())

  render = () => {
    const {intl, shown, title, subtitle, url, branchShareInfos} = this.props
    const d = {debug: DEBUG}
    if (shown) {
      debby("render()", {title, branchShareInfos})
    }
    return (
      <AnimatePresence>
        {shown && (
          <PresenceContainer {...d} initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} onClick={this.onBackgroundClick}>
            {false && (
              <HeaderContainer {...d}>
                <TitleContainer {...d}>{title}</TitleContainer>
                <CloseContainer {...d} onClick={this.onCloseClick}>
                  <CIcon id={`MobileMenuClose`} color={CONFIG.layout.colors.codGray} />
                </CloseContainer>
              </HeaderContainer>
            )}
            <ImageContainer {...d}>
              <Image src={url} />
              <div style={{position: "absolute", right: 0, top: 0, width: 50, height: 50, display: "flex"}}>
                <CloseContainer {...d} onClick={this.onCloseClick}>
                  <CIcon id={`MobileMenuClose`} color={CONFIG.layout.colors.codGray} />
                </CloseContainer>
              </div>
              {!!branchShareInfos && (
                <PresenceQrContainer initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}}>
                  <CBranchQrCode
                    //
                    label={intl.formatMessage({id: `GalleryLandingViewAtHome`})}
                    labelBackgroundColor={`rgba(255,255,255,0.5)`}
                    qrImageUrl={branchShareInfos.qrImageUrl}
                  />
                </PresenceQrContainer>
              )}
            </ImageContainer>
            {(!!subtitle || !!title) && (
              <TextContainer {...d}>
                <Subtitle>{subtitle}</Subtitle>
                {true && <Dot>-</Dot>}
                <Title>{title}</Title>
              </TextContainer>
            )}
          </PresenceContainer>
        )}
      </AnimatePresence>
    )
  }
}

const PresenceContainer = styled(motion.div)`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 20001; // in front of CHeaderMenu
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 20px;
  padding-bottom: 20px;
  ${props => selectors.getPaddingLeftRightByThemeAndVariant(props.theme, "content")}
  background-color: ${props => props.theme.vars.colors.gallery};
  ${props => selectors.getBackgroundColorByProps(props, "rgba(0,0,255,0.35)")}
`

const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  ${props => false && props.debug && selectors.getDebugOverlayCss(props, `HeaderContainer`, `rgba(255,0,255,0.35)`)}
`

const TitleContainer = styled.div`
  flex: 1;
  align-self: stretch;
  padding-bottom: 40px;
  color: ${props => props.theme.vars.colors.codGray};
  letter-spacing: 0.1em;
  font-size: 16px;
  ${props => props.debug && selectors.getDebugOverlayCss(props, `TitleContainer`, `rgba(255,0,255,0.35)`)}
`

const CloseContainer = styled.div`
  width: 50px;
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0.5;
  :hover {
    opacity: 1;
  }
  transition: opacity 0.2s ease-in-out;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 25px;
  ${props => props.debug && selectors.getDebugOverlayCss(props, `CloseContainer`, `rgba(255,255,0,0.35)`)}
`

const ImageContainer = styled.div`
  flex: 1;
  position: relative;
  align-self: stretch;
  overflow: hidden;
  ${props => props.debug && selectors.getDebugOverlayCss(props, `ImageContainer`, `rgba(0,255,0,0.35)`)}
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  // border: 1px solid red;
  object-fit: contain;
`

const TextContainer = styled.div`
  padding: 20px;
  text-align: center;
  ${props => props.debug && selectors.getDebugOverlayCss(props, `TextContainer`, `rgba(0,0,255,0.35)`)}
  line-height: 1.3;

  font-size: 17px;
  ${props => css`
    ${props.theme.media.smdown} {
      font-size: 14px;
    }
  `}
`

const Title = styled.span`
  padding: 0px 5px;
  font-weight: ${props => props.theme.vars.fonts.weights.bold};
  letter-spacing: 0.1em;
`
const Dot = styled.span`
  font-weight: ${props => props.theme.vars.fonts.weights.default};
`
const Subtitle = styled.span`
  padding: 0px 5px;
  font-weight: ${props => props.theme.vars.fonts.weights.default};
  text-align: center;
  letter-spacing: 0.1em;
`

const PresenceQrContainer = styled(motion.div)`
  position: absolute;
  right: 0;
  bottom: 0;

  ${props => css`
    ${props.theme.media.smdown} {
      display: none;
    }
  `}
`

const getExtraData = state => god(state, "session.imageOverlay.image.extraData")
const getBranchLinksState = state => state.branchLinks

const getWallBranchShareInfosKey = createSelector([selectors.getIntlLocale, getExtraData], (lang, extraData) => {
  const type = god(extraData, "type")
  if (type == "wall") {
    const id = god(extraData, "wallId")
    if (!!id) {
      const wallBranchShareInfosKey = selectors.getBranchShareLinkKey({type: "wall", lang, id})
      return wallBranchShareInfosKey
    }
  }
  return null
})

const getWallBranchShareInfos = createSelector([getBranchLinksState, getWallBranchShareInfosKey], (branchLinksState, wallBranchShareInfosKey) => {
  const qrData = !wallBranchShareInfosKey ? null : god(branchLinksState, [wallBranchShareInfosKey, "qrData"])
  return !qrData
    ? null
    : {
        url: god(qrData, "targetUrl"),
        qrImageUrl: god(qrData, "url"),
      }
})

// eslint-disable-next-line
const mapStateToProps = (state, props) => ({
  shown: god(state, "session.imageOverlay.shown", false),
  title: god(state, "session.imageOverlay.image.title"),
  subtitle: god(state, "session.imageOverlay.image.subtitle"),
  url: god(state, "session.imageOverlay.image.url"),
  extraData: god(state, "session.imageOverlay.image.extraData"),
  branchShareInfos: getWallBranchShareInfos(state, props),
})
export default injectIntl(connect(mapStateToProps)(CImageOverlay))
