/* istanbul ignore file */

import firebase from "firebase/app"
import "firebase/analytics"

import {CONFIG} from "../../config"

import {debbify} from "../../data/selectors/helpers"
const debby = (...args) => debbify("AnalyticsManager", ...args) // eslint-disable-line

const AnalyticsManager = {
  debug: false && __DEV__,

  use: () => {
    if (__DEV__ && CONFIG.firebase.useAnalyticsInDevVersion) {
      return true
    }
    return !__DEV__ && CONFIG.app.isReleaseVersion
  },

  init: () => {
    if (!AnalyticsManager.use()) {
      const {useAnalyticsInDevVersion} = CONFIG.firebase
      const {isReleaseVersion} = CONFIG.app
      console.warn(`Not using Firebase Analytics.`, {useAnalyticsInDevVersion, isReleaseVersion})
      return
    }
    // debby(`init()`)
    if (__DEV__ && CONFIG.firebase.useAnalyticsInDevVersion) {
      console.warn("Using Firebase Analytics (DEV MODE).")
    }
    const firebaseConfig = CONFIG.firebase.config
    firebase.initializeApp(firebaseConfig)
    firebase.analytics()
  },

  sendPageView(path) {
    debby(`sendPageView(${path})`)
    if (!AnalyticsManager.use()) {
      return
    }
    firebase.analytics().setCurrentScreen(path, path)
  },

  sendEvent(event, value) {
    debby(`sendEvent(${event})`, value)
    if (!AnalyticsManager.use()) {
      return
    }
    firebase.analytics().logEvent(event, value)
  },

  setPlatform(platform) {
    if (!AnalyticsManager.use()) {
      return
    }
    // debby(`setPlatform(${platform})`)
    firebase.analytics().setUserProperties("Platform", platform)
  },

  setLanguage(lang) {
    if (!AnalyticsManager.use()) {
      return
    }
    // debby(`setLanguage(${lang})`)
    firebase.analytics().setUserProperties("Language", lang)
  },

  setGallerySlug(slug) {
    if (!AnalyticsManager.use()) {
      return
    }
    firebase.analytics().setUserProperties("GallerySlug", slug)
  },

  setUserProperty(property, value) {
    if (!AnalyticsManager.use()) {
      return
    }
    // debby(`setUserProperty(${property}, ${value})`)
    firebase.analytics().setUserProperties(property, value)
  },
}

export default AnalyticsManager
