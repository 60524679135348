import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import styled, {css} from "styled-components" // eslint-disable-line
import {motion, useViewportScroll, useTransform, AnimatePresence} from "framer-motion" // eslint-disable-line
import {connect} from "react-redux"

import * as selectors from "../../data/selectors"
import * as actions from "../../data/actions"

const getHrefRecursively = target => {
  if (target.nodeName == "A") {
    return target.href
  } else if (!!target.parentNode) {
    return getHrefRecursively(target.parentNode)
  }
  return null
}
const getHrefByClickEvent = event => (!event ? null : getHrefRecursively(event.target)) // TODO: Move to helpers

class CContent extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    resourceId: PropTypes.number,
    dispatch: PropTypes.func.isRequired,
    html: PropTypes.string,
    textColor: PropTypes.string,
    onImageClick: PropTypes.func,
    onLinkClick: PropTypes.func,
  }
  static defaultProps = {
    debug: false && __DEV__,
    textColor: "black",
    resourceId: -1,
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  onClick = event => {
    event.preventDefault() // always
    const {onImageClick, onLinkClick} = this.props

    const nodeName = event.target.nodeName
    if (nodeName == "IMG") {
      const imageId = parseInt(event.target.getAttribute("data-id"))
      if (!!imageId) {
        !!onImageClick && onImageClick({imageId})
        return
      }
    }

    const href = getHrefByClickEvent(event)
    if (!!href) {
      !!onLinkClick && onLinkClick({href})
      if (!onLinkClick) {
        this.props.dispatch(actions.handleUrl(href))
      }
    }
  }

  render = () => {
    const {debug, html, textColor, resourceId} = this.props
    const d = {debug: debug || CContent.defaultProps.debug}
    return (
      <Content
        //
        {...d}
        textColor={textColor}
        dangerouslySetInnerHTML={{__html: selectors.getHtmlSafe(html, resourceId)}}
        onClick={this.onClick}
      />
    )
  }
}

const Content = styled.div`
  max-width: 100%;

  color: ${props => props.textColor};
  // font-size: 17px;
  line-height: 1.5;
  letter-spacing: 0.1em;
  font-kerning: none;
  font-weight: ${props => props.theme.vars.fonts.weights.medium};

  p {
    margin: 0;
    margin-bottom: 30px;
    background-color: ${props => (props.debug ? "rgba(255,0,0,0.2)" : "none")};
  }

  a,
  p > a {
    color: ${props => props.textColor};
    font-weight: ${props => props.theme.vars.fonts.weights.semibold};
  }

  figure {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0px;
    margin: 0;
    ${props => props.debug && selectors.getDebugOverlayCss(props, `Figure`, `rgba(255,0,255,0.35)`)}
  }

  figure > figcaption {
    max-width: 400px;
    display: inline-block;
    font-size: 14px;
    padding-top: 10px;
    line-height: 1.4;
    ${props => props.debug && selectors.getDebugOverlayCss(props, `figure > figcaption`, `rgba(0,0,255,0.35)`)}
  }

  figure > img {
    max-width: 400px;
    width: 100%;
    display: block;
    cursor: pointer;
  }

  ${props => props.debug && selectors.getDebugOverlayCss(props, `OurStoryText`, `rgba(255,255,0,0.35)`)}
`

// eslint-disable-next-line
const mapStateToProps = (state, props) => ({})
export default connect(mapStateToProps)(CContent)
