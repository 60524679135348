import axios from "axios"
import shortid from "shortid"
import * as actionsConsts from "../../data/actionsConsts"
import BugReportManager from "../../data/utils/BugReportManager"
import AnalyticsManager from "../../data/utils/AnalyticsManager"
import {getObjectDeep, getEmailIsValid} from "../selectors/helpers"
import {getApiUrl} from "../selectors/api"
import moment from "moment"

import {debbify} from "../../data/selectors/helpers"
const debby = (...args) => debbify("AAPI", ...args) // eslint-disable-line

export const getIdForError = error => {
  let errorIntlId = null
  switch (error) {
    case null:
      break
    case "missing-param-name":
      errorIntlId = "FormErrorMissingParamName"
      break
    case "missing-param-email":
      errorIntlId = "FormErrorMissingParamEmail"
      break
    case "invalid-email":
      errorIntlId = "FormErrorInvalidEmail"
      break
    case "server-error":
      errorIntlId = "UnknownServerError"
      break
    default:
      errorIntlId = "FormErrorGeneric"
      break
  }
  return errorIntlId
}

const getErrorAndId = error => ({
  error,
  errorIntlId: getIdForError(error),
})

export const sendContactRequest =
  (fields, options = {}) =>
  async dispatch => {
    const dryMode = getObjectDeep(options, "dryMode", false)
    // const dryMode = false && __DEV__
    if (dryMode) {
      alert("sendContactRequest() performing in dry mode.")
    }

    const id = shortid.generate()

    //  validate
    const name = getObjectDeep(fields, "name", null)
    const email = getObjectDeep(fields, "email", null)
    const emailValid = getEmailIsValid(email)
    if (!name) {
      return {success: false, ...getErrorAndId("missing-param-name")}
    } else if (!email) {
      return {success: false, ...getErrorAndId("missing-param-email")}
    } else if (!emailValid) {
      return {success: false, ...getErrorAndId("invalid-email")}
    }
    dispatch({type: actionsConsts.REQUEST_SEND_CONTACT_REQUEST, id, fields})

    const params = {params: {...fields, dryMode}}

    try {
      const contactRequestResponse = await axios.get(getApiUrl("/add/contactrequest"), params)
      const {success, payload} = contactRequestResponse.data.response
      const error = getObjectDeep(payload, "error")
      dispatch({type: actionsConsts.RECEIVE_SEND_CONTACT_REQUEST, id, success, ...getErrorAndId(error)})
      return {success, payload, ...getErrorAndId(error)}
    } catch (exception) {
      const serverResponse = getObjectDeep(exception, "response.data.response") // eslint-disable-line
      const status = serverResponse.status
      const error = status == "ServerError" ? "server-error" : undefined // unknown

      dispatch({type: actionsConsts.RECEIVE_SEND_CONTACT_REQUEST, id, success: false, ...getErrorAndId(error)})
      return {success: false, ...getErrorAndId(error)}
    }
  }

export const clearContactRequests = () => dispatch => {
  dispatch({type: actionsConsts.CLEAR_CONTACT_REQUESTS})
}

let postDataPathsRequestedInSession = [] // avoid double calls in same session

export const requestPostData =
  (pathRaw, options = {}) =>
  async (dispatch, getState) => {
    let success = false
    let pageTemplate = null
    let postData = null
    let postType = null

    const fromPreload = !!options && options.hasOwnProperty("fromPreload") ? options["fromPreload"] : false
    if (fromPreload) {
    }

    let path = `${pathRaw}`
    while (path != "/" && path.endsWith("/")) {
      path = path.substr(0, path.length - 1)
    }

    const stateApi = getState().api
    if (!!stateApi) {
      const postDataState = stateApi.postData
      if (postDataPathsRequestedInSession.indexOf(path) != -1 && postDataState.hasOwnProperty(path) && !postDataState[path].busy && !!postDataState[path].data) {
        postData = postDataState[path].data
        pageTemplate = postDataState[path].pageTemplate
        postType = postDataState[path].postType
        return {success: true, pageTemplate, data: postData}
      }
    }

    // fromPreload && debby(`requestPostData(): Preloading..`, {path, postDataPathsRequestedInSession})
    // !fromPreload && debby(`requestPostData(): Loading..`, {path})

    dispatch({type: actionsConsts.REQUEST_POST_DATA, path})

    const getPathApiParams = path => (path != "/" && path.startsWith("/") ? path.substr(1) : path)
    const params = {params: {path: getPathApiParams(path)}} // /ovr fix: use sanitizer function

    const serverResponse = await axios.get(getApiUrl("/postdata", true), params) // true: old API
    const {data} = serverResponse
    if (!!data) {
      const payload = getObjectDeep(data, "response.payload")
      if (!!payload) {
        postData = getObjectDeep(payload, "pathProps.pathData")
        pageTemplate = getObjectDeep(payload, "pathProps.pathPageTemplate")
        postType = getObjectDeep(payload, "pathProps.pathPostType")
        // if (!pageTemplate) { debugger } // prettier-ignore
        if (!!postData) {
          success = true
          if (postDataPathsRequestedInSession.indexOf(path) == -1) {
            postDataPathsRequestedInSession.push(path)
          }
        }
      }
    }

    // if (!pageTemplate) { debugger } // prettier-ignore

    // debby(`requestPostData() done.`, {path, success})

    dispatch({type: actionsConsts.RECEIVE_POST_DATA, path, success, pageTemplate, data: postData, postType})
    return {success, pageTemplate, data: postData, postType}
  }

export const requestSeoData = path => async dispatch => {
  const {success, data} = await dispatch(requestPostData(path))
  if (success && !!data.seoData) {
    return data.seoData
  }
  return null
}

export const clearPostData = () => dispatch => {
  dispatch({type: actionsConsts.CLEAR_POST_DATA})
}

export const refreshGeoInfos = () => async dispatch => {
  dispatch({type: actionsConsts.REQUEST_GEO_INFOS})

  let geoInfos = null

  const serverResponse = await axios.get(getApiUrl("/geo", true)) // true: old API
  const {data} = serverResponse
  if (!!data) {
    geoInfos = getObjectDeep(data, "response.payload")
    const city = getObjectDeep(geoInfos, "city")
    const country = getObjectDeep(geoInfos, "countryCode")
    BugReportManager.setUserContext({
      City: city,
      Country: country,
    })
    AnalyticsManager.setUserProperty("City", city)
    AnalyticsManager.setUserProperty("Country", country)
  }

  dispatch({type: actionsConsts.RECEIVE_GEO_INFOS, data: geoInfos})
}

export const requestWhitepaper = data => async dispatch => {
  const dryMode = false && __DEV__
  if (dryMode) {
    alert("requestWhitepaper() performing in dry mode.")
  }

  const id = shortid.generate()

  //  validate
  const email = getObjectDeep(data, "email", null)
  const lang = getObjectDeep(data, "lang", null)
  const emailValid = getEmailIsValid(email)
  if (!email) {
    return {success: false, ...getErrorAndId("missing-param-email")}
  } else if (!lang) {
    return {success: false, ...getErrorAndId("missing-param-lang")}
  } else if (!emailValid) {
    return {success: false, ...getErrorAndId("invalid-email")}
  }

  dispatch({type: actionsConsts.REQUEST_WHITEPAPER, id, fields: data})

  const params = {params: {...data, lang, dryMode}}
  debugger

  try {
    const sendMessageResponse = await axios.get(getApiUrl("/add/whitepaperrequest"), params)
    const {success, payload} = sendMessageResponse.data.response
    const error = getObjectDeep(payload, "error")
    dispatch({type: actionsConsts.RECEIVE_WHITEPAPER, id, success, ...getErrorAndId(error)})
    debugger
    return {success, payload, ...getErrorAndId(error)}
  } catch (exception) {
    const serverResponse = getObjectDeep(exception, "response.data.response") // eslint-disable-line
    const status = serverResponse.status
    const error = status == "ServerError" ? "server-error" : undefined // unknown
    dispatch({type: actionsConsts.RECEIVE_WHITEPAPER, id, success: false, ...getErrorAndId(error)})
    debugger
    return {success: false, ...getErrorAndId(error)}
  }
}

export const addNewsletterSubscriber = data => async dispatch => {
  const dryMode = false && __DEV__

  const id = shortid.generate()

  //  validate
  const email = getObjectDeep(data, "email", null)
  const lang = getObjectDeep(data, "lang", null)
  const emailValid = getEmailIsValid(email)
  if (!email) {
    return {success: false, ...getErrorAndId("missing-param-email")}
  } else if (!lang) {
    return {success: false, ...getErrorAndId("missing-param-lang")}
  } else if (!emailValid) {
    return {success: false, ...getErrorAndId("invalid-email")}
  }

  dispatch({type: actionsConsts.REQUEST_ADD_NEWSLETTER_SUBSCRIBER, id, fields: data})

  const params = {params: {...data, lang, dryMode}}

  try {
    const addSubscriberResult = await axios.get(getApiUrl("/add/newslettersubscriber"), params)
    const {success, payload} = addSubscriberResult.data.response
    const error = getObjectDeep(payload, "error")

    dispatch({type: actionsConsts.RECEIVE_ADD_NEWSLETTER_SUBSCRIBER, id, success, ...getErrorAndId(error)})
    return {success, payload, ...getErrorAndId(error), dryMode}
  } catch (exception) {
    const serverResponse = getObjectDeep(exception, "response.data.response") // eslint-disable-line
    const status = serverResponse.status
    const error = status == "ServerError" ? "server-error" : undefined // unknown

    dispatch({type: actionsConsts.RECEIVE_ADD_NEWSLETTER_SUBSCRIBER, id, success: false, ...getErrorAndId(error)})
    return {success: false, ...getErrorAndId(error), dryMode}
  }
}

export const requestSuggestedCurrency = () => async dispatch => {
  try {
    const result = await axios.get(getApiUrl("/geo", true)) // true: legacy API
    const {success} = result.data.response
    const suggestedCurrency = getObjectDeep(result, "data.response.payload.suggestedCurrency", "EUR")
    dispatch({type: actionsConsts.RECEIVE_SUGGESTED_CURRENCY, suggestedCurrency})
    return {success, suggestedCurrency}
  } catch (exception) {
    return {success: false}
  }
}

export const requestPublicWallsForGallery = gallerySlug => async dispatch => {
  dispatch({type: actionsConsts.REQUEST_PUBLIC_WALLS_FOR_GALLERY, gallerySlug})
  try {
    const serverResponse = await axios.get(getApiUrl(`/publicwalls/${gallerySlug}`))
    const publicWalls = getObjectDeep(serverResponse, "data.response.payload.publicWalls")
    dispatch({type: actionsConsts.RECEIVE_PUBLIC_WALLS_FOR_GALLERY, gallerySlug, publicWalls})
    return {success: true, publicWalls}
  } catch (error) {
    __DEV__ && console.error(error)
    BugReportManager.sendException(error)
    return {success: false, error}
  }
}

export const requestKnowledgeBaseArticles = () => async dispatch => {
  dispatch({type: actionsConsts.REQUEST_KNOWLEDGEBASE_ARTICLES})
  try {
    const serverResponse = await axios.get(getApiUrl(`/kbarticles`, true))
    const items = getObjectDeep(serverResponse, "data.response.payload.articles")
    dispatch({type: actionsConsts.RECEIVE_KNOWLEDGEBASE_ARTICLES, items})
    return {success: true, items}
  } catch (error) {
    __DEV__ && console.error(error)
    BugReportManager.sendException(error)
    return {success: false, error}
  }
}

export const requestGalleryStats = secretCode => async dispatch => {
  try {
    const SITE_API_KEY = "46ekvnaJAstAhU7DgVQsw3BZ33TW5kAG2fSCDVjPTDQGPCUrBe5xWLSQ34jZy6BJ"

    const yearMonthThisMonth = moment().format("YYYY/MM")
    const yearMonthLastMonth = moment().add(-1, "months").format("YYYY/MM") // eslint-disable-line

    debby(`requestGalleryStats(${secretCode})..`, {yearMonthThisMonth, yearMonthLastMonth})

    const [serverResponseThisMonth, serverResponseLastMonth] = await Promise.all([
      //
      axios.get(getApiUrl(`/gallerystats/${secretCode}/${yearMonthThisMonth}?apikey=${SITE_API_KEY}`, true)),
      axios.get(getApiUrl(`/gallerystats/${secretCode}/${yearMonthLastMonth}?apikey=${SITE_API_KEY}`, true)),
    ])

    const dataThisMonth = getObjectDeep(serverResponseThisMonth.data, "response.payload.data")
    const dataLastMonth = getObjectDeep(serverResponseLastMonth.data, "response.payload.data")

    debby(`requestGalleryStats(${secretCode}) done.`)

    dispatch({type: actionsConsts.RECEIVE_GALLERY_STATS, secretCode, yearMonth: yearMonthThisMonth, data: dataThisMonth})
    dispatch({type: actionsConsts.RECEIVE_GALLERY_STATS, secretCode, yearMonth: yearMonthLastMonth, data: dataLastMonth})

    return {success: true}
  } catch (error) {
    __DEV__ && console.error(error)
    BugReportManager.sendException(error)
    return {success: false, error}
  }
}
