/* istanbul ignore file */

import React, {Component} from "react"
import PropTypes from "prop-types" // eslint-disable-line
import {Route} from "react-router-dom"
import {createBrowserHistory} from "history"
import {Provider} from "react-intl-redux"
import {ConnectedRouter} from "connected-react-router"
import isEqual from "lodash/isEqual"
import {setConfiguration} from "react-grid-system"
import {PersistGate} from "redux-persist/integration/react"

import {CONFIG} from "./config"
import {createAndPersistStore} from "./data/store"
import AppWithState from "./view/AppWithState"
import * as selectors from "./data/selectors"
import * as actions from "./data/actions" // eslint-disable-line
import {AnalyticsManager, FacebookPixelManager} from "./data/utils"
import {ParallaxProvider} from "react-scroll-parallax"

const {basename} = CONFIG.routing
let usedHistory = createBrowserHistory({basename})

let storeRef = null
let persistorRef = null

// const DEBUG = false && __DEV__
import {debbify} from "./data/selectors/helpers"
const debby = (...args) => debbify("App", ...args)

setConfiguration(selectors.getChildContextGrid()) // initialize react-grid-system

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      drawProvider: false,
    }
  }

  componentDidMount = async () => {
    let localeDefault = selectors.getDeviceLocaleUsable() //  TODO: Instead, get intl locale by route path's postdata that was before injected in index.php
    // localeDefault = "de" // debug
    window.browserLang = localeDefault

    debby("componentDidMount()..", {localeDefault})

    AnalyticsManager.init()
    AnalyticsManager.setPlatform("Web")
    AnalyticsManager.setLanguage(localeDefault)

    FacebookPixelManager.init()

    // will purge in case storePersistKey changed in config.js
    const {store, persistor} = await createAndPersistStore({locale: localeDefault, history: usedHistory})
    storeRef = store
    persistorRef = persistor
    debby("componentDidMount() done.", {})
    this.setState({drawProvider: true})
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  renderRoute = ({location}) => <AppWithState location={location} />

  render = () => {
    if (!this.state.drawProvider) {
      return <div style={{height: 10000}} /> // enables scroll restauration on reload
    }
    return (
      <ParallaxProvider>
        <Provider store={storeRef}>
          <PersistGate loading={<PersistLoadingIndicator />} persistor={persistorRef}>
            <ConnectedRouter history={usedHistory}>
              <Route render={this.renderRoute} />
            </ConnectedRouter>
          </PersistGate>
        </Provider>
      </ParallaxProvider>
    )
  }
}

class PersistLoadingIndicator extends Component {
  componentWillUnmount = () => {
    debby("PersistLoadingIndicator.componentWillUnmount()")
  }
  render = () => {
    // console.log("PersistLoadingIndicator.render()")
    return null
  }
}

export default App
