import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import {motion, useViewportScroll, useTransform, AnimatePresence} from "framer-motion" // eslint-disable-line
import styled, {css} from "styled-components" // eslint-disable-line

import * as selectors from "../../data/selectors" // eslint-disable-line

import CFreeTrialButton from "./CFreeTrialButton"
import CLink from "./CLink"

import {debbify} from "../../data/selectors/helpers"
const debby = (...args) => debbify("CImageFaded", ...args)

class CImageFaded extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    sectionKey: PropTypes.oneOf(["homeTop", "homeTailorMade", "homeRightAngle", "homeBrandMap", "homeTutorials", "PortfolioDetailsHeroOverlayArtGalleries", "PortfolioDetailsHeroOverlayArtInstitutions", "PortfolioDetailsHeroOverlayArtists"]),
    sources: PropTypes.array,
    sourcesWp: PropTypes.array,
    index: PropTypes.number,
    baseTransform: PropTypes.string,
    extraTransforms: PropTypes.array,
    autoplaySpeed: PropTypes.number, // ms
    onFreeTrialButtonClick: PropTypes.func,
  }
  static defaultProps = {
    debug: false && __DEV__,
    index: 0,
    baseTransform: ``,
    extraTransforms: [],
    autoplaySpeed: 5000,
  }

  constructor(props) {
    super(props)
    this.state = {
      index: 0,
    }
  }

  componentDidMount = () => {
    this.moveTimeoutId = setTimeout(this.moveToNext, this.props.autoplaySpeed)
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  componentWillUnmount = () => {
    clearTimeout(this.moveTimeoutId)
  }

  moveToNext = () => {
    const amountSources = !!this.props.sources ? this.props.sources.length : !!this.props.sourcesWp ? this.props.sourcesWp.length : 0
    !!amountSources && this.setState({index: (this.state.index + 1) % (amountSources - 1)})
    clearTimeout(this.moveTimeoutId)
    this.moveTimeoutId = setTimeout(this.moveToNext, this.props.autoplaySpeed)
  }

  render = () => {
    const {index} = this.state
    const {debug, sectionKey, sources, sourcesWp, baseTransform, extraTransforms, onFreeTrialButtonClick} = this.props
    const d = {debug: debug || CImageFaded.defaultProps.debug}
    if (!sources && !sourcesWp) {
      return <div />
    }
    const transform = baseTransform + " " + extraTransforms.join(" ")

    if (!!sectionKey && !!sources && !!sources.length) {
      __DEV__ && console.warn(`CImageFaded (${sectionKey}): Change to WP Image!`)
    }

    const sourceFirst = !!sources && !!sources.length ? sources[0] : null
    const sourceWpFirst = !!sourcesWp && !!sourcesWp.length ? selectors.getSrcAndSrcSetByWordpressImage(sourcesWp[0]) : null

    !!sectionKey && debby("render()", {sectionKey, sourcesWp})
    return (
      <ImageFadedContainer {...d}>
        {!!sourceFirst && !sourceWpFirst && (
          <ImageFaded
            //
            {...d}
            transform={transform}
            src={sourceFirst} /*srcSet={sourceFirst.srcSet}*/
          />
        )}
        {!!sourceWpFirst && (
          <ImageFaded
            //
            {...d}
            transform={transform}
            src={sourceWpFirst.src}
            srcSet={sourceWpFirst.srcSet}
          />
        )}

        {!sourceWpFirst &&
          sources.map((src, i) => {
            if (i == 0) {
              return null
            }
            const opacity = index == i - 1 ? (debug ? 0.5 : 1) : 0.0
            return (
              <AbsoluteFill key={`img_${src}`}>
                <ImageFaded
                  //
                  {...d}
                  transform={transform}
                  opacity={opacity}
                  src={src}
                />
              </AbsoluteFill>
            )
          })}

        {!!sourceWpFirst &&
          sourcesWp.map((sourceWp, i) => {
            if (i == 0) {
              return null
            }
            const image = selectors.getSrcAndSrcSetByWordpressImage(sourceWp)
            const opacity = index == i - 1 ? (debug ? 0.5 : 1) : 0.0
            return (
              <AbsoluteFill key={`img_${i}`}>
                <ImageFaded
                  //
                  {...d}
                  transform={transform}
                  opacity={opacity}
                  src={image.src}
                  srcSet={image.srcSet}
                />
              </AbsoluteFill>
            )
          })}

        {!!onFreeTrialButtonClick && false && (
          <FreeTrialButtonContainerRightBottom style={{backgroundColor: d.debug ? "rgba(255,0,0,0.5)" : "none", transform}}>
            <CLink to={"/contact"} hash={"#freetrial"}></CLink>
            <CFreeTrialButton onClick={onFreeTrialButtonClick} />
          </FreeTrialButtonContainerRightBottom>
        )}
      </ImageFadedContainer>
    )
  }
}

const ImageFadedContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 5;

  ${props => selectors.getDebugOverlayCss(props, "ImageFadedContainer", "rgba(0,255,255,0.35)")}
`

const ImageFaded = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;

  transition: all 1s ease-in-out;
  opacity: ${props => (props.hasOwnProperty("opacity") ? props.opacity : 1)};
  transform: ${props => props.transform};

  ${props =>
    props.debug &&
    css`
      border: 1px solid black;
      background-color: rgba(0, 0, 0, 0.15);
    `};
`

const FreeTrialButtonContainerRightBottom = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;

  ${props =>
    css`
      ${props.theme.media.smdown} {
        display: none;
      }
    `}
`

const AbsoluteFill = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`

export default CImageFaded
