import React, {Component} from "react"
import PropTypes from "prop-types"
import {connect} from "react-redux"
import isEqual from "lodash/isEqual"
import styled, {css} from "styled-components" // eslint-disable-line

import * as selectors from "../../data/selectors"
import * as actions from "../../data/actions"

import * as images from "../../images"

class CAppStoreButton extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    platform: PropTypes.oneOf(["ios", "android"]),
    intlLocale: PropTypes.string.isRequired,
    onClick: PropTypes.func,
  }
  static defaultProps = {
    platform: "ios",
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  getButtonImageSrc = () => {
    const {platform, intlLocale} = this.props
    const buttonImagesKey = `AppStore` + selectors.getFirstCapital(platform) + selectors.getFirstCapital(intlLocale)
    const buttonUrl = images[buttonImagesKey]
    if (!buttonUrl) {
      console.warn(`No image found for CAppStoreButton (platform: ${platform}, lang: ${intlLocale})`)
      return null
    }
    return buttonUrl
  }

  getAppStoreUrl = () => selectors.getAppStoreUrl(this.props.platform)

  onLinkClick = e => {
    e.preventDefault()

    const {dispatch, platform} = this.props

    const url = e.currentTarget.href
    window.open(url)
    dispatch(actions.sendAnalyticsEvent("app_store_button_click", {Platform: platform})) // TODO: Mark this as conversion event

    !!this.props.onClick && this.props.onClick(platform, url)
  }

  render = () => {
    const buttonUrl = this.getButtonImageSrc()
    const appStoreUrl = this.getAppStoreUrl()
    return (
      <Container>
        <AHref href={appStoreUrl} onClick={this.onLinkClick}>
          {!!buttonUrl && <Image src={buttonUrl} /*srcSet={buttonUrl.srcSet}*/ />}
        </AHref>
      </Container>
    )
  }
}

const Container = styled.div`
  width: 100%;
`

const AHref = styled.a`
  width: 100%;
`

const Image = styled.img`
  width: 100%;
`

const mapStateToProps = () => ({})

export default connect(mapStateToProps)(CAppStoreButton)
