import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import {connect} from "react-redux"
import {createSelector} from "reselect"
import styled, {ThemeProvider} from "styled-components"
import {withController} from "react-scroll-parallax"
import Tabs, {TabPane} from "rc-tabs"
import TabContent from "rc-tabs/lib/TabContent"
import ScrollableInkTabBar from "rc-tabs/lib/ScrollableInkTabBar"
import {push} from "connected-react-router"

import withScreenProps from "../../../data/hocs/withScreenProps" // {postData, ..}
import * as selectors from "../../../data/selectors"
import * as actions from "../../../data/actions"
const god = selectors.getObjectDeep

import CFooter from "../../../view/components/CFooter"
import CImage from "../../../view/components/CImage"
import CGetInTouch from "../../../view/components/CGetInTouch"
import CContent from "../../../view/components/CContent"
import CEditInWordPress from "../../../view/components/CEditInWordPress"
import CCollapse from "../../../view/components/CCollapse"
import CIcon from "../../../view/components/CIcon"
import CSpacer from "../../../view/components/CSpacer"

import CH1 from "../../../view/components/text/CH1" // eslint-disable-line
import CH2 from "../../../view/components/text/CH2" // eslint-disable-line
import CH3 from "../../../view/components/text/CH3" // eslint-disable-line
import CHomeSubheader from "../../../view/components/text/CHomeSubheader"

import {CHomeHeroVideoButton} from "../SHome/components"
// import {CPortfolioTestimonials, CPortfolioMainSections, CPortfolioSupportedBy} from "./components"

const debby = (funcName, data = null) => console.log("SFAQs." + funcName, data) // eslint-disable-line
const debbyWarn = (funcName, data = null) => console.warn("SFAQs." + funcName, data) // eslint-disable-line

const FAQ_CATEGORIES = ["general", "features"]

class SFAQs extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    sitemapData: PropTypes.array,
    parallaxController: PropTypes.object.isRequired,
    tabKeys: PropTypes.array,
    activeTabKey: PropTypes.string,
    faqs: PropTypes.object, // key: category

    //  withScreenProps
    dispatch: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    intlLocale: PropTypes.string.isRequired,
    themeKey: PropTypes.string,
    postData: PropTypes.object,
    editInWordPressProps: PropTypes.object,
    pageTemplate: PropTypes.string,
    locationPathname: PropTypes.string,
    locationHash: PropTypes.string,
    locationHashValues: PropTypes.object,
    wordPressUserId: PropTypes.number,
    wordPressResourceId: PropTypes.number,
  }
  static defaultProps = {
    debug: false && __DEV__,
    tabKeys: ["how-it-works", ...FAQ_CATEGORIES], // all except "how-it-works" are FAQ item's "category" prop
  }

  constructor(props) {
    super(props)
    this.state = {
      faqIdExpanded: null,
    }
  }
  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  onShowVideoIntoClick = () => {
    const {dispatch, postData} = this.props
    const videoYoutubeUrl = god(postData, "sectionIntroVideo.videoYoutubeUrl")
    const videoLightboxHeader = god(postData, "sectionIntroVideo.videoLightboxHeader")
    if (!!videoYoutubeUrl) {
      dispatch(actions.showVideoOverlay(videoLightboxHeader, videoYoutubeUrl))
    }
  }

  renderTabContent = tabKey => {
    const {faqIdExpanded} = this.state
    const {debug, intl, postData, faqs, wordPressResourceId, wordPressUserId} = this.props
    const d = {debug}

    if (tabKey == "how-it-works") {
      // first tab
      const howItWorksContent = god(postData, "sectionHowItWorks.content")
      if (!howItWorksContent) {
        return <div />
      }
      return (
        <div>
          <CContent resourceId={wordPressResourceId} html={howItWorksContent} />
          <CHomeHeroVideoButton
            //
            {...d}
            label={intl.formatMessage({id: `ShowIntroVideoButtonLabel`})}
            onClick={this.onShowVideoIntoClick}
          />
        </div>
      )
    }
    if (!faqs || !Object.keys(faqs).length) {
      return null
    }
    const faqsForKey = faqs[tabKey]
    if (!faqsForKey.length) {
      return null
    }

    return (
      <FAQItemsContainer {...d}>
        {tabKey == "features" && (
          <ExtraSentenceContainerTop {...d}>
            <CContent resourceId={wordPressResourceId} html={god(postData, "sectionExtraSentences.features.top")} />
          </ExtraSentenceContainerTop>
        )}
        {faqsForKey.map(faqItem => {
          const {id, title, contentFiltered, hasTranslation} = faqItem // eslint-disable-line
          return (
            <FaqItem {...d} key={`faq_item_${id}`}>
              <FaqHeaderContainer
                //
                {...d}
                active={faqIdExpanded == id}
                onClick={() => this.toggleFaqExpanded(tabKey, id)}>
                <PlusMinusContainer {...d}>
                  <CIcon id={faqIdExpanded == id ? "FaqMinus" : "FaqPlus"} />
                </PlusMinusContainer>
                <TitleContainer {...d}>{title}</TitleContainer>
              </FaqHeaderContainer>
              <CCollapse collapsed={faqIdExpanded != id}>
                <ContentContainer>
                  <CContent resourceId={id} html={contentFiltered} />
                </ContentContainer>
              </CCollapse>
              {!!wordPressUserId && <CEditInWordPress resourceId={id} label={"EDIT"} />}
            </FaqItem>
          )
        })}
        {tabKey == "features" && (
          <ExtraSentenceContainerBottom {...d}>
            <CContent resourceId={wordPressResourceId} html={god(postData, "sectionExtraSentences.features.bottom")} />{" "}
          </ExtraSentenceContainerBottom>
        )}
      </FAQItemsContainer>
    )
  }

  toggleFaqExpanded = (category, id) => this.setState({faqIdExpanded: this.state.faqIdExpanded == id ? null : id})

  onTabChange = tabKey => {
    const {locationPathname, locationHash} = this.props
    const newHash = selectors.getLocationHashNew(locationHash, {t: tabKey != "how-it-works" ? tabKey : null})
    this.props.dispatch(push({pathname: locationPathname, hash: newHash}))
    this.setState({faqIdExpanded: null})
  }

  render = () => {
    const {debug, intl, postData, editInWordPressProps, intlLocale, sitemapData, parallaxController, pageTemplate, tabKeys, activeTabKey} = this.props
    const d = {debug}
    debby("render()", {props: this.props})
    const parallaxRatio = 0.1
    const heroImageSrcData = selectors.getSrcAndSrcSetByWordpressImage(god(postData, "featuredImage"), {useCropped: !!parallaxRatio})
    const privacySmallprintHtml = selectors.getPrivacySmallprintHtml(intl, intlLocale, sitemapData)
    const faqItems = god(postData, "sectionFAQs.items") // eslint-disable-line

    return (
      <ThemeProvider theme={{}}>
        <Container {...d}>
          <CImage
            //
            src={heroImageSrcData}
            srcSet={heroImageSrcData.srcSet}
            overlayColor={`rgba(0,0,0,0.25)`}
            parallaxRatio={parallaxRatio}
            parallaxController={parallaxController}
            heightGrows>
            <PaddedContainer {...d} variant={`content`}>
              <HeaderContainer>
                <CH2 {...d} text={god(postData, "sectionTop.header", "")} />
                <CSpacer {...d} variant={`herosections`} />
                <CHomeSubheader {...d} text={god(postData, "sectionTop.subheader", "")} variant={`home-demo`} />
              </HeaderContainer>
            </PaddedContainer>
          </CImage>

          <FAQsContainer {...d}>
            <Tabs
              //
              defaultActiveKey={activeTabKey}
              onChange={this.onTabChange}
              renderTabBar={() => <ScrollableInkTabBar />}
              renderTabContent={() => <TabContent />}>
              {tabKeys.map(tabKey => {
                let tabLabel = ""
                if (tabKey == "how-it-works") {
                  tabLabel = god(postData, "sectionHowItWorks.header")
                } else {
                  tabLabel = intl.formatMessage({id: getIdForTabKey(tabKey)})
                }
                return (
                  <TabPane tab={tabLabel} key={`${tabKey}`}>
                    <TabContentContainer {...d}>{this.renderTabContent(tabKey)}</TabContentContainer>
                  </TabPane>
                )
              })}
            </Tabs>
          </FAQsContainer>

          <GetInTouchContainer {...d}>
            <CGetInTouch
              //  labels
              {...d}
              pageTemplate={pageTemplate}
              header={god(postData, "sectionGetInTouch.labelHeader")}
              button={god(postData, "sectionGetInTouch.labelButton")}
              teamMember={god(postData, "sectionGetInTouch.teamMember")}
              smallprint={privacySmallprintHtml}
              onLinkPress={this.onLinkPress}
            />
          </GetInTouchContainer>
          <CFooter />
          <CEditInWordPress fixed {...editInWordPressProps} />
        </Container>
      </ThemeProvider>
    )
  }
}

const getIdForTabKey = tabKey => {
  let intlIdTabKey = null
  switch (tabKey) {
    case "general": {
      intlIdTabKey = "FAQsTabLabelGeneral"
      break
    }
    case "features": {
      intlIdTabKey = "FAQsTabLabelFeatures"
      break
    }
  }
  return intlIdTabKey
}

const Container = styled.div`
  position: relative;
  overflow-x: hidden; // because of huge images
  background-color: ${props => props.theme.backgroundColor};
  transition: all ${props => props.theme.vars.transitions.themeTransitionDuration}s linear;
`

const PaddedContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  transition: all ${props => props.theme.vars.transitions.themeTransitionDuration}s linear;
  ${props => selectors.getPaddingLeftRightByThemeAndVariant(props.theme, props.variant)}
  ${props => false && props.debug && selectors.getDebugOverlayCss(props, `PaddedContainer`, `rgba(0,0,255,0.15)`)}
  color: white;
  text-align: ${props => (props.align == "center" ? "center" : "left")};
`

const HeaderContainer = styled.div`
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  ${props => props.debug && selectors.getDebugOverlayCss(props, `HeaderContainer`, `rgba(255,0,0,0.15)`)}
`

const FAQsContainer = styled.div`
  padding-top: 100px;
  ${props => selectors.getPaddingLeftRightByThemeAndVariant(props.theme, "content")}
  ${props => props.debug && selectors.getDebugOverlayCss(props, `FAQsContainer`, `rgba(0,0,255,0.15)`)}
`

const TabContentContainer = styled.div`
  padding: 40px 0px;
  ${props => props.debug && selectors.getDebugOverlayCss(props, `TabContentContainer`, `rgba(0,0,255,0.15)`)}
`

const GetInTouchContainer = styled.div`
  width: 100%;
  padding: 90px 0px;
  ${props => props.debug && selectors.getDebugOverlayCss(props, `GetInTouchContainer`, `rgba(0,255,255,0.15)`)}
`

const FAQItemsContainer = styled.div`
  ${props => props.debug && selectors.getDebugOverlayCss(props, `FAQItemsContainer`, `rgba(255,0,255,0.15)`)}
`

const ExtraSentenceContainerTop = styled.div`
  background-color: ${props => (props.debug ? "green" : "none")};
`

const ExtraSentenceContainerBottom = styled.div`
  padding-top: 30px;
  margin-bottom: -10px;
  background-color: ${props => (props.debug ? "red" : "none")};
`

const FaqItem = styled.div`
  padding-bottom: 20px;
  position: relative;
  ${props => false && props.debug && selectors.getDebugOverlayCss(props, `FaqItem`, `rgba(0,255,255,0.15)`)}
`

const FaqHeaderContainer = styled.div`
  width: 100%;
  display: flex;
  cursor: pointer;
  opacity: ${props => (props.active ? 1 : 0.7)};
  :hover {
    opacity: 1;
  }
  transition: opacity 0.2s ease-in-out;
  ${props => false && props.debug && selectors.getDebugOverlayCss(props, `FaqHeaderContainer`, `rgba(0,255,255,0.15)`)}
`

const PlusMinusContainer = styled.div`
  width: 30px;
  background-color: ${props => (props.debug ? "rgba(255,0,0,0.5)" : "none")};
  padding-top: 3px;
`

const TitleContainer = styled.div`
  flex: 1;
  font-weight: ${props => props.theme.vars.fonts.weights.bold};
  letter-spacing: 0.1em;
  font-kerning: none;
  font-size: 17px;
  line-height: 1.5;
`

const ContentContainer = styled.div`
  font-size: 16px;
  padding-left: 30px; // icon width
  padding-top: 10px;
  padding-bottom: 20px;
  max-width: 700px;
`

////////////////////////////////////////////////////////////////////////////////

const getActiveTabKey = createSelector([selectors.getLocationHashValues], locationHashValues => {
  const tabKeyActive = god(locationHashValues, "t", "how-it-works")
  return tabKeyActive
})

const getFaqItemsByCategory = createSelector([selectors.getPostData], postData => {
  const faqItems = god(postData, "sectionFAQs.items", [])
  if (!faqItems.length) {
    return null
  }
  let faqItemsByCategory = {}
  FAQ_CATEGORIES.map(categoryKey => {
    const faqsForCategory = faqItems.filter(item => item.category == categoryKey)
    faqItemsByCategory[categoryKey] = faqsForCategory
  })
  return faqItemsByCategory
})

const getFAQsLocalized = createSelector([getFaqItemsByCategory, selectors.getIntlLocale], (faqItemsByCategory, intlLocale) => {
  let faqItemsLocalized = {}
  if (!faqItemsByCategory || Object.keys(faqItemsByCategory) == 0) {
    return null
  }
  Object.keys(faqItemsByCategory).map(categoryKey => {
    const items = faqItemsByCategory[categoryKey]
    const itemsLocalized = items.map(item => {
      const title = god(item, `languageVariants.${intlLocale}.title`, item.title)
      const contentFiltered = god(item, `languageVariants.${intlLocale}.contentFiltered`, item.contentFiltered)
      return {
        ...item,
        title,
        contentFiltered,
        hasTranslation: intlLocale == "en" || title != item.title,
      }
    })
    faqItemsLocalized[categoryKey] = itemsLocalized
  })
  return faqItemsLocalized
})

const mapStateToProps = (state, props) => ({
  sitemapData: selectors.getSitemapData(state),
  activeTabKey: getActiveTabKey(state, props),
  faqs: getFAQsLocalized(state, props),
})
export default withController(withScreenProps(connect(mapStateToProps)(SFAQs)))
