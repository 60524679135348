import * as actionsConsts from "../actionsConsts"

export const initialState = {
  stageDimensions: {
    width: 100,
    height: 100,
  },
  tabVisible: true,
}

export function device(state = initialState, action) {
  switch (action.type) {
    case actionsConsts.SET_STAGE_DIMENSIONS: {
      return {...state, stageDimensions: {width: action.width, height: action.height}}
    }
    case actionsConsts.SET_TAB_VISIBLE: {
      return {...state, tabVisible: action.visible}
    }
    default:
      return state
  }
}
