import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import styled, {css} from "styled-components" // eslint-disable-line

import * as selectors from "../../../data/selectors"
// const god = selectors.getObjectDeep

import CImage from "../../../view/components/CImage"
import CLink from "../../../view/components/CLink"
import CUmbrellaContactButton from "../../../view/components/CUmbrellaContactButton"

const DEBUG = false && __DEV__
import {debbify} from "../../../data/selectors/helpers"
const debby = (...args) => debbify("CExhibitorPortfolioLinks", ...args)

const itemShape = PropTypes.shape({
  image: PropTypes.object,
  header: PropTypes.string,
  linkLabel: PropTypes.linkLabel,
})

class CExhibitorPortfolioLinks extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    keys: PropTypes.array,
    galleries: itemShape,
    institutions: itemShape,
    artists: itemShape,
    mediaQueryClass: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]).isRequired,
  }
  static defaultProps = {
    debug: DEBUG,
    keys: ["galleries", "institutions", "artists"],
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  render = () => {
    const {mediaQueryClass, keys, galleries, institutions, artists} = this.props
    const d = {debug: DEBUG || this.props.debug} // eslint-disable-line
    if (!galleries || !institutions || !artists) {
      return <div />
    }
    debby("render()")
    return (
      <AllContainer {...d}>
        <Container {...d}>
          {keys.map((key, i) => {
            const {image, header, linkLabel} = this.props[key] // eslint-disable-line
            const imageSrcData = selectors.getSrcAndSrcSetByWordpressImage(image)
            const linkTo = key == "galleries" ? "/portfolio/art-galleries" : key == "institutions" ? "/portfolio/art-institutions" : "/portfolio/artists"
            return (
              <ItemContainer {...d} key={`p_${key}`}>
                <CLink to={linkTo}>
                  <CImage
                    //
                    {...d}
                    backgroundColor={"black"}
                    loadDelay={1000 + i * 500}
                    src={imageSrcData.src}
                    srcSet={imageSrcData.srcSet}
                    ratio={200 / 120}
                    overlayColor={"rgba(0,0,0,0.4)"}>
                    <ImageOverlay {...d}>
                      <HeaderText {...d}>{header}</HeaderText>
                      <CUmbrellaContactButton
                        //
                        {...d}
                        label={linkLabel}
                        align={"left"}
                        color={"white"}
                        colorCaret={"#1e1e1e"}
                        variant={"umbrella-portfoliolink"} // different size on md
                        mediaQueryClass={mediaQueryClass}
                        linkTo={null}
                        linkHash={null}
                      />
                    </ImageOverlay>
                  </CImage>
                </CLink>
              </ItemContainer>
            )
          })}
        </Container>
      </AllContainer>
    )
  }
}

const AllContainer = styled.div`
  ${props => true && selectors.getDebugOverlayCss(props, "AllContainer", "rgba(255,255,0,0.25)")}
  padding: 30px 0px;
`

const Container = styled.div`
  ${props => true && selectors.getDebugOverlayCss(props, "CExhibitorPortfolioLinks", "rgba(255,0,0,0.25)")}
  display: flex;

  margin: -15px;
  ${props => css`
    ${props.theme.media.lg} {
      margin: -10px;
    }
    ${props.theme.media.md} {
      margin: -5px;
    }
    ${props.theme.media.smdown} {
      flex-direction: column;
    }
  `}
`

const ItemContainer = styled.div`
  flex: 1;
  border: ${props => (props.debug ? 1 : 0)}px solid black;
  ${props => true && selectors.getDebugOverlayCss(props, "rgba(255,0,0,0.25)", "ItemContainer")}
  position: relative;

  margin: 15px;
  ${props => css`
    ${props.theme.media.lg} {
      margin: 10px;
    }
    ${props.theme.media.md} {
      margin: 5px;
    }
  `}
`

const ImageOverlay = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border: ${props => (props.debug ? 3 : 0)}px solid red;
  display: flex;
  flex-direction: column;
  align-items: flex-start; // horizontal
  justify-content: flex-end; // vertical
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.4);

  padding: 30px;
  ${props => css`
    ${props.theme.media.lg} {
      padding: 15px;
    }
    ${props.theme.media.md} {
      padding: 10px;
    }
  `}
`

const HeaderText = styled.div`
  color: white;
  text-transform: uppercase;
  font-weight: ${props => props.theme.vars.fonts.weights.bold};
  letter-spacing: 0.1em;
  ${props => true && selectors.getDebugOverlayCss(props, "rgba(255,0,0,0.25)", "HeaderText")}

  font-size: 18px;
  padding-bottom: 20px;
  ${props => css`
    ${props.theme.media.md} {
      font-size: 16px;
      padding-bottom: 10px;
    }
  `}
`

export default CExhibitorPortfolioLinks
