// import {createSelector} from "reselect"
// import {CONFIG} from "../../config"

import {createSelector} from "reselect"

export const getUser = state => state.user

export const getWordPressUserId = createSelector(
  [getUser],
  user => user.wordPressUser.userId,
)
