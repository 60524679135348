import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import styled, {css, ThemeProvider} from "styled-components" // eslint-disable-line

class CBranchQrCode extends Component {
  static propTypes = {
    label: PropTypes.string,
    qrImageUrl: PropTypes.string,
    labelBackgroundColor: PropTypes.string,
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  render = () => {
    const {label, qrImageUrl, labelBackgroundColor} = this.props
    return (
      <LabelAndQrCodeContainer>
        <QrCodeLabel bg={labelBackgroundColor}>{label}</QrCodeLabel>
        <QrCodeImage src={qrImageUrl} />
      </LabelAndQrCodeContainer>
    )
  }
}

const LabelAndQrCodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const QrCodeLabel = styled.div`
  padding: 10px;
  text-transform: uppercase;
  font-size: 13px;
  line-height: 1.3;
  letter-spacing: 0.15em;
  align-self: stretch;
  text-align: center;
  ${props =>
    props.bg &&
    css`
      background-color: ${props.bg};
    `}
`
const QrCodeImage = styled.img`
  width: 100%;
  max-width: 140px;
  height: auto;
`

export default CBranchQrCode
