import * as actionsConsts from "../actionsConsts"
import {getTimestampNowProps} from "../selectors/helpers"

export const meta = (
  state = {
    branchLinks: {},
  },
  action,
) => {
  switch (action.type) {
    case actionsConsts.RECEIVE_BRANCH_SHARE_LINK_INFOS: {
      const {key} = action
      return {
        ...state,
        branchLinks: {
          ...state.branchLinks,
          [key]: {
            ...getTimestampNowProps("updated", true),
          },
        },
      }
    }
    default:
      return state
  }
}
