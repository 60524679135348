// import StoreHelper from "../StoreHelper"
import * as selectors from "../selectors"

var HelperPlatform = {
  getPlatform() {
    return "web"
  },

  getPlatformIsWeb() {
    return this.getPlatform() == "web"
  },

  getDeviceLocale() {
    const localeLang = selectors.getBrowserLang() // already lower-case
    return localeLang
  },

  getDeviceInfosFirebase() {
    return {
      platformName: "web",
      userAgent: !!navigator ? navigator.userAgent : "",
      versionApp: this.getAppVersion(),
    }
  },

  // getStorageValue(key, fallback = null) {
  //   return StoreHelper.getValue(key, fallback)
  // },

  // getStorageValues(keys, fallbacks) {
  //   return new Promise((resolve, reject) => {
  //     const promisesGetAll = keys.map((key, keyIndex) => this.getStorageValue(key, fallbacks[keyIndex]))
  //     Promise.all(promisesGetAll)
  //       .then(values => {
  //         // this is an indexed array, convert to object
  //         let valuesObj = {}
  //         // debugger
  //         values.map((value, i) => {
  //           valuesObj[keys[i]] = value
  //         })
  //         resolve(valuesObj)
  //       })
  //       .catch(errors => {
  //         console.warn(errors)
  //         console.warn("Errors @ HelperPlatform_web.getStorageValues ()")
  //         reject()
  //       })
  //   })
  // },

  // // setStorageValues(values) {
  //   return new Promise(resolve => {
  //     const promisesSetAll = Object.keys(values).map(key => {
  //       const value = values[key]
  //       return StoreHelper.setValue(key, value)
  //     })
  //     Promise.all(promisesSetAll)
  //       .then(() => {
  //         resolve()
  //       })
  //       .catch(errors => {
  //         console.error(errors)
  //         resolve()
  //       })
  //   })
  // },

  // setStorageValue(key, valueNew = null) {
  //   return StoreHelper.setValue(key, valueNew)
  // },

  getWindowScrollY() {
    let supportPageOffset = window.pageXOffset !== undefined
    let isCSS1Compat = (document.compatMode || "") === "CSS1Compat"
    let scroll = {
      // x: supportPageOffset ? window.pageXOffset : isCSS1Compat ? document.documentElement.scrollLeft : document.body.scrollLeft,
      y: supportPageOffset ? window.pageYOffset : isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop,
    }
    const scrollY = scroll.y
    return scrollY
  },

  setDocumentLang(lang) {
    window.document.documentElement.lang = lang
  },
}

export default HelperPlatform
