import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import {motion, useViewportScroll, useTransform, AnimatePresence} from "framer-motion" // eslint-disable-line
import styled, {css} from "styled-components" // eslint-disable-line
import {Row, Col} from "react-grid-system"

import * as selectors from "../../data/selectors" // eslint-disable-line

class CGrid extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    breakpoints: PropTypes.object,
    xs: PropTypes.number,
    sm: PropTypes.number,
    md: PropTypes.number,
    lg: PropTypes.number,
    xl: PropTypes.number,
    rowProps: PropTypes.object,
    amountItems: PropTypes.number.isRequired,
    renderItem: PropTypes.func.isRequired,
    gutter: PropTypes.number,
    guttersBreakpoints: PropTypes.object,
  }
  static defaultProps = {
    debug: false && __DEV__,
    xs: 12,
    sm: 12,
    md: 12,
    lg: 12,
    xl: 12,
    rowProps: {
      type: "flex",
      // align: "start", // vertical
      // align: "stretch", // vertical
      // align: "center", // vertical
      justify: "center",
      width: "100%",
      height: "100%",
      gutter: 0,
    },
    gutter: 0, // actually used gutter
    guttersBreakpoints: null,
    breakpoints: {
      sm: 12,
    },
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  getColProps = () => {
    /*
    const {xs, sm, md, lg, xl} = this.props
    let colProps = {
      ...(!!xs && xs),
      ...(!!sm && sm),
      ...(!!md && md),
      ...(!!lg && lg),
      ...(!!xl && xl),
    }
    return colProps
    */
  }

  render = () => {
    const {debug, rowProps, breakpoints, amountItems, renderItem, gutter, guttersBreakpoints} = this.props
    const d = {debug: debug || CGrid.defaultProps.debug}
    if (!amountItems) {
      return null
    }
    let colProps = {...breakpoints}
    if (!Object.keys(colProps).length) {
      colProps.sm = 12
    }

    return (
      <Container {...d} gutter={gutter} guttersBreakpoints={guttersBreakpoints}>
        <StyledRow {...d} {...rowProps}>
          {Array(amountItems)
            .fill()
            .map((v, i) => (
              <StyledCol {...d} key={`item_${i}`} {...colProps}>
                <Item {...d} gutter={gutter} guttersBreakpoints={guttersBreakpoints}>
                  {renderItem(i)}
                </Item>
              </StyledCol>
            ))}
        </StyledRow>
      </Container>
    )
  }
}

const Container = styled.div`
  text-align: left;
  overflow: hidden;

  margin: 0px -${props => props.gutter}px; // default
  ${props =>
    !!props.guttersBreakpoints &&
    Object.keys(props.guttersBreakpoints).map(
      breakpoint => css`
        ${props.theme.media[breakpoint]} {
          margin: 0px -${props.guttersBreakpoints[breakpoint]}px;
        }
      `,
    )}

  ${props => props.debug && selectors.getDebugOverlayCss(props, `Container`, `rgba(0,0,255,0.35)`)}
`

const StyledRow = styled(Row)`
  width: 100%;
  // color: ${props => props.theme.vars.colors.codGray};
  overflow: hidden;
`

const StyledCol = styled(Col)``

const Item = styled.div`
  padding: 0px ${props => props.gutter}px;
  ${props =>
    !!props.guttersBreakpoints &&
    Object.keys(props.guttersBreakpoints).map(
      breakpoint => css`
        ${props.theme.media[breakpoint]} {
          padding: 0px ${props.guttersBreakpoints[breakpoint]}px;
        }
      `,
    )}

  ${props => props.debug && selectors.getDebugOverlayCss(props, `Item`, `rgba(255,0,0,0.35)`)}
`

export default CGrid
