import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import styled, {css} from "styled-components"

import * as selectors from "../../../data/selectors"
// const god = selectors.getObjectDeep

const DEBUG = false && __DEV__
import {debbify} from "../../../data/selectors/helpers"
const debby = (...args) => debbify("CExhibitorsStatsMetrics", ...args)

class CExhibitorsStatsMetrics extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    metrics: PropTypes.array,
    mediaQueryClass: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
  }
  static defaultProps = {
    debug: false && __DEV__,
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  render = () => {
    const {metrics} = this.props
    const d = {debug: DEBUG || this.props.debug} // eslint-disable-line
    if (!metrics || !metrics.length) {
      return <div />
    }
    debby("render()")
    return (
      <StatsMetricsContainer {...d}>
        {metrics.map((metric, i) => {
          const {number, label} = metric
          return (
            <StatContainer {...d} key={`metrics_stat_${i}`}>
              <StatNumber {...d}>{number}</StatNumber>
              <StatLabel {...d} dangerouslySetInnerHTML={{__html: selectors.getHtmlSafe(label)}} />
            </StatContainer>
          )
        })}
      </StatsMetricsContainer>
    )
  }
}

const StatsMetricsContainer = styled.div`
  display: flex;
  ${props => false && selectors.getDebugOverlayCss(props, "StatsMetricsContainer", "rgba(255,0,0,0.15)")}
  ${props => css`
    ${props.theme.media.smdown} {
      flex-direction: column;
    }
  `}
`

const StatContainer = styled.div`
  flex: 1;
  text-align: center;
  ${props => true && selectors.getDebugOverlayCss(props, "StatContainer", "rgba(0,255,0,0.15)")}

  padding: 20px 20px;
  ${props => css`
    ${props.theme.media.lg} {
      padding: 20px 10px;
    }
    ${props.theme.media.md} {
      padding: 10px 10px;
    }
    ${props.theme.media.smdown} {
      padding: 0px 20px;
      margin-bottom: 70px;
    }
  `}
`

const StatNumber = styled.div`
  font-family: ${props => props.theme.vars.fonts.families.numerals};
  font-weight: ${props => props.theme.vars.fonts.weights.light};
  color: #32a1bc;
  text-align: center;
  border: ${props => (props.debug ? 1 : 0)}px solid blue;
  ${props => true && selectors.getDebugOverlayCss(props, "StatNumber", "rgba(0,0,255,0.15)")}

  font-size: 80px;
  line-height: 1.4em;
  letter-spacing: 0.1em;
  margin-bottom: 20px;
  ${props => css`
    ${props.theme.media.lg} {
      font-size: 50px;
      margin-bottom: 10px;
    }
    ${props.theme.media.md} {
      font-size: 40px;
      margin-bottom: 10px;
    }
    ${props.theme.media.smdown} {
      font-size: 65px;
      margin-bottom: 10px;
    }
  `}
`

const StatLabel = styled.div`
  font-family: ${props => props.theme.vars.fonts.families.default};
  color: #000000;
  text-align: center;
  ${props => true && selectors.getDebugOverlayCss(props, "StatLabel", "rgba(255,0,0,0.25)")}

  font-size: 26px;
  line-height: 1.4em;
  letter-spacing: 0.1em;
  ${props => css`
    ${props.theme.media.lg} {
      font-size: 22px;
    }
    ${props.theme.media.md} {
      font-size: 18px;
    }
    ${props.theme.media.smdown} {
      font-size: 22px;
    }
  `}
`

export default CExhibitorsStatsMetrics
