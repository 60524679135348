import React from "react"
import PropTypes from "prop-types"
import styled, {css} from "styled-components"

import * as selectors from "../../../data/selectors"

import CIcon from "../../../view/components/CIcon"

const CHomeHeroVideoButton = ({label, onClick, debug}) => (
  <VideoButtonContainer debug={debug} onClick={onClick}>
    <VideoButtonIcon>
      <CIcon id={"HomeShowVideoIntro"} />
    </VideoButtonIcon>
    <VideoButtonLabel>{label}</VideoButtonLabel>
  </VideoButtonContainer>
)
CHomeHeroVideoButton.propTypes = {label: PropTypes.string, onClick: PropTypes.func, debug: PropTypes.bool}

const VideoButtonContainer = styled.div`
  display: flex;
  align-items: center;
  padding-top: 10px;

  flex-direction: row;
  justify-content: flex-start;
  cursor: pointer;
  ${props => css`
    ${props.theme.media.smdown} {
      flex-direction: column;
      justify-content: center;
      // padding-bottom: 40px; // same as CSpacer.herosections
    }
  `}

  opacity: 0.75;
  :hover {
    opacity: 1;
  }
  transition: opacity 0.2s ease-in-out;

  ${props => selectors.getDebugOverlayCss(props, "VideoButtonContainer", "rgba(0,255,255,0.35)")}
`
const VideoButtonIcon = styled.div``
const VideoButtonLabel = styled.div`
  padding: 10px 0px;
  font-size: 16px;
  font-weight: ${props => props.theme.vars.fonts.weights.semibold};
  letter-spacing: 2px;

  margin-left: 20px;
  ${props => css`
    ${props.theme.media.smdown} {
      margin-left: 0px;
    }
  `}
`

export default CHomeHeroVideoButton
