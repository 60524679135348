import axios from "axios"

import * as actionsConsts from "../../data/actionsConsts"

import BugReportManager from "../../data/utils/BugReportManager"

import {getApiUrl} from "../selectors/api"
import {getBranchShareLinkKey} from "../selectors/branch"
import {getIntlLocale} from "../selectors/intl"
import {debbify, wait, getObjectDeep as god} from "../selectors/helpers"

const debby = (...args) => debbify("ABranch", ...args)

export const requestBranchLinkingKeyData = linkingKey => async dispatch => {
  debby("requestBranchLinkingKeyData()..", {linkingKey})
  const params = {params: {key: linkingKey}}
  const response = await axios.get(getApiUrl("/branch/key/read", true), params) // true: old API
  const success = god(response.data, "response.success", false)
  const error = god(response.data, "response.payload.error", null)
  const data = !success ? null : god(response.data, "response.payload", null)
  dispatch({type: actionsConsts.RECEIVE_BRANCH_LINKING_KEY_DATA, linkingKey, success, error, data})
  debby("requestBranchLinkingKeyData() done.", {linkingKey, success, error, data})
  return {success, error, data}
}

export const createShareLink =
  (type, options = {}) =>
  async (dispatch, getState) => {
    const state = getState()

    // if (false && __DEV__ && type == "gallery") {
    //   debby("createShareLink() for gallery: simulating 5s API lag")
    //   await wait(5000)
    // }

    const triesBefore = god(options, "triesBefore", 0)
    const source = god(options, "source", undefined)
    // const loose = god(options, "loose", false)
    const force = god(options, "force", false)
    const lang = god(options, "lang", getIntlLocale(state))

    let params = {lang}
    switch (type) {
      case "gallery":
      case "artist":
      case "artwork":
      case "event":
        params.slug = options.slug
        break
      case "wall":
        params.id = options.id
        break
      default:
        console.error(`createShareLink(): Unhandled type '${type}'.`)
        return {error: `unknown-type-${type}`}
    }

    if (force) {
      params.force = true
    }

    const key = getBranchShareLinkKey({type, lang, ...options})
    debby("createShareLink()..", {force, type, key, ...params})

    const useCachedStateDataForApiRequests = true

    if (!force && (!__DEV__ || useCachedStateDataForApiRequests)) {
      const stateEntry = god(state.branchLinks, key)
      const updated = god(state.meta.branchLinks, [key, "updated"])
      if (!!stateEntry) {
        const {qrData} = stateEntry
        if (!!qrData && !!updated) {
          const updatedDiffHours = (new Date().getTime() - updated) / (1000 * 60 * 60)
          if (updatedDiffHours < 1) {
            debby("createShareLink() done (using state).", {type, key, updatedDiffHours, source: "state"})
            return {success: true, source: "state"}
          }
        }
      }
    }

    let apiResponse = null
    try {
      apiResponse = await axios.post(`https://iazzu.com/api/link/${type}`, {...params}, {maxRedirects: 0, ["Content-Type"]: "application/x-www-form-urlencoded"})
      const errorApi = god(apiResponse.data, "response.payload.error") // "no-content-found"
      if (!!errorApi) {
        BugReportManager.captureMessage(`ABranch.createShareLink(): API Error '${errorApi}'`, {type, params})
      }
    } catch (error) {
      if (__DEV__) {
        throw error
      }
      apiResponse = null // maybe this frees up memory..
      if (triesBefore < 3) {
        const waitMs = 3000
        await wait(waitMs)
        debby(`createShareLink(): Server Error. Retrying in ${waitMs}ms..`, {}, true)
        return await dispatch(createShareLink(type, {...options, triesBefore: triesBefore + 1}))
      }
      debby("createShareLink(): Server error", {source, type, lang, ...options}, true)
      return {success: false, payload: null, error: "server-error"}
    }

    const payloadApi = god(apiResponse.data, "response.payload")
    if (!payloadApi) {
      BugReportManager.captureMessage(`ABranch.createShareLink(): Missing payloadApi.`)
      return {success: false, payload: null, error: "missing-payload"}
    }

    const targetUrl = god(payloadApi, "qrData.targetUrl")

    dispatch({type: actionsConsts.RECEIVE_BRANCH_SHARE_LINK_INFOS, key, payload: payloadApi})

    debby("createShareLink() done (using API).", {force, type, ...params, targetUrl})

    return {success: true, type, options}
  }
