import {createSelector} from "reselect"

import {getObjectDeep as god, getTextFromHtml, getExcerptWordsFromText} from "./helpers"
import {getIntlLocale} from "./intl"
import {getWordPressUserId} from "./user"

import {CONFIG} from "../../config"

const getApiContactRequests = state => state.api.contactRequests

export const getContactRequestBusy = createSelector([getApiContactRequests], apiContactRequests => {
  const amountBusy = Object.keys(apiContactRequests).filter(id => apiContactRequests[id].type == "getintouch" && apiContactRequests[id].busy).length
  return !!amountBusy
})

export const getWhitepaperRequestBusy = createSelector([getApiContactRequests], apiContactRequests => {
  const amountBusy = Object.keys(apiContactRequests).filter(id => apiContactRequests[id].type == "whitepaper" && apiContactRequests[id].busy).length
  return !!amountBusy
})

export const getSubscribeRequestBusy = createSelector([getApiContactRequests], apiContactRequests => {
  const amountBusy = Object.keys(apiContactRequests).filter(id => apiContactRequests[id].type == "newsletter" && apiContactRequests[id].busy).length
  return !!amountBusy
})

export const getApiUrl = (path, legacy = false) => CONFIG.server.root + (legacy ? CONFIG.server.folderApiLegacy : CONFIG.server.folderApi) + path

const getGlobalsData = state => god(state, "routes.globals.data")

export const getGlobalsLocalized = createSelector([getIntlLocale, getGlobalsData], (intlLocale, globalsData) => {
  const values = {
    logoIcon: god(globalsData, `globalsPage.strings.${intlLocale}.logoIcon`, null),
    logoSubheader: god(globalsData, `globalsPage.strings.${intlLocale}.sectionFooter.labelLogoSubheader`, null),
    navigationHeader: god(globalsData, `globalsPage.strings.${intlLocale}.sectionFooter.labelNavigationHeader`, null),
    disclaimer: god(globalsData, `globalsPage.strings.${intlLocale}.sectionFooter.disclaimer`, null),
    copyright: god(globalsData, `globalsPage.strings.${intlLocale}.sectionFooter.copyright`, null),
    contact: {
      twitter: god(globalsData, `wp.contact.twitter`),
      facebook: god(globalsData, `wp.contact.facebook`),
      instagram: god(globalsData, `wp.contact.instagram`),
      email: god(globalsData, `wp.contact.email`),
      linkedin: god(globalsData, `wp.contact.linkedin`),
    },
    sectionNewsletter: {
      header: god(globalsData, `globalsPage.strings.${intlLocale}.sectionNewsletter.labelHeader`, null),
      subheader: god(globalsData, `globalsPage.strings.${intlLocale}.sectionNewsletter.labelSubheader`, null),
      privacyPolicy: god(globalsData, `globalsPage.strings.${intlLocale}.sectionNewsletter.labelPrivacyPolicy`, null),
      button: god(globalsData, `globalsPage.strings.${intlLocale}.sectionNewsletter.labelButton`, null),
      thankYou: god(globalsData, `globalsPage.strings.${intlLocale}.sectionNewsletter.labelThankYou`, null),
    },
    sectionCookieNotice: {
      paragraph: god(globalsData, `globalsPage.strings.${intlLocale}.sectionCookieNotice.paragraph`, null),
      button: god(globalsData, `globalsPage.strings.${intlLocale}.sectionCookieNotice.button`, null),
    },
    sectionLIA2020: {
      show: god(globalsData, `globalsPage.strings.${intlLocale}.sectionLIA2020.show`, false),
      text: god(globalsData, `globalsPage.strings.${intlLocale}.sectionLIA2020.text`, ""),
      moreButtonLabel: god(globalsData, `globalsPage.strings.${intlLocale}.sectionLIA2020.moreButtonLabel`, ""),
      moreButtonLink: god(globalsData, `globalsPage.strings.${intlLocale}.sectionLIA2020.moreButtonLink`, ""),
    },
  }
  return values
})

const getStateKnowledgeBaseArticles = state => state.api.knowledgeBaseArticles.items

export const getKnowledgeBaseArticles = createSelector([getStateKnowledgeBaseArticles, getIntlLocale, getWordPressUserId], (stateKnowledgeBaseArticles, intlLocale, wordPressUserId) => {
  if (!stateKnowledgeBaseArticles || !stateKnowledgeBaseArticles.length) {
    return null
  }
  const articles = stateKnowledgeBaseArticles
    .map(article => {
      const titleLocalized = god(article, `languageVariants.${intlLocale}.title`, article.title)
      const contentLocalized = god(article, `languageVariants.${intlLocale}.contentFiltered`, article.contentFiltered)
      const contentTextOnly = getTextFromHtml(contentLocalized, {spaceForListItems: true})
      let slugsAllLangs = [article.slug]
      for (const lang of Object.keys(article.languageVariants)) {
        if (lang != "en") {
          const slug = article.languageVariants[lang].slug
          if (!!slug) {
            slugsAllLangs.push(article.languageVariants[lang].slug)
          }
        }
      }
      const featuredImageUrl = god(article, "featuredImage.sizes.1000.url")
      if (!article.slug) {
        return null
      }
      return {
        status: article.status, // "publish", "private", "future"
        title: titleLocalized,
        dateTimestamp: article.dateTimestamp,
        slug: article.slug,
        lang: article.lang,
        slugsAllLangs,
        pathLocalized: "/" + god(article, `translationsSanified.${intlLocale}.path`),
        content: contentLocalized,
        contentExcerpt: getExcerptWordsFromText(contentTextOnly, 15),
        featuredImageUrl,
      }
    })
    .filter(article => !!article)
    .filter(article => !!wordPressUserId || article.status == "publish")

  return articles
})
