import * as actionsConsts from "../actionsConsts"
import {getObjectDeep as god} from "../../data/selectors/helpers"

export const initialState = {
  linkingKeyData: {},
}

export function branch(state = initialState, action) {
  switch (action.type) {
    case actionsConsts.RECEIVE_BRANCH_LINKING_KEY_DATA: {
      const {linkingKey, success, error, data} = action
      return {
        //
        ...state,
        linkingKeyData: {
          ...state.linkingKeyData,
          [linkingKey]: {
            success,
            error,
            data,
          },
        },
      }
    }
    default:
      return state
  }
}

export function branchLinks(state = {}, action) {
  switch (action.type) {
    case actionsConsts.RECEIVE_BRANCH_SHARE_LINK_INFOS: {
      const {key, payload} = action
      return {
        ...state,
        [key]: {
          //
          qrData: god(payload, "qrData"),
          seoData: god(payload, "seoData"),
        },
      }
    }
    default:
      return state
  }
}
