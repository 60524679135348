import {createSelector} from "reselect"
import queryString from "query-string"

import {getObjectDeep as god, getSitemapDataAndLangForPathname, getWordPressEditLinkForResourceId, getHrefProps} from "./helpers"
import {getOtherLocales} from "./intl"
import {getWordPressUserId} from "./user"

import {CONFIG} from "../../config"

const getLocation = (state, props) => props.location
const getApiPostData = state => state.api.postData

export const getQueryProps = (_, props) => queryString.parse(props.location.search)

export const getLocationPathname = (state, props) => props.location.pathname
export const getSitemapData = state => state.routes.sitemap.data
export const getPortfolioMainSectionKeys = () => ["art-galleries", "art-institutions", "artists"] // TODO: Write test ensuring those are actually returned by GET /v3/sitemap

const DEBUG = false && __DEV__
const debby = (funcName, data = null) => DEBUG && console.log("routes." + funcName, data)

export const getIntlLocaleLocation = createSelector([getLocation, getSitemapData], (location, sitemap) => {
  const itemFound = getSitemapDataAndLangForPathname(sitemap, location.pathname)
  return god(itemFound, "lang", "en")
})

export const getPostData = createSelector([getLocation, getSitemapData, getApiPostData], (location, sitemap, apiPostData) => {
  const {pathname} = location

  //  Sitemap
  const itemFound = getSitemapDataAndLangForPathname(sitemap, pathname)
  const postDataSitemap = god(itemFound, "postData", null)

  //  Postdata (incl. featured image)
  let postDataApi = !!apiPostData && apiPostData.hasOwnProperty(pathname) && apiPostData[pathname].hasOwnProperty("data") ? apiPostData[pathname].data : null
  debby("getPostData()", {pathname, postDataSitemap, postDataApi})
  if (!!postDataApi) {
    if (!postDataApi.id) {
      console.warn(`PostData has no ID, pathname: ${pathname}`) // TODO: Add this to BugReportManager
    }
    return postDataApi
  }
  return postDataSitemap
})

export const getPostDataSuccess = createSelector([getLocation, getSitemapData, getApiPostData], (location, sitemap, apiPostData) => {
  const {pathname} = location
  const stateEntry = !!apiPostData && apiPostData.hasOwnProperty(pathname) ? apiPostData[pathname] : null // eslint-disable-line
  if (!!stateEntry && !stateEntry.busy) {
    return stateEntry.success
  }
  return undefined
})

export const getSeoData = createSelector([getPostData], postData => {
  return god(postData, "seoData")
})

export const getHeaderMenuFilledWhenScrolledUp = createSelector([getPostData], postData => god(postData, "sectionMenuProperties.filledWhenScrolledUp", false))

export const getDocumentTitle = createSelector([getPostData], postData => {
  const documentTitlePrefix = god(postData, "title", null)
  // return !!documentTitlePrefix ? `${documentTitlePrefix} | iazzu` : `iazzu`
  return !!documentTitlePrefix ? `${documentTitlePrefix} | iazzu` : null
})

export const getAppStoreUrl = platform => {
  const {stores} = CONFIG.app
  if (!!stores[platform] && !!stores[platform].url) {
    return stores[platform].url
  }
  return null
}

export const getCurrentPageSitemapDataEntry = createSelector([getLocation, getSitemapData], (location, sitemapData) => {
  const entry = getSitemapDataAndLangForPathname(sitemapData, location.pathname, {debug: false})
  return entry
})

export const getCurrentPageLinkOtherLangs = createSelector([getLocation, getPostData, getOtherLocales, getCurrentPageSitemapDataEntry], (location, postData, otherLocales, currentPageSitemapDataEntry) => {
  if (!currentPageSitemapDataEntry) {
    // throw new Error(`No sitemap entry found for location.pathname '${location.pathname}'.`)
    return null
  } else {
    let currentPageLinkOtherLangs = {}
    for (const locale of otherLocales) {
      const localePath = god(currentPageSitemapDataEntry, `entryFull.langs.${locale}.path`, null)
      if (!!localePath) {
        currentPageLinkOtherLangs[locale] = localePath
      }
    }

    if (!Object.keys(currentPageLinkOtherLangs).length) {
      // check postData for translationsSanified (e.g. for /docs articles)
      if (!!postData) {
        for (const locale of otherLocales) {
          const localePath = god(postData, `translationsSanified.${locale}.path`, null)
          if (!!localePath) {
            currentPageLinkOtherLangs[locale] = "/" + localePath.split("/").filter(item => !!item && item != "/").join("/") // prettier-ignore
          }
        }
      }
    }

    return currentPageLinkOtherLangs
  }
})

export const getWordPressResourceId = createSelector([getWordPressUserId, getPostData], (wordPressUserId, postData) => {
  if (!postData || !wordPressUserId) {
    return null
  }
  return postData.id
})

export const getWordPressEditLink = createSelector([getWordPressResourceId], wordPressResourceId => getWordPressEditLinkForResourceId(wordPressResourceId))

export const getEditInWordPressProps = createSelector([getWordPressResourceId, getWordPressEditLink, getWordPressUserId], (wordPressResourceId, wordPressEditLink, wordPressUserId) => ({
  resourceId: wordPressResourceId,
  editLink: wordPressEditLink,
  userId: wordPressUserId,
}))

export const getTocSmallprintHtml = (intl, intlLocale, sitemapData) => {
  const hrefProps = getHrefProps("/other/terms-conditions", {sitemapData, locale: intlLocale})
  const text = intl.formatMessage({id: `RegisterSmallprint`})
  const tocSmallprintHtml = text.split(`<link>`).join(`<a href='${hrefProps.linkToWithBasePath}'>`).split("</link>").join("</a>") // prettier-ignore
  return tocSmallprintHtml
}

export const getPrivacySmallprintHtml = (intl, intlLocale, sitemapData) => {
  const hrefProps = getHrefProps("/other/privacy-policy", {sitemapData, locale: intlLocale})
  const text = intl.formatMessage({id: `ContactSmallprintPrivacyPolicy`})
  const tocSmallprintHtml = text.split(`<link>`).join(`<a href='${hrefProps.linkToWithBasePath}'>`).split("</link>").join("</a>") // prettier-ignore
  return tocSmallprintHtml
}

export const getGalleryPackagesSmallprintHtml = (intl, intlLocale, sitemapData) => {
  const hrefProps = getHrefProps("/other/faq", {sitemapData, locale: intlLocale})
  const text = intl.formatMessage({id: `GalleryPackagesSmallprint`})
  const tocSmallprintHtml = text.split(`<link>`).join(`<a href='${hrefProps.linkToWithBasePath}'>`).split("</link>").join("</a>") // prettier-ignore
  return tocSmallprintHtml
}

export const getPageTemplate = createSelector([getCurrentPageSitemapDataEntry], currentPageSitemapDataEntry => {
  return god(currentPageSitemapDataEntry, "entryFull.pageTemplate")
})

const getObjectFromHash = hash => {
  let hashParts = !!hash ? hash.split("#").filter(part => !!part) : []
  let hashValues = {}
  hashParts.map(part => {
    let partSplit = part.split("=")
    if (partSplit.length == 2) {
      if (partSplit[1] == "true") {
        hashValues[partSplit[0]] = true
      } else {
        hashValues[partSplit[0]] = partSplit[1]
      }
    } else {
      hashValues[part] = true
    }
  })
  return hashValues
}

export const getLocationHashNew = (hash, values) => {
  // hash is e.g. "#search", or "#imgid=14#search", or "#imgid=14"
  let hashValues = getObjectFromHash(hash)

  Object.keys(values).map(valueKey => {
    const valueValue = values[valueKey]
    if (!!valueValue) {
      hashValues[valueKey] = valueValue
    } else {
      delete hashValues[valueKey]
    }
  })

  let hashNew = ""
  if (Object.keys(hashValues).length > 0) {
    hashNew =
      "#" +
      Object.keys(hashValues)
        .map(valueKey => {
          const valueValue = hashValues[valueKey]
          if (valueValue == true || valueValue == "true") {
            return `${valueKey}`
          } else {
            return `${valueKey}=${valueValue}`
          }
        })
        .join("#")
  }

  return hashNew
}

export const getLocationHash = createSelector([getLocation], location => (!!location && !!location.hash ? location.hash : null))

export const getLocationHashValues = createSelector([getLocationHash], locationHash => {
  if (!locationHash) {
    return null
  }
  const hashValues = getObjectFromHash(locationHash)
  return hashValues
})

const getPropsLocationPathname = (state, props) => props.locationPathname
const getStateApiPostData = state => state.api.postData

export const getSeoDataByLocationPathname = createSelector(
  //
  [getStateApiPostData, getPropsLocationPathname],
  (stateApiPostData, locationPathname) => {
    if (!!stateApiPostData && stateApiPostData.hasOwnProperty(locationPathname) && !!stateApiPostData[locationPathname].data) {
      return stateApiPostData[locationPathname].data.seoData
    }
    return null
  },
)

export const getUseBrowserLanguageForPageTemplate = pageTemplate => {
  const pageTemplatesUsingBrowserLanguage = ["linking", "gallery-landing", "gallery-landing-artwork"]
  return pageTemplatesUsingBrowserLanguage.indexOf(pageTemplate) != -1
}
