import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import {EmailShareButton, FacebookShareButton, TwitterShareButton, LinkedinShareButton} from "react-share"
import styled, {css, ThemeProvider} from "styled-components" // eslint-disable-line

import {getObjectDeep as god} from "../../../data/selectors/helpers"
import {CONFIG} from "../../../config"
import CIcon from "../../../view/components/CIcon"
import CLink from "../../../view/components/CLink"

class CEmbeddedShareIcons extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    shown: PropTypes.bool,
    seoData: PropTypes.object.isRequired,
    galleryTitle: PropTypes.string,
    customEmailShareButton: PropTypes.bool,
  }
  static defaultProps = {
    debug: false && __DEV__,
    customEmailShareButton: true,
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  render = () => {
    const {debug, shown, seoData, customEmailShareButton, galleryTitle} = this.props
    const d = {debug: debug || CEmbeddedShareIcons.defaultProps.debug}
    const url = god(seoData, "url")
    const title = god(seoData, "og:title")
    const description = god(seoData, "og:description")
    const titleRaw = god(seoData, "title_raw")
    // if (!!galleryTitle) {
    //   debugger
    // }
    const emailSubject = titleRaw != galleryTitle ? titleRaw + " | " + galleryTitle : titleRaw
    return (
      <ShareIconsContainer {...d} shown={shown}>
        {!customEmailShareButton && (
          <EmailShareButton url={url} subject={title} body={encodeURI(description)}>
            <ShareIconContainer {...d} yOffset={1}>
              <CIcon id={"FooterEmail"} color={CONFIG.layout.colors.trout} />
            </ShareIconContainer>
          </EmailShareButton>
        )}
        {customEmailShareButton && (
          <div style={{display: "inline-block"}}>
            <CLink
              //
              url={`mailto:?subject=${encodeURI(emailSubject)}&body=${encodeURI("Click here to view " + emailSubject + ":\n" + url)}`}
              // url={`mailto:?subject=sub&body=body man`}
              openInNewWindow>
              <ShareIconContainer {...d} yOffset={1}>
                <CIcon id={"FooterEmail"} color={CONFIG.layout.colors.trout} />
              </ShareIconContainer>
            </CLink>
          </div>
        )}
        <TwitterShareButton url={url} title={title} via={"iazzu"} tags={["iazzu", "gallery"]} related={["iazzu"]}>
          <ShareIconContainer {...d} yOffset={1}>
            <CIcon id={"FooterTwitter"} color={CONFIG.layout.colors.trout} />
          </ShareIconContainer>
        </TwitterShareButton>
        <FacebookShareButton url={url}>
          <ShareIconContainer {...d} yOffset={2}>
            <CIcon id={"FooterFacebook"} color={CONFIG.layout.colors.trout} />
          </ShareIconContainer>
        </FacebookShareButton>
        <LinkedinShareButton url={url} title={title}>
          <ShareIconContainer {...d} yOffset={1}>
            <CIcon id={"FooterLinkedin"} color={CONFIG.layout.colors.trout} />
          </ShareIconContainer>
        </LinkedinShareButton>
      </ShareIconsContainer>
    )
  }
}

const ShareIconsContainer = styled.div`
  background-color: ${props => (props.debug ? "red" : "none")};
  padding: 10px;
  opacity: ${props => (!props.shown ? 0 : 1)};
  // transform: translate3d(${props => (!props.shown ? "-30px" : "0px")}, 0px, 0px);
  pointer-events: ${props => (!props.shown ? "none" : "auto")};
  transition: all 0.3s;
`
const ShareIconContainer = styled.div`
  background-color: ${props => (props.debug ? "yellow" : "none")};
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  transform: translate3d(0px, ${props => props.yOffset || 0}px, 0px);
`

export default CEmbeddedShareIcons
