const SERVER_ROOT = "https://iazzu.com"

// Also change this in package.json > homepage
// const SERVER_BASENAME = "v3" // staging
const SERVER_BASENAME = "" //      release

const PERSIST_STORE = true
const STORE_PERSIST_KEY = "Lang2"

module.exports = {
  CONFIG: {
    server: {
      root: SERVER_ROOT,
      folderReact: `/${SERVER_BASENAME}`, // must match package.json
      folderApi: "/api/v3",
      folderApiLegacy: "/api",
      folderWalls: "/wp/wp-content/plugins/iazzu-plugin/api/uploads/walls/",
    },
    routing: {
      basename: SERVER_BASENAME,
    },
    app: {
      stores: {
        ios: {
          storeId: "id1210715366",
          url: "https://itunes.apple.com/us/app/iazzu-art-buy-art-with-the-augmented-reality-app/id1210715366?ls=1&mt=8",
        },
        android: {
          storeId: "not-set-yet", // TODO: Set this as soon as app is in Play Store!
          url: "https://play.google.com/store/apps/details?id=com.iazzu.app",
        },
      },
      isReleaseVersion: !SERVER_BASENAME,
      isStagingVersion: !!SERVER_BASENAME,
    },
    redux: {
      // storePersistKey: "ReleaseVersion_" + new Date().getTime(), // change key to flush store
      storePersistPhrase: STORE_PERSIST_KEY,
      storePersistKey: STORE_PERSIST_KEY + (PERSIST_STORE ? "" : "___" + new Date().getTime()), // change key to flush store
      useReactotron: true, // && __DEV__
      useLogger: false, // && __DEV__
      loggerProps: {
        diff: true,
        timestamp: true,
        collapsed: false,
      },
      reducers: {
        whitelist: [
          "api",
          "routes", // sitemap, globals
          "branch",
          "meta",
          "branchLinks",
        ],
      },
    },
    facebook: {
      pixelId: "1649467472013177",
    },
    intl: {
      baseLang: "en",
      langs: ["en", "de", "es", "fr"],
    },
    firebase: {
      useAnalyticsInDevVersion: false, // needs to be false for tests to succeed
      config: {
        apiKey: "AIzaSyCZXipXo2JBmHdkDEihF2t2V1B2OFqPM2I",
        authDomain: "iazzu-1c1f8.firebaseapp.com",
        databaseURL: "https://iazzu-1c1f8.firebaseio.com",
        projectId: "iazzu-1c1f8",
        storageBucket: "iazzu-1c1f8.appspot.com",
        messagingSenderId: "458127881634",
        appId: "1:458127881634:web:1f105581cae3be33",
        measurementId: "G-PFJCQNTKJZ",
      },
    },
    layout: {
      breakpoints: {
        // same as in _variables.scss
        xs: {max: 576}, // Smartphone
        sm: {min: 576, max: 768}, // Big-Ass Smartphone
        md: {min: 768, max: 992}, // iPad
        lg: {min: 992, max: 1200}, // Desktop Small
        xl: {min: 1200}, // Desktop Large
      },
      waypointThemeTransitionDuration: 0.5, // in seconds, same as in _variables.scss
      colors: {
        codGray: "#1e1e1e",
        trout: "#4d5662",
        siccoro: "#778180",
        bisonHide: "#c4b7ab",
        westar: "#e3ded9",
        gallery: "#ebebeb",
        ciCyan: "#246c90",
      },
    },
    uploads: {
      press: {
        sourceFolder: "/Users/steff/Dropbox/iazzu/_steff/web/press/_FoldersForZipping", // must contain folders "logos", "photos", and "video"
      },
    },
    youtube: {
      tutorialsChannelUrl: "https://www.youtube.com/channel/UCRzTPZiWuCKsCV-w5GrBR_w",
    },
    googleMaps: {
      apiKeys: {
        // ios: "AIzaSyBeuQGK606hqrFrPptx094UMAcmspoO00c",
        // android: "AIzaSyAJb7lgKCxQIIy4jYHhBipADWt-mp_8FhA",
        web: "AIzaSyCZXipXo2JBmHdkDEihF2t2V1B2OFqPM2I",
      },
    },
  },
}
