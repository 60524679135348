import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import styled, {css} from "styled-components" // eslint-disable-line
import {motion} from "framer-motion"

import * as selectors from "../../../data/selectors"
// const god = selectors.getObjectDeep

import CUmbrellaContactButton from "../../../view/components/CUmbrellaContactButton"
import CInitMotion from "../../../view/components/CInitMotion"
import CAppStoreButton from "../../../view/components/CAppStoreButton"

import CH3 from "../../../view/components/text/CH3"

const DEBUG = false && __DEV__
import {debbify} from "../../../data/selectors/helpers"
const debby = (...args) => debbify("CExhibitorsAppDownload", ...args)

class CExhibitorsAppDownload extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    intlLocale: PropTypes.string.isRequired,
    header: PropTypes.string,
    contactButtonLabel: PropTypes.string,
    subHeader: PropTypes.string,
    variant: PropTypes.oneOf(["default", "umbrella-main"]),
    noPaddingHorizontal: PropTypes.bool,
    noPaddingVertical: PropTypes.bool,
    noBackgroundColor: PropTypes.bool,
  }
  static defaultProps = {
    debug: false && __DEV__,
    variant: "default",
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  render = () => {
    const {header, contactButtonLabel, subHeader, intlLocale, variant, noPaddingHorizontal, noPaddingVertical, noBackgroundColor} = this.props
    const d = {debug: DEBUG || this.props.debug} // eslint-disable-line
    debby("render()", {noPaddingHorizontal, noPaddingVertical})
    return (
      <PaddedContainerMdUp {...d} variant={`header`} noPaddingHorizontal={noPaddingHorizontal}>
        <Container {...d} variant={`header`} bgVariant={variant} noPaddingVertical={noPaddingVertical} noPaddingHorizontal={noPaddingHorizontal} noBackgroundColor={noBackgroundColor}>
          <Content {...d}>
            {!!header && <CH3 {...d} text={header} variant={"umbrellaAppDownload"} />}
            {!!contactButtonLabel && (
              <ContactButtonContainer {...d}>
                <CUmbrellaContactButton {...d} label={contactButtonLabel} align={"center"} />
              </ContactButtonContainer>
            )}
            {(!!header || !!contactButtonLabel) && <VSpacer {...d} />}
            <DownloadLabel {...d}>{subHeader}</DownloadLabel>
            <AppStoreButtonsContainer {...d}>
              <AppStoreButton {...d} intlLocale={intlLocale} delay={200} platform={"ios"} marginRight />
              <AppStoreButton {...d} intlLocale={intlLocale} delay={300} platform={"android"} />
            </AppStoreButtonsContainer>
          </Content>
        </Container>
      </PaddedContainerMdUp>
    )
  }
}

const VSpacer = styled.div`
  height: 30px;
  background-color: ${props => (props.debug ? "#999" : "none")};

  ${props =>
    props.mdUpOnly &&
    css`
      ${props.theme.media.smdown} {
        display: none;
      }
    `}
`

const PaddedContainerMdUp = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  ${props => props.debug && selectors.getDebugOverlayCss(props, `PaddedContainer`, `rgba(0,0,255,0.15)`)}

  ${props => !props.noPaddingHorizontal && selectors.getPaddingLeftRightByThemeAndVariant(props.theme, props.variant, "padding")}
  ${props => css`
    ${props.theme.media.smdown} {
      padding-left: 0px;
      padding-right: 0px;
    }
  `}

  ${props =>
    props.noPaddingHorizontal &&
    css`
      // padding-left: 0px;
      // padding-right: 0px;
    `}
`

const Container = styled.div`
  ${props =>
    props.bgVariant == "default" &&
    !props.noBackgroundColor &&
    css`
      background: rgb(246, 244, 245);
      background: linear-gradient(90deg, rgba(246, 244, 245, 1) 0%, rgba(229, 226, 221, 1) 100%);
    `}

  display: flex;
  flex-direction: row;
  justify-content: center;
  border: ${props => (props.debug ? 2 : 0)}px solid red;
  ${props => !props.noPaddingHorizontal && selectors.getPaddingLeftRightByThemeAndVariant(props.theme, props.variant, "padding")}

  padding-top: 90px;
  padding-bottom: 90px;
  ${props => css`
    ${props.theme.media.smdown} {
      padding-top: 30px;
      padding-bottom: 30px;
    }
  `}

  ${props =>
    props.noPaddingVertical &&
    css`
      padding-top: 0px;
      padding-bottom: 0px;
    `}
`

const Content = styled.div`
  max-width: 750px;
  border: ${props => (props.debug ? 1 : 0)}px solid green;
`
const ContactButtonContainer = styled.div`
  padding-top: 5px;
  border: ${props => (props.debug ? 1 : 0)}px solid red;
  padding-bottom: 10px;
`
const DownloadLabel = styled.div`
  font-weight: ${props => props.theme.vars.fonts.weights.semibold};
  font-size: 15px;
  letter-spacing: 2.31px;
  color: #1e1e1e;
  text-align: center;
  text-transform: uppercase;
`

const AppStoreButtonsContainer = styled.div`
  padding-top: 30px;
  display: flex;
  justify-content: center;

  flex-direction: row;

  ${props => css`
    ${props.theme.media.smdown} {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  `}
  ${props => selectors.getDebugOverlayCss(props, "AppStoreButtonsContainer", "rgba(0,0,255,0.25)")}
`

const AppStoreButton = ({delay, platform, debug, marginRight, intlLocale}) => (
  <AppStoreButtonSingle marginRight={marginRight}>
    <CInitMotion delay={delay}>
      <motion.div whileHover={{scale: 1.05}} style={{width: "100%", marginRight: 23}}>
        <CAppStoreButton intlLocale={intlLocale} platform={platform} debug={debug} />
      </motion.div>
    </CInitMotion>
  </AppStoreButtonSingle>
)
AppStoreButton.propTypes = {debug: PropTypes.bool, delay: PropTypes.number, platform: PropTypes.string, marginRight: PropTypes.bool, intlLocale: PropTypes.string.isRequired}

const AppStoreButtonSingle = styled.div`
  width: 40%;
  max-width: 150px;
  padding-right: ${props => (props.marginRight ? 20 : 0)}px;
  ${props => css`
    ${props.theme.media.smdown} {
      width: 100%;
      max-width: 200px;
      padding-right: 0;
      margin-bottom: 20px;
    }
  `}
`

export default CExhibitorsAppDownload
