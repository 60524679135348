import * as actionsConsts from "../actionsConsts"

export const initialState = {
  wordPressUser: {
    busy: false,
    userId: null,
    userLogin: null,
  },
}

export function user(state = initialState, action) {
  switch (action.type) {
    case actionsConsts.REQUEST_WORDPRESS_USER: {
      return {...state, wordPressUser: {...state.wordPressUser, busy: true}}
    }
    case actionsConsts.RECEIVE_WORDPRESS_USER: {
      const {userId, userLogin} = action
      return {...state, wordPressUser: {busy: false, userId, userLogin}}
    }
    default:
      return state
  }
}
