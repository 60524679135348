import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import {connect} from "react-redux"
import {createSelector} from "reselect"
import styled, {ThemeProvider, css} from "styled-components"
import {withController} from "react-scroll-parallax"

import withScreenProps from "../../../data/hocs/withScreenProps" // {postData, ..}
import * as actions from "../../../data/actions"
import * as selectors from "../../../data/selectors"
const god = selectors.getObjectDeep

import CFooter from "../../../view/components/CFooter"
import CImage from "../../../view/components/CImage"
import CIcon from "../../../view/components/CIcon"
import CContent from "../../../view/components/CContent"
import CPerson from "../../../view/components/CPerson"
import CGrid from "../../../view/components/CGrid"
import CGetInTouch from "../../../view/components/CGetInTouch"
import CFixedSections from "../../../view/components/CFixedSections"
import CEditInWordPress from "../../../view/components/CEditInWordPress"
import CWaypointProvider from "../../../view/components/CWaypointProvider"
import CWaypoint from "../../../view/components/CWaypoint"
import CPortfolioTestimonials from "../../../view/screens/SPortfolio/CPortfolioTestimonials"

import CH1 from "../../../view/components/text/CH1" // eslint-disable-line
import CH2 from "../../../view/components/text/CH2" // eslint-disable-line
import CH3 from "../../../view/components/text/CH3" // eslint-disable-line

import {CHomeDemo} from "../SHome/components"

const debby = (funcName, data = null) => console.log("SAboutUs." + funcName, data) // eslint-disable-line
const debbyWarn = (funcName, data = null) => console.warn("SAboutUs." + funcName, data) // eslint-disable-line

class SAboutUs extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    sitemapData: PropTypes.array,

    parallaxController: PropTypes.object.isRequired,
    fixedSectionsItems: PropTypes.array,
    stageHeight: PropTypes.number,
    testimonials: PropTypes.array,

    //  withScreenProps
    dispatch: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    intlLocale: PropTypes.string.isRequired,
    themeKey: PropTypes.string,
    postData: PropTypes.object,
    editInWordPressProps: PropTypes.object,
    pageTemplate: PropTypes.string,
    mediaQueryClass: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
  }
  static defaultProps = {
    debug: false && __DEV__,
  }

  constructor(props) {
    super(props)
    this.state = {
      activeFixedSectionsItemKey: null,
    }
  }
  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  renderAdvisoryBoardItemMember = i => {
    const {postData} = this.props
    const advisoryBoardPersons = god(postData, "sectionAdvisoryBoard.persons")
    if (!advisoryBoardPersons || !advisoryBoardPersons.length || advisoryBoardPersons.length < i) {
      return null
    }
    const person = advisoryBoardPersons[i]
    return (
      <CPerson
        //
        {...person}
        type={"advisoryBoard"}
        linkTypesAvailable={["linkedin"]} // in order of appearance
      />
    )
  }

  renderAmbassadorsMember = i => {
    const {intl, postData} = this.props
    const ambassadorsPersons = god(postData, "sectionAmbassadors.persons")
    if (!ambassadorsPersons || !ambassadorsPersons.length || ambassadorsPersons.length < i) {
      return null
    }
    const person = ambassadorsPersons[i]
    const requestAccessData = !person.requestAccessData
      ? null
      : {
          ...person.requestAccessData,
          label: intl.formatMessage({id: `PortfolioTestimonialsRequestAccess`}),
          qrCodeLabel: intl.formatMessage({id: `TestimonialQrCodeLabel`}),
        }
    return (
      <CPerson
        //
        {...person}
        type={"ambassador"}
        requestAccessData={requestAccessData}
        // linkTypesAvailable={["linkedin"]} // in order of appearance
      />
    )
  }

  renderCollaborationsMember = i => {
    const {intl, postData} = this.props
    const persons = god(postData, "sectionCollaborations.persons")
    if (!persons || !persons.length || persons.length < i) {
      return null
    }
    const person = persons[i]
    const requestAccessData = !person.requestAccessData
      ? null
      : {
          ...person.requestAccessData,
          label: intl.formatMessage({id: `PortfolioTestimonialsRequestAccess`}),
          qrCodeLabel: intl.formatMessage({id: `TestimonialQrCodeLabel`}),
        }
    return (
      <CPerson
        //
        {...person}
        type={"collaborator"}
        requestAccessData={requestAccessData}
        // linkTypesAvailable={["linkedin"]} // in order of appearance
      />
    )
  }

  renderTeamItemMember = i => {
    const {postData, editInWordPressProps} = this.props
    const teamMemberPersons = god(postData, "sectionTeamMembers.persons")
    if (!teamMemberPersons || !teamMemberPersons.length || teamMemberPersons.length < i) {
      return null
    }
    const person = teamMemberPersons[i]
    return (
      <CPerson
        //
        {...person}
        type={"default"}
        showEditButton={!!editInWordPressProps.userId}
        linkTypesAvailable={["linkedin"]} // in order of appearance
      />
    )
  }

  onImageClick = data => {
    const {imageId} = data
    const {postData} = this.props
    const wpImages = god(postData, "sectionOurStory.wpImages")
    if (!!imageId && !!wpImages && !!wpImages.length) {
      const wpImage = wpImages.find(im => im.id == imageId)
      if (wpImage) {
        this.props.dispatch(actions.showLightbox([wpImage]))
      }
    }
  }

  onProviderWaypointChange = (id, themeKey, extraData) => {
    const sectionKey = god(extraData, "sectionKey")
    debby(`onProviderWaypointChange(${id}, ${themeKey})`, {sectionKey})
    this.setState({activeFixedSectionsItemKey: sectionKey})
    // this.props.themeKey != themeKey && this.props.dispatch(actions.setWaypointThemeKey(themeKey))
  }

  playLaunchPartyVideo = () => {
    const {dispatch, postData} = this.props
    const launchVideoHeader = god(postData, "sectionAboutIazzu.launchVideoHeader", null)
    const launchVideoYoutubeUrl = god(postData, "sectionAboutIazzu.launchVideoYoutubeUrl", null)
    if (!!launchVideoYoutubeUrl) {
      dispatch(actions.showVideoOverlay(launchVideoHeader, launchVideoYoutubeUrl))
    }
  }

  render = () => {
    const {activeFixedSectionsItemKey} = this.state
    const {debug, intl, postData, editInWordPressProps, intlLocale, sitemapData, parallaxController, pageTemplate, fixedSectionsItems, stageHeight, testimonials} = this.props
    const d = {debug}
    debby("render()", {props: this.props})
    const parallaxRatio = 0.1
    const heroImageSrcData = selectors.getSrcAndSrcSetByWordpressImage(god(postData, "featuredImage"), {useCropped: !!parallaxRatio})
    const showLaunchVideo = god(postData, "sectionAboutIazzu.showLaunchVideo", false)
    const launchVideoSrcData = showLaunchVideo ? selectors.getSrcAndSrcSetByWordpressImage(god(postData, "sectionAboutIazzu.launchVideoImage")) : null
    const teamMemberPersons = god(postData, "sectionTeamMembers.persons")
    const advisoryBoardPersons = god(postData, "sectionAdvisoryBoard.persons")
    const privacySmallprintHtml = selectors.getPrivacySmallprintHtml(intl, intlLocale, sitemapData)
    const wpOffsets = {bottomOffset: stageHeight * 0.4, topOffset: stageHeight * 0.4}

    const ambassadorsPersons = god(postData, "sectionAmbassadors.persons")
    const showAmbassadors = god(postData, "sectionAmbassadors.show", false)

    const collaborationsPersons = god(postData, "sectionCollaborations.persons")
    const showCollaborations = god(postData, "sectionCollaborations.show", false)

    const showWhy = god(postData, "sectionWhy.show", false)

    return (
      <ThemeProvider theme={{}}>
        <Container {...d}>
          <CWaypointProvider onWaypointChange={this.onProviderWaypointChange}>
            <CWaypoint id={`hero`} {...wpOffsets} themeKey={"white"} extraData={{sectionKey: null}}>
              <CImage
                //
                src={heroImageSrcData}
                srcSet={heroImageSrcData.srcSet}
                overlayColor={`rgba(0,0,0,0.25)`}
                parallaxRatio={parallaxRatio}
                parallaxController={parallaxController}
                heightGrows
              >
                <PaddedContainer {...d} variant={`content`}>
                  <HeaderContainer>
                    <CH2 {...d} text={god(postData, "title", "")} />
                  </HeaderContainer>
                </PaddedContainer>
              </CImage>
            </CWaypoint>

            <CWaypoint id={`our-story`} {...wpOffsets} themeKey={"white"} extraData={{sectionKey: "our-story"}} scrollElementName={`our-story`}>
              <OurStoryContainer {...d}>
                <PaddedContainer {...d} variant={`content`}>
                  <ContentHeaderContainer>
                    <CH3 {...d} text={god(postData, "sectionOurStory.header")} />
                  </ContentHeaderContainer>
                  <CContent
                    //
                    html={god(postData, "sectionOurStory.content")}
                    onImageClick={this.onImageClick}
                    onLinkClick={this.onLinkClick}
                  />
                </PaddedContainer>
              </OurStoryContainer>
            </CWaypoint>

            <AboutIazzuContainer {...d}>
              <PaddedContainer {...d} variant={`content`}>
                <AboutIazzuVideoTextContainer {...d}>
                  <TextContainer>
                    <ContentHeaderContainer>
                      <CH3 {...d} text={god(postData, "sectionAboutIazzu.header")} />
                    </ContentHeaderContainer>
                    <CContent
                      //
                      html={god(postData, "sectionAboutIazzu.content")}
                      // onImageClick={this.onImageClick} // there shoud images in here
                      onLinkClick={this.onLinkClick}
                    />
                  </TextContainer>
                  {showLaunchVideo && !!launchVideoSrcData && (
                    <VideoContainer {...d}>
                      <VideoImage src={launchVideoSrcData.src} srcSet={launchVideoSrcData.srcSet} />
                      <VideoThumbnailOverlayContainer onClick={this.playLaunchPartyVideo}>
                        <CIcon {...d} id={`TutorialPlay`} scale={1.2} />
                        <VideoHeader>{god(postData, "sectionAboutIazzu.launchVideoHeader")}</VideoHeader>
                        <VideoSubHeader>{god(postData, "sectionAboutIazzu.launchVideoSubheader")}</VideoSubHeader>
                      </VideoThumbnailOverlayContainer>
                    </VideoContainer>
                  )}
                </AboutIazzuVideoTextContainer>
              </PaddedContainer>
            </AboutIazzuContainer>

            <CWaypoint id={`team-members`} {...wpOffsets} themeKey={"white"} extraData={{sectionKey: "team-members"}} scrollElementName={`team-members`}>
              {!!teamMemberPersons && !!teamMemberPersons.length && (
                <TeamMembersContainer {...d}>
                  <PaddedContainer {...d} variant={`content`}>
                    <ContentHeaderContainer {...d}>
                      <CH3 {...d} text={god(postData, "sectionTeamMembers.header")} />
                    </ContentHeaderContainer>
                    <CGrid
                      //
                      amountItems={teamMemberPersons.length}
                      renderItem={this.renderTeamItemMember}
                      gutter={90} // xl
                      guttersBreakpoints={{
                        lg: 90,
                        md: 40,
                      }}
                      breakpoints={{
                        lg: 4,
                        md: 6,
                      }}
                    />
                  </PaddedContainer>
                </TeamMembersContainer>
              )}{" "}
            </CWaypoint>

            <CWaypoint id={`advisory-board`} {...wpOffsets} themeKey={"white"} extraData={{sectionKey: "advisory-board"}} scrollElementName={`advisory-board`}>
              {!!advisoryBoardPersons && !!advisoryBoardPersons.length && (
                <AdvisoryBoardContainer {...d}>
                  <PaddedContainer {...d} variant={`content`} align={"center"}>
                    <ContentHeaderContainer {...d}>
                      <CH3 {...d} text={god(postData, "sectionAdvisoryBoard.header")} />
                    </ContentHeaderContainer>
                    <CGrid
                      //
                      amountItems={advisoryBoardPersons.length}
                      renderItem={this.renderAdvisoryBoardItemMember}
                      gutter={90} // xl
                      guttersBreakpoints={{lg: 50, md: 40, sm: 30}}
                      breakpoints={{lg: 4, md: 6}}
                    />
                  </PaddedContainer>
                </AdvisoryBoardContainer>
              )}
            </CWaypoint>

            <CWaypoint id={`ambassadors`} {...wpOffsets} themeKey={"white"} extraData={{sectionKey: "ambassadors"}} scrollElementName={`ambassadors`}>
              {showAmbassadors && !!ambassadorsPersons && !!ambassadorsPersons.length && (
                <AmbassadorsContainer {...d}>
                  <PaddedContainer {...d} variant={`content`} align={"center"}>
                    <ContentHeaderContainer {...d} smallPaddingBottom>
                      <CH3 {...d} text={god(postData, "sectionAmbassadors.header")} />
                    </ContentHeaderContainer>
                    <CGrid
                      //
                      amountItems={ambassadorsPersons.length}
                      renderItem={this.renderAmbassadorsMember}
                      gutter={90} // xl
                      guttersBreakpoints={{lg: 50, md: 40, sm: 30}}
                      breakpoints={{lg: 4, md: 6}}
                    />
                  </PaddedContainer>
                </AmbassadorsContainer>
              )}
            </CWaypoint>

            <CWaypoint id={`collaborations`} {...wpOffsets} themeKey={"white"} extraData={{sectionKey: "collaborations"}} scrollElementName={`collaborations`}>
              {showCollaborations && !!collaborationsPersons && !!collaborationsPersons.length && (
                <CollaborationsContainer {...d}>
                  <PaddedContainer {...d} variant={`content`} align={"center"}>
                    <ContentHeaderContainer {...d} smallPaddingBottom>
                      <CH3 {...d} text={god(postData, "sectionCollaborations.header")} />
                    </ContentHeaderContainer>
                    <CGrid
                      //
                      amountItems={collaborationsPersons.length}
                      renderItem={this.renderCollaborationsMember}
                      gutter={90} // xl
                      guttersBreakpoints={{lg: 50, md: 40, sm: 30}}
                      breakpoints={{lg: 4, md: 6}}
                    />
                  </PaddedContainer>
                </CollaborationsContainer>
              )}
            </CWaypoint>

            <CWaypoint id={`why`} {...wpOffsets} themeKey={"white"} extraData={{sectionKey: "why"}} scrollElementName={`why`}>
              {showWhy && !!testimonials && !!testimonials.length && (
                <TestimonialsContainer {...d}>
                  <PaddedContainer {...d} variant={`content`} align={"center"}>
                    <ContentHeaderContainer {...d} smallPaddingBottom>
                      <CH3 {...d} text={god(postData, "sectionWhy.header")} />
                      <SubHeader {...d}>{god(postData, "sectionWhy.subheader")}</SubHeader>
                    </ContentHeaderContainer>
                  </PaddedContainer>
                  <div style={{height: 30}} />
                  <CPortfolioTestimonials
                    //
                    // header={god(postData, "sectionWhy.testimonialsHeader", "Testimonials")}
                    mediaQueryClass={this.props.mediaQueryClass}
                    testimonials={testimonials.map(t => ({...t, headline: t.name}))}
                    iosOrAndroid={selectors.getIsIosOrAndroid()}
                    showEditButton={!!editInWordPressProps.userId}
                    // alreadyPadded
                    // alwaysShowImages
                    variant={"ambassadors"}
                    // variant={"umbrella"}
                  />
                </TestimonialsContainer>
              )}
            </CWaypoint>

            <DemoContainer {...d}>
              <CHomeDemo
                //
                header={god(postData, "sectionDemo.labelHeader")}
                subheader={god(postData, "sectionDemo.labelSubheader")}
                showAppStoreButtons
                intlLocale={intlLocale}
              />
            </DemoContainer>

            <GetInTouchContainer {...d}>
              <CGetInTouch
                //  labels
                {...d}
                pageTemplate={pageTemplate}
                header={god(postData, "sectionGetInTouch.labelHeader")}
                button={god(postData, "sectionGetInTouch.labelButton")}
                teamMember={god(postData, "sectionGetInTouch.teamMember")}
                smallprint={privacySmallprintHtml}
                onLinkPress={this.onLinkPress}
              />
            </GetInTouchContainer>

            <CFooter />
          </CWaypointProvider>

          <CEditInWordPress fixed {...editInWordPressProps} />
        </Container>

        <CFixedSections
          //
          items={fixedSectionsItems}
          activeItemKey={activeFixedSectionsItemKey}
          // theme={themeKey}
        />
      </ThemeProvider>
    )
  }
}

const Container = styled.div`
  position: relative;
  overflow-x: hidden; // because of huge images
  background-color: ${props => props.theme.backgroundColor};
  transition: all ${props => props.theme.vars.transitions.themeTransitionDuration}s linear;
  color: ${props => props.theme.vars.colors.codGray};
`

const PaddedContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  transition: all ${props => props.theme.vars.transitions.themeTransitionDuration}s linear;
  ${props => selectors.getPaddingLeftRightByThemeAndVariant(props.theme, props.variant)}
  ${props => false && props.debug && selectors.getDebugOverlayCss(props, `PaddedContainer`, `rgba(0,0,255,0.35)`)}
  color: white;
  text-align: ${props => (props.align == "center" ? "center" : "left")};
`

const HeaderContainer = styled.div`
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  ${props => props.debug && selectors.getDebugOverlayCss(props, `HeaderContainer`, `rgba(255,0,0,0.35)`)}
`

const OurStoryContainer = styled.div`
  padding-top: 120px;
  text-align: left;
  ${props => props.debug && selectors.getDebugOverlayCss(props, `OurStoryContainer`, `rgba(255,255,0,0.35)`)}
`

const ContentHeaderContainer = styled.div`
  align-self: stretch;
  color: ${props => props.theme.vars.colors.codGray};
  padding-bottom: ${props => (!props.smallPaddingBottom ? 50 : 10)}px;
  position: relative;
  ${props =>
    css`
      ${props.theme.media.smdown} {
        text-align: center;
      }
    `}
  ${props => props.debug && selectors.getDebugOverlayCss(props, `ContentHeaderContainer`, `rgba(255,0,0,0.35)`)}
`

const SubHeader = styled.p`
  text-align: center;
  margin-block-start: 0;
  margin-block-end: 0;
  border: ${props => (props.debug ? 1 : 0)}px solid green;
  ${props => true && props.debug && selectors.getDebugOverlayCss(props, `SubHeader`, `rgba(0,0,255,0.15)`)}

  padding-top: 30px;
  color: #1e1e1e;
  font-size: 20px;
  line-height: 27px;
  letter-spacing: 2px;

  ${props => css`
    ${props.theme.media.md} {
      padding-top: 20px;
      padding-bottom: 60px;
    }
    ${props.theme.media.smdown} {
      color: #282828;
      font-size: 18px;
      line-height: 27px;
      letter-spacing: 1.8px;
    }
  `}
`

const AboutIazzuContainer = styled.div`
  padding-top: 80px;
  text-align: left;
  padding-bottom: 40px;
  ${props => props.debug && selectors.getDebugOverlayCss(props, `AboutIazzuContainer`, `rgba(255,255,0,0.35)`)}
`

const AboutIazzuVideoTextContainer = styled.div`
  display: flex;
  overflow: visible;
  ${props => props.debug && selectors.getDebugOverlayCss(props, `AboutIazzuVideoTextContainer`, `rgba(255,0,0,0.35)`)}
  ${props =>
    css`
      ${props.theme.media.smdown} {
        flex-direction: column;
      }
    `}
`

const TextContainer = styled.div`
  flex: 1;
  overflow: visible !important;
  font-size: 15px;
  padding-right: 40px;
  ${props =>
    css`
      ${props.theme.media.smdown} {
        padding-right: 0px;
      }
    `}
  ${props => props.debug && selectors.getDebugOverlayCss(props, `TextContainer`, `rgba(0,0,255,0.35)`)}
`

const VideoContainer = styled.div`
  flex: 1;
  position: relative;
  // padding-bottom: 36px; // for CContent > p
  ${props => props.debug && selectors.getDebugOverlayCss(props, `VideoContainer`, `rgba(0,0,255,0.35)`)}
`

const VideoImage = styled.img`
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const VideoThumbnailOverlayContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.3);
  cursor: pointer;

  :hover {
    background-color: rgba(0, 0, 0, 0.6);
  }
  transition: background-color 0.5s ease-in-out;
`

const VideoHeader = styled.div`
  color: white;
  font-size: 16px;
  padding-top: 10px;
  letter-spacing: 0.1em;
`

const VideoSubHeader = styled.div`
  color: white;
  font-size: 16px;
  font-weight: ${props => props.theme.vars.fonts.weights.extrabold};
  letter-spacing: 0.2em;
  font-kerning: none;
  padding-top: 10px;
`

const TeamMembersContainer = styled.div`
  text-align: left;
  padding-top: 120px;
  ${props => props.debug && selectors.getDebugOverlayCss(props, `TeamMembersContainer`, `rgba(0,0,255,0.35)`)}
  overflow: hidden;
  color: ${props => props.theme.vars.colors.codGray};
`

const AdvisoryBoardContainer = styled.div`
  width: 100%;
  text-align: center;
  padding-top: 120px;
  padding-bottom: 120px;
  ${props => props.debug && selectors.getDebugOverlayCss(props, `AdvisoryBoardContainer`, `rgba(255,255,0,0.35)`)}
`

const AmbassadorsContainer = styled.div`
  width: 100%;
  text-align: center;
  padding-top: 80px;
  padding-bottom: 80px;
  ${props => props.debug && selectors.getDebugOverlayCss(props, `AmbassadorsContainer`, `rgba(255,0,0,0.35)`)}
`

const CollaborationsContainer = styled.div`
  width: 100%;
  text-align: center;
  padding-top: 80px;
  padding-bottom: 80px;
  ${props => props.debug && selectors.getDebugOverlayCss(props, `CollaborationsContainer`, `rgba(0,0,255,0.15)`)}
`

const TestimonialsContainer = styled.div`
  width: 100%;
  text-align: left;
  padding-top: 80px;
  padding-bottom: 80px;
  ${props => props.debug && selectors.getDebugOverlayCss(props, `TestimonialsContainer`, `rgba(0,255,255,0.15)`)}
`

const DemoContainer = styled.div`
  width: 100%;
  text-align: center;
  ${props => props.debug && selectors.getDebugOverlayCss(props, `DemoContainer`, `rgba(255,0,255,0.35)`)}
`

const GetInTouchContainer = styled.div`
  width: 100%;
  padding: 90px 0px;
  ${props => props.debug && selectors.getDebugOverlayCss(props, `GetInTouchContainer`, `rgba(0,255,255,0.35)`)}
`

const getFixedSectionsItems = () => {
  //  prettier-ignore
  return [
    {key: `our-story`,      intlId: `AboutUsFixedSectionsOurStoryLabel`,    scrollElementName: `our-story`},
    {key: `team-members`,   intlId: `HomeFixedSectionsTeamMembersLabel`,    scrollElementName: `team-members`},
    {key: `advisory-board`, intlId: `HomeFixedSectionsAdvisoryBoardLabel`,  scrollElementName: `advisory-board`},
    // {key: `tutorials`,  intlId: `HomeFixedSectionsTutorialsLabel`,  scrollElementName: `tutorials`},
    // {key: `whitepaper`, intlId: `HomeFixedSectionsWhitepaperLabel`, scrollElementName: `whitepaper`},
    // {key: `demo`,       intlId: `HomeFixedSectionsDemoLabel`,       scrollElementName: `demo`},
  ]
}

export const getTestimonials = createSelector([selectors.getPostData], postData => {
  const testimonials = selectors.getObjectDeep(postData, "sectionWhy.testimonials", null)
  if (!!testimonials && !!testimonials.length) {
    const testimonialsItems = testimonials.map(testimonial => {
      const {id, name, role, citation, buttonBehaviour, buttonGallery, buttonUrl} = testimonial
      const logoSrcData = selectors.getSrcAndSrcSetByWordpressImage(testimonial.featuredImage)
      return {
        id,
        logoSrc: logoSrcData.src,
        logoSrcSet: logoSrcData.srcSet,
        name,
        role,
        citation,
        buttonBehaviour,
        buttonGallery,
        buttonUrl,
      }
    })
    return testimonialsItems
  }
  return null
})

const mapStateToProps = (state, props) => ({
  sitemapData: selectors.getSitemapData(state),
  fixedSectionsItems: getFixedSectionsItems(),
  stageHeight: state.device.stageDimensions.height,
  testimonials: getTestimonials(state, props),
})
export default withController(withScreenProps(connect(mapStateToProps)(SAboutUs)))
