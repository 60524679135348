import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import styled, {css} from "styled-components" // eslint-disable-line
import Measure from "react-measure"

import * as selectors from "../../../data/selectors"
const god = selectors.getObjectDeep // eslint-disable-line

import CImage from "../../../view/components/CImage"
import CIcon from "../../../view/components/CIcon"
import CButton from "../../../view/components/CButton"

import CH3 from "../../../view/components/text/CH3"

import {basicTheme} from "../../../view/Theme"

const DEBUG = false && __DEV__
import {debbify} from "../../../data/selectors/helpers"
const debby = (...args) => debbify("CUmbrellaCategories", ...args)

const categoryShape = PropTypes.shape({
  headerImage: PropTypes.object,
  header: PropTypes.string,
  subHeader: PropTypes.string,
  features: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
    }),
  ),
  learnMoreButtonLabel: PropTypes.string,
})

class CUmbrellaCategories extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    keys: PropTypes.array,
    exhibitors: categoryShape,
    artlovers: categoryShape,
    mediaQueryClass: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
  }
  static defaultProps = {
    debug: false,
    keys: ["exhibitors", "artlovers"],
  }

  constructor(props) {
    super(props)
    this.heightsByKey = {}
    props.keys.map(key => {
      this.heightsByKey[key] = {header: 0}
    })
    this.state = {
      lastHeightsUpdate: null,
      // heightsByKey,
    }
    // this.heightsByKey = heightsByKey
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  onSectionResize = (key, sectionKey, contentRect) => {
    const sectionHeight = contentRect.bounds.height
    debby("onSectionResize()", {key, sectionKey, sectionHeight})
    this.heightsByKey[key][sectionKey] = sectionHeight
    this.setState({lastHeightsUpdate: new Date().getTime()})
  }

  getMaxHeightsBySectionKey = () => {
    const {heightsByKey} = this // keys: {exhibitors, artlovers}
    let maxHeightsBySectionKey = {} //   keys: {header}
    if (!!heightsByKey && !!Object.keys(heightsByKey).length) {
      for (const key of Object.keys(heightsByKey)) {
        for (const sectionKey of Object.keys(heightsByKey[key])) {
          const height = heightsByKey[key][sectionKey]
          if (!maxHeightsBySectionKey.hasOwnProperty(sectionKey)) {
            maxHeightsBySectionKey[sectionKey] = height
          }
          if (height > maxHeightsBySectionKey[sectionKey]) {
            maxHeightsBySectionKey[sectionKey] = height
          }
        }
      }
    }
    return maxHeightsBySectionKey
  }

  getImageRatio = () => {
    switch (this.props.mediaQueryClass) {
      case "xs":
      case "sm":
        return 300 / 200
      // case "xl":
      // case "lg":
      // case "md":
      // case "sm":
      //   return 300 / 150
    }
    return 300 / 150
  }

  render = () => {
    const {heightsByKey} = this.state
    const {keys, mediaQueryClass} = this.props
    const d = {debug: DEBUG || this.props.debug}
    const maxHeightsBySectionKey = this.getMaxHeightsBySectionKey()
    const isMd = selectors.getMediaQueryMatching(mediaQueryClass, "==", "md")
    const isSmDown = selectors.getMediaQueryMatching(mediaQueryClass, "<=", "sm")
    const iconScale = isMd ? 0.9 : 1
    const imageRatio = this.getImageRatio()

    debby("render()", {maxHeightsBySectionKey, isSmDown})
    return (
      <Container {...d}>
        {keys.map((key, i) => {
          if (!this.props[key]) {
            return <div key={`placeholder_${key}`} />
          }

          const {headerImage, header, subHeader, features, learnMoreButtonLabel} = this.props[key] // eslint-disable-line
          const imageSrcData = selectors.getSrcAndSrcSetByWordpressImage(headerImage)
          const buttonLinkTo = key == "exhibitors" ? "/exhibitors" : "/art-lovers"
          const maxHeightHeader = god(maxHeightsBySectionKey, `header`, 10)
          const maxHeightSubHeader = god(maxHeightsBySectionKey, `subHeader`, 10)
          const maxHeightFeatures = god(maxHeightsBySectionKey, `features`, 10)
          return (
            <ItemContainer {...d} key={`catitem_${key}`} isLeft={i == 0}>
              <CImage
                //
                {...d}
                backgroundColor={"black"}
                src={imageSrcData.src}
                srcSet={imageSrcData.srcSet}
                ratio={imageRatio}
              />
              <InfosContainer {...d}>
                <MinHeightContainer {...d} minHeight={maxHeightHeader}>
                  <Measure bounds onResize={contentRect => this.onSectionResize(key, "header", contentRect)}>
                    {({measureRef}) => (
                      <MeasuredContainer ref={measureRef} {...d}>
                        <HeaderContainer {...d}>
                          <CH3 text={header} noWordWrap variant={"umbrellaCategories"} />
                        </HeaderContainer>
                      </MeasuredContainer>
                    )}
                  </Measure>
                </MinHeightContainer>
                <VSpacer {...d} /* ------------------------------------------------------------- */ />
                <MinHeightContainer {...d} minHeight={maxHeightSubHeader}>
                  <Measure bounds onResize={contentRect => this.onSectionResize(key, "subHeader", contentRect)}>
                    {({measureRef}) => (
                      <MeasuredContainer ref={measureRef} {...d}>
                        <SubHeaderText {...d}>{subHeader}</SubHeaderText>
                      </MeasuredContainer>
                    )}
                  </Measure>
                </MinHeightContainer>
                <VSpacer {...d} /* ------------------------------------------------------------- */ />
                <MinHeightContainer {...d} minHeight={maxHeightFeatures}>
                  <Measure bounds onResize={contentRect => this.onSectionResize(key, "features", contentRect)}>
                    {({measureRef}) => (
                      <MeasuredContainer ref={measureRef} {...d}>
                        <FeaturesListContainer {...d}>
                          {features.map((feature, i) => (
                            <FeatureContainer {...d} key={`feature_${i}`}>
                              <FeatureIconContainer {...d}>
                                <CIcon id={`UmbrellaFeature`} scale={iconScale} color={basicTheme.vars.colors.ciCyan} />
                              </FeatureIconContainer>
                              <FeatureTextContainer {...d}>{feature.label}</FeatureTextContainer>
                            </FeatureContainer>
                          ))}
                        </FeaturesListContainer>
                      </MeasuredContainer>
                    )}
                  </Measure>
                </MinHeightContainer>
                <VSpacer {...d} mdUpOnly /* ------------------------------------------------------------- */ />
                <Button {...d}>
                  <CButton
                    //
                    {...d}
                    label={learnMoreButtonLabel}
                    stretched={isSmDown}
                    linkTo={buttonLinkTo}
                    noHoverScale // weirdly enough, there is some hiddden overflow somewhere
                  />
                </Button>
              </InfosContainer>
            </ItemContainer>
          )
        })}

        {d.debug && false && (
          <div style={{position: "fixed", pointerEvents: "none", fontSize: 9, left: 0, bottom: 0, zIndex: 20000, color: "blue", backgroundColor: "rgba(255,255,255,0.5)", padding: 10}}>
            <div>SUmbrella_web.js</div>
            <div>
              heightsByKey: <pre>{JSON.stringify(heightsByKey, null, 2)}</pre>
            </div>
            <div>
              maxHeightsBySectionKey: <pre>{JSON.stringify(maxHeightsBySectionKey, null, 2)}</pre>
            </div>
          </div>
        )}
      </Container>
    )
  }
}

const Container = styled.div`
  margin: -10px;

  border: ${props => (props.debug ? 1 : 0)}px solid black;
  ${props => false && props.debug && selectors.getDebugOverlayCss(props, `CUmbrellaCategories`, `rgba(255,255,0,0.35)`)}
  display: flex;
  padding: 50px 0px; // for box-shadow

  ${props => css`
    ${props.theme.media.smdown} {
      flex-direction: column;
      padding: 0px 0px;
    }
  `}
`

const ItemContainer = styled.div`
  margin: 10px;
  flex: 1;
  border: ${props => (props.debug ? 3 : 0)}px solid green;

  background-color: white;
  ${props => true && selectors.getDebugOverlayCss(props, "ItemContainer", "rgba(255,0,0,0.25)")}

  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.2);
  ${props => css`
    ${props.theme.media.smdown} {
      box-shadow: none;
      margin-bottom ${props.isLeft ? 50 : 10}px;
    }
  `}
`

const InfosContainer = styled.div`
  padding: 60px;
  ${props => css`
    ${props.theme.media.lg} {
      padding: 40px;
    }
    ${props.theme.media.md} {
      padding: 30px;
    }
    ${props.theme.media.smdown} {
      padding: 0px;
    }
  `}
`

const MinHeightContainer = styled.div`
  position: relative;
  border: ${props => (props.debug ? 1 : 0)}px solid brown;

  min-height: ${props => props.minHeight}px;
  ${props => css`
    ${props.theme.media.smdown} {
      min-height: 0;
    }
  `}
`
const MeasuredContainer = styled.div`
  position: relative;
  display: block;
  background-color: white;
  border: 1px solid white;
  ${props => true && selectors.getDebugOverlayCss(props, "MeasuredContainer", "rgba(255,255,0,0.25)")}
`
const VSpacer = styled.div`
  height: 20px;
  background-color: ${props => (props.debug ? "#999" : "none")};

  ${props =>
    props.mdUpOnly &&
    css`
      ${props.theme.media.smdown} {
        display: none;
      }
    `}
`

const HeaderContainer = styled.div`
  ${props => css`
    ${props.theme.media.smdown} {
      padding-top: 40px;
      padding-left: 20px;
      padding-right: 20px;
    }
  `}
`

const SubHeaderText = styled.div`
  color: #000000;
  letter-spacing: 0.1em;
  line-height: 1.4em;

  font-size: 25px;
  ${props => css`
    ${props.theme.media.md} {
      font-size: 18px;
    }
    ${props.theme.media.smdown} {
      font-size: 20px;
      text-align: center;
    }
  `}
`

const FeaturesListContainer = styled.div`
  ${props => true && selectors.getDebugOverlayCss(props, "FeaturesListContainer", "rgba(255,0,0,0.25)")}
  position: relative;

  ${props => css`
    ${props.theme.media.lg} {
      // padding-top: 0px;
    }
  `}
`

const FeatureContainer = styled.div`
  display: flex;

  margin: 25px 0px;
  ${props => css`
    ${props.theme.media.lg} {
      // margin: 30px 0px;
    }
    ${props.theme.media.md} {
      // margin: 25px 0px;
    }
    ${props.theme.media.smdown} {
      margin: 15px 0px;
    }
  `}
`

const FeatureIconContainer = styled.div`
  align-self: stretch;
  padding-top: 2px;
  background-color: ${props => (props.debug ? "rgba(255,0,0,0.2)" : "none")};
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  width: 50px;
  ${props => css`
    ${props.theme.media.md} {
      // width: 40px;
    }
    ${props.theme.media.lg} {
      // width: 40px;
    }
  `}
`

const FeatureTextContainer = styled.div`
  flex: 1;
  align-self: stretch;
  color: #282828;
  text-align: left;
  padding-top: 2px;

  font-size: 20px;
  line-height: 1.4em;
  letter-spacing: 2px;
  letter-spacing: 0.1em;
  ${props => css`
    ${props.theme.media.md} {
      font-size: 16px;
    }
    ${props.theme.media.smdown} {
      font-size: 18px;
    }
  `}
`

const Button = styled.div`
  padding-top: 10px;
  background-color: ${props => (props.debug ? "red" : "none")};
  ${props => props.debug && false && selectors.getDebugOverlayCss(props, `Button`, `rgba(0,255,255,0.35)`)}
`

export default CUmbrellaCategories
