import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import styled, {css} from "styled-components" // eslint-disable-line
import * as selectors from "../../../data/selectors" // eslint-disable-line

import CText from "../CText"

class CSmallprint extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    variant: PropTypes.oneOf(["default", "gallery-packages"]),
    text: PropTypes.string,
    onLinkPress: PropTypes.func,
  }
  static defaultProps = {
    debug: false && __DEV__,
    variant: "default",
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  render = () => {
    const {debug, variant, text, onLinkPress} = this.props
    const d = {debug: debug || CSmallprint.defaultProps.debug}
    return (
      <Smallprint {...d} variant={variant}>
        <CText {...d} html={text} onLinkPress={onLinkPress} />
      </Smallprint>
    )
  }
}

const Smallprint = styled.div`
  font-size: 11px;
  line-height: 17px;
  color: ${props => props.theme.textColor};
  opacity: 0.5;

  a,
  *,
  * > a {
    color: ${props => props.theme.textColor} !important;
    background-color: ${props => (props.debug ? "rgba(0,255,0,0.75)" : "none")};
  }

  ${props =>
    props.variant == "gallery-packages" &&
    css`
      font-size: 17px;
      letter-spacing: 0.1em;
      line-height: 1.7;
      opacity: 1;
    `}
`

export default CSmallprint
