import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import {motion, useViewportScroll, useTransform, AnimatePresence} from "framer-motion" // eslint-disable-line
import styled, {css, ThemeProvider} from "styled-components"
import {Row, Col} from "react-grid-system"

import * as selectors from "../../../data/selectors"
import {BenefitsCommissionLight, BenefitsCommissionDark, BenefitsAugmentedRealityLight, BenefitsAugmentedRealityDark, BenefitsInteractionLight, BenefitsInteractionDark, BenefitsReachabilityLight, BenefitsReachabilityDark} from "../../../images"

import CCrossfadeImage from "../../../view/components/CCrossfadeImage"

import CH2 from "../../../view/components/text/CH2"

class CHomeBenefits extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    header: PropTypes.string,
    commissionHeader: PropTypes.string,
    commissionLabel: PropTypes.string,
    augmentedRealityHeader: PropTypes.string,
    augmentedRealityLabel: PropTypes.string,
    interactionHeader: PropTypes.string,
    interactionLabel: PropTypes.string,
    reachabilityHeader: PropTypes.string,
    reachabilityLabel: PropTypes.string,
    themeKey: PropTypes.string,
    defaultThemeProps: PropTypes.object,
  }
  static defaultProps = {
    debug: false && __DEV__,
    defaultThemeProps: {
      // backgroundColor: "white",
      textColor: "black",
    },
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  render = () => {
    const {debug, themeKey, defaultThemeProps, header, commissionHeader, commissionLabel, augmentedRealityHeader, augmentedRealityLabel, interactionHeader, interactionLabel, reachabilityHeader, reachabilityLabel} = this.props
    const d = {debug: debug || CHomeBenefits.defaultProps.debug}
    const rowProps = {
      type: "flex",
      // align: "start", // vertical
      align: "stretch", // vertical
      // align: "center", // vertical
      justify: "center",
      width: "100%",
      height: "100%",
      gutter: 0,
    }
    let themeOverrides = {}
    if (themeKey === "dark") {
      themeOverrides = {
        ...themeOverrides,
        textColor: "white",
      }
    }

    return (
      <ThemeProvider theme={{...defaultThemeProps, ...themeOverrides}}>
        <PaddedContainer {...d} variant={"content"}>
          <Content {...d}>
            <HeaderContainer {...d}>
              <CH2 {...d} text={header} />
            </HeaderContainer>
            <StyledRow {...rowProps}>
              <StyledCol last={"false"} xl={3} lg={3} md={6} sm={6} xs={12}>
                <Item {...d}>
                  <CrossfadeImage {...d} src={themeKey === "dark" ? BenefitsCommissionLight : BenefitsCommissionDark} />
                  <Header {...d}>{commissionHeader}</Header>
                  <Label {...d}>{commissionLabel}</Label>
                </Item>
              </StyledCol>
              <StyledCol last={"false"} xl={3} lg={3} md={6} sm={6} xs={12}>
                <Item {...d}>
                  <CrossfadeImage {...d} src={themeKey === "dark" ? BenefitsAugmentedRealityLight : BenefitsAugmentedRealityDark} />
                  <Header {...d}>{augmentedRealityHeader}</Header>
                  <Label {...d}>{augmentedRealityLabel}</Label>
                </Item>
              </StyledCol>
              <StyledCol last={"false"} xl={3} lg={3} md={6} sm={6} xs={12}>
                <Item {...d}>
                  <CrossfadeImage {...d} src={themeKey === "dark" ? BenefitsInteractionLight : BenefitsInteractionDark} />
                  <Header {...d}>{interactionHeader}</Header>
                  <Label {...d}>{interactionLabel}</Label>
                </Item>
              </StyledCol>
              <StyledCol last={"true"} xl={3} lg={3} md={6} sm={6} xs={12}>
                <Item {...d}>
                  <CrossfadeImage {...d} src={themeKey === "dark" ? BenefitsReachabilityLight : BenefitsReachabilityDark} />
                  <Header {...d}>{reachabilityHeader}</Header>
                  <Label {...d}>{reachabilityLabel}</Label>
                </Item>
              </StyledCol>
            </StyledRow>
          </Content>
        </PaddedContainer>
      </ThemeProvider>
    )
  }
}

const PaddedContainer = styled.div`
  display: flex;
  flex-direction: row; // Desktop: 2 Cols
  min-height: 100vh;
  align-items: center;
  ${props => selectors.getPaddingLeftRightByThemeAndVariant(props.theme, props.variant)}

  ${props => props.debug && selectors.getDebugOverlayCss(props, "CHomeBenefits.PaddedContainer", "rgba(0,0,255,0.35)")}
  transition: all ${props => props.theme.vars.transitions.themeTransitionDuration}s linear;
`

const Content = styled.div`
  flex: 1;
  padding: 80px 0px; // about menu height minus padding height <=md
  ${props => selectors.getDebugOverlayCss(props, "Content", "rgba(0,255,0,0.35)")}
`

const HeaderContainer = styled.div`
  ${props => props.debug && selectors.getDebugOverlayCss(props, "HeaderContainer", "rgba(0,255,255,0.35)")}
  color: ${props => props.theme.textColor};
  transition: all ${props => props.theme.vars.transitions.themeTransitionDuration}s linear;
  text-align: center;
  padding-bottom: 50px;
`

const StyledRow = styled(Row)`
  // opacity: 0.2;
`

const StyledCol = styled(Col)`
  align-items: stretch;
  justify-content: center;

  ${props =>
    props.last != "true" &&
    css`
      ${props.theme.media.xs} {
        margin-bottom: 50px;
      }
    `}
`

const Item = styled.div`
  text-align: center;

  padding-left: 10px;
  padding-right: 10px;

  ${props =>
    css`
      ${props.theme.media.md} {
        padding-top: 30px;
        padding-bottom: 30px;
      }
    `}

  ${props => selectors.getDebugOverlayCss(props, "Item", "rgba(255,255,0,0.35)")}
`

const CrossfadeImage = styled(CCrossfadeImage)`
  width: 50%;
  height: 100px;
  ${props => selectors.getDebugOverlayCss(props, "CrossfadeImage", "rgba(255,0,0,0.35)")}
`

const Header = styled.div`
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-weight: ${props => props.theme.vars.fonts.weights.bold};
  font-size: 17px;
  font-kerning: none;

  margin: 10px 0px;
  min-height: 40px;
  ${props => css`
    ${props.theme.media.xs} {
      margin: 10px 0px;
      min-height: 0px;
    }
  `}

  color: ${props => props.theme.textColor};
  transition: all ${props => props.theme.vars.transitions.themeTransitionDuration}s linear;
  ${props => selectors.getDebugOverlayCss(props, "Header", "rgba(0,0,255,0.35)")}

  display: flex;
  align-items: center;
  justify-content: center;
`

const Label = styled.div`
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.1em;
  font-weight: ${props => props.theme.vars.fonts.weights.regular};
  word-wrap: break-word;

  color: ${props => props.theme.textColor};
  transition: all ${props => props.theme.vars.transitions.themeTransitionDuration}s linear;

  ${props => selectors.getDebugOverlayCss(props, "Label", "rgba(0,255,255,0.35)")}
`

export default CHomeBenefits
