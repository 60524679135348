import React, {Component} from "react"
import PropTypes from "prop-types"
import styled, {css, ThemeProvider} from "styled-components" // eslint-disable-line
import isEqual from "lodash/isEqual"

import CLink from "../../../view/components/CLink"
// import CCollapse from "../../../view/components/CCollapse"
// import * as selectors from "../../../data/selectors"

class CGalleryStatsMonth extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    defaultThemeProps: PropTypes.object,
    gallerySlug: PropTypes.string.isRequired, // for CLink
    header: PropTypes.string.isRequired,
    data: PropTypes.shape({
      artworksLiked: PropTypes.array.isRequired,
      artistsLiked: PropTypes.array.isRequired,
      firstScans: PropTypes.shape({total: PropTypes.number}).isRequired,
      scans: PropTypes.shape({total: PropTypes.number}).isRequired,
      triggeredNotificationsSent: PropTypes.shape({total: PropTypes.number}).isRequired,
      wallsCreated: PropTypes.shape({total: PropTypes.number}).isRequired,
    }).isRequired,
  }

  static defaultProps = {
    debug: false,
    defaultThemeProps: {
      textColor: "black",
    },
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  render = () => {
    const {gallerySlug, header, data, debug} = this.props
    if (!data) {
      return <div />
    }
    const {artworksLiked, artistsLiked, firstScans, scans, triggeredNotificationsSent, wallsCreated, userArtworksAdded, contentSharedGallery, contentSharedArtist, contentSharedArtwork, contentSharedWall} = data
    const d = {debug: debug || CGalleryStatsMonth.defaultProps.debug}
    return (
      <Container {...d}>
        <Header>{header}</Header>

        <SingleStatContainer>
          <StatHeader>First Visits: 👤{firstScans.total}</StatHeader>
        </SingleStatContainer>

        <SingleStatContainer>
          <StatHeader>Total Visits: 👤{scans.total}</StatHeader>
        </SingleStatContainer>

        <SingleStatContainer>
          <StatHeader>Created User Artworks: {!!userArtworksAdded ? userArtworksAdded.length : 0}</StatHeader>
        </SingleStatContainer>

        <SingleStatContainer>
          <StatHeader>Triggered Notifications: {triggeredNotificationsSent.total}</StatHeader>
        </SingleStatContainer>

        <SingleStatContainer>
          <StatHeader>Most Liked Artworks:</StatHeader>
          {(!artworksLiked || !artworksLiked.length) && <StatValue>None.</StatValue>}
          {!!artworksLiked &&
            !!artworksLiked.length &&
            artworksLiked.map(artwork => (
              <CLink
                //
                key={`artwork_${artwork.slug}`}
                to={`/g/${gallerySlug}/p/${artwork.slug}`}
              >
                <StatValue>
                  <strong>{artwork.title}</strong> (👤{artwork.total})
                </StatValue>
              </CLink>
            ))}
        </SingleStatContainer>

        <SingleStatContainer>
          <StatHeader>Most Liked Artists:</StatHeader>
          {(!artistsLiked || !artistsLiked.length) && <StatValue>None.</StatValue>}
          {!!artistsLiked &&
            !!artistsLiked.length &&
            artistsLiked.map(artist => (
              <StatValue key={`artwork_${artist.slug}`}>
                <strong>{artist.title}</strong> (👤{artist.total})
              </StatValue>
            ))}
        </SingleStatContainer>

        <SingleStatContainer>
          <StatHeader>Created Walls: {wallsCreated.total}</StatHeader>
        </SingleStatContainer>

        <SingleStatContainer>
          <StatHeader>Profile Shares: {contentSharedGallery.length}</StatHeader>
        </SingleStatContainer>

        <SingleStatContainer>
          <StatHeader>Artist Shares: {contentSharedArtist.length}</StatHeader>
        </SingleStatContainer>

        <SingleStatContainer>
          <StatHeader>Artwork Shares: {contentSharedArtwork.length}</StatHeader>
        </SingleStatContainer>

        <SingleStatContainer>
          <StatHeader>Wall Shares: {contentSharedWall.length}</StatHeader>
        </SingleStatContainer>

        {false && <pre>{JSON.stringify(data, null, 2)}</pre>}
      </Container>
    )
  }
}

const Container = styled.div`
  ${props =>
    props.debug &&
    css`
      border: 1px solid blue;
    `}
`

const Header = styled.div`
  margin-top: 40px;
  font-size: 20px;
  font-weight: ${props => props.theme.vars.fonts.weights.bold};
  text-align: center;
  padding-bottom: 10px;
  text-transform: uppercase;
  letter-spacing: 0.15em;
  font-kerning: none;
`

const SingleStatContainer = styled.div`
  margin: 5px 0px 10px 0px;
  font-size: 11px;
  text-align: center;
  letter-spacing: 0.1em;
`

const StatHeader = styled.div`
  font-size: 16px;
  font-weight: ${props => props.theme.vars.fonts.weights.medium};
`

const StatValue = styled.div`
  padding-left: 10px;
`

export default CGalleryStatsMonth
