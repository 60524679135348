import * as actionsConsts from "../actionsConsts"

export const initialState = {
  waypointThemeKey: null,
  alertShown: false,
  alerts: [],
  videoOverlay: null,
  lightbox: null,
  referrerCode: null, // set by URL param "refc", e.g. https://iazzu.com?refc=vkms
  referrerCodeData: null,
  referrerCodePopupShownOnce: false,
  referrerCodePopup: null,
  embeddedMode: false,
  imageOverlay: null,
}

export function session(state = initialState, action) {
  switch (action.type) {
    case actionsConsts.SET_WAYPOINT_THEME_KEY: {
      return {...state, waypointThemeKey: action.themeKey}
    }
    case actionsConsts.SHOW_ALERT: {
      const {data} = action
      return {
        ...state,
        alertShown: true,
        alerts: [...state.alerts, {shown: new Date().getTime(), data}],
      }
    }
    case actionsConsts.SHOW_VIDEO_OVERLAY: {
      const {header, youtubeUrl} = action
      return {
        ...state,
        videoOverlay: {
          shown: true,
          header,
          youtubeUrl,
        },
      }
    }
    case actionsConsts.HIDE_VIDEO_OVERLAY: {
      return {
        ...state,
        videoOverlay: {
          ...state.videoOverlay,
          shown: false,
        },
      }
    }
    case actionsConsts.SHOW_IMAGE_OVERLAY: {
      return {
        ...state,
        imageOverlay: {
          shown: true,
          image: action.image,
        },
      }
    }
    case actionsConsts.HIDE_IMAGE_OVERLAY: {
      return {
        ...state,
        imageOverlay: {
          ...state.imageOverlay,
          shown: false,
        },
      }
    }
    case actionsConsts.SHOW_LIGHTBOX: {
      const {wpImages} = action
      return {
        ...state,
        lightbox: {
          shown: true,
          wpImages,
        },
      }
    }
    case actionsConsts.HIDE_LIGHTBOX: {
      return {
        ...state,
        lightbox: {
          ...state.lightbox,
          shown: false,
        },
      }
    }
    // Referrer Code
    case actionsConsts.SET_REFERRER_CODE: {
      return {
        ...state,
        referrerCode: action.code,
        referrerCodeData: action.codeData,
      }
    }
    case actionsConsts.SHOW_REFERRER_CODE_POPUP: {
      return {
        ...state,
        referrerCodePopupShownOnce: true,
        referrerCodePopup: {
          shown: true,
        },
      }
    }
    case actionsConsts.CLOSE_REFERRER_CODE_POPUP: {
      return {
        ...state,
        referrerCodePopupShownOnce: true,
        referrerCodePopup: {
          shown: false,
        },
      }
    }
    case actionsConsts.SET_EMBEDDED_MODE_ACTIVE: {
      return {
        ...state,
        embeddedMode: action.active,
      }
    }
    default:
      return state
  }
}
