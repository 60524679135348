import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import styled, {css} from "styled-components"
import {Parallax} from "react-scroll-parallax"

import * as selectors from "../../../data/selectors"

import CButton from "../../../view/components/CButton"
import CSpacer from "../../../view/components/CSpacer"
import CImageFaded from "../../../view/components/CImageFaded"

import CHomeSubheader from "../../../view/components/text/CHomeSubheader"
import CH2 from "../../../view/components/text/CH2"

const DEBUG = false && __DEV__
import {debbify} from "../../../data/selectors/helpers"
const debby = (...args) => debbify("CHomeRightAngle", ...args)

class CHomeRightAngle extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    header: PropTypes.string,
    subheader: PropTypes.string,
    button: PropTypes.string,
    fadedImages: PropTypes.array,
  }
  static defaultProps = {
    debug: DEBUG,
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  render = () => {
    const {header, subheader, button, fadedImages} = this.props
    const d = {debug: DEBUG || this.props.debug}
    const parallaxRatio = 0.1
    debby("render()", {fadedImages})
    return (
      <PaddedContainer {...d} variant={"content"} {...d}>
        <ContentImage {...d}>
          <ImageFadedResponsive {...d}>
            <Parallax y={[`-${parallaxRatio * 100}%`, `${parallaxRatio * 100}%`]} styleOuter={{display: "flex", width: "100%", height: "100%"}} styleInner={{width: "100%"}}>
              <CImageFaded
                //  params
                {...d}
                sectionKey={"homeRightAngle"}
                sourcesWp={fadedImages}
                baseTransform={`scale(1.5) translate(-1%,0%)`}
                // extraTransforms={[`scale(1.1) translate(-3%,5%)`]} // depending on layout
              />
            </Parallax>
          </ImageFadedResponsive>
        </ContentImage>
        <ContentText {...d}>
          <ContentTextPadded {...d}>
            <CH2 {...d} text={header} />
            <CSpacer {...d} variant={"herosections"} />
            <CHomeSubheader {...d} text={subheader} />
            <CSpacer {...d} variant={"herosections"} />
            <CButton label={button} linkTo={"/portfolio"} />
          </ContentTextPadded>
        </ContentText>
      </PaddedContainer>
    )
  }
}

const PaddedContainer = styled.div`
  display: flex;
  align-items: center;
  ${props => selectors.getPaddingLeftRightByThemeAndVariant(props.theme, props.variant)}

  min-height: 100vh;
  flex-direction: row; // Desktop: 2 Cols
  ${props => css`
    ${props.theme.media.smdown} {
      min-height: 0;
      flex-direction: column;
      padding-top: 40px;
    }
  `}

  ${props => selectors.getDebugOverlayCss(props, "PaddedContainer", "rgba(255,0,255,0.25)")}
`

const ContentText = styled.div`
  padding: 40px 0px;
  z-index: 1;

  flex: 1;
  ${props => css`
    ${props.theme.media.smdown} {
      flex: 0;
      height: auto;
      background-color: rgba(255, 255, 255, 0.5);
    }
  `}

  ${props => selectors.getDebugOverlayCss(props, "ContentText", "rgba(255,255,0,0.25)")}
`

const ContentTextPadded = styled.div`
  padding-left: 80px;
  ${props => css`
    ${props.theme.media.md} {
      padding-left: 30px;
    }
    ${props.theme.media.smdown} {
      padding-left: 0px;
    }
  `}

  ${props => selectors.getDebugOverlayCss(props, "ContentTextPadded", "rgba(255,255,0,0.25)")}
`

const ContentImage = styled.div`
  align-self: stretch;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  flex: 1;
  ${props => css`
    ${props.theme.media.smdown} {
      flex: 0;
      height: auto;
    }
  `}
  ${props => selectors.getDebugOverlayCss(props, "ContentImage", "rgba(0,255,255,0.25)")}
`

const ImageFadedResponsive = styled.div`
  width: 100%;
  padding: 80px 0px;
  ${props => css`
    ${props.theme.media.sm} {
      width: 50%;
      padding: 20px 0px;
    }
    ${props.theme.media.xs} {
      width: 60%;
      padding: 20px 0px;
    }
  `}
  ${props => selectors.getDebugOverlayCss(props, "ImageFadedResponsive", "rgba(255,0,0,0.35)")}
`

export default CHomeRightAngle
