import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import {injectIntl} from "react-intl"
import {connect} from "react-redux"
import {createSelector} from "reselect" // eslint-disable-line
import styled, {css, ThemeProvider} from "styled-components" // eslint-disable-line
import {withRouter} from "react-router"

import {LogoBlack} from "../../images"

import * as selectors from "../../data/selectors"

import CLink from "../../view/components/CLink"
import CEmbeddedShareIcons from "../../view/screens/SGalleryLanding/CEmbeddedShareIcons"

const DEBUG = false && __DEV__

class CHeaderEmbeddedMode extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    locationPathname: PropTypes.string.isRequired,
    shareButtonLabel: PropTypes.string.isRequired,
    galleryTitle: PropTypes.string,
  }
  static defaultProps = {
    debug: DEBUG,
  }

  constructor(props) {
    super(props)
    this.state = {
      shareIconsShown: true && __DEV__,
    }
    this.handleProps(this.props, true)
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  handleProps = (nextProps, initial = false) => {
    if (initial || nextProps.locationPathname != this.props.locationPathname) {
      // grab SEO infos
    }
  }

  getTheme = () => ({
    textColorInactive: "#888",
    textColorActive: "black",
  })

  toggleIconsShown = () => this.setState({shareIconsShown: !this.state.shareIconsShown})

  render = () => {
    const {shareIconsShown} = this.state
    const {debug, locationPathname, seoData, shareButtonLabel, embeddedMode, galleryTitle} = this.props // eslint-disable-line
    const d = {debug: debug || CHeaderEmbeddedMode.defaultProps.debug}
    const theme = this.getTheme() // text & border colors
    return (
      <div style={{position: "relative"}}>
        <ThemeProvider theme={theme}>
          <Container
            //
            {...d}
            paddingVariant={"header"}>
            <ContContent {...d}>
              {!embeddedMode && (
                <LogoContainer {...d}>
                  <CLink to={"/"} openInNewWindow={embeddedMode}>
                    <LogoImage src={LogoBlack} />
                  </CLink>
                </LogoContainer>
              )}
              {!!seoData && (
                <ShareContainer>
                  <CEmbeddedShareIcons
                    //
                    {...d}
                    shown={shareIconsShown}
                    seoData={seoData}
                    galleryTitle={galleryTitle}
                  />
                  <ShareButton {...d} onClick={this.toggleIconsShown}>
                    {shareButtonLabel}
                  </ShareButton>
                </ShareContainer>
              )}
            </ContContent>
          </Container>
        </ThemeProvider>
      </div>
    )
  }
}

const Container = styled.div`
  position: fixed;
  z-index: 10; // higher than CFixedSections
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  background-color: white;
  transition: all 0.3s;
  ${selectors.getNoSelectCss()}
  border-bottom: 1px solid #DDD;

  ${props => selectors.getPaddingLeftRightByThemeAndVariant(props.theme, props.paddingVariant)}
  ${props => true && selectors.getBackgroundColorByProps(props, "rgba(255,0,0,0.25)")}

  height: 70px; // see also: CSpacer
  ${props => css`
    ${props.theme.media.mddown} {
      height: 70px;
      display: none; // hide generally
    }
  `}
`

const ContContent = styled.div`
  flex: 1;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  ${props => true && selectors.getDebugOverlayCss(props, "ContContent", "rgba(0,0,255,0.25)")};
  justify-content: center;
`

const LogoContainer = styled.div`
  align-self: stretch;
  display: flex;
  align-items: center;
  ${props => true && selectors.getDebugOverlayCss(props, "LogoContainer", "rgba(0,255,255,0.25)")};
  justify-content: center;
`

const LogoImage = styled.img`
  height: 28px;
  ${props => css`
    ${props.theme.media.mddown} {
      height: 23px;
    }
  `}
`

const ShareContainer = styled.div`
  flex: 1;
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  ${props => true && selectors.getDebugOverlayCss(props, "ShareContainer", "rgba(255,0,255,0.25)")};
  ${props => css`
    ${props.theme.media.mddown} {
      display: none;
    }
  `}
`

const ShareButton = styled.div`
  font-size: 13px;
  font-weight: ${props => props.theme.vars.fonts.weights.default};
  letter-spacing: 0.2em;
  font-kerning: none;
  text-transform: uppercase;
  background-color: ${props => (props.debug ? "green" : "none")};
  padding: 10px;
  margin-right: -10px;
  cursor: pointer;
`

const mapStateToProps = (state, props) => ({
  seoData: selectors.getSeoDataByLocationPathname(state, props), // routes.js
  embeddedMode: state.session.embeddedMode,
})
export default injectIntl(connect(mapStateToProps)(withRouter(CHeaderEmbeddedMode)))
