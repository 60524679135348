/* istanbul ignore file */

import ReactPixel from "react-facebook-pixel"

import {CONFIG} from "../../config"

import {debbify} from "../../data/selectors/helpers"
const debby = (...args) => debbify("FacebookPixelManager", ...args) // eslint-disable-line

const FacebookPixelManager = {
  debug: false && __DEV__,

  init: () => {
    const {pixelId} = CONFIG.facebook
    debby("init()", {pixelId})

    const advancedMatching = {} // optional, more info: https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking#advanced_match
    const options = {
      autoConfig: true, // set pixel's autoConfig
      debug: false, // enable logs
    }
    ReactPixel.init(pixelId, advancedMatching, options)
  },

  pageView: () => {
    debby("pageView()")
    ReactPixel.pageView()
  },
}

export default FacebookPixelManager
