import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import styled, {css} from "styled-components" // eslint-disable-line

import * as selectors from "../../../data/selectors"

import CImage from "../../../view/components/CImage"
import CLink from "../../../view/components/CLink"

import CH3 from "../../../view/components/text/CH3"

const DEBUG = false && __DEV__
import {debbify, getObjectDeep as god} from "../../../data/selectors/helpers"
const debby = (...args) => debbify("COVRFeatured", ...args)

class COVRFeatured extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    intlLocale: PropTypes.string,
    headerText: PropTypes.string,
    gallery: PropTypes.object,
    galleryCountryReadable: PropTypes.string,
    gradientDarkestAlpha: PropTypes.number,
  }
  static defaultProps = {
    debug: DEBUG,
    gradientDarkestAlpha: 0.4,
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  render = () => {
    const {intlLocale, headerText, gallery, galleryCountryReadable, gradientDarkestAlpha} = this.props
    const d = {debug: DEBUG || this.props.debug}
    if (!gallery) {
      return <div />
    }
    const {src, srcSet} = selectors.getSrcAndSrcSetByWordpressImage(god(gallery, "featuredImage"))
    const slug = god(gallery, "slug")
    const title = god(gallery, "title")
    const city = god(gallery, `city.${intlLocale}`)
    const linkTo = `/g/${slug}`
    debby("render()", {headerText, linkTo, srcSet})
    return (
      <PaddedContainer {...d} variant={"header"} {...d}>
        <HeaderContainer {...d}>
          <CH3 text={headerText} noWordWrap />
        </HeaderContainer>
        <CLink to={linkTo}>
          <ImageContainer {...d}>
            <CImage src={src} srcSet={srcSet} ratio={1140 / 560} />
            <ImageOverlayContainer {...d}>
              <GradientTop darkestAlpha={gradientDarkestAlpha} />
              <Content {...d} darkestAlpha={gradientDarkestAlpha}>
                <GalleryTitle {...d}>{title}</GalleryTitle>
                <GalleryLocation {...d}>
                  {!!city ? `${city}, ` : ``}
                  {galleryCountryReadable}
                </GalleryLocation>
              </Content>
            </ImageOverlayContainer>
          </ImageContainer>
        </CLink>
      </PaddedContainer>
    )
  }
}

const PaddedContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${props => selectors.getPaddingLeftRightByThemeAndVariant(props.theme, props.variant)}
  ${props => selectors.getBackgroundColorByProps(props, "rgba(0,0,255,0.25)")} // background-color: ${props => props.theme.vars.colors.westar};
`

const HeaderContainer = styled.div`
  max-width: 900px;
  ${props => selectors.getDebugOverlayCss(props, "HeaderContainer", "rgba(0,255,255,0.35)")};
  padding-top: 50px;
  padding-bottom: 50px;

  ${props => css`
    ${props.theme.media.smdown} {
      padding-top: 30px;
      padding-bottom: 20px;
    }
  `}
`

const ImageContainer = styled.div`
  width: 100%;
  ${props => selectors.getDebugOverlayCss(props, "ImageContainer", "rgba(0,0,255,0.35)")};
`

const ImageOverlayContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
`

const GradientTop = styled.div`
  display: block;
  width: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, ${props => props.darkestAlpha}) 0%, rgba(0, 0, 0, 0) 100%);

  height: 200px;
  ${props => css`
    ${props.theme.media.smdown} {
      height: 100px;
    }
  `}
`

const Content = styled.div`
  background-color: rgba(0, 0, 0, ${props => props.darkestAlpha});
  color: white;
  padding: 50px 30px;
  ${props => css`
    ${props.theme.media.smdown} {
      padding: 15px 15px;
    }
  `}
`

const GalleryTitle = styled.div`
  letter-spacing: 0.07em;
  font-weight: ${props => props.theme.vars.fonts.weights.bold};
  font-size: 30px;
  ${props => css`
    ${props.theme.media.smdown} {
      font-size: 16px;
    }
  `}
`

const GalleryLocation = styled.div`
  letter-spacing: 0.07em;
  padding-top: 8px;
  font-size: 20px;
  ${props => css`
    ${props.theme.media.smdown} {
      padding-top: 4px;
      font-size: 14px;
    }
  `}
`

export default COVRFeatured
