/* istanbul ignore file */

import {applyMiddleware, combineReducers, createStore, compose} from "redux"
import thunkMiddleware from "redux-thunk"
import {connectRouter, routerMiddleware} from "connected-react-router"
import {addLocaleData} from "react-intl" // eslint-disable-line
import {intlReducer} from "react-intl-redux"
import storage from "redux-persist/lib/storage"
import {persistStore, persistReducer, getStoredState} from "redux-persist"

import {langs, getLangDynamic} from "../lang/langsDynamic"

import ReactotronManager from "./utils/ReactotronManager"
import ReduxLoggerManager from "./utils/ReduxLoggerManager"
import HelperPlatform from "./utils/HelperPlatform"

import {initialState as initialStateApi} from "./reducers/RAPI"
import {initialState as initialStateDevice} from "./reducers/RDevice"
import {initialState as initialStateRoutes} from "./reducers/RRoutes"

import * as reducers from "./reducers"
import * as selectors from "./selectors"

import {CONFIG} from "../config"

import {debbify} from "./selectors/helpers"
const debby = (...args) => debbify("store", ...args)

let globalStore = null
let persistor = null

export const saveStore = store => {
  globalStore = store
}
export const getStore = () => {
  return globalStore
}

export const createAndPersistStore = async (options = {}) => {
  const locale = !!options && options.hasOwnProperty("locale") ? options.locale : "en"
  const history = options.history

  let reducersInit = {
    ...reducers, // all from data/reducers/index.js
    router: connectRouter(history),
    intl: intlReducer,
  }

  let middleware = [thunkMiddleware]

  const {storePersistKey} = CONFIG.redux
  const lastPersistKey = await storage.getItem(`iazzu_v3_lastPersistKey`) // eslint-disable-line
  let purge = lastPersistKey != storePersistKey
  if (purge) {
    await localStorage.clear()
  }
  await storage.setItem(`iazzu_v3_lastPersistKey`, storePersistKey)

  const createLogger = await ReduxLoggerManager.getCreateLoggerFunction()
  if (!!createLogger) {
    middleware.push(
      createLogger({
        predicate: (getState, action) => true, // eslint-disable-line
        ...CONFIG.redux.loggerProps,
      }),
    )
  }

  if (!!history) {
    middleware.push(routerMiddleware(history))
  }

  let deviceLocaleUsable = locale
  if (langs.indexOf(deviceLocaleUsable) == -1) {
    deviceLocaleUsable = "en"
  }

  if (!!window.langPath) {
    deviceLocaleUsable = window.langPath // from index.php
    debby("Language from index.php injection: " + window.langPath)
  }

  HelperPlatform.setDocumentLang(deviceLocaleUsable)
  const langData = await getLangDynamic(deviceLocaleUsable)
  const reducer = combineReducers(reducersInit)

  let initialState = {
    intl: {
      defaultLocale: "en",
      locale: deviceLocaleUsable,
      messages: langData.messages,
    },
    device: {
      ...initialStateDevice,
      stageDimensions: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
    },
  }

  debby("createAndPersistStore()", {initialState})

  if (!!window.sitemapDataCachedLastEncoded && !!window.sitemapDataCachedLastEncoded.length) {
    initialState.routes = {
      ...initialStateRoutes,
      sitemap: {
        fetching: false,
        data: window.sitemapDataCachedLastEncoded,
      },
    }
    debby("Sitemap entries from index.php injection: " + window.sitemapDataCachedLastEncoded.length)
    debby("New initialState: ", initialState)
  }

  if (!!window.postDataPath && !!window.pathPropsInitEncoded) {
    const postDataPath = window.postDataPath
    const pathPageTemplate = selectors.getObjectDeep(window.pathPropsInitEncoded, "pathPageTemplate")
    const postData = selectors.getObjectDeep(window.pathPropsInitEncoded, "pathData")
    debby(`Postdata for '${postDataPath}' (template: '${pathPageTemplate}') from index.php injection: `, postData)
    if (!!postData) {
      initialState.api = {
        ...initialStateApi,
        postData: {
          [postDataPath]: {
            busy: false,
            pageTemplate: pathPageTemplate,
            data: postData,
          },
        },
      }
    }
    debby("New initialState: ", initialState)
  }

  // inject postData here

  const persistConfig = {
    key: storePersistKey,
    storage,
    // blacklist: ["intl", "network"],
    whitelist: CONFIG.redux.reducers.whitelist,
  }

  let persistedReducer = persistReducer(persistConfig, reducer, initialState)

  let store = null
  await ReactotronManager.init()
  if (ReactotronManager.useReactotron()) {
    store = createStore(persistedReducer, initialState, compose(applyMiddleware(...middleware), ReactotronManager.createEnhancer()))
  } else {
    store = createStore(persistedReducer, initialState, compose(applyMiddleware(...middleware)))
  }

  saveStore(store)

  const USE_MANUAL_PERSIST = false // MUST BE FALSE

  if (!USE_MANUAL_PERSIST) {
    persistor = persistStore(store, {manualPersist: USE_MANUAL_PERSIST})
    await ReactotronManager.connect()
    return {store, persistor}
  } else {
    persistor = persistStore(store, {manualPersist: USE_MANUAL_PERSIST})
    persistor.pause()

    if (!purge) {
      // restore
      const restoredState = await getStoredState(persistConfig)
      await persistor.purge()
      debby("getStoredState ()", {restoredState})
      debugger
      store.dispatch({
        type: "persist/REHYDRATE",
        payload: restoredState,
      })
      persistor.persist()
      await ReactotronManager.connect()
      return {store, persistor}
    } else {
      await persistor.flush()
      persistor.persist()
      await ReactotronManager.connect()
      return {store, persistor}
    }
  }
}
