import * as Sentry from "@sentry/browser"
import md5 from "md5"
// const debby = (funcName, data = null) => console.log("BugReportManager." + funcName, data) // eslint-disable-line

let messagesSentOnceHashes = []
let userContext = {}

const BugReportManager = {
  use() {
    // const use = true || !__DEV__ // eslint-disable-line
    const use = !__DEV__
    return use
  },

  init() {
    if (!BugReportManager.use()) {
      return
    }
    // debby("init()")
    Sentry.init({dsn: "https://4de8a0982ff1453ca5f5b0ad5af5e035@sentry.io/1774163"})
  },

  setUserContext(options) {
    if (!BugReportManager.use()) {
      return
    }
    userContext = {...userContext, ...options}
    // debby("setUserContext()", options)
    Sentry.setUser(userContext)
  },

  clearUserContext() {
    Sentry.setUser({})
  },

  setTagsContext(tags = {}) {
    if (!BugReportManager.use()) {
      return
    }
    // debby("setTagsContext()", {tags})
    Sentry.setExtra(tags)
  },

  captureMessage(messageParam, data = {}) {
    if (!BugReportManager.use()) {
      return
    }
    __DEV__ && console.warn(messageParam)
    const dataString = Object.keys(data).length ? `: ${JSON.stringify(data)}` : ``
    const message = messageParam + dataString
    if (!message) {
      this.captureMessage("Param 'message' not set.")
      return
    }
    Sentry.captureMessage(message, Sentry.Severity.Warning)
  },

  sendMessageOncePerSession(message) {
    if (!BugReportManager.use()) {
      return
    }
    const messageHash = md5(message + Sentry.Severity.Warning)
    if (messagesSentOnceHashes.indexOf(messageHash) != -1) {
      return
    }
    messagesSentOnceHashes.push(messageHash)
    // debby("sendMessageOncePerSession()", {message, Sentry.Severity.Warning})

    setTimeout(() => {
      this.captureMessage(message, Sentry.Severity.Warning)
    }, 3000)
  },

  sendException(message, data = {}) {
    // Default SentrySeverity.Error
    if (__DEV__) {
      console.error("BugReportManager.sendException(): " + message)
    }
    if (!BugReportManager.use()) {
      return
    }
    if (!message) {
      this.captureMessage("Param 'message' not set.")
      return
    }
    // debby("sendException()", {message, data})
    Sentry.captureException(new Error(message), data)
  },
  captureException(message, data = {}) {
    this.sendException(message, data)
  },
  /*
  handleError (error) {
    if (!error) {
      return
    }
    console.error (error)
    if (!BugReportManager.use()) { return }
    this.sendException (JSON.stringify (error))
  },
  */

  captureBreadcrumb(category = "general", message = "message", data = {}) {
    if (!BugReportManager.use()) {
      return
    }
    // debby("captureBreadcrumb()", {category, message, data})
    Sentry.addBreadcrumb({category, message, data})
  },
}

export default BugReportManager
