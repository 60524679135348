import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import {connect} from "react-redux"
import {withController} from "react-scroll-parallax"
import styled, {ThemeProvider} from "styled-components"

import {CONFIG} from "../../../config"

import * as actions from "../../../data/actions"
import * as selectors from "../../../data/selectors"
import withScreenProps from "../../../data/hocs/withScreenProps" // {postData, pageTemplate, ..}

import CWaypointProvider from "../../../view/components/CWaypointProvider"
import CWaypoint from "../../../view/components/CWaypoint"
import CFixedSections from "../../../view/components/CFixedSections"
import CImage from "../../../view/components/CImage"
import CSpacer from "../../../view/components/CSpacer"
import CFooter from "../../../view/components/CFooter"
import CEditInWordPress from "../../../view/components/CEditInWordPress"
import CGetInTouch from "../../../view/components/CGetInTouch"
import {CHomeBenefits, CHomeHeroOverlay, CHomeBrandMap, CHomeRightAngle, CHomePartners, CHomeTailorMade, CHomeTutorials, CHomeWhitepaper, CHomeDemo} from "./components"

const DEBUG = false && __DEV__
import {debbify} from "../../../data/selectors/helpers"
const debby = (...args) => debbify("SHome", ...args)

const god = selectors.getObjectDeep

class SHome extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    parallaxController: PropTypes.object.isRequired,

    stageHeight: PropTypes.number,
    sitemapData: PropTypes.array,
    fixedSectionsItems: PropTypes.array,
    defaultThemeProps: PropTypes.object,

    //  withScreenProps
    dispatch: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    intlLocale: PropTypes.string.isRequired,
    themeKey: PropTypes.string,
    postData: PropTypes.object,
    wordPressUserId: PropTypes.number,
    editInWordPressProps: PropTypes.object,
    pageTemplate: PropTypes.string,
    mediaQueryClass: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]).isRequired,
  }
  static defaultProps = {
    debug: DEBUG,
    defaultThemeProps: {
      backgroundColor: "white",
      textColor: CONFIG.layout.colors.codGray,
    },
  }

  constructor(props) {
    super(props)
    this.state = {
      activeFixedSectionsItemKey: null,
    }
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  onButtonShowIntroVideoClick = () => {
    const {dispatch, postData} = this.props
    const videoYoutubeUrl = god(postData, "sectionTop.videoYoutubeUrl")
    const videoLightboxHeader = god(postData, "sectionTop.videoLightboxHeader")
    if (!!videoYoutubeUrl) {
      dispatch(actions.showVideoOverlay(videoLightboxHeader, videoYoutubeUrl))
    }
  }

  onTutorialsVideoClick = video => this.props.dispatch(actions.showVideoOverlay(video.label, video.youtubeUrl))

  onLinkPress = url => this.props.dispatch(actions.handleUrl(url))

  onProviderWaypointChange = (id, themeKey, extraData) => {
    const sectionKey = god(extraData, "sectionKey")
    debby(`onProviderWaypointChange(${id}, ${themeKey})`, {sectionKey})
    this.setState({activeFixedSectionsItemKey: sectionKey})
    this.props.themeKey != themeKey && this.props.dispatch(actions.setWaypointThemeKey(themeKey))
  }

  render = () => {
    const {activeFixedSectionsItemKey} = this.state
    const {defaultThemeProps, intl, intlLocale, sitemapData, postData, fixedSectionsItems, stageHeight, themeKey, parallaxController, wordPressUserId, editInWordPressProps, pageTemplate, mediaQueryClass} = this.props
    const d = {debug: DEBUG || this.props.debug}
    const wpOffsets = {bottomOffset: stageHeight * 0.4, topOffset: stageHeight * 0.4}
    const tocSmallprintHtml = selectors.getTocSmallprintHtml(intl, intlLocale, sitemapData)
    const privacySmallprintHtml = selectors.getPrivacySmallprintHtml(intl, intlLocale, sitemapData)
    let themeOverrides = {}
    if (themeKey === "dark") {
      themeOverrides = {backgroundColor: CONFIG.layout.colors.codGray, textColor: "white"}
      // debugger
    }
    const heroImageSrcData = selectors.getSrcAndSrcSetByWordpressImage(god(postData, "sectionTop.heroImage"))
    debby("render()", {postData})
    return (
      <ThemeProvider theme={{...defaultThemeProps, ...themeOverrides}}>
        <Container {...d}>
          <CWaypointProvider onWaypointChange={this.onProviderWaypointChange}>
            <CWaypoint id={`hero`} {...wpOffsets} themeKey={"white"} extraData={{sectionKey: null}}>
              <CImage
                {...d}
                src={heroImageSrcData.src}
                srcSet={heroImageSrcData.srcSet}
                opacity={0.5}
                parallaxRatio={0.1}
                heightGrows // requires children to be position: relative
                parallaxController={parallaxController}
              >
                <CHomeHeroOverlay
                  //
                  {...d}
                  relative // for heightGrows
                  showLia2020
                  fadedImages={god(postData, "sectionTop.fadedImages")} // TODO: Write tests
                  header={god(postData, "sectionTop.labelHeader")}
                  subheader={god(postData, "sectionTop.labelSubheader")}
                  button={god(postData, "sectionTop.buttonVideoLabel")}
                  onButtonClick={this.onButtonShowIntroVideoClick}
                />
              </CImage>
            </CWaypoint>

            <CSpacer {...d} variant={`home-hero-bottom`} />

            <CWaypoint id={`partners`}>
              <CHomePartners
                //
                {...d}
                header={god(postData, "sectionPartners.labelHeader")}
                partners={god(postData, `sectionPartners.partners`)}
                mediaQueryClass={mediaQueryClass}
              />
            </CWaypoint>

            <CWaypoint id={`tailor-made-app`} {...wpOffsets} themeKey={"white"} extraData={{sectionKey: "overview"}}>
              <CHomeTailorMade
                //
                {...d}
                themeKey={themeKey}
                fadedImages={god(postData, "sectionTailormade.fadedImages")} // TODO: Write tests
                header={god(postData, "sectionTailormade.labelHeader")}
                subheader={god(postData, "sectionTailormade.labelSubheader")}
                button={intl.formatMessage({id: `ButtonDiscoverMoreLabel`})}
              />
            </CWaypoint>

            <CWaypoint id={`right-angle`} {...wpOffsets} themeKey={"white"} extraData={{sectionKey: "overview"}} scrollElementName={`overview`}>
              <CHomeRightAngle
                //
                {...d}
                fadedImages={god(postData, "sectionRightAngle.fadedImages")} // TODO: Write tests
                header={god(postData, "sectionRightAngle.labelHeader")}
                subheader={god(postData, "sectionRightAngle.labelSubheader")}
                button={intl.formatMessage({id: `ButtonDiscoverMoreLabel`})}
              />
            </CWaypoint>

            <CWaypoint id={`brand-map`} {...wpOffsets} themeKey={"white"} extraData={{sectionKey: "overview"}}>
              <CHomeBrandMap
                //
                {...d}
                fadedImages={god(postData, "sectionBrandMap.fadedImages")} // TODO: Write tests
                header={god(postData, "sectionBrandMap.labelHeader")}
                subheader={god(postData, "sectionBrandMap.labelSubheader")}
                button={intl.formatMessage({id: `ButtonDiscoverMoreLabel`})}
                themeKey={themeKey}
              />
            </CWaypoint>

            <CWaypoint id={`benefits`} {...wpOffsets} themeKey={"dark"} extraData={{sectionKey: "benefits"}} scrollElementName={`benefits`}>
              <CHomeBenefits
                //
                {...d}
                themeKey={themeKey}
                header={god(postData, `sectionBenefits.labelHeader`)}
                commissionHeader={god(postData, `sectionBenefits.commissionHeader`)}
                commissionLabel={god(postData, `sectionBenefits.commissionLabel`)}
                augmentedRealityHeader={god(postData, `sectionBenefits.augmentedRealityHeader`)}
                augmentedRealityLabel={god(postData, `sectionBenefits.augmentedRealityLabel`)}
                interactionHeader={god(postData, `sectionBenefits.interactionHeader`)}
                interactionLabel={god(postData, `sectionBenefits.interactionLabel`)}
                reachabilityHeader={god(postData, `sectionBenefits.reachabilityHeader`)}
                reachabilityLabel={god(postData, `sectionBenefits.reachabilityLabel`)}
              />
            </CWaypoint>

            <CWaypoint id={`tutorials`} {...wpOffsets} themeKey={"white"} extraData={{sectionKey: "tutorials"}} scrollElementName={`tutorials`}>
              <CHomeTutorials
                //
                {...d}
                themeKey={themeKey}
                fadedImages={god(postData, "sectionTutorials.fadedImages")} // TODO: Write tests
                header={god(postData, "sectionTutorials.labelHeader")}
                subheader={god(postData, "sectionTutorials.labelSubheader")}
                videos={god(postData, `sectionTutorials.videos`)}
                button={god(postData, "sectionTutorials.buttonSeeAllLabel")}
                onVideoClick={this.onTutorialsVideoClick}
                buttonUrl={CONFIG.youtube.tutorialsChannelUrl}
              />
            </CWaypoint>

            <CWaypoint id={`whitepaper`} {...wpOffsets} themeKey={"dark"} extraData={{sectionKey: "whitepaper"}} scrollElementName={`whitepaper`}>
              <CHomeWhitepaper
                //
                {...d}
                themeKey={themeKey}
                header={god(postData, "sectionWhitepaper.labelHeader")}
                subheader={god(postData, "sectionWhitepaper.labelSubheader")}
                emailPlaceholder={god(postData, `sectionWhitepaper.emailPlaceholder`, "E-Mail")}
                emailErrorText={intl.formatMessage({id: `FormErrorInvalidEmail`})}
                button={god(postData, `sectionWhitepaper.buttonShowMeHowLabel`)}
                smallprint={tocSmallprintHtml}
              />
            </CWaypoint>

            <CWaypoint id={`demo`} themeKey={"white"} extraData={{sectionKey: "demo"}} scrollElementName={`demo`}>
              <CHomeDemo
                //
                {...d}
                intlLocale={intlLocale}
                header={god(postData, "sectionDemo.labelHeader")}
                subheader={god(postData, "sectionDemo.labelSubheader")}
                button={god(postData, "sectionDemo.buttonLabel")}
                parallaxController={parallaxController}
              />
            </CWaypoint>

            <CWaypoint id={`contact`}>
              <GetInTouchContainer>
                <CGetInTouch
                  //
                  {...d}
                  pageTemplate={pageTemplate}
                  header={god(postData, "sectionGetInTouch.labelHeader")}
                  button={god(postData, "sectionGetInTouch.labelButton")}
                  teamMember={god(postData, "sectionGetInTouch.teamMember")}
                  smallprint={privacySmallprintHtml}
                  onLinkPress={this.onLinkPress}
                />
              </GetInTouchContainer>
            </CWaypoint>

            <CFooter />
          </CWaypointProvider>

          <CEditInWordPress fixed {...editInWordPressProps} />

          <CFixedSections
            //
            items={fixedSectionsItems}
            activeItemKey={activeFixedSectionsItemKey}
            theme={themeKey}
          />

          {d.debug && (
            <div style={{position: "fixed", pointerEvents: "none", fontSize: 9, left: 0, bottom: 0, zIndex: 20000, color: "blue", backgroundColor: "rgba(255,255,255,0.5)", padding: 10}}>
              <div>SHome_web.js</div>
              <div>themeKey: {themeKey}</div>
              <div>
                location: <pre>{JSON.stringify(location, null, 2)}</pre>
              </div>
              <div>wordPressUserId: {wordPressUserId}</div>
              <div>
                editInWordPressProps: <pre>{JSON.stringify(editInWordPressProps, null, 2)}</pre>
              </div>
            </div>
          )}
        </Container>
      </ThemeProvider>
    )
  }
}

const Container = styled.div`
  position: relative;
  overflow-x: hidden; // because of huge images
  background-color: ${props => props.theme.backgroundColor};
  transition: all ${props => props.theme.vars.transitions.themeTransitionDuration}s linear;
`

const GetInTouchContainer = styled.div`
  padding: 80px 0px;
`

const getFixedSectionsItems = () => {
  //  prettier-ignore
  return [
    {key: `overview`,   intlId: `HomeFixedSectionsOverviewLabel`,   scrollElementName: `overview`},
    {key: `benefits`,   intlId: `HomeFixedSectionsBenefitsLabel`,   scrollElementName: `benefits`},
    {key: `tutorials`,  intlId: `HomeFixedSectionsTutorialsLabel`,  scrollElementName: `tutorials`},
    {key: `whitepaper`, intlId: `HomeFixedSectionsWhitepaperLabel`, scrollElementName: `whitepaper`},
    {key: `demo`,       intlId: `HomeFixedSectionsDemoLabel`,       scrollElementName: `demo`},
  ]
}

const getIntl = (state, props) => props.intl // eslint-disable-line

const mapStateToProps = (state, props) => ({
  stageHeight: state.device.stageDimensions.height,
  sitemapData: selectors.getSitemapData(state),
  fixedSectionsItems: getFixedSectionsItems(state, props),
})
export default withController(withScreenProps(connect(mapStateToProps)(SHome)))
