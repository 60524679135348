/* eslint-disable */

import React, {Component} from "react"
import PropTypes from "prop-types"
import {connect} from "react-redux"
import {injectIntl} from "react-intl"
import isEqual from "lodash/isEqual"
import styled, {css} from "styled-components" // eslint-disable-line
import {motion, useViewportScroll, useTransform, AnimatePresence} from "framer-motion" // eslint-disable-line

import * as selectors from "../../data/selectors"

import CIcon from "../../view/components/CIcon"

class CAlert extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    expanded: PropTypes.bool,
    disabled: PropTypes.bool, // unused
    alertShown: PropTypes.bool,
    test: PropTypes.any,
  }
  static defaultProps = {
    debug: true && __DEV__,
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  render = () => {
    const {debug, expanded, disabled, alertShown} = this.props
    const d = {debug: debug || CAlert.defaultProps.debug}
    return (
      <Container
        //  props
        shown={alertShown}
        {...d}>
        overlay content
      </Container>
    )
  }
}

const Container = styled(motion.div)`
  position: fixed;
  z-index: 10000;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: ${props => (props.shown ? "auto" : "none")};
  background-color: ${props => (props.shown ? rgba(0, 0, 0, 0.5) : "rgba(0,0,0,0)")};
  transition: background-color 1s ease-in-out;
`

const mapStateToProps = (state, props) => ({
  alertShown: state.session.alertShown,
})
export default injectIntl(connect(mapStateToProps)(CAlert))
